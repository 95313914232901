import React, { useEffect, useState } from 'react'
import { convert_bytes } from 'src/utils/--deprecated'
import Dropzone from 'src/widgets/--deprecated/upload-file/dropzone-upload-file/dropzone-upload-file'
import Progress from 'src/widgets/--deprecated/upload-file/progress-upload-file/progress-upload-file'
import './upload-file.scss'

interface upload_file_props {
  with_button?: boolean;
  get_file?: (file:File[]) => void;
  on_files_change?: (file:File[]) => void;
  reset_file?:boolean;
}

interface upload_progress_props {
  [key: string]: {
    state: string;
    percentage: number;
  };
}

const Upload: React.FC<upload_file_props> = (props: upload_file_props) => {
  const [files, set_files] = useState<File[]>([])
  const [send_file,set_send_file] = useState(false)
  // const [uploading, set_uploading] = useState(false)
  const [upload_progress, set_upload_progress] = useState<upload_progress_props>({})
  // const [success_full_uploaded, set_success_full_uploaded] = useState(false)

  useEffect(() => {
    props?.on_files_change?.(files)
  }, [files])

  const onFilesAdded = (files_data: (File | null)[]) => {
    let exists = files.filter((val: File) =>
      files_data.some((val2: File | null) => val.name === val2?.name)
    )
    if (exists.length !== 0) {
      exists = []
      alert('Alcuni file sono duplicati INSERT TEXT ')
    } else {
      if (files.length >= 3 || files_data.length > 3) {
        alert('Si possono inserire al massimo 3 allegati INSERT TEXT')
      } else {
        // eslint-disable-next-line prettier/prettier
        set_files((files).concat((files_data as ConcatArray<File>)))
      }
    }
    return
  }

  const formData = new FormData()

  const sendRequest = async (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest()

      req.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const copy = { ...upload_progress }
          copy[file.name] = {
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          }
          set_upload_progress(copy)
        }
      })

      req.upload.addEventListener('load', () => {
        const copy: upload_progress_props = { ...upload_progress }
        copy[file.name] = { state: 'done', percentage: 100 }
        set_upload_progress(copy)
        resolve(req.response)
      })

      req.upload.addEventListener('error', () => {
        alert('Qualcosa è andato storto, la preghiamo di riprovare. INSERT TEXT')
        const copy: upload_progress_props = { ...upload_progress }
        copy[file.name] = { state: 'error', percentage: 0 }
        set_upload_progress(copy)
        reject(req.response)
      })


      formData.append('file', file, file.name)

      // req.open('POST', 'http://localhost:3000')
      // req.send(formData)
    })
  }

  useEffect(() => {
    props?.get_file?.(files)
  }, [send_file])

  useEffect(()=>{
    props.reset_file && set_files([])
  },[props.reset_file])

  const renderProgress = (file: File) => {
    const uploadProgress = upload_progress[file.name]
    // if (uploading && success_full_uploaded) {
    // if (uploading) {
    return (
      <div className="ProgressWrapper">
        <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
      </div>
    )
    // }
  }

  const delete_file_handler = (file_name: string) => {
    set_files(files.filter((item: File) => item.name !== file_name))
    //TO FIX USE SET UPLOAD
    upload_progress[file_name] = {
      state: '',
      percentage: 0,
    }
  }

  return (
    <div className="upload-file">
      <Dropzone with_button={props.with_button} on_file_added={onFilesAdded} disabled={false} />
      {files.map((file: File) => {
        return (
          <div key={file.name}>
            <div className="upload-file-info-row unnamed-character-style-1-00000080">
              <div>
                {file.name} ({convert_bytes(file.size)})
              </div>
              <div className="upload-x-button" onClick={() => delete_file_handler(file.name)} />
            </div>

            {renderProgress(file)}
          </div>
        )
      })}
    </div>
  )
}

export default Upload
