import {
  ax_get_banners,
  ax_get_communications,
  ax_get_faqs,
  ax_get_footer,
  ax_get_privacies,
} from 'src/types'

import axios from 'axios'

import { AUTHORIZATION_BEARER, axios_request, configify, GET, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_CMS || ''

/**
 * CMS client
 */
export const cms_client = axios.create(configify({ base_url: BASE_URL }))
const CONTEXT_ROOT = 'cms'

/**
 * GET privacies
 */
export const get_privacies = (
  requestId: string,
  { access_token }: ax_get_privacies
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/privacies`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * GET banners
 */
export const get_banners = (
  requestId: string,
  { access_token, banner_types }: ax_get_banners
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/banners${
    banner_types ? '?bannerTypes=' + banner_types : ''
  }`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * GET FAQs
 */
export const get_faqs = (requestId: string, { access_token }: ax_get_faqs): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/faqs`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * GET communications
 */
export const get_communications = (
  requestId: string,
  { access_token, ...params }: ax_get_communications
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/communications`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params,
})

/**
 * GET communications registration
 */
export const get_communications_registration = (
  requestId: string,
  { access_token, ...params }: ax_get_communications
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/registration/communications`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params,
})

/**
 * GET footer
 */
export const get_footer = (requestId: string, { access_token }: ax_get_footer): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/footer`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})
