import './select-menu.scss'

import { FC, useEffect, useState } from 'react'
import { Image, Row } from 'react-bootstrap'
import { left_icon } from 'src/assets'
import i18n from 'src/i18n'
import { opt, option_t, select_menu_props } from 'src/types'
import { is_required } from 'src/utils'

export const SelectMenu: FC<select_menu_props> = ({
  id = '',
  formik_schema,
  touched,
  separator = true,
  disabled,
  label,
  error,
  placeholder = i18n.t('commons.select'),
  options,
  default_option,
  classes = '',
  classes_field = 'm-0 pl-3 pr-3 d-flex border-normal justify-content-between bg-ffffff',
  classes_input = 'py-2 font-16 no-border flex-1 w-100',
  classes_label = 'm-0 font-15',
  classes_error = 'm-0 font-14 c-862633',
  classes_icon = 'ml-3 filter-7f7f7f',
  classes_menu = 'c-7f7f7f font-14',
  set_field_value,
  set_field_touched,
}: select_menu_props) => {
  const [expanded, set_expanded] = useState(false)
  const [selected_option, set_selected_option] = useState<opt<option_t>>(default_option)

  //TO CHANGE - UPGRADE - CLICK OUTSIDE
  // const ref = useRef(null)
  // useOutsideClick(ref, () => (expanded ? set_expanded(false) : ''))
  // TO USE
  // ref={ref}

  /**
   * Use Effect
   */
  useEffect(() => {
    set_selected_option(default_option)
  }, [default_option])

  useEffect(() => {
    set_field_value?.(id, selected_option?.value)
    set_field_value?.(`${id}_id`, selected_option?.id)
  }, [selected_option])

  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? 'd-block' : 'd-none'}
          ${label ? '' : 'opacity-0'}
        `}
      >
        {`${label_} `}
      </label>
    )
  }

  const render_head = () => (
    <Row
      className={classes_field}
      onClick={() => set_expanded(!expanded)}
      role={disabled ? '' : 'button'}
    >
      <div
        className={`
          ${classes_input}
          ${selected_option?.value ? '' : 'c-7f7f7f'}
        `}
      >
        {selected_option?.value || placeholder}
      </div>

      <Image
        src={left_icon}
        width={8}
        className={`icon
          ${classes_icon}
          ${expanded && !disabled ? '' : 'rotate'}
        `}
      />
    </Row>
  )

  const render_menu = () => (
    <div className="p-1 mt-2 w-100 position-absolute shadow z-index-style bg-ffffff">
      {options?.map((option, index) => (
        <div key={index} className="child">
          <div
            className={`p-2 hover ${classes_menu}`}
            onClick={() => {
              set_selected_option(option)
              set_expanded(false)
            }}
            role="button"
          >
            {option.value}
          </div>

          {separator && <div className="mx-1 border c-0000001a" />}
        </div>
      ))}
    </div>
  )

  const render_field = () => (
    <div
      className={`position-relative ${touched && error ? 'border-error' : 'border-normal'}`}
      onClick={() => {
        if (expanded) set_field_touched?.(id, true)
      }}
    >
      {/* HEAD */}
      {render_head()}

      {/* MENU */}
      {expanded && !disabled && render_menu()}
    </div>
  )

  const render_error = () => (
    <p
      className={`
        ${classes_error}
        ${classes_error ? 'd-block' : 'd-none'}
        ${touched && error ? '' : 'opacity-0'}
      `}
    >
      {`${error} `}
    </p>
  )

  return (
    <main className={classes}>
      <div className="d-flex flex-column">
        {render_label()}
        {render_field()}
        {render_error()}
      </div>
    </main>
  )
}
