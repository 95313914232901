import axios from 'axios'
import {
  ax_get_policies,
  ax_get_policies_assistance,
  ax_get_policy_detail,
  ax_post_policy,
  ax_put_policy,
} from 'src/types'

import {
  AUTHORIZATION_BEARER,
  axios_request,
  configify,
  GET,
  POST,
  PUT,
  VERSION_V1,
} from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_POLICY || ''
const CONTEXT_ROOT = 'policies'

/**
 * Policies client
 */
export const policies_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * GET policies
 */
export const get_policies = (
  requestId: string,
  { access_token }: ax_get_policies
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * GET policy detail
 */
export const get_policy_detail = (
  requestId: string,
  { access_token, portfolio_id, policy_id, ...params }: ax_get_policy_detail
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/${portfolio_id}/${policy_id?.split('/')[0]}/details`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params,
})

/**
 * POST policy
 */
export const post_policy = (
  requestId: string,
  {
    access_token,
    policy_update: { operationType, portfolio_id, policy_id, ...data },
  }: ax_post_policy
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/operations/${portfolio_id}/${
    policy_id?.split('/')[0]
  }/update`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params: { operationType },
  data,
})

/**
 * PUT verify policy
 */
export const put_policy = (
  requestId: string,
  {
    access_token,
    policy_verify: { portfolio_id, policy_id, operationType, ...data },
  }: ax_put_policy
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/operations/${portfolio_id}/${
    policy_id?.split('/')[0]
  }/verify`,
  method: PUT,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params: { operationType },
  data,
})

/**
 * GET assistance policies
 */
export const get_policies_assistance = (
  requestId: string,
  { access_token }: ax_get_policies_assistance
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/ass`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})
