/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosInstance } from 'axios'
import { axios_request } from 'src/axios/base.axios'
import { MOCKED } from 'src/config'
import { to_error } from 'src/error-management'
import { raw_error, raw_response, response_t } from 'src/types'

interface request_args {
  client: AxiosInstance;
  request: axios_request;
  child: string;
  saga_type?: string;
}

export async function do_request<T>({
  client,
  request,
  child,
  saga_type,
}: request_args): Promise<response_t<T>> {
  const mock_src = request?.url
    ?.slice(1)
    ?.replaceAll('/', '-')
    .replaceAll('?', '__')
    .replaceAll('=', '')
    .replaceAll('&', '-')
  const mock_url = `mocks/${mock_src}.json`

  const req = MOCKED === 'true' ? axios.get(mock_url) : client.request(request)

  return req
    .then((response: raw_response<T> | any) => {
      return { data: response?.data?.[child] || response.data }
    })

    .catch((error: raw_error) => {
      return to_error(error, saga_type)
    })
}
