import { check_token_t, login_token_t, opt, redux_store } from 'src/types'

export const select_logged = (state: redux_store): opt<boolean> => state?.oauth?.logged

export const select_login_token = (state: redux_store): opt<login_token_t> =>
  state?.oauth?.login_token

export const select_already_logged = (state: redux_store): opt<boolean> =>
  state?.oauth?.already_logged

export const select_proclie = (state: redux_store): opt<string> => {
  const login_token = state?.oauth?.login_token
  return (
    login_token?.ar?.pro ||
    login_token?.imp?.pro ||
    login_token?.imp_itas360_dir?.pro ||
    login_token?.imp_itas360_age?.pro
  )
}

export const select_check_token = (state: redux_store): opt<check_token_t> =>
  state?.oauth?.check_token

export const select_sso_otp = (state: redux_store): opt<string> => state?.oauth?.sso_otp
