import './dropzone-upload-file.scss'

import React, { ChangeEvent, DragEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/widgets/--deprecated/button/button'

interface dropzone_props {
  with_button?: boolean;
  disabled: boolean;
  on_file_added: (list: (File | null)[]) => void;
}

const Dropzone: React.FC<dropzone_props> = (props: dropzone_props) => {
  const fileInputRef = React.createRef<HTMLInputElement>()
  const [hightlight, set_hightlight] = useState(false)
  const { t } = useTranslation()

  const openFileDialog = () => {
    if (props.disabled) return
    fileInputRef?.current?.click()
  }

  const fileListToArray = (list: FileList | null) => {
    const array = []
    if (list?.length !== undefined) {
      for (let i = 0; i < list?.length; i++) {
        array.push(list?.item(i))
      }
    }
    return array
  }

  const onFilesAdded = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) return

    const files = event.target.files
    if (props.on_file_added) {
      const array = fileListToArray(files)
      props.on_file_added(array)
    }
  }

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (props.disabled) return
    set_hightlight(true)
  }

  const onDragLeave = () => {
    set_hightlight(false)
  }

  const onDrop = (event: DragEvent) => {
    event.preventDefault()
    if (props.disabled) return
    const files = event?.dataTransfer?.files
    if (props.on_file_added) {
      const array = fileListToArray(files)
      props.on_file_added(array)
    }
    set_hightlight(false)
  }

  return (
    <div>
      {props.with_button ? (
        <div>
          <Button
            name="text_862633_bold"
            padding="0"
            justify_content="start"
            height="0.9rem"
            onClick={openFileDialog}
            type="button"
          >
            {t('support.form.attached_label')}
            <div className="attached-file-icon" />
          </Button>
          <input
            ref={fileInputRef}
            className="FileInput"
            type="file"
            accept={'.jpg,.png,.jpeg,.pdf,.docx'}
            multiple
            onChange={onFilesAdded}
          />
        </div>
      ) : (
        <div
          className={`dropzone ${hightlight ? 'highlight' : ''}`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
          style={{ cursor: props.disabled ? 'default' : 'pointer' }}
        >
          <input
            ref={fileInputRef}
            className="FileInput"
            type="file"
            accept={'.jpg,.png,.jpeg,.pdf,.docx'}
            multiple
            onChange={onFilesAdded}
          />
          <div className="add-file-icon" />

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <div>Trascina e rilascia o</div>
          <div></div>
          <div>i tuoi file DOCX, PDF, immagini</div> */}
            <div style={{ display: 'inline-block' }}>
              <Button name="text_059881" padding="0" uppercase={false} justify_content="center">
                <div>sfoglia</div>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropzone
