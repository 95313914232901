import './date-picker.scss'

import { FC, useEffect, useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import { calendar_icon, left_icon, right_icon } from 'src/assets'
import { date_picker_props } from 'src/types'
import { compare_dates, is_required, is_sm } from 'src/utils'

import { InputText } from '../input-text/input-text'
import { DAYS, MONTHS } from './date-picker.config'

export const DatePicker_: FC<date_picker_props> = ({
  id = '',
  value,
  formik_schema,
  disabled_from,
  disabled_to,
  set_field_value,
  set_field_touched,
  classes,
  ...input_props
}: date_picker_props) => {
  const label_ = is_required(formik_schema, id) ? `${input_props?.label}*` : input_props?.label

  /**
   * Use State
   */
  const [days, set_days] = useState<Date[]>()
  const [expanded_calendar, set_expanded_calendar] = useState(false)
  const [current_date, set_current_date] = useState(new Date())

  const firstday_currmonth = new Date(current_date.getFullYear(), current_date.getMonth(), 1)
  const firstday_nextmonth = new Date(current_date.getFullYear(), current_date.getMonth() + 1, 1)
  const day_of_week = (firstday_currmonth.getDay() + 6) % 7

  /**
   * Use Effect
   */
  useEffect(() => {
    const days_ = []
    for (const day = firstday_currmonth; day < firstday_nextmonth; day.setDate(day.getDate() + 1)) {
      days_.push(new Date(day))
    }
    set_days(days_)
  }, [current_date])

  useEffect(() => {
    on_change_input()
    if ((value || '')?.length >= 10) {
      set_field_touched?.(id, true)
    }
  }, [value])

  /**
   * Support functions
   */
  const goto_month = (previuos?: boolean) => {
    const month_ = current_date.getMonth()
    update_current_date(new Date(current_date.setMonth(previuos ? month_ - 1 : month_ + 1)))
  }

  const update_current_date = (new_date: Date) => {
    if (!isNaN(new_date.getTime())) {
      set_current_date(new_date)
    }
  }

  const update_calendar = () => {
    if (value) {
      const str = value
      const res = str?.replaceAll(' ', '')
      const arr = res?.split('/')
      const day = +arr[0]
      let month = +arr[1] || new Date().getMonth()
      month--
      const year = arr[2]?.length === 4 ? +arr[2] : new Date().getFullYear()
      update_current_date(new Date(year, month, day))
    }
  }

  const update_day = (day: Date) => {
    on_change_date(day?.getDate(), day?.getMonth() + 1, day?.getFullYear())
    is_sm && set_expanded_calendar(false)
  }

  const on_change_input = () => {
    set_field_value?.(id, value)
    update_calendar()
  }

  const on_change_date = (DD: number, MM: number, YYYY: number) => {
    const DD_ = `${DD}`?.length === 1 ? `0${DD}` : `${DD}`
    const MM_ = `${MM}`?.length === 1 ? `0${MM}` : `${MM}`
    set_field_value?.(id, `${DD_}/${MM_}/${YYYY}`)
    update_calendar()
  }

  /**
   * Render functions
   */
  const render_input = () => (
    <InputText
      id={id}
      placeholder="DD / MM / YYYY"
      icon={calendar_icon}
      on_press_icon={() => {
        set_expanded_calendar(!expanded_calendar)
        update_calendar()
      }}
      {...input_props}
    />
  )

  const render_months = () => (
    <div className="p-2 mt-4 d-flex flex-wrap align-items-baseline justify-content-between">
      <Image src={left_icon} onClick={() => goto_month(true)} role="button" />
      <p className="m-0 p-0 font-16-semibold">
        {`${MONTHS?.[current_date?.getMonth()]?.toUpperCase()} ${current_date?.getFullYear()}`}
      </p>
      <Image src={right_icon} onClick={() => goto_month(false)} role="button" />
    </div>
  )

  const render_days = () => (
    <div>
      {/* CALENDAR HEAD */}
      <div className="mt-4 d-flex flex-wrap">
        {DAYS.map((day) => (
          <div key={day} className="calendar-day">
            <small>{day}</small>
          </div>
        ))}
      </div>

      {/* CALENDAR BODY */}
      <div className="d-flex flex-wrap">
        <div style={{ width: `${day_of_week * 14.28}%` }} />
        {days?.map((day) => {
          const highlight = value && compare_dates(day, current_date) === 0

          const disabled_f = compare_dates(day, disabled_from) === 1
          const disabled_t = compare_dates(day, disabled_to) === -1
          const disabled = disabled_f || disabled_t

          const variant = highlight
            ? 'day-selected c-ffffff'
            : disabled
            ? 'day-disabled c-7f7f7f'
            : ''

          return (
            <div key={day?.getTime()} className="mt-2 calendar-day">
              <Button
                onClick={() => update_day(day)}
                className={'p-0 calendar-day-button rounded-circle'}
                variant={variant}
                disabled={disabled}
              >
                {day.getDate()}
              </Button>
            </div>
          )
        })}
      </div>
    </div>
  )

  return (
    <main className={classes}>
      {is_sm || !expanded_calendar ? render_input() : ''}
      {!is_sm && expanded_calendar && <div className="mb-3 font-15">{label_}</div>}
      <div className={`calendar-container ${expanded_calendar ? 'd-block mb-3' : 'd-none'}`}>
        {render_months()}
        {render_days()}
      </div>
    </main>
  )
}
