import { call, put } from 'redux-saga/effects'
import { outcome_w } from 'src/backend-codes'
import { http_send_email } from 'src/http'
import { saga_act } from 'src/types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Send email
 */
export function* saga_send_email(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_send_email, action)
  if (outcome === 'success') {
    yield put(shoot.reduce_outcome_email(outcome))
  }
}
