import { createReducer } from 'reduxsauce'
import { config_store } from 'src/types'

import { config_types } from '../../actions'
import INITIAL_STATE from './initial-state'

export const reduce_access = (state: config_store, { access }: config_store): config_store => ({
  ...state,
  access,
})

export const reduce_onboarding_showed = (
  state: config_store,
  { onboarding_showed }: config_store
): config_store => ({
  ...state,
  onboarding_showed,
})

export const reduce_support_visibility = (
  state: config_store,
  { support_visibility }: config_store
): config_store => ({
  ...state,
  support_visibility,
})

export const reduce_elections_showed = (
  state: config_store,
  { elections_showed }: config_store
): config_store => ({
  ...state,
  elections_showed,
})

export const reduce_policies_renewed = (
  state: config_store,
  { policies_renewed }: config_store
): config_store => ({
  ...state,
  policies_renewed,
})

export const reduce_ar_access = (
  state: config_store,
  { ar_access }: config_store
): config_store => ({
  ...state,
  ar_access,
})

export default createReducer(INITIAL_STATE, {
  [config_types.REDUCE_ACCESS]: reduce_access,
  [config_types.REDUCE_ONBOARDING_SHOWED]: reduce_onboarding_showed,
  [config_types.REDUCE_SUPPORT_VISIBILITY]: reduce_support_visibility,
  [config_types.REDUCE_ELECTIONS_SHOWED]: reduce_elections_showed,
  [config_types.REDUCE_POLICIES_RENEWED]: reduce_policies_renewed,
  [config_types.REDUCE_AR_ACCESS]: reduce_ar_access,
})
