export const WEBAPP_NAME =
  process.env.REACT_APP_WEB_APP_NAME !== undefined ? process.env.REACT_APP_WEB_APP_NAME : ''

export const WEBAPP_VERSION =
  process.env.REACT_APP_VERSION !== undefined ? process.env.REACT_APP_VERSION : ''

export const ITAS_SITE_BASE_URI = process.env.REACT_APP_ITAS_SITE_BASE_URI

export const WEBAPP_ENV = process.env.REACT_APP_ENV !== undefined ? process.env.REACT_APP_ENV : ''

export const MOCKED = process.env.REACT_APP_MOCKED

export const LIFERAY_URL = process.env.REACT_APP_BASE_URL_LIFERAY

export const SITE_KEY = process.env.REACT_APP_SITE_KEY

export const APP_COOKIE = process.env.REACT_APP_APP_COOKIE || ''

export const IMPERSONIFICATION_COOKIE = process.env.REACT_APP_IMPERSONIFICATION_COOKIE || ''

export const IMPERSONIFICATION_I360_DIR_COOKIE =
  process.env.REACT_APP_IMPERSONIFICATION_I360_DIR_COOKIE || ''

export const IMPERSONIFICATION_I360_AGE_COOKIE =
  process.env.REACT_APP_IMPERSONIFICATION_I360_AGE_COOKIE || ''

export const CHUNK_N_COOKIE = process.env.REACT_APP_CHUNK_N_COOKIE || ''
