import { AxiosRequestConfig, Method, ResponseType } from 'axios'
import { get_language } from 'src/utils'

const PROTOCOL = 'http'
const RESPONSE_TYPE: ResponseType = 'json'

export const VERSION_V1 = 'v1'
export const AUTHORIZATION_BASIC = 'Basic aXRhc19hcjppdGFzX2Fy'
export const AUTHORIZATION_BASIC_IMP = 'Basic aXRhc19pbXA6aXRhc19pbXA='
export const AUTHORIZATION_BASIC_IMP_I360_DIR = 'Basic aXRhc19pbXBfMzYwX2RpcjppdGFzX2ltcF8zNjBfZGly'
export const AUTHORIZATION_BASIC_IMP_I360_AGE = 'Basic aXRhc19pbXBfMzYwX2FnZTppdGFzX2ltcF8zNjBfYWdl'
export const AUTHORIZATION_BEARER = 'Bearer '
export const CONTENT_TYPE_JSON = 'application/json'
export const CONTENT_TYPE_FORM = 'application/x-www-form-urlencoded'
export const GET: Method = 'GET'
export const POST: Method = 'POST'
export const PUT: Method = 'PUT'
export const DELETE: Method = 'DELETE'

export interface axios_request extends AxiosRequestConfig {
  url: string;
  method: Method;
  headers?: {
    requestId: string,
    Authorization?: string,
    captcha?: string,
  };
  data?: unknown;
}

interface config {
  base_url: string;
  authorization?: string;
  content_type?: string;
}

interface client_params {
  protocol: string;
  baseURL: string;
  responseType: ResponseType;
  headers: {
    Authorization: string,
    'Content-Type': string,
    'Accept-Language': string,
    client_id: '1b4948f8098842528c803d01defffb54',
    client_secret: '5095991aB1fa49109A6cf64742984Ff6',
  };
}

export const configify = ({
  base_url,
  authorization = AUTHORIZATION_BEARER,
  content_type = CONTENT_TYPE_JSON,
}: config): client_params => ({
  protocol: PROTOCOL,
  responseType: RESPONSE_TYPE,
  headers: {
    Authorization: authorization,
    'Content-Type': content_type,
    'Accept-Language': get_language(),
    client_id: '1b4948f8098842528c803d01defffb54',
    client_secret: '5095991aB1fa49109A6cf64742984Ff6',
  },
  baseURL: base_url,
})
