import { FC } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { not_supported_browser_icon } from 'src/assets'

const NotSupportedBrowser: FC = () => {
  const { t } = useTranslation()

  return (
    <Container className="d-flex flex-column flex-1 justify-content-center">
      <Row className="justify-content-center py-8">
        <Col lg={5} md={8} sm={10}>
          <div className="font-36-freightdisp-semibold text-center pre-line">
            {t('browser_not_supported.title')}
          </div>
          <div className="mt-5 text-center">
            <Image src={not_supported_browser_icon} fluid />
          </div>
          <div className="mt-5 font-18 text-center">{t('browser_not_supported.description')}</div>
        </Col>
      </Row>
    </Container>
  )
}

export default NotSupportedBrowser
