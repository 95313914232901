import axios from 'axios'
import { ax_post_iban, ax_post_payment, ax_post_payment_status } from 'src/types'

import { AUTHORIZATION_BEARER, axios_request, configify, POST, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_PAYMENT || ''
const CONTEXT_ROOT = 'iban'
const PAYMENT_ROOT = 'payments'

/**
 * Payment client
 */
export const payment_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * POST iban
 */
export const post_iban = (
  requestId: string,
  { access_token, proclie, company_code }: ax_post_iban
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: {
    proclie,
    companyCode: company_code,
  },
})

/**
 * POST payment
 */
export const post_payment = (
  requestId: string,
  { access_token, policy_number, policy_order_number }: ax_post_payment
): axios_request => ({
  url: `/${VERSION_V1}/${PAYMENT_ROOT}`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: {
    policyNumber: policy_number,
    policyOrderNumber: policy_order_number,
  },
})

/**
 * POST payment status
 */
export const post_payment_status = (
  requestId: string,
  { access_token, payment_id }: ax_post_payment_status
): axios_request => ({
  url: `/${VERSION_V1}/${PAYMENT_ROOT}/check`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: {
    paymentID: payment_id,
  },
})
