import moment from 'moment'

import { is_valorized } from './safety.utils'

export const capitalize = (s: string): string => {
  return s ? s.charAt(0)?.toUpperCase() + s.slice(1)?.toLocaleLowerCase() : ''
}

export const capitalize_all = (s: string): string => {
  return s
    ? s
        .split(' ')
        .map((word: string) => capitalize(word))
        .join(' ')
    : ''
}

export const fill_placeholders = (text: string, placeholders: string[]): string => {
  let res = text
  placeholders.forEach((placeholder) => {
    res = res.replace('***', placeholder)
  })
  return res
}

export const fill_strong = (text: string, strong_class?: string): JSX.Element => {
  const text_pieces = text.split('<strong>')
  return (
    <span>
      {text_pieces.map((str, idx) => {
        if (idx % 2 === 0) {
          return str
        }
        return (
          <strong key={idx} className={strong_class}>
            {str}
          </strong>
        )
      })}
    </span>
  )
}

export const yesterday = (): Date => {
  const yestarday_ = new Date()
  yestarday_.setDate(yestarday_.getDate() - 1)
  return yestarday_
}

export const compare_dates = (d1?: Date, d2?: Date): number => {
  if (!d1 || !d2 || isNaN(d1?.getTime()) || isNaN(d2?.getTime())) {
    return -2
  }
  if (moment(d1).format('DD/MM/YYYY') === moment(d2).format('DD/MM/YYYY')) {
    return 0
  }
  return d1 < d2 ? -1 : 1
}

export const format_date = (date?: string, from = 'YYYY-MM-DD', to = 'DD/MM/YYYY'): string => {
  return date ? moment(date, from).format(to) : ''
}

export const format_size = (size = 0): string => {
  const pos = Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / Math.pow(1024, pos)).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][pos]}`
}

export const format_euro = (digit?: string | number): string => {
  const result = format_number(digit)
  return result ? `${result}€` : ''
}

export const format_number = (digit?: string | number): string => {
  if (is_valorized(digit)) {
    const digit_str = String(digit)?.split(' ')[0]
    const digit_num = Number(digit_str?.replace(',', '.'))
    return digit_num
      ?.toFixed(2)
      ?.replace('.', ',')
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  return ''
}

export const format_phone = (phone?: string): string => {
  return phone?.includes('/')
    ? phone?.replace('/', '').replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
    : phone?.replace(
        phone?.length > 10 ? /(\+\d{1,2})(\d{3})(\d{4})(\d{3})/ : /(\d{3})(\d{4})(\d{3})/,
        phone?.length > 10 ? '$1 $2 $3 $4' : '$1 $2 $3'
      ) || ''
}

export const format_address = (address?: string): string => {
  return address?.includes('(') && address?.includes(')')
    ? capitalize_all(address.replace((/\(([^)]+)\)/.exec(address) || [])[0], ' ') || '') +
        (/\(([^)]+)\)/.exec(address) || [])[0].toUpperCase()
    : capitalize_all(address || '')
}

export const unescape_html = (html_content: string | undefined): JSX.Element => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html_content || '',
      }}
    />
  )
}

export const parseDate = (dateString: string): Date | undefined => {
  const parts = dateString.split("/");
  if (parts.length !== 3) {
    return undefined;
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  const date = new Date(year, month, day);

  if (
    isNaN(date.getTime()) ||
    date.getDate() !== day ||
    date.getMonth() !== month ||
    date.getFullYear() !== year
  ) {
    return undefined;
  }

  return date;
}
