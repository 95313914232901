import './dropdown.scss'

import React, { FC, useRef, useState } from 'react'
import { Col } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropDownItems, DropDownProps } from 'src/types/--deprecated/types'
import { get_language } from 'src/utils'
import { ClassNames, useOutsideClick } from 'src/utils/--deprecated'

import LanguageSelector from '../header/language-selector/language-selector'

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

interface custom_toggle_props {
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  icon_state: boolean;
  changed_icon_handler: () => void;
  showBackdropToggle?: () => void;
  navbar?: boolean;
}
//Custom Dropdown toggle
const CustomToggle = React.forwardRef((props: custom_toggle_props, ref: any) => {
  return (
    <div
      className={props?.navbar ? 'dropdown_navbar_title' : 'dropdown_character dropdown_title'}
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        props.onClick(e)
        props.changed_icon_handler()
      }}
    >
      {props.children}
      <span
        ref={ref}
        className={
          props?.navbar
            ? `dropdown_navbar_icon ${
                !props.icon_state ? 'dropdown_navbar_icon--up' : 'dropdown_navbar_icon--bottom'
              }`
            : `dropdown_icon ${!props.icon_state ? 'dropdown_icon--up' : 'dropdown_icon--bottom'}`
        }
      />
    </div>
  )
})

//DropDown Component
const DropDownButton: FC<DropDownProps> = ({
  title,
  menuData,
  navbar,
  showBackdrop,
  on_click_item_handler,
}: DropDownProps) => {
  const ref = useRef()

  /**
   * Use State
   */
  const [icon_menu_open, set_icon_menu_open] = useState(true)

  const path = window.location.pathname

  //Click outside
  useOutsideClick(ref, () => {
    icon_menu_open !== true ? set_icon_menu_open(true) : null
  })
  return (
    <div
      onClick={() => {
        showBackdrop ? showBackdrop() : null
      }}
    >
      <Dropdown className="dropdown-container mt-1">
        <Dropdown.Toggle
          ref={ref}
          navbar
          icon_state={icon_menu_open}
          changed_icon_handler={() => set_icon_menu_open(!icon_menu_open)}
          as={CustomToggle}
          id="dropdown-custom-components"
        >
          {navbar ? (
            <div className="dropdown-menu-label">
              <div className="font-12-semibold text-capitalize c-862633 align-middle">
                {title?.toLowerCase()}
              </div>
            </div>
          ) : (
            title
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight={true}
          className={ClassNames(
            get_language() === 'IT' ? 'dropdown-menu' : 'dropdown-menu--de',
            navbar ? 'dropdown-menu-navbar' : ''
          )}
        >
          {menuData?.map((item: DropDownItems, index) => (
            <Dropdown.Item
              key={index}
              onClick={() =>
                item?.id !== 'ar_id' &&
                (item.on_click_item_handler
                  ? item.on_click_item_handler()
                  : on_click_item_handler
                  ? on_click_item_handler(item.id, item.link)
                  : null)
              }
              className="dropdown-container-menu-item"
            >
              {/* VOCE AREA RISERVATA*/}
              {item?.id === 'ar_id' ? (
                <div className="dropdown-menu--item dropdown_character_menu_item">
                  <Col className="m-0 p-0">
                    <div className="d-flex">
                      {item.icon && <div className={`mr-2 ${item.icon}`} />}
                      <div className="font-16-semibold c-000000">{item.text}</div>
                    </div>
                    <LanguageSelector />
                  </Col>
                  <hr className="dropdown-menu--separator w-100" />
                </div>
              ) : (
                <>
                  <div
                    className={`dropdown_character_menu_item dropdown_character_menu_item ${
                      path === item?.link ? 'c-862633' : ''
                    }
                  ${navbar ? 'dropdown-menu-navbar--items' : ''}`}
                  >
                    {item.text}

                    <div className={path === item?.link ? item.icon_brown : item.icon} />
                  </div>
                  {index !== menuData.length - 1 && <hr className="dropdown-menu--separator" />}
                </>
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

CustomToggle.displayName = 'CustomToggle'

export default DropDownButton
