import { useEffect } from 'react'
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { phone_icon } from 'src/assets'
import { shoot } from 'src/redux/actions'
import { select_customer, select_policies_assistance, select_policy } from 'src/redux/store'
import { policy_assistance_t } from 'src/types'
import { format_address, is_md, render_contacts_card, render_topbar } from 'src/utils'

const AssistanceDetail: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  /**
   * URL params
   */
  const policy_number = new URLSearchParams(useLocation().search).get('number')

  /**
   * Use Selector
   */
  const policy = useSelector(select_policy(policy_number || ''))
  const policies_assistance: policy_assistance_t =
    useSelector(select_policies_assistance)?.find((p_a) => p_a.policyNumber === policy_number) || {}
  const customer = useSelector(select_customer)
  const car =
    policy?.category?.toUpperCase() === 'AUTO' ||
    policies_assistance?.category?.toUpperCase() === 'AUTO'

  const policy_: policy_assistance_t = policy ? policy : policies_assistance

  /**
   * Use Effect
   */
  useEffect(() => {
    dispatch(shoot.reduce_history_item('from-assistance-detail', 'true'))
  }, [])

  /**
   * Render functions
   */

  const render_detail_card = () => (
    <div className="col-md-7 col-sm-12 pr-0 d-flex flex-column mt-2">
      <div className="bg-ffffff col-12 p-4">
        <div className="font-18">{t('assistance_detail.card.main_text')}</div>
        <div className="mt-4">
          <div className="row">
            <div className="col-12 col-md-12 font-15-semibold">
              {car ? (
                policy_?.carName
              ) : (
                <>
                  <div className="col-12 m-0 p-0 font-15-semibold d-block d-md-none">
                    {t('commons.address')}
                  </div>
                  <div className="col-12 m-0 p-0 font-15 c-00000080 d-block d-md-none">
                    {format_address(policy_?.addressRe || '')}
                  </div>
                  <div className="col-12 m-0 p-0 font-15-semibold d-none d-md-block">
                    {format_address(policy_?.addressRe || '')}
                  </div>
                </>
              )}
            </div>
            <div className={`col-12 col-md-5 font-15 c-00000080 ${car ? 'd-block' : 'd-none'}`}>
              {policy_?.targaNumber}
            </div>
          </div>
          <div className="row mt-4 mt-md-2">
            <div className="col-12 col-md-7 font-15-semibold">
              {t('assistance_detail.card.policy_number')}
            </div>
            <div className="col-12 col-md-5 font-15 c-00000080 m-0 p-0 pl-3">
              {policy_?.policyNumber}
            </div>
          </div>
          <div className="row mt-4 mt-md-2">
            <div className="col-12 col-md-7 font-15-semibold">{t('assistance_detail.card.cf')}</div>
            <div className="col-12 col-md-5 font-15 c-00000080 m-0 p-0 pl-3">
              {customer?.taxCode}
            </div>
          </div>
          <div className="row mt-4 mt-md-2">
            <div className="col-12 col-md-7 font-15-semibold">
              {t('assistance_detail.card.client_id')}
            </div>
            <div className="col-12 col-md-5 font-15 c-00000080 m-0 p-0 pl-3">
              {customer?.proClie}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Container fluid className="d-flex justify-content-center mb-4">
      <Col lg={11} className="mr-2 align-items-center">
        {/* TOP-BAR */}
        {render_topbar(
          () => history.goBack(),
          t('assistance_detail.back'),
          'transparent_primary_back'
        )}

        <div
          className={`${
            !is_md ? 'font-30-freightdisp-semibold mt-md-4' : 'font-40-freightdisp-semibold mt-md-4'
          }`}
        >{`${policy_?.category} ${policy_?.policyNumber}`}</div>

        <div className="font-12-semibold text-uppercase mt-4">
          {t('assistance_detail.card.title')}
        </div>

        <div className="row d-flex flex-wrap mt-md-4">
          {/* CONTACTS */}
          {render_contacts_card(t, car, phone_icon, 'col-md-5 pr-0')}

          {/* DETAIL */}
          {render_detail_card()}
        </div>
      </Col>
    </Container>
  )
}

export default AssistanceDetail
