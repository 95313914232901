import PropTypes from 'prop-types'
import React from 'react'

import './progress-bar.scss'

export type ProgressBarElementType = {
  kind: 'dot' | 'line',
  state: 'on' | 'off',
}

interface ProgressBarProps {
  elements: Array<ProgressBarElementType>;
}

function createElement(el: { kind: 'dot' | 'line', state: 'on' | 'off' }, i: number) {
  const kind: string = el.kind
  const state: string = el.state
  const key: string = 'key_' + kind + '_' + state + '_' + i
  const className = 'pb-' + kind + '-' + state

  const element = <div key={key} className={className} />

  return element
}

function createElements(params: Array<ProgressBarElementType>) {
  const elements = []

  for (let i = 0; i < params.length; i++) {
    elements.push(createElement(params[i], i))
  }

  return elements
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  return <div className="pb-container">{createElements(props.elements)}</div>
}

ProgressBar.propTypes = {
  elements: PropTypes.array.isRequired,
}

export default ProgressBar
