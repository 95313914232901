import { communication_client, post_email } from 'src/axios'
import { outcome_w } from 'src/backend-codes'
import { ax_post_email, response_t, saga_act } from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Send email
 */
export async function http_send_email({
  args,
  type,
}: saga_act<ax_post_email>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: communication_client,
    request: post_email(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}
