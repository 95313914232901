import { useFormik } from 'formik'
import { FC, useRef } from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { POLICY_COMPANIES } from 'src/backend-codes'
import { shoot } from 'src/redux/actions'
import { select_customer, select_customer_email, select_proclie } from 'src/redux/store'
import { consent_modal_props, consent_t, customer_update_t } from 'src/types'
import { fill_placeholders, fill_strong } from 'src/utils'
import { Accordion, Button, CheckBox, Modal } from 'src/widgets'
import * as Yup from 'yup'

export const ConsentModal: FC<consent_modal_props> = ({
  visible,
  policy,
  operation,
  on_close,
}: consent_modal_props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  /**
   * Use Selector
   */
  const proclie = useSelector(select_proclie)
  const customer = useSelector(select_customer)
  const customer_email = useSelector(select_customer_email)

  /**
   * Formik
   */
  const initial_values = useRef({ consent: false })
  const { handleSubmit, getFieldProps, isSubmitting, isValid } = useFormik({
    initialValues: initial_values.current,
    validateOnMount: true,
    validationSchema: Yup.object({ consent: Yup.bool().oneOf([true]) }),
    onSubmit: () => {
      on_close?.()
      give_consent()
    },
  })

  /**
   * Net functions
   */
  const give_consent = () => {
    const new_consent: consent_t = {
      ...customer?.communication?.consentsTD?.find(
        (consent) => consent?.companyCode === POLICY_COMPANIES(policy)
      ),
      email: customer_email,
      flgValue: 'S',
      companyCode: POLICY_COMPANIES(policy),
    }
    const customer_update: customer_update_t = {
      type: 4,
      proClie: proclie,
      consentsTD: [new_consent].filter((item) => item.companyCode === 'VALPIAVE'),
    }
    dispatch(shoot.saga_update_customer({ customer_update }))
  }

  return (
    <Modal visible={visible} on_close={on_close}>
      <div className="p-4">
        {/* HEADER */}
        <h1 className="font-18-semibold text-uppercase text-center">{operation?.title}</h1>
        <p className="mt-4 font-16 text-center">
          {fill_strong(
            fill_placeholders(t('policy_operations.consent.header'), [
              operation?.name || '',
              policy?.category || '',
              policy?.policyNumber || '',
            ]),
            'font-16-semibold'
          )}
        </p>

        {/* DESCRIPTION */}
        <p className="mt-4 font-16">{t('policy_operations.consent.subtitle')}</p>
        <p className="m-0 p-0 font-16 c-7f7f7f">{t('policy_operations.consent.description')}</p>
        <Accordion
          title={t('commons.read_more')}
          flavor="green"
          classes_head="m-0 p-0 py-3 justify-content-between"
          classes_title="m-0 p-0 font-16 c-159881 text-uppercase"
        >
          <p className="m-0 p-0 py-3 font-16">{t('policy_operations.consent.read_more')}</p>
        </Accordion>

        {/* ACCEPT */}
        <form onSubmit={handleSubmit} className="mt-4">
          <CheckBox
            id="consent"
            label={fill_placeholders(t('policy_operations.consent.accept'), [
              policy?.policyType || '',
            ])}
            formik_props={getFieldProps('consent')}
            classes_label="m-0 p-0 font-16-semibold d-flex align-items-center"
            classes_error=""
          />
          <p className="m-0 p-0 mt-3 font-14 c-7f7f7f">{t('policy_operations.consent.email')}</p>
          <p className="m-0 p-0 font-14 c-159881">{customer_email}</p>
          <Row className="justify-content-center">
            <Button
              flavor="accent_next"
              type="submit"
              text={fill_placeholders(t('policy_operations.consent.confirm'), [
                operation?.name?.replaceAll('<strong>', '') || '',
              ])}
              disabled={!isValid || isSubmitting}
              classes="mt-5"
            />
          </Row>
        </form>
      </div>
    </Modal>
  )
}
