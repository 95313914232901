import './header.scss'

import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { vhv } from 'src/assets'
import { NAV_TO_LOGIN } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import {
  select_customer,
  select_logged,
  select_login_token,
  select_outcome_logout,
} from 'src/redux/store'
import DropDownButton from 'src/widgets/--deprecated/dropdown/dropdown'
import MobileMenu from 'src/widgets/--deprecated/header/mobile-menu/mobile-menu'

import Version from '../version/version'
import LanguageSelector from './language-selector/language-selector'

interface HeaderProps {
  name?: string;
  showBackdrop?: () => void;
  backdrop_open?: boolean;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  /**
   * Use State
   */
  const [mobile_menu_visible, set_mobile_menu_visible] = useState(false)
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 810)

  /**
   * Use Selector
   */
  const customer = useSelector(select_customer)
  const login_token = useSelector(select_login_token)
  const outcome_logout = useSelector(select_outcome_logout)
  const is_logged = useSelector(select_logged)

  const header_data: any = t('header', { returnObjects: true })

  // NO DELETE
  // const onClickBellHandler = () => {
  //   alert('Add link')
  // }

  const show_mobile_menu_handler = () => {
    !mobile_menu_visible
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '')
    return set_mobile_menu_visible(!mobile_menu_visible)
  }

  const dropdown_item_handler = (id: string, link: string) => {
    if (id === 'logout_id') {
      dispatch(shoot.saga_logout({ bearer_token: login_token?.access_token }))
    } else {
      history.push(link)
    }
  }

  /**
   * Use effect
   */
  useEffect(() => {
    if (outcome_logout) {
      dispatch(shoot.reduce_outcome_logout())
      history.push(NAV_TO_LOGIN)
    }
  }, [outcome_logout])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== is_mobile) {
          set_is_mobile(ismobile)
          set_mobile_menu_visible(false)
        }
        mobile_menu_visible
          ? (document.body.style.overflow = 'hidden')
          : (document.body.style.overflow = '')
      },
      false
    )
  }, [is_mobile])

  const desktop_form = (
    <header className="header-navbar">
      <div className="header-logo">
        <a href="https://www.vhv.it/" target="_blank" rel="noreferrer">
          <Image src={vhv} width={130} />
        </a>
      </div>
      <nav className="header-nav">
        {is_logged ? (
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="icon-person row align-items-center c-ffffff mr-2">
                <div className="w-100 text-center text-uppercase">
                  {customer?.personTypeCode === 'F'
                    ? `${customer?.name?.charAt(0)}${customer?.surname?.charAt(0)}`
                    : customer?.businessName?.substring(0, 2)}
                </div>
              </div>
              <div className="row mx-2">
                <div className="w-100 text-capitalize font-12-semibold">
                  {customer?.personTypeCode === 'F' ? customer?.name : customer?.businessName}
                </div>
                <div className="font-12 text-center c-7f7f7f">{`ID ${customer?.proClie}`}</div>
              </div>
            </div>

            <div
              className={`
          row pb-1 ${props?.backdrop_open ? 'header-border' : ''}`}
            >
              <DropDownButton
                title={t('header.menu_text')}
                navbar
                on_click_item_handler={dropdown_item_handler}
                menuData={header_data.menu_items}
                showBackdrop={props?.showBackdrop}
              />
            </div>
          </div>
        ) : null}
        {/* <div className="bell-icon" onClick={onClickBellHandler} /> */}
        <div className={is_logged ? 'd-none' : 'd-block'}>
          <LanguageSelector />
        </div>
      </nav>
    </header>
  )

  const mobile_form = (
    <header className="header-navbar">
      <div className="header-logo">
        <a href="https://www.vhv.it/">
          <Image src={vhv} width={100} />
        </a>
      </div>
      <nav className="header-nav">
        <div>
          <LanguageSelector />
        </div>
        {/* <div className="bell-icon" onClick={onClickBellHandler} /> */}
        {/* {is_logged ? <div className="side-drawer-icon" onClick={show_mobile_menu_handler} /> : null} */}
        {is_logged && (
          <div
            className="
          row py-1 pl-md-4 pr-md-2"
            onClick={show_mobile_menu_handler}
          >
            <div className="icon-person row align-items-center c-ffffff mr-2">
              <div className="w-100 text-center">
                {customer?.personTypeCode === 'F'
                  ? `${customer?.name?.charAt(0)}${customer?.surname?.charAt(0)}`
                  : customer?.businessName?.substring(0, 2)}
              </div>
            </div>
            <div className="dropdown_icon--bottom filter-7f7f7f" />
          </div>
        )}
      </nav>
    </header>
  )

  return (
    <div className="position-relative">
      {!is_mobile && process.env.REACT_APP_ENV !== 'PRD' && <Version />}
      {is_mobile ? mobile_form : desktop_form}
      {mobile_menu_visible && (
        <MobileMenu
          title={
            customer?.personTypeCode === 'F' ? customer?.name || '' : customer?.businessName || ''
          }
          proclie={customer?.proClie || ''}
          show={mobile_menu_visible}
          menu_items={header_data.menu_items}
          closed={show_mobile_menu_handler}
          on_click_item_handler={dropdown_item_handler}
        />
      )}
    </div>
  )
}

export default Header
