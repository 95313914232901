import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  /**
   * Store
   */
  reduce_logged: ['logged'],
  reduce_login_token: ['login_token'],
  reduce_check_token: ['check_token'],
  reduce_sso_otp: ['sso_otp'],
  reduce_already_logged: ['already_logged'],

  /**
   * Sagas
   */
  saga_login: ['args'],
  saga_logout: ['args'],
  saga_check_token: ['args'],
  saga_refresh_token: ['args'],
  saga_magic_login: ['args'],
  saga_reset_password: ['args'],
  saga_password_expired: ['args'],
  saga_unlock_user: ['args'],
  saga_check_token_imp: ['args'],
  saga_check_token_imp_i360_dir: ['args'],
  saga_check_token_imp_i360_age: ['args'],
  saga_refresh_token_imp: ['args'],
  saga_refresh_token_imp_i360_dir: ['args'],
  saga_refresh_token_imp_i360_age: ['args'],
  saga_register_user: ['args'],
  saga_recovery_client_id: ['args'],
  saga_recovery_password: ['args'],
  saga_require_sso_otp: ['args'],
})

export const oauth_types = Types
export const oauth_creators = Creators
