import './modify-residence.scss'

import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IS_MOBILE_WIDTH_THRESHOLD_IN_PX } from 'src/constants'
import i18n from 'src/i18n'
import { shoot } from 'src/redux/actions'
import {
  select_associated_customers,
  select_customer,
  select_error,
  select_history_item,
  select_nations,
  select_outcome_customer,
} from 'src/redux/store'
import { customer_t, customer_update_t } from 'src/types'
import { ClassNames } from 'src/utils/--deprecated'
import Button from 'src/widgets/--deprecated/button/button'
import CheckBox from 'src/widgets/--deprecated/check-box/check-box'
import ComboBox from 'src/widgets/--deprecated/combo-box/combo-box'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import * as Yup from 'yup'

interface checkbox_customers extends customer_t {
  selected: boolean;
}

interface residence_form_props {
  back_button_handler: () => void;
}

const ForeignResidenceForm: React.FC<residence_form_props> = (props: residence_form_props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const error = useSelector(select_error())
  const nations = useSelector(select_nations)
  const customer = useSelector(select_customer)
  const customer_outcome = useSelector(select_outcome_customer)
  const customer_error = useSelector(select_error(['SAGA_UPDATE_CUSTOMER']))
  const associated_customers = useSelector(select_associated_customers)
  const main_email = useSelector(select_history_item('main-email'))

  const [is_mobile, set_is_mobile] = useState(window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX)
  const [customers_selected, set_customers_selected] = useState<checkbox_customers[]>([])
  const [customer_update, set_customer_update] = useState<customer_update_t>()

  /**
   * Use effect
   */
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  useEffect(() => {
    if (customer_update) {
      if (customer_error) {
        history.goBack()
      }
      if (customer_outcome?.outcome === 'success') {
        dispatch(shoot.reduce_history_item('show-otp', 'true'))
        dispatch(shoot.reduce_history_item('residence-request', JSON.stringify(customer_update)))
        history.goBack()
      }
    }
  }, [customer_update, customer_outcome, customer_error])

  useEffect(() => {
    dispatch(shoot.saga_resolve_associated_customers({ tax_code: customer?.taxCode }))
    dispatch(shoot.saga_resolve_nations())
    set_customers_selected(
      associated_customers
        ?.filter((cust) => cust?.taxCode !== customer?.taxCode)
        ?.map((cust) => ({
          ...cust,
          selected: false,
        })) || []
    )
  }, [])

  const send_request_handler = (values: any) => {
    const proclies =
      customers_selected?.filter((cust) => cust?.selected).map((cust) => cust.proClie || '') || []
    const cust_update: customer_update_t = {
      proClie: customer?.proClie || '',
      type: 5,
      address: {
        idAddress: customer?.address?.idAddress,
        topAddress: values.toponym,
        address: values.address,
        addressNumber: values.street_number,
        city: values.locality,
        province: values.district,
        postalCode: values.cap,
        codeCountry: Number(values.nation),
        country: values?.nation,
        personTypeCode: customer?.personTypeCode,
        email: main_email,
      },
      associatedCustomers: proclies,
    }
    set_customer_update(cust_update)
    dispatch(shoot.saga_update_customer({ customer_update: cust_update }))
  }

  const tr_modify_residence: any = t('modify_residence', {
    returnObjects: true,
  })

  const dismiss_error = () => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }

  const validation_schema = Yup.object().shape({
    nation: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    district: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    municipality: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    locality: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    cap: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    toponym: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    address: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    street_number: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
  })

  const on_selected_handler = (index: number) => {
    const linked_customers = [...customers_selected]
    const item = { ...linked_customers[index] }
    item.selected = !item.selected
    linked_customers[index] = item
    set_customers_selected(linked_customers)
  }

  const toggle_button_sumbit = (values: any, errors: any) => {
    const btn_enabled =
      values.nation == '' ||
      values.district == '' ||
      values.municipality == '' ||
      values.locality == '' ||
      values.cap == '' ||
      values.toponym == '' ||
      values.address == '' ||
      values.street_number == '' ||
      errors.nation != undefined ||
      errors.district != undefined ||
      errors.municipality != undefined ||
      errors.locality != undefined ||
      errors.cap != undefined ||
      errors.toponym != undefined ||
      errors.address != undefined ||
      errors.street_number != undefined
        ? true
        : false
    return btn_enabled
  }

  return (
    <div className="mod-residence-form-container">
      <Formik
        validationSchema={validation_schema}
        enableReinitialize={true}
        initialValues={{
          nation: '',
          district: customer?.address?.province,
          municipality: customer?.address?.locality,
          locality: customer?.address?.city,
          cap: customer?.address?.postalCode,
          toponym: customer?.address?.topAddress,
          address: customer?.address?.address,
          street_number: customer?.address?.addressNumber,
        }}
        onSubmit={(values, { resetForm }) => {
          send_request_handler(values)
          resetForm()
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form
            onSubmit={handleSubmit}
            style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
          >
            {/* PRIMA RIGA*/}
            <div className="mod-residence-form-content">
              <div className="unnamed-character-style-27-13-22">
                {tr_modify_residence.form.nation}
              </div>
              <ComboBox
                id="nation"
                options={nations}
                change={(e: string) => {
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue('nation', e)
                }}
                placeholder={tr_modify_residence.form.nation_placeholder}
              />
              <div className="error-character support-extra-error">
                {errors.nation && typeof values.nation != 'object' ? errors.nation : ''}
              </div>
            </div>

            <Form.Group
              controlId="district"
              className="mod-residence-form-content unnamed-character-style-27-13-22"
            >
              <InputTxt
                id="district"
                label={tr_modify_residence.form.district}
                type="text"
                placeholder={tr_modify_residence.form.district_placeholder}
                value={values.district}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  dismiss_error()
                  setFieldValue('district', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () =>
                      !((touched.district && errors.district) || values.district === ''),
                    text: errors.district || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.district && errors.district ? errors.district : ''}
              </div>
            </Form.Group>

            {/* SECONDA RIGA*/}

            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="municipality"
                label={tr_modify_residence.form.municipality}
                type="text"
                placeholder={tr_modify_residence.form.municipality_placeholder}
                value={values.municipality}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  dismiss_error()
                  setFieldValue('municipality', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () =>
                      !(
                        (touched.municipality && errors.municipality) ||
                        values.municipality === ''
                      ),
                    text: errors.municipality || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.municipality && errors.municipality ? errors.municipality : ''}
              </div>
            </div>

            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="locality"
                label={tr_modify_residence.form.locality}
                type="text"
                placeholder={tr_modify_residence.form.locality_placeholder}
                value={values.locality}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  dismiss_error()
                  setFieldValue('locality', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () =>
                      !((touched.locality && errors.locality) || values.locality === ''),
                    text: errors.locality || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.locality && errors.locality ? errors.locality : ''}
              </div>
            </div>

            {/* TERZA RIGA*/}

            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="cap"
                label={tr_modify_residence.form.cap}
                type="text"
                placeholder={tr_modify_residence.form.cap_placeholder}
                value={values.cap}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  dismiss_error()
                  setFieldValue('cap', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () => !((touched.cap && errors.cap) || values.cap === ''),
                    text: errors.cap || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.cap && errors.cap ? errors.cap : ''}
              </div>
            </div>

            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="toponym"
                label={tr_modify_residence.form.toponym}
                type="text"
                placeholder={tr_modify_residence.form.toponym_placeholder}
                value={values.toponym}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  dismiss_error()
                  setFieldValue('toponym', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () => !((touched.toponym && errors.toponym) || values.toponym === ''),
                    text: errors.toponym || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.toponym && errors.toponym ? errors.toponym : ''}
              </div>
            </div>

            {/* QUARTA RIGA*/}

            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="address"
                label={tr_modify_residence.form.address}
                type="text"
                placeholder={tr_modify_residence.form.address_placeholder}
                value={values.address}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  dismiss_error()
                  setFieldValue('address', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () => !((touched.address && errors.address) || values.address === ''),
                    text: errors.address || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.address && errors.address ? errors.address : ''}
              </div>
            </div>

            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="street_number"
                label={tr_modify_residence.form.street_number}
                type="text"
                placeholder={tr_modify_residence.form.street_number_placeholder}
                value={values.street_number}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue('street_number', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () => !(touched.street_number && errors.street_number),
                    text: errors.street_number || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.street_number && errors.street_number ? errors.street_number : ''}
              </div>
            </div>
            <div
              className={ClassNames(
                'mt-5',
                is_mobile && customers_selected?.length != 0
                  ? 'unnamed-character-style-22 d-block'
                  : customers_selected?.length != 0
                  ? 'unnamed-character-style-11 d-block'
                  : 'd-none'
              )}
            >
              <div>{tr_modify_residence.form.associated_customer_label}</div>
              {customers_selected?.map((customer: customer_t, index_checkbox: number) => (
                <div className="checkbox-item" key={index_checkbox}>
                  <CheckBox
                    id={customer.proClie + ''}
                    text={
                      customer.name ? `${customer.surname} ${customer.name}` : customer.vatNumber
                    }
                    item_style="unnamed-character-style-10"
                    selected={customers_selected[index_checkbox].selected}
                    checked={() => on_selected_handler(index_checkbox)}
                  />
                </div>
              ))}
            </div>

            <div
              style={{ width: '100%', margin: 'auto' }}
              className={ClassNames(is_mobile ? 'mod-residence-d-block' : 'mod-residence-d-flex')}
            >
              <div className="mod-residence-button-confirm">
                <Button name="text_A90433_bold" onClick={props?.back_button_handler}>
                  <div style={{ margin: 'auto' }}>{tr_modify_residence.form.cancel}</div>
                </Button>
              </div>
              <div className="mod-residence-button-confirm">
                <Button
                  name="full_059881"
                  disabled={toggle_button_sumbit(values, errors)}
                  classes="gtm-residence"
                >
                  <div>{tr_modify_residence.form.send}</div>
                  <div className="right-arrow-white" />
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ForeignResidenceForm
