import { FC, useState } from 'react'
import { Col, Container } from 'react-bootstrap'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'

const DataMatrix: FC = () => {
  const [scan, set_scan] = useState(false)
  const [logs, set_log] = useState<Array<string>>([])

  const BASE64_MARKER = ';base64,'

  // Setting worker path to worker bundle.

  // function getPageText(pageNum: any, PDFDocumentInstance: any) {
  //   // Return a Promise that is solved once the text of the page is retrieven
  //   return new Promise(function (resolve, reject) {
  //     PDFDocumentInstance.getPage(pageNum).then(function (pdfPage: any) {
  //       // The main trick to obtain the text of the PDF page, use the getTextContent method
  //       pdfPage.getTextContent().then(function (textContent: any) {
  //         const textItems = textContent.items
  //         let finalString = ''

  //         // Concatenate the string of the item to the final string
  //         for (let i = 0; i < textItems.length; i++) {
  //           const item = textItems[i]

  //           finalString += item.str + ' '
  //         }

  //         // Solve promise with the text retrieven from the page
  //         resolve(finalString)
  //       })
  //     })
  //   })
  // }

  // function pdfAsArray(pdfAsArray: any) {
  //   /* eslint-disable */
  //   const pdfjsLib = require('pdfjs-dist')
  //   pdfjsLib.getDocument(pdfAsArray).promise.then(
  //     function (pdf: any) {
  //       const pdfDocument = pdf
  //       // Create an array that will contain our promises
  //       const pagesPromises = []

  //       for (let i = 0; i < pdf._pdfInfo.numPages; i++) {
  //         // Required to prevent that i is always the total of pages
  //         (function (pageNumber) {
  //           // Store the promise of getPageText that returns the text of a page
  //           pagesPromises.push(getPageText(pageNumber, pdfDocument))
  //         })(i + 1)
  //       }

  //       // Execute all the promises
  //       Promise.all(pagesPromises).then(function (pagesText: any) {
  //         // Display text of all the pages in the console
  //         // e.g ["Text content page 1", "Text content page 2", "Text content page 3" ... ]
  //         //console.log(pagesText); // representing every single page of PDF Document by array indexing
  //         //console.log(pagesText.length);
  //         let outputStr = ''
  //         let fiscalCodeFound = false
  //         for (let pageNum = 0; pageNum < pagesText.length; pageNum++) {
  //           //console.log(pagesText[pageNum]);
  //           outputStr = ''
  //           outputStr = '<br/><br/>Page ' + (pageNum + 1) + ' contents <br/> <br/>'

  //           const div = document.getElementById('output')
  //           if (div) {
  //             div.innerHTML += outputStr + pagesText[pageNum]
  //           }

  //           if (!fiscalCodeFound) {
  //             const pos = pagesText[pageNum].search('Codice fiscale')
  //             const parts = pagesText[pageNum].substring(pos, pagesText[pageNum].length).split(' ')
  //             const fiscalCode = parts.find((el: any) =>
  //               new RegExp(
  //                 '^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$'
  //               ).test(el)
  //             )
  //             if (fiscalCode) {
  //               console.log('Codice fiscale estratto: ' + fiscalCode)
  //               fiscalCodeFound = true
  //             }
  //           }
  //         }
  //       })
  //     },
  //     function (reason: any) {
  //       // PDF loading error
  //       console.error(reason)
  //     }
  //   )
  // }

  // function convertDataURIToBinary(dataURI: any) {

  //   var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  //   var base64 = dataURI.substring(base64Index);
  //   var raw = window.atob(base64);
  //   var rawLength = raw.length;
  //   var array = new Uint8Array(new ArrayBuffer(rawLength));

  //   for (var i = 0; i < rawLength; i++) {
  //     array[i] = raw.charCodeAt(i);
  //   }
  //   pdfAsArray(array)

  // }

  // function ExtractText() {
  //   //var pdfjsLib = require("pdfjs-dist");
  //   //pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  //   var input: HTMLInputElement | null = document.getElementById("file-id") as HTMLInputElement;
  //   if(input && input.files){
  //     var fReader = new FileReader();
  //     fReader.readAsDataURL(input?.files?.[0]);
  //     // console.log(input.files[0]);
  //     fReader.onloadend = function (event) {
  //       convertDataURIToBinary(event?.target?.result);
  //     }
  //   }

  // }

  const barcodeScannerComponentHandleUpdate = (error: any, result: any) => {
    if (result) {
      set_log([...logs, result.text])
      window.navigator.vibrate(100)
      set_scan(false)
    }
  }

  return (
    <Container fluid className="mb-5 pb-5 d-flex justify-content-center bg-f7f7f7">
      <Col lg={11}>
        <button onClick={() => set_scan(true)}>SCAN</button>
        <button onClick={() => set_scan(false)}>CANCEL</button>
        {scan && (
          <div className="w-full h-12">
            <BarcodeScannerComponent onUpdate={barcodeScannerComponentHandleUpdate} />
          </div>
        )}
        <div>
          {logs.map((log) => (
            <div key={log}>{log}</div>
          ))}

          <button onClick={() => set_log([])}>CLEAR</button>
        </div>
      </Col>
      {/* <Col lg={11}>
        <input
          type="file"
          id="file-id"
          name="file_name"
          onChange={() => console.log('extracttext')}
        />

        <div id="output" />
      </Col> */}
    </Container>
  )
}

export default DataMatrix
