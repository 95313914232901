import { FC } from 'react'
import { Image, Modal } from 'react-bootstrap'
import { loader_icon } from 'src/assets'
import { loader_props } from 'src/types'

import './loader.scss'

export const Loader: FC<loader_props> = (props: loader_props) => {
  return props.overlay ? (
    <Modal centered show={props.overlay} animation={false} className="loader-modal">
      <Image src={loader_icon} className="loader" />
    </Modal>
  ) : (
    <Image src={loader_icon} className="loader" />
  )
}
