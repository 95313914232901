import './footer.scss'

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SAGA_GUARDS } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import {
  select_error,
  select_footer,
  select_logged,
  select_maintenance_active,
} from 'src/redux/store'
import { footer_link, opening_days } from 'src/types'
import Button from 'src/widgets/--deprecated/button/button'

import { partner_contacts } from './footer-config'

//Footer component
// const RESIZE = 810
const Footer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const footer_language_data: any = t('footer', {
    returnObjects: true,
  })

  const mocked_footer = {
    phoneNumber: t('footer_new.phone_number'),
    openingDays: [
      {
        days: t('footer_new.opening_days_1'),
        timetables: t('footer_new.time_tabels_1'),
      },
      {
        days: t('footer_new.opening_days_2'),
        timetables: t('footer_new.time_tabels_2'),
      },
    ],
    mainLinks: [
      { label: t('footer_new.privacy_label'), value: t('footer_new.privacy_link') },
      { label: t('footer_new.cookie_label'), value: t('footer_new.cookie_link') },
    ],
    secondaryLinks: [
      { label: t('footer_new.itas_mutua_label'), value: '' },
      { label: t('footer_new.itas_vita_label'), value: '' },
      { label: t('footer_new.itas_valpiave_label'), value: '' },
      { label: t('footer_new.itas_group_label'), value: '' },
    ],
  }

  /**
   * Selectors
   */
  const footer = useSelector(select_footer)
  const maintenance_active = useSelector(select_maintenance_active)
  const error = useSelector(select_error([...SAGA_GUARDS.FOOTER]))
  const logged = useSelector(select_logged)

  /**
   * Effects
   */
  useEffect(() => {
    dispatch(shoot.saga_resolve_footer())
  }, [])

  useEffect(() => {
    if (!logged) {
      dispatch(shoot.saga_resolve_footer())
    }
  }, [logged])

  useEffect(() => {
    if (error) {
      dispatch(shoot.saga_resolve_communications_registration({ domain: 'FOO', subdomain: 'CNT' }))
      dispatch(shoot.reduce_footer(mocked_footer))
    }
  }, [error])

  useEffect(() => {
    if (footer && error) {
      dispatch(shoot.remove_error(error))
    }
  }, [footer])

  const partner_contacts_data = partner_contacts(footer_language_data.partner_contacts)
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 810)

  const open_modal_support_handler = () => {
    dispatch(shoot.reduce_support_visibility(true))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  return (
    <div className="footer">
      <Col>
        <Row className="footer--top-row">
          <Col className="d-sm-flex justify-content-sm-end">
            {/* partner contacts column, desktop */}
            <div>
              <div>
                <div className="footer-title-character footer-title-space footer-mobile-space--extra">
                  {partner_contacts_data.title}
                </div>
                <div className="footer-line" />

                <div className="footer-info">
                  <div
                    onClick={() => {
                      footer?.phoneNumber !== undefined && footer?.phoneNumber != ''
                        ? window.open('tel:' + footer?.phoneNumber)
                        : null
                    }}
                  >
                    <div className="footer-space footer-flex footer-cursor">
                      <div className="phone-call" />
                      <div className="footer-item-character">{footer?.phoneNumber}</div>
                    </div>
                  </div>
                  <div className="footer-sub-element-space">
                    {footer?.openingDays?.map((opening_days: opening_days) => (
                      <>
                        <div className="footer-item-character-size-15-ffffffbf">
                          {opening_days?.days}
                        </div>
                        <div className="footer-item-character-size-15-ffffffbf">
                          {opening_days?.timetables}
                        </div>
                      </>
                    ))}

                    <div className="footer-item-character-size-15-ffffffbf">
                      {footer?.timetables}
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-footer">
                {!maintenance_active && (
                  <Button
                    name="border_ffffff"
                    onClick={() => {
                      open_modal_support_handler()
                    }}
                  >
                    <div>{partner_contacts_data.button_text}</div>
                    <div className={partner_contacts_data.button_icon} />
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="footer--bottom-row">
          <Col>
            {footer?.mainLinks?.map((link: footer_link, index) => {
              const length = footer?.mainLinks?.length ? footer?.mainLinks?.length : 0
              return (
                <div
                  key={index}
                  className="footer-policy-character-size-15 footer-cursor footer-policy-items footer-display-initial"
                  onClick={() => {
                    window.open(link?.value, '_blank')
                  }}
                >
                  {link?.label}
                  {index === length - 1 ? ' ' : ' | '}
                </div>
              )
            })}

            <Row className="m-0 p-0">
              <div className="footer-policy-items">
                {footer?.secondaryLinks?.map((link: footer_link, index: number) => {
                  const length = footer?.secondaryLinks?.length ? footer?.secondaryLinks?.length : 0

                  return (
                    <div
                      key={index}
                      className="footer-item-character-size-13-ffffffbf footer-policy-items footer-display-initial"
                    >
                      <div
                        className={`footer-display-initial ${
                          link?.value !== '' ? 'footer-cursor' : ''
                        }`}
                        onClick={() => {
                          if (link?.value !== '') {
                            window.open(link?.value, '_blank')
                          }
                        }}
                      >
                        <div className="footer-display-initial">
                          {link?.label}
                          {index === length - 1 ? ' ' : ' | '}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default Footer
