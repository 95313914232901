import { BE_ERROR_CODES, SEVERITY_CODES } from 'src/backend-codes'
import i18n from 'src/i18n'
import { error_t } from 'src/types'

/**
 * TODO: Severity-img mapping
 */
export const severity_img_mapping = {
  [SEVERITY_CODES.FATAL]: 'Alert',
  [SEVERITY_CODES.ERROR]: 'Alert',
  [SEVERITY_CODES.WARNING]: 'Alert',
  [SEVERITY_CODES.INFORMATIONAL]: 'Alert',
}

/**
 * BE errors --> FE errors
 */
export const fe_errors: { [key: string]: error_t } = {
  [BE_ERROR_CODES.INVALID_USERNAME]: {
    description_fe: i18n.t('login.errors.credentials_incorrect'),
  },

  [BE_ERROR_CODES.INVALID_PASSWORD]: {
    description_fe: i18n.t('login.errors.credentials_incorrect'),
  },

  [BE_ERROR_CODES.INVALID_PASSWORD_LAST_ATTEMPT]: {
    description_fe: i18n.t('login.errors.credentials_incorrect_last_attempt'),
  },

  [BE_ERROR_CODES.INVALID_PASSWORD_USER_BLOCKED]: {
    title: i18n.t('login.errors.user_blocked'),
    description_fe: i18n.t('login.errors.user_blocked_info'),
    is_tech: true,
  },

  [BE_ERROR_CODES.LOGIN_USER_BLOCKED]: {
    title: i18n.t('login.errors.user_blocked'),
    description_fe: i18n.t('login.errors.user_blocked_info'),
    is_tech: true,
  },

  [BE_ERROR_CODES.UNVERIFIED_EMAIL]: {
    title: i18n.t('login.errors.unverified_email'),
    description_fe: i18n.t('login.errors.unverified_email'),
    is_tech: true,
  },

  [BE_ERROR_CODES.PASSWORD_EXPIRED]: {
    description_fe: i18n.t('login.errors.password_expired'),
  },

  [BE_ERROR_CODES.SERVICE_UNAVAILABLE]: {
    title: i18n.t('login.errors.service_unavailable'),
    description_fe: i18n.t('login.errors.service_unavailable'),
    is_tech: true,
  },

  [BE_ERROR_CODES.AUTHENTICATION_ERROR_1]: {
    title: i18n.t('login.errors.authentication_error_1'),
    description_fe: i18n.t('login.errors.authentication_error_1'),
    is_tech: true,
  },

  [BE_ERROR_CODES.AUTHENTICATION_ERROR_2]: {
    title: i18n.t('login.errors.authentication_error_2'),
    description_fe: i18n.t('login.errors.authentication_error_2'),
    is_tech: true,
  },

  [BE_ERROR_CODES.MAGIC_LINK_EXPIRED]: {
    description_fe: i18n.t('login.errors.magic_link_expired'),
    is_tech: true,
  },

  [BE_ERROR_CODES.MAGIC_LINK_INVALIDATED]: {
    description_fe: i18n.t('login.errors.magic_link_invalidated'),
    is_tech: true,
  },

  [BE_ERROR_CODES.USER_DUPLICATE_LOGIN]: {
    title: i18n.t('login.errors.unauthorized_access'),
    description_fe: i18n.t('login.errors.user_duplicate_info'),
    is_tech: true,
  },

  [BE_ERROR_CODES.USER_DUPLICATE_RECOVERY]: {
    description_fe: i18n.t('login.errors.user_duplicate_info'),
    is_tech: true,
  },

  [BE_ERROR_CODES.CUSTOMER_CHECK]: {
    // TO CHECK
    description_fe: i18n.t('user_registration.errors.customer_check'),
  },

  [BE_ERROR_CODES.EMAIL_ALREADY_REGISTERED_1]: {
    description_fe: i18n.t('user_registration.errors.email_already_registered_1'),
    is_tech: true,
  },

  [BE_ERROR_CODES.EMAIL_ALREADY_REGISTERED_2]: {
    description_fe: i18n.t('user_registration.errors.email_already_registered_2'),
    is_tech: true,
  },

  [BE_ERROR_CODES.WRONG_OR_ABSENT_POLICY_ID]: {
    description_fe: i18n.t('user_registration.errors.wrong_or_absent_policy_id'),
    is_tech: true,
  },

  [BE_ERROR_CODES.WRONG_USER_DATA_1]: {
    description_fe: i18n.t('user_registration.errors.wrong_user_data_1'),
    is_tech: true,
  },

  [BE_ERROR_CODES.WRONG_USER_DATA_2]: {
    description_fe: i18n.t('user_registration.errors.wrong_user_data_2'),
    is_tech: true,
  },

  [BE_ERROR_CODES.WRONG_TAXCODE_OR_VATCODE]: {
    description_fe: i18n.t('user_registration.errors.wrong_taxcode_or_vatcode'),
    is_tech: true,
  },

  [BE_ERROR_CODES.INACTIVE_POLICY]: {
    description_fe: i18n.t('user_registration.errors.inactive_policy'),
    is_tech: true,
  },

  [BE_ERROR_CODES.INVALID_POLICY]: {
    description_fe: i18n.t('user_registration.errors.invalid_policy'),
    is_tech: true,
  },

  [BE_ERROR_CODES.SEARCH_POLICY]: {
    description_fe: i18n.t('user_registration.errors.search_policy'),
    is_tech: true,
  },

  //Document
  [BE_ERROR_CODES.DOC_EXTERNAL_SERVICE]: {
    description_fe: i18n.t('modal_generic_tecnical_error.text'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT_DANNI_LIST_DEDUCTIONS]: {
    description_fe: i18n.t('documents.errors.ant_danni_list_deductions'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_USER_NOT_FOUND]: {
    description_fe: i18n.t('documents.errors.user_not_found'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT]: {
    description_fe: i18n.t('documents.errors.recovery_ant'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT_DANNI_LIST_RISK]: {
    description_fe: i18n.t('documents.errors.ant_danni_list_risk'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT_VITA_LIST_COMUN_FIN]: {
    description_fe: i18n.t('documents.errors.ant_vita_list_comun_fin'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT_VITA_LIST_LETT_DETRAZ]: {
    description_fe: i18n.t('documents.errors.ant_vita_list_lett_detraz'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_SERVICE]: {
    description_fe: i18n.t('modal_generic_tecnical_error.text'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT_DANNI_STAMPE]: {
    description_fe: i18n.t('documents.errors.ant_danni_stampe'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT_VITA_STAMPE]: {
    description_fe: i18n.t('documents.errors.ant_vita_stampe'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL]: {
    description_fe: i18n.t('documents.errors.document_detail'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_VITA_COMUN_FIN]: {
    description_fe: i18n.t('documents.errors.detail_vita_comun_fin'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_VITA_LETT_DETRAZ]: {
    description_fe: i18n.t('documents.errors.detail_vita_lett_detraz'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_VITA_ESTR_CONT]: {
    description_fe: i18n.t('documents.errors.detail_vita_estr_cont'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_VITA_STAMPA]: {
    description_fe: i18n.t('documents.errors.detail_vita_stampa'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_NOT_FOUND]: {
    description_fe: i18n.t('documents.errors.detail_not_found'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_DANNI_LETT_DETRAZ]: {
    description_fe: i18n.t('documents.errors.detail_danni_lett_detraz'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_DANNI_AVVISI]: {
    description_fe: i18n.t('documents.errors.detail_danni_avvisi'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_ANT_DANNI_LIST_AVVISI]: {
    description_fe: i18n.t('documents.errors.ant_danni_list_avvisi'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_DANNI_ATTESTATO]: {
    description_fe: i18n.t('documents.errors.detail_danni_attestato'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_DANNI_ATTESTATO_COMPANY]: {
    description_fe: i18n.t('documents.errors.detail_danni_attestato_company'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_DANNI_ATTESTATO_NOT_AVAILABLE]: {
    description_fe: i18n.t('documents.errors.detail_danni_attestato_not_available'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_DANNI_STAMPE]: {
    description_fe: i18n.t('documents.errors.detail_danni_stampe'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_DETAIL_DANNI_STAMPE_CGA]: {
    description_fe: i18n.t('documents.errors.detail_danni_stampe_cga'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_LISTA_INSTITUZIONALI]: {
    description_fe: i18n.t('documents.errors.lista_instituzionali'),
    is_tech: true,
  },
  [BE_ERROR_CODES.DOC_UNAUTHORIZED]: {
    description_fe: i18n.t('documents.errors.unauthorized'),
    is_tech: true,
  },

  //Password
  [BE_ERROR_CODES.PASSWORD_POLICY_INVALID]: {
    description_fe: i18n.t('new_password.errors.password_invalid'),
    is_tech: false,
  },
  [BE_ERROR_CODES.CHANGE_PW_POLICY_INVALID]: {
    description_fe: i18n.t('password_expired.errors.password_policy_invalid'),
    is_tech: false,
  },

  //Generic
  [BE_ERROR_CODES.LANGUAGE_NOT_FOUND]: {
    description_fe: i18n.t('modal_generic_tecnical_error.text'),
  },

  [BE_ERROR_CODES.PROCLIE_RECOVERY]: {
    description_fe: i18n.t('recovery_credentials.errors.proclie_recovery'),
    is_tech: true,
  },

  [BE_ERROR_CODES.PASSWORD_RECOVERY]: {
    description_fe: i18n.t('recovery_credentials.errors.password_recovery'),
    is_tech: true,
  },

  [BE_ERROR_CODES.RECOVERY_USER_NOT_FOUND]: {
    description_fe: i18n.t('recovery_credentials.errors.user_not_found'),
    is_tech: true,
  },

  [BE_ERROR_CODES.USER_EXTERNAL_SERVICE]: {
    description_fe: i18n.t('modal_generic_tecnical_error.title'),
    is_tech: true,
  },

  [BE_ERROR_CODES.NEW_PASSWORD_INVALID]: {
    description_fe: i18n.t('new_password.errors.password_invalid_last_five'),
  },

  [BE_ERROR_CODES.CHANGE_PASSWORD]: {
    description_fe: i18n.t('recovery_credentials.errors.change_password'),
    is_tech: true,
  },

  [BE_ERROR_CODES.ACCESS_DATA_NOT_FOUND]: {
    description_fe: i18n.t('recovery_credentials.errors.access_data_not_found'),
    is_tech: true,
  },

  [BE_ERROR_CODES.USER_BLOCKED]: {
    description_fe: i18n.t('recovery_credentials.errors.user_blocked'),
    is_tech: true,
  },

  [BE_ERROR_CODES.PW_EXP_INVALID_CREDENTIAL]: {
    description_fe: i18n.t('password_expired.errors.invalid_current_password'),
  },

  [BE_ERROR_CODES.PW_RESET_INVALID_CREDENTIAL]: {
    description_fe: i18n.t('password_reset.errors.invalid_current_password'),
  },

  [BE_ERROR_CODES.PW_EXP_USER_NOT_FOUND]: {
    description_fe: i18n.t('password_expired.errors.user_not_found'),
    is_tech: true,
  },

  [BE_ERROR_CODES.UNAUTHORIZED]: {
    description_fe: i18n.t('login.errors.unauthorized'),
    is_tech: true,
  },

  [BE_ERROR_CODES.ACCESS_DENIED_USER_BLOCKED]: {
    description_fe: i18n.t('new_password.errors.access_denied_user_blocked'),
    is_tech: true,
  },

  //Payment
  [BE_ERROR_CODES.PA_CHECK_PAYMENT_KO]: {
    description_fe: i18n.t('policy_operations.renewal.errors.pa_check_payment_ko_msg'),
  },

  [BE_ERROR_CODES.PA_PAYMENT_NOT_FOUND]: {
    description_fe: i18n.t('policy_operations.renewal.errors.pa_payment_not_found_msg'),
  },

  [BE_ERROR_CODES.PA_CHECK_PAYMENT_KO_REJECTED]: {
    description_fe: i18n.t('policy_operations.renewal.errors.pa_check_payment_ko_rejected_msg'),
  },

  [BE_ERROR_CODES.PA_CHECK_PAYMENT_EXTERNAL_ESEGUI_INC]: {
    description_fe: i18n.t(
      'policy_operations.renewal.errors.pa_check_payment_external_esegui_inc_msg'
    ),
  },

  [BE_ERROR_CODES.PA_CHECK_PAYMENT]: {
    description_fe: i18n.t('policy_operations.renewal.errors.pa_check_payment_msg'),
  },
}
