/**
 * These are the initial values for the 'startup' redux state.
 */

import { config_store } from 'src/types'

const store: config_store = {
  onboarding_showed: true,
}

export default store
