import {
  delete_token,
  oauth_client,
  oauth_client_basic,
  oauth_client_basic_imp,
  oauth_client_basic_imp_i360_age,
  oauth_client_basic_imp_i360_dir,
  oauth_client_bearer,
  post_check_token,
  post_check_token_imp,
  post_check_token_imp_i360_age,
  post_check_token_imp_i360_dir,
  post_refresh_token,
  post_refresh_token_imp,
  post_refresh_token_imp_i360_age,
  post_refresh_token_imp_i360_dir,
  post_sso,
  post_token,
  put_magic_login,
  put_password_expired,
  put_reset_password,
  put_unlock_user,
} from 'src/axios'
import {
  ax_client_bearer,
  ax_post_check_token,
  ax_post_check_token_imp,
  ax_post_refresh_token,
  ax_post_refresh_token_imp,
  ax_post_token,
  ax_put_magic_login,
  ax_put_password_expired,
  ax_put_reset_password,
  ax_put_unlock_user,
  check_token_t,
  login_token_t,
  response_t,
  saga_act,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Login
 */
export async function http_login({
  args,
  type,
}: saga_act<ax_post_token>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client_basic,
    request: post_token(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Logout
 */
export async function http_logout({
  args,
  type,
}: saga_act<ax_client_bearer>): Promise<response_t<string>> {
  return do_request<string>({
    client: oauth_client_bearer(args),
    request: delete_token(v4()),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Check token
 */
export async function http_check_token({
  args,
  type,
}: saga_act<ax_post_check_token>): Promise<response_t<check_token_t>> {
  return do_request<check_token_t>({
    client: oauth_client_basic,
    request: post_check_token(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Refresh token
 */
export async function http_refresh_token({
  args,
  type,
}: saga_act<ax_post_refresh_token>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client_basic,
    request: post_refresh_token(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Login with magic link
 */
export async function http_magic_login({
  args,
  type,
}: saga_act<ax_put_magic_login>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client,
    request: put_magic_login(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Reset password
 */
export async function http_reset_password({
  args,
  type,
}: saga_act<ax_put_reset_password>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client,
    request: put_reset_password(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Update password expired
 */
export async function http_password_expired({
  args,
  type,
}: saga_act<ax_put_password_expired>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client,
    request: put_password_expired(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Unlock user
 */
export async function http_unlock_user({
  args,
  type,
}: saga_act<ax_put_unlock_user>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client,
    request: put_unlock_user(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Check token impersonification
 */
export async function http_check_token_imp({
  args,
  type,
}: saga_act<ax_post_check_token_imp>): Promise<response_t<check_token_t>> {
  return do_request<check_token_t>({
    client: oauth_client_basic_imp,
    request: post_check_token_imp(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Check token impersonification i360 dir
 */
export async function http_check_token_imp_i360_dir({
  args,
  type,
}: saga_act<ax_post_check_token_imp>): Promise<response_t<check_token_t>> {
  return do_request<check_token_t>({
    client: oauth_client_basic_imp_i360_dir,
    request: post_check_token_imp_i360_dir(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Check token impersonification i360 age
 */
export async function http_check_token_imp_i360_age({
  args,
  type,
}: saga_act<ax_post_check_token_imp>): Promise<response_t<check_token_t>> {
  return do_request<check_token_t>({
    client: oauth_client_basic_imp_i360_age,
    request: post_check_token_imp_i360_age(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Refresh token impersonification
 */
export async function http_refresh_token_imp({
  args,
  type,
}: saga_act<ax_post_refresh_token_imp>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client_basic_imp,
    request: post_refresh_token_imp(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Refresh token impersonification i360 dir
 */
export async function http_refresh_token_imp_i360_dir({
  args,
  type,
}: saga_act<ax_post_refresh_token_imp>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client_basic_imp_i360_dir,
    request: post_refresh_token_imp_i360_dir(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Refresh token impersonification i360 age
 */
export async function http_refresh_token_imp_i360_age({
  args,
  type,
}: saga_act<ax_post_refresh_token_imp>): Promise<response_t<login_token_t>> {
  return do_request<login_token_t>({
    client: oauth_client_basic_imp_i360_age,
    request: post_refresh_token_imp_i360_age(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Require SSO
 */
export async function http_require_sso_otp({
  args,
  type,
}: saga_act<ax_client_bearer>): Promise<response_t<string>> {
  return do_request<string>({
    client: oauth_client_bearer(args),
    request: post_sso(v4()),
    child: 'otp',
    saga_type: type,
  })
}
