import axios from 'axios'
import {
  ax_get_retrieve_dati_contraente_from_num_ordine,
  ax_get_retrieve_dati_contraente_from_num_polizza,
  ax_get_retrieve_dati_contraente_from_num_polizza_vita,
  ax_post_decode_datamatrix,
  ax_post_recovery_client_id,
  ax_post_recovery_password,
  ax_post_user,
} from 'src/types'

import { axios_request, configify, GET, POST, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_USER || ''
const CONTEXT_ROOT = 'users'

/**
 * User client
 */
export const user_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * POST user
 */
export const post_user = (requestId: string, { user, captcha }: ax_post_user): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: POST,
  headers: { requestId, captcha },
  data: user,
})

/**
 * POST recovery client id
 */
export const post_recovery_client_id = (
  requestId: string,
  { recovery, captcha }: ax_post_recovery_client_id
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/recovery/proclie`,
  method: POST,
  headers: { requestId, captcha },
  data: recovery,
})

/**
 * POST recovery client id
 */
export const post_recovery_password = (
  requestId: string,
  { recovery, captcha }: ax_post_recovery_password
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/recovery/password`,
  method: POST,
  headers: { requestId, captcha },
  data: recovery,
})

export const get_retrieve_dati_contraente_from_num_polizza = (
  requestId: string,
  { numPolizza }: ax_get_retrieve_dati_contraente_from_num_polizza
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/retrieveDatiContraente?numPolizza=${numPolizza}`,
  method: GET,
  headers: {
    requestId,
  },
})

export const get_retrieve_dati_contraente_from_num_ordine = (
  requestId: string,
  { numOrdine, numMovi }: ax_get_retrieve_dati_contraente_from_num_ordine
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/retrieveDatiContraente?numOrdine=${numOrdine}&numMovi=${numMovi}`,
  method: GET,
  headers: {
    requestId,
  },
})

export const get_retrieve_dati_contraente_from_num_polizza_vita = (
  requestId: string,
  { numPolizzaVita, numOrdineVita }: ax_get_retrieve_dati_contraente_from_num_polizza_vita
): axios_request => ({
  url: numOrdineVita
    ? `/${VERSION_V1}/${CONTEXT_ROOT}/retrieveDatiContraente?numPolizzaVita=${numPolizzaVita}&numOrdineVita=${numOrdineVita}`
    : `/${VERSION_V1}/${CONTEXT_ROOT}/retrieveDatiContraente?numPolizzaVita=${numPolizzaVita}`,
  method: GET,
  headers: {
    requestId,
  },
})

export const post_decode_datamatrix = (
  requestId: string,
  base64pdf: ax_post_decode_datamatrix
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/decodeDatamatrix`,
  method: POST,
  headers: {
    requestId,
  },
  data: base64pdf,
})

export default user_client
