import { call, put } from 'redux-saga/effects'
import { http_resolve_agencies } from 'src/http/agencies.http'
import { agency_t, dict, saga_act } from 'src/types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Resolve agencies
 */
export function* saga_resolve_agencies(action: saga_act): unknown {
  const agencies: dict<agency_t> = yield call(saga_do_request, http_resolve_agencies, action)
  if (agencies) {
    yield put(shoot.reduce_agencies(agencies))
  }
}
