import { FC } from 'react'
import { Image } from 'react-bootstrap'
import { close_icon } from 'src/assets'
import { sidebar_props } from 'src/types'

import './sidebar.scss'

export const SideBar: FC<sidebar_props> = ({
  visible = false,
  children,
  head = <div />,
  on_close,
}: sidebar_props) => {
  return (
    <main className="root" style={{ width: visible ? '100%' : '0' }}>
      <div className="background" onClick={on_close} />
      <div className={`sidebar pb-5 ${visible ? 'visible' : 'gone'}`}>
        <div className="p-3 d-flex align-items-center justify-content-between head">
          {head}
          <Image src={close_icon} width={18} role="button" onClick={on_close} />
        </div>
        <div className="overflow-auto pb-5" style={{ height: '100%' }}>
          {children}
        </div>
      </div>
    </main>
  )
}
