import './voucher-detail.scss'

import { FC, useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next/'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { down_arrow_icon_white, email_icon, print_icon } from 'src/assets'
import { LIFERAY_URL } from 'src/config'
import { render_button } from 'src/containers/documents/bricks'
import { SAGA_GUARDS } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import {
  select_loading,
  select_policies,
  select_voucher,
  select_voucher_document,
} from 'src/redux/store'
import { button_props } from 'src/types'
import { download_blob, goto_link, is_md, render_topbar, to_blob, unescape_html } from 'src/utils'
import { Button, EmailModal, Loader } from 'src/widgets'

type action_t = 'print' | 'download' | 'send' | ''

const VoucherDetail: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  /**
   * URL params
   */
  const voucher_number = new URLSearchParams(useLocation().search).get('voucher-number')

  /**
   * Use Selector
   */
  const loading = useSelector(select_loading(SAGA_GUARDS.VOUCHER_DETAIL))
  const policies = useSelector(select_policies)
  const voucher = useSelector(select_voucher(voucher_number || ''))
  const voucher_document = useSelector(select_voucher_document)
  const desktop_url = voucher?.bannerImage?.contentUrl
  const mobile_url = voucher?.bannerImageMobile?.contentUrl

  /**
   * Use State
   */
  const [action, set_action] = useState<action_t>('')

  /**
   * Use Effect
   */
  useEffect(() => {
    dispatch(shoot.saga_resolve_vouchers())
  }, [])

  useEffect(() => {
    if (voucher_document) {
      if (action === 'print') {
        window.open(URL.createObjectURL(to_blob(voucher_document?.pdf || '')))
        set_action('')
      } else if (action === 'download') {
        download_blob(to_blob(voucher_document?.pdf || ''), voucher_document?.name)
        set_action('')
      }
    }
  }, [voucher_document])

  /**
   * Net functions
   */
  const to_params = (email?: string) => ({
    email,
    accessMode: voucher?.accessMode,
    partner: voucher?.partner,
    idPartner: voucher?.idPartner,
    codeGenerator: voucher?.codeGenerator,
    infoVoucher: voucher?.infoVoucher,
    prefCodPromo: voucher?.prefCodPromo,
    mutua: policies?.find((p) => p?.company === '022') ? 'S' : 'N',
    emailAddress: voucher?.emailAddress,
  })

  const to_print = () => {
    dispatch(shoot.saga_download_voucher(to_params()))
    set_action('print')
  }

  const to_send = () => {
    set_action('send')
  }

  const to_download = () => {
    dispatch(shoot.saga_download_voucher(to_params()))
    set_action('download')
  }

  /**
   * Render functions
   */
  const render_email_modal = () => (
    <EmailModal
      visible={action === 'send'}
      title={t('community.modal.title')}
      description={t('community.modal.insert_email')}
      cancel={{
        label: t('community.modal.cancel'),
        on_press: () => set_action(''),
      }}
      confirm={{
        label: t('community.modal.send'),
        on_press: (email) => {
          dispatch(shoot.saga_send_voucher(to_params(email)))
          set_action('')
        },
      }}
    />
  )

  const render_partner_mode = () => (
    <div className="mt-4">
      <div className="font-24 text-center">{t('community.voucher_site_access_label')}</div>
      <Button
        flavor="accent"
        iconr={down_arrow_icon_white}
        text={t('community.voucher_site_access_button')}
        classes="mt-4 mx-auto"
        classes_iconr="ml-3 voucher-button--rotate"
        on_press={() => goto_link(voucher?.link || '', '_blank', history)}
      />
    </div>
  )

  const render_download_mode = () => {
    const btn_props: button_props = {
      flavor: 'transparent_primary',
      classes_text: 'font-14-semibold text-uppercase c-159881',
    }
    return (
      <div className="mt-4 pt-4 pb-4 bg-ffffff">
        <div className="font-32-semibold text-center">{t('community.voucher_mail_send_label')}</div>
        <hr className="border border-1 bg-e5e5e5 w-25 mx-auto my-4" />
        <div className="d-sm-flex flex-md-row flex-sm-column w-75 mx-auto">
          {render_button({
            ...btn_props,
            text: t('community.download_pdf'),
            iconr: down_arrow_icon_white,
            classes: 'mr-md-3 p-md-2 mt-2 mx-auto',
            on_press: to_download,
          })}
          {render_button({
            ...btn_props,
            text: t('community.send_pdf'),
            iconr: email_icon,
            classes: 'p-md-2 mr-md-3 mt-2 mx-auto',
            on_press: to_send,
          })}
          {render_button({
            ...btn_props,
            text: t('community.print_pdf'),
            iconr: print_icon,
            classes: 'p-md-2 mt-md-2 mx-auto',
            on_press: to_print,
          })}
        </div>
      </div>
    )
  }

  return (
    <Container fluid className="mb-5 pb-5 d-flex justify-content-center bg-f7f7f7">
      <Col lg={11} className="align-items-center">
        {/* TOP-BAR */}
        {render_topbar(() => history.goBack(), t('community.back'))}

        {/* LOADER */}
        {loading && <Loader overlay={true} />}

        {/* EMAIL MODAL */}
        {render_email_modal()}

        <Row className="d-flex justify-content-center">
          <Col md={10} lg={8}>
            <div className="font-18 c-7f7f7f text-center">{voucher?.partner}</div>
            <div
              className={`${
                !is_md
                  ? 'font-45-freightdisp-semibold text-center mt-1'
                  : 'font-54-freightdisp-semibold text-center mt-1'
              }`}
            >
              {voucher?.title}
            </div>
            <div className="mt-5 d-none justify-content-center d-md-flex">
              <Image
                fluid
                src={desktop_url?.startsWith('/') ? `${LIFERAY_URL}${desktop_url}` : desktop_url}
              />
            </div>

            <div className="mt-5 d-flex justify-content-center d-md-none">
              <Image
                fluid
                src={mobile_url?.startsWith('/') ? `${LIFERAY_URL}${mobile_url}` : mobile_url}
              />
            </div>

            <div className="mt-5 pt-2 px-sm-5 mx-auto">
              <div className="font-15 d-flex justify-content-center">
                {unescape_html(voucher?.description)}
              </div>

              {/* DOWNLOAD VOUCHER SECTION */}
              {voucher?.accessMode === 'download' && render_download_mode()}

              {/* SITE ACCESS SECTION */}
              {voucher?.accessMode === 'partner_access' && render_partner_mode()}
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  )
}

export default VoucherDetail
