import {
  cms_client,
  get_banners,
  get_communications,
  get_communications_registration,
  get_faqs,
  get_footer,
  get_privacies,
} from 'src/axios/cms.axios'
import {
  ax_get_banners,
  ax_get_communications,
  ax_get_faqs,
  ax_get_footer,
  ax_get_privacies,
  banner_cms,
  communication_cms,
  faq_cms,
  footer_cms,
  privacy_cms,
  response_t,
  saga_act,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve privacies
 */
export async function http_resolve_privacies({
  args,
  type,
}: saga_act<ax_get_privacies>): Promise<response_t<privacy_cms[]>> {
  return do_request<privacy_cms[]>({
    client: cms_client,
    request: get_privacies(v4(), args),
    child: 'privacies',
    saga_type: type,
  })
}

/**
 * Resolve banners
 */
export async function http_resolve_banners({
  args,
  type,
}: saga_act<ax_get_banners>): Promise<response_t<banner_cms[]>> {
  return do_request<banner_cms[]>({
    client: cms_client,
    request: get_banners(v4(), args),
    child: 'banners',
    saga_type: type,
  })
}

/**
 * Resolve FAQs
 */
export async function http_resolve_faqs({
  args,
  type,
}: saga_act<ax_get_faqs>): Promise<response_t<faq_cms[]>> {
  return do_request<faq_cms[]>({
    client: cms_client,
    request: get_faqs(v4(), args),
    child: 'faqs',
    saga_type: type,
  })
}

/**
 * Resolve communications
 */
export async function http_resolve_communications({
  args,
  type,
}: saga_act<ax_get_communications>): Promise<response_t<communication_cms[]>> {
  return do_request<communication_cms[]>({
    client: cms_client,
    request: get_communications(v4(), args),
    child: 'communications',
    saga_type: type,
  })
}

/**
 * Resolve communications registration
 */
export async function http_resolve_communications_registration({
  args,
  type,
}: saga_act<ax_get_communications>): Promise<response_t<communication_cms[]>> {
  return do_request<communication_cms[]>({
    client: cms_client,
    request: get_communications_registration(v4(), args),
    child: 'communications',
    saga_type: type,
  })
}

/**
 * Resolve footer
 */
export async function http_resolve_footer({
  args,
  type,
}: saga_act<ax_get_footer>): Promise<response_t<footer_cms>> {
  return do_request<footer_cms>({
    client: cms_client,
    request: get_footer(v4(), args),
    child: '',
    saga_type: type,
  })
}
