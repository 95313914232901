import { History, LocationState } from 'history'
import { POLICY_COMPANIES } from 'src/backend-codes'
import i18n from 'src/i18n'
import {
  agency_t,
  customer_t,
  dict,
  district_t,
  history_request_t,
  locality_t,
  municipality_t,
  opt,
  policy_t,
  privacy_cms,
  voucher_t,
} from 'src/types'

import { compare_dates, format_date } from './formatters.utils'

export const is_sm = window.innerWidth >= 576
export const is_md = window.innerWidth >= 768
export const is_lg = window.innerWidth >= 992
export const is_xl = window.innerWidth >= 1200

export const deep_copy = <T>(item: T): T => {
  return JSON.parse(JSON.stringify(item))
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const get_language = (): 'IT' | 'DE' => {
  return i18n.language.slice(0, 2).toUpperCase() === 'DE' ? 'DE' : 'IT'
}

export const update_counter_dict = (dict_: dict<number>, key?: string, value?: number): void => {
  if (key) {
    if (dict_[key]) {
      dict_[key] += 1
    } else {
      dict_[key] = value || 1
    }
  }
}

export const goto_link = (link: string, target: string, history: History<LocationState>): void => {
  if (target === '_top') {
    history.push(link)
  } else if (target === '_blank') {
    link = link.replace('http://', 'https://')
    link = link?.startsWith('https://') ? link : 'https://' + link
    window.open(link, target, 'noopener,noreferrer')
  }
}

export const find_district = (districts?: district_t[], description?: string): opt<district_t> => {
  return districts?.find((district) => district?.description === description)
}

export const find_municipality = (
  municipalities?: municipality_t[],
  description?: string
): opt<municipality_t> => {
  return municipalities?.find((municipality) => municipality?.description === description)
}

export const find_locality = (
  localities?: locality_t[],
  description?: string
): opt<municipality_t> => {
  return localities?.find((locality) => locality?.description === description)
}

export const sort_policies = (policies: policy_t[]): policy_t[] => {
  const priority = ['03-1', '02', '01', '06', '03-0', '04', '07', '05']
  const policies_ = [...policies]
  policies_.sort((p1, p2) => {
    const pn1 = p1?.policyNumber || ''
    const pn2 = p2?.policyNumber || ''
    return pn1 < pn2 ? -1 : 1
  })
  policies_.sort((p1, p2) => {
    const s1 = p1?.statusCodContract || ''
    const s2 = p2?.statusCodContract || ''
    const k1 = s1 === '03' ? `${s1}-${p1?.flgRinn || 0}` : s1
    const k2 = s2 === '03' ? `${s2}-${p2?.flgRinn || 0}` : s2
    return priority.indexOf(k1) - priority.indexOf(k2)
  })
  return policies_
}

export const sort_privacies = (privacies: privacy_cms[]): privacy_cms[] => {
  const priority = ['D1', 'D4', 'D3', 'D2', 'D5']
  const privacies_ = [...privacies]
  privacies_.sort((p1, p2) => {
    const k1 = p1?.consentTypes?.[0] || ''
    const k2 = p2?.consentTypes?.[0] || ''
    return priority.indexOf(k1) - priority.indexOf(k2)
  })
  return privacies_
}

export const sort_agencies = (agencies: agency_t[]): agency_t[] => {
  const priority = ['AGENZIA', 'BROKER', 'BANCA']
  const agencies_ = [...agencies]
  agencies_.sort((a1, a2) => {
    const k1 = a1?.codeType || ''
    const k2 = a2?.codeType || ''
    return priority.indexOf(k1) - priority.indexOf(k2)
  })
  return agencies_
}

export const sort_vouchers = (vouchers: voucher_t[]): voucher_t[] => {
  const vouchers_ = [...vouchers]
  vouchers_.sort((a, b) => {
    const k1 = Number(a?.order) || 1000
    const k2 = Number(b?.order) || 1000
    return k1 - k2
  })
  return vouchers_
}

export const is_consent_given = (customer?: customer_t, policy?: policy_t): boolean => {
  return !!customer?.communication?.consentsTD?.find(
    (consent) =>
      consent?.companyCode === POLICY_COMPANIES(policy) && consent?.email && consent?.status === 1
  )
}

export const is_policy_suspendable = (
  policy?: policy_t,
  history_requests?: history_request_t[]
): boolean => {
  const last_susp = history_requests?.find((req) => req?.requestType === 'SOSPENSIONE')
  const date_susp = new Date(format_date(policy?.coverageDate, 'YYYY-MM-DD', 'MM/DD/YYYY'))
  return (
    !!policy?.flgSosp &&
    (!last_susp || last_susp?.status === 'FORWARDED' || last_susp?.status === 'TO_CONFIRM') &&
    compare_dates(new Date(), date_susp) === -1
  )
}
