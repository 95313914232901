import './support.scss'

import { Formik, FormikErrors } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'src/i18n'
import { shoot } from 'src/redux/actions'
import {
  select_customer,
  select_districts,
  select_logged,
  select_outcome_email,
  select_proclie,
  select_support_visibility,
} from 'src/redux/store'
import { regex_email, regex_name, regex_phone_number } from 'src/regex'
import { email_t } from 'src/types'
import { is_void_obj } from 'src/utils'
import Button from 'src/widgets/--deprecated/button/button'
import CheckBox from 'src/widgets/--deprecated/check-box/check-box'
import ComboBox from 'src/widgets/--deprecated/combo-box/combo-box'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import ModalSuccess from 'src/widgets/--deprecated/modal-success/modal-success'
import Modal, {
  modalProps_genericError,
  ModalPropsDataType,
} from 'src/widgets/--deprecated/modal/modal'
import Upload from 'src/widgets/--deprecated/upload-file/upload-file'
import { Recaptcha, submit_captcha } from 'src/widgets/recaptcha/recaptcha'
import * as Yup from 'yup'

interface support_props {
  show?: boolean;
  handleClose: () => void;
}

/**
 * Support components
 */
const Support: React.FC<support_props> = (props: support_props) => {
  /**
   * Variables
   */
  const { t } = useTranslation()

  const districts_ = [
    { code: 'CN', description: 'Cuneo' },
    { code: 'TO', description: 'Torino' },
    { code: 'AL', description: 'Alessandria' },
    { code: 'AT', description: 'Asti' },
    { code: 'BI', description: 'Biella' },
    { code: 'NO', description: 'Novara' },
    { code: 'VC', description: 'Vercelli' },
    { code: 'VB', description: 'Verbania' },
  ]

  const arguments_arr = [
    {
      code: '1',
      description: t('support.arguments_arr.arg_1'),
      email: 'itas.area.riservata.list@reply.it' /*'servizioclienti@vhv.it'*/,
      flgCfPolizza: true,
    },
    {
      code: '2',
      description: t('support.arguments_arr.arg_2'),
      email: 'itas.area.riservata.list@reply.it' /*'servizioclienti@vhv.it'*/,
      flgCfPolizza: true,
    },
    {
      code: '3',
      description: t('support.arguments_arr.arg_3'),
      email: 'itas.area.riservata.list@reply.it' /*'sinistri@vhv.it'*/,
      flgCfPolizza: true,
    },
    {
      //TODO TO USE
      code: '4',
      description: t('support.arguments_arr.arg_4'),
      email: 'itas.area.riservata.list@reply.it' /*'servizioclienti@vhv.it'*/,
      flgCfPolizza: true,
    },
    {
      code: '5',
      description: t('support.arguments_arr.arg_5'),
      email: 'itas.area.riservata.list@reply.it' /*'servizioclienti@vhv.it'*/,
      flgCfPolizza: false,
    },
    {
      code: '6',
      description: t('support.arguments_arr.arg_6'),
      email: 'itas.area.riservata.list@reply.it' /*'servizioclienti@vhv.it'*/,
      flgCfPolizza: false,
    },
    {
      code: '7',
      description: t('support.arguments_arr.arg_7'),
      email: 'itas.area.riservata.list@reply.it' /*'comunicazione@vhv.it'*/,
      flgCfPolizza: false,
    },
    {
      code: '8',
      description: t('support.arguments_arr.arg_8'),
      email: 'itas.area.riservata.list@reply.it' /*email: 'comunicazione@vhv.it'*/,
      flgCfPolizza: false,
    },
    {
      code: '9',
      description: t('support.arguments_arr.arg_9'),
      email: 'itas.area.riservata.list@reply.it' /*email: 'assistenza.direzionesinistri@vhv.it'*/,
      flgCfPolizza: false,
    },
    {
      code: '10',
      description: t('support.arguments_arr.arg_10'),
      email: 'itas.area.riservata.list@reply.it' /*email: 'liquidazioni.vita@vhv.it'*/,
      flgCfPolizza: false,
    },
    {
      code: '11',
      description: t('support.arguments_arr.arg_11'),
      email: 'itas.area.riservata.list@reply.it' /*'servizioclienti@vhv.it'*/,
      flgCfPolizza: true,
    },
  ]

  window.location.pathname !== '/profilo' && window.scrollTo(0, 0)

  /**
   * Selectors
   */
  const proclie = useSelector(select_proclie)
  const customer = useSelector(select_customer)
  const districts = useSelector(select_districts)
  const is_logged = useSelector(select_logged)
  const show_modal_support = useSelector(select_support_visibility)
  const email_outcome = useSelector(select_outcome_email)
  const show_hide_support =
    props.show || show_modal_support ? 'support support-open' : 'support support-close'

  const customer_name = customer?.name
  const customer_surname = customer?.surname
  const contacts = customer?.communication?.contacts
  const customer_main_email = contacts?.find(
    (c) => c?.type === 'EMAIL' && c?.flgPrimary === 'S'
  )?.value
  const customer_main_phone = contacts?.find(
    (c) => c?.type === 'PHONE' && c?.flgPrimary === 'S'
  )?.value
  const fiscal_code = customer?.taxCode

  /**
   * States
   */
  const [checkbox_selected, set_checkbox_selected] = useState(false)
  const [email, set_email] = useState<email_t>({})
  const [files, set_files] = useState<File[]>([])
  const [reset_combobox, set_reset_combobox] = useState(false)
  const [reset_file, set_reset_file] = useState(false)
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 810)
  const [success_visible, set_success_visible] = useState(false)
  const [error_visible, set_error_visible] = useState(false)
  const [captcha, set_captcha] = useState('')
  const [touched_district, set_touched_district] = useState(false)

  /**
   * Dispatch
   */
  const dispatch = useDispatch()

  /**
   * Error Props
   */
  const modal_props: ModalPropsDataType = {
    ...modalProps_genericError.data,
    showTitle: false,
    showText: true,
    text: t('support.errors.email_error'),
    closeButtonOnClick: () => set_error_visible(false),
    showBackButton: false,
    showNextButton: false,
  }

  /**
   * Use Effects
   * */
  useEffect(() => {
    set_checkbox_selected(false)
    set_reset_combobox(true)
    set_reset_file(true)
    setTimeout(() => {
      set_reset_combobox(false)
      set_reset_file(false)
    })
  }, [is_logged])

  useEffect(() => {
    set_reset_combobox(true)
    set_reset_combobox(false)
  }, [i18n.language])

  useEffect(() => {
    if (!is_void_obj(email) && captcha !== '') {
      dispatch(shoot.saga_send_email({ email, captcha }))
    }
  }, [email, captcha])

  useEffect(() => {
    if (props.show) {
      dispatch(shoot.saga_resolve_districts({ nation_code: 1 }))
    }
  }, [props.show])

  useEffect(() => {
    if (email_outcome === 'success') {
      set_success_visible(true)
      dispatch(shoot.reduce_outcome_email(undefined))
      dispatch(shoot.reduce_support_visibility(false))
    } else if (email_outcome === 'failure') {
      set_error_visible(true)
      dispatch(shoot.reduce_outcome_email(undefined))
      dispatch(shoot.reduce_support_visibility(false))
    }
  }, [email_outcome])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  /**
   * Functions
   */
  const set_privacy_handler = () => {
    set_checkbox_selected(!checkbox_selected)
  }

  /**
   * Validation schema
   */
  const validation_schema = Yup.object().shape({
    name: Yup.string()
      .matches(regex_name, i18n.t('support.errors.name_invalid'))
      .max(254, i18n.t('support.errors.name_max_char'))
      .required(i18n.t('support.errors.name_required')),

    surname: Yup.string()
      .matches(regex_name, i18n.t('support.errors.surname_invalid'))
      .max(254, i18n.t('support.errors.surname_max_char'))
      .required(i18n.t('support.errors.surname_required')),

    email: Yup.string()
      .matches(regex_email, i18n.t('support.errors.email_invalid'))
      .max(254, i18n.t('support.errors.email_max_char'))
      .required(i18n.t('support.errors.email_required')),

    phone_number: Yup.string()
      .matches(regex_phone_number, i18n.t('support.errors.cell_invalid'))
      .required(i18n.t('support.errors.cell_required')),

    message: Yup.string().required(i18n.t('support.errors.message_required')),

    district: Yup.string().test('', t('support.errors.district_required'), (code) => {
      if (code) {
        return true
      } else {
        return false
      }
    }),
  })

  const file_to_base64 = (file: File): any => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = function (event) {
        resolve(event?.target?.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const disable_button_handler = (
    name: string,
    surname: string,
    email: string,
    phone_number: string,
    district: string,
    message: string,
    policy_number: string,
    fc: string,
    checkbox: boolean,
    errors: FormikErrors<{
      name: string,
      surname: string,
      email: string,
      phone_number: string,
      district: string,
      message: string,
      policy_number: string,
      fc: string,
    }>
  ) => {
    if (
      name !== '' &&
      surname !== '' &&
      phone_number !== '' &&
      email !== '' &&
      district !== '' &&
      message !== '' &&
      checkbox &&
      Object.keys(errors)?.length === 0
    ) {
      return false
    }

    return true
  }

  return (
    <div className={show_hide_support}>
      <div className="support-background" onClick={props.handleClose} />
      <Recaptcha />
      <Formik
        validationSchema={validation_schema}
        enableReinitialize={true}
        initialValues={{
          name: customer_name && is_logged ? customer_name : '',
          surname: customer_surname && is_logged ? customer_surname : '',
          email: customer_main_email && is_logged ? customer_main_email : '',
          phone_number: customer_main_phone && is_logged ? customer_main_phone : '',
          district: '',
          argument: '',
          message: '',
          policy_number: '',
          fc: fiscal_code && is_logged ? fiscal_code : '',
        }}
        onSubmit={(values, { resetForm }) => {
          resetForm()
          set_checkbox_selected(false)
          set_reset_combobox(true)
          set_reset_file(true)
          set_captcha('')
          submit_captcha().then((token: string) => {
            set_captcha(token)
          })
          setTimeout(() => {
            set_reset_combobox(false)
            set_reset_file(false)
          })

          const file_promises = files?.map((file) => file_to_base64(file))
          Promise.all(file_promises).then((file_contents) => {
            const attachments = file_contents?.map((content, idx) => ({
              fileName: files[idx]?.name,
              mymeType: files[idx]?.type,
              fileContent: content.split(',')[1],
            }))
            set_email({
              customerEmail: values.email,
              //companyEmail: process.env.REACT_APP_SUPPORT_MAIL,
              codeTemplate: 'T51',
              name: values.name,
              surname: values.surname,
              taxCode: values.fc ? values.fc : '', //customer?.taxCode,
              companyName: '',
              policyNumber: values.policy_number ? values.policy_number : '',
              vatNumber: customer?.vatNumber ? customer?.vatNumber : '',
              // to: process.env.REACT_APP_SUPPORT_MAIL,
              cc: values?.email,
              subject: t('support.write_to_itas'),
              proclie,
              message: values.message,
              phone: values.phone_number,
              region: districts?.find((d) => d?.code === values.district)?.description,
              attachmentList: attachments,
              topic: t('support.arguments_arr.arg_4'),
            })
          })
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="support-modal col-12">
              {/* TITLE */}
              <div className="support-row row-title-x-button">
                <div className="unnamed-character-style-13-20-bold-uppercase">
                  {t('support.title')}
                </div>
                <div className="x-button" onClick={props.handleClose} />
              </div>

              <div className="row support-row">
                {/* NAME */}
                <div className="m-0 p-0 col-sm-6 col-12 mt-sm-0 pr-sm-2">
                  <InputTxt
                    id="name"
                    label={t('support.form.name')}
                    type="text"
                    placeholder={t('support.form.name_placeholder')}
                    value={values.name}
                    readonly={customer_name && is_logged ? true : false}
                    on_change={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e)
                      handleBlur(e)
                      setFieldValue('name', e.target.value)
                    }}
                    validators={[
                      {
                        is_valid: () => !(touched.name && errors.name),
                        text: errors.name || '',
                      },
                    ]}
                  />
                </div>
                {/* SURNAME */}
                <div className="m-0 p-0 col-sm-6 col-12 mt-sm-0 mt-3 pl-sm-2">
                  <InputTxt
                    id="surname"
                    label={t('support.form.surname')}
                    type="text"
                    placeholder={t('support.form.surname_placeholder')}
                    value={values.surname}
                    readonly={customer_surname && is_logged ? true : false}
                    on_change={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e)
                      handleBlur(e)
                      setFieldValue('surname', e.target.value)
                    }}
                    validators={[
                      {
                        is_valid: () => !(touched.surname && errors.surname),
                        text: errors.surname || '',
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="row support-row">
                {/* E-MAIL */}
                <div className="m-0 p-0 col-12">
                  <InputTxt
                    id="email"
                    label={t('support.form.email')}
                    type="text"
                    placeholder={t('support.form.email_placeholder')}
                    value={'' + values.email}
                    readonly={customer_main_email && is_logged ? true : false}
                    on_change={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e)
                      handleBlur(e)
                      setFieldValue('email', e.target.value)
                    }}
                    validators={[
                      {
                        is_valid: () => !(touched.email && errors.email),
                        text: errors.email || '',
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="row support-row">
                {/* PHONE */}
                <div className="m-0 p-0 col-sm-6 col-12 mt-sm-0 pr-sm-2">
                  <InputTxt
                    id="phone_number"
                    label={t('support.form.cell_number')}
                    type="text"
                    placeholder={t('support.form.cell_number_placeholder')}
                    value={'' + values.phone_number}
                    readonly={customer_main_phone && is_logged ? true : false}
                    on_change={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e)
                      handleBlur(e)
                      setFieldValue('phone_number', e.target.value)
                    }}
                    validators={[
                      {
                        is_valid: () => !(touched.phone_number && errors.phone_number),
                        text: errors.phone_number || '',
                      },
                    ]}
                  />
                </div>

                {/* PROVINCE */}
                <div className="m-0 p-0 col-sm-6 col-12 mt-sm-0 mt-3 pl-sm-2">
                  <div className="unnamed-character-style-27-13-22">
                    {t('support.form.district')}
                  </div>
                  <ComboBox
                    id={'district'}
                    options={districts || []}
                    change={(e: string) => {
                      handleChange(e)
                      handleBlur(e)
                      setFieldValue('district', e)
                    }}
                    on_input_change={() => {
                      set_touched_district(true)
                    }}
                    placeholder={t('support.form.district_placeholder')}
                    refresh_component={reset_combobox}
                  />
                  <div className="error-character support-extra-error ml-1">
                    {touched_district && (errors.district || values.district === '')
                      ? t('support.errors.district_required')
                      : ''}
                  </div>
                </div>
              </div>

              <div className="row support-row">
                {/* Topic */}
                <div className="m-0 p-0 col-12">
                  <InputTxt
                    id="topic"
                    label={t('support.form.argument')}
                    type="text"
                    placeholder={t('support.arguments_arr.arg_4')}
                    readonly
                  />
                </div>
              </div>

              <div>
                <div className="row support-row">
                  {/* Policy number */}
                  <div className="m-0 p-0 col-12">
                    <InputTxt
                      id="policy_number"
                      label={t('support.form.policy_number')}
                      type="text"
                      placeholder={t('support.form.policy_number_placeholder')}
                      value={'' + values.policy_number}
                      // readonly={customer_main_email && is_logged ? true : false}
                      on_change={(e: ChangeEvent<HTMLInputElement>) => {
                        handleChange(e)
                        handleBlur(e)
                        setFieldValue('policy_number', e.target.value)
                      }}
                      validators={[
                        {
                          is_valid: () => !(touched.policy_number && errors.policy_number),
                          text: errors.policy_number || '',
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row support-row">
                  {/* Fiscal code */}
                  <div className="m-0 p-0 col-12">
                    <InputTxt
                      id="fc"
                      label={t('support.form.fc_text')}
                      type="text"
                      placeholder={t('support.form.fc_text_placeholder')}
                      value={'' + values.fc}
                      readonly={fiscal_code && is_logged ? true : false}
                      on_change={(e: ChangeEvent<HTMLInputElement>) => {
                        handleChange(e)
                        handleBlur(e)
                        setFieldValue('fc', e.target.value)
                      }}
                      validators={[
                        {
                          is_valid: () => !(touched.fc && errors.fc),
                          text: errors.fc || '',
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              {/* /////// */}
              <div className="row support-message-row">
                {/* Message */}
                <div className="unnamed-character-style-27-13-22 support-message-label">
                  {t('support.form.message')}
                </div>
                <textarea
                  id="message"
                  className="col-12 support-message-textarea unnamed-character-style-2"
                  rows={3}
                  value={values.message}
                  placeholder={t('support.form.message_placeholder')}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    handleChange(e)
                    handleBlur(e)
                    setFieldValue('message', e.target.value)
                  }}
                />
              </div>

              {/* Attached file */}
              <div className="m-0 p-0 support-row col-12 mb-4">
                <Upload with_button={true} on_files_change={set_files} reset_file={reset_file} />
              </div>

              {/* Privacy */}
              <div className="support-row support-checkbox-info-privacy">
                <div className="support-checkbox">
                  <CheckBox
                    id="privacy_id_checkbox"
                    selected={checkbox_selected}
                    checked={set_privacy_handler}
                  />
                </div>

                <div className="unnamed-character-style-52">
                  {t('support.form.info_privacy_1')}
                  <a
                    className="d-inline"
                    href={process.env.REACT_APP_LINK_VHV_PRIVACY || ''}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('support.form.info_privacy_link')}
                  </a>
                  {t('support.form.info_privacy_2')}
                </div>
              </div>
              <div className="row support-row support-button">
                <Button
                  name="full_059881"
                  justify_content={is_mobile ? 'space-between' : 'center'}
                  disabled={disable_button_handler(
                    values.name,
                    values.surname,
                    values.email,
                    values.phone_number,
                    values.district,
                    values.message,
                    values.policy_number,
                    values.fc,
                    checkbox_selected,
                    errors
                  )}
                >
                  <div>{t('support.form.btn_submit')}</div>

                  <div className="right-arrow-white" />
                </Button>
              </div>
            </div>
            {error_visible && <Modal data={modal_props} />}
            {success_visible && (
              <ModalSuccess
                show={success_visible}
                handle_close={() => set_success_visible(false)}
                title={i18n.t('support.modal_success.title')}
                description={i18n.t('support.modal_success.description')}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Support
