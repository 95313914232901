import { FC } from 'react'
import { Image } from 'react-bootstrap'
import {
  checkbox_empty_disabled,
  checkbox_empty_enabled,
  checkbox_full_disabled,
  checkbox_full_enabled,
} from 'src/assets'
import { checkbox_props, dict } from 'src/types'
import { is_required } from 'src/utils'

import './check-box.scss'

const SVG: dict<string> = {
  'full-disabled': checkbox_full_disabled,
  'full-enabled': checkbox_full_enabled,
  'empty-disabled': checkbox_empty_disabled,
  'empty-enabled': checkbox_empty_enabled,
}

export const CheckBox: FC<checkbox_props> = ({
  id,
  formik_props,
  formik_schema,
  touched,
  disabled,
  label,
  error,
  classes = 'w-100',
  classes_field = 'd-flex width-fit-content',
  classes_label = 'm-0 p-0 font-16 d-flex align-items-center',
  classes_error = 'm-0 p-0 font-14 c-862633',
}: checkbox_props) => {
  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label
    const svg = `${formik_props?.value ? 'full' : 'empty'}-${disabled ? 'disabled' : 'enabled'}`
    const src = SVG[svg]
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? 'd-block' : 'd-none'}
          ${label ? '' : 'opacity-0'}
          ${disabled ? 'c-7f7f7f' : ''}
        `}
        role="button"
      >
        <Image src={src} className="check m-0 p-0 mr-2" />
        {`${label_} `}
      </label>
    )
  }

  const render_error = () => (
    <p
      className={`
        ${classes_error}
        ${classes_error ? 'd-block' : 'd-none'}
        ${touched && error ? '' : 'opacity-0'}
      `}
    >
      {`${error} `}
    </p>
  )

  return (
    <main className={classes}>
      <div className={classes_field} role="button">
        <input id={id} type="checkbox" disabled={disabled} hidden={true} {...formik_props} />
        {render_label()}
      </div>
      {render_error()}
    </main>
  )
}
