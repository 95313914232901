import { useFormik } from 'formik'
import { FC, useRef } from 'react'
import i18n from 'src/i18n'
import { otp_modal_props } from 'src/types'
import { yotp_required } from 'src/utils'
import { Button, InputText, Modal } from 'src/widgets'
import * as Yup from 'yup'

export const OTPModal: FC<otp_modal_props> = ({
  look = '',
  title,
  description,
  wrong_otp = i18n.t('commons.otp.wrong'),
  on_close,
  on_verify,
  on_resend,
  operation,
}: otp_modal_props) => {
  /**
   * Formik
   */
  const i_values = useRef({ otp: '' })
  const i_schema = Yup.object({ otp: yotp_required })
  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setErrors,
    isSubmitting,
    isValid,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: i_values.current,
    validateOnMount: true,
    validationSchema: i_schema,
    onSubmit: () => {
      //
    },
  })

  return (
    <Modal
      visible={look !== ''}
      on_close={() => {
        on_close?.()
      }}
    >
      <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center text-center">
        <p className="mt-3 font-18-semibold text-uppercase">{title}</p>

        {look === 'verify' && <p className="font-16">{description}</p>}
        {look === 'resend' && <p className="font-16 mt-4">{wrong_otp}</p>}

        <InputText
          id="otp"
          formik_props={getFieldProps('otp')}
          formik_schema={i_schema}
          error={errors?.otp}
          touched={touched?.otp}
          classes="col-lg-6 col-md-8 col-sm-10 col-12"
        />

        {look === 'verify' && (
          <Button
            flavor="accent_next"
            type="submit"
            text={i18n.t('commons.otp.verify')}
            disabled={!isValid || isSubmitting}
            classes={`my-3 ${operation}`}
            on_press={() => {
              on_verify?.(values?.otp)
              setFieldValue('otp', '')
              setTimeout(() => setErrors({}))
            }}
          />
        )}

        {look === 'resend' && (
          <Button
            flavor="primary_next"
            type="submit"
            text={i18n.t('commons.otp.resend')}
            disabled={!isValid || isSubmitting}
            classes="my-3"
            on_press={on_resend}
          />
        )}
      </form>
    </Modal>
  )
}
