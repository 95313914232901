import { call, put } from 'redux-saga/effects'
import {
  http_check_token,
  http_check_token_imp,
  http_check_token_imp_i360_age,
  http_check_token_imp_i360_dir,
  http_login,
  http_logout,
  http_magic_login,
  http_password_expired,
  http_refresh_token,
  http_refresh_token_imp,
  http_refresh_token_imp_i360_age,
  http_refresh_token_imp_i360_dir,
  http_require_sso_otp,
  http_reset_password,
  http_unlock_user,
} from 'src/http'
import {
  ax_client_bearer,
  ax_post_check_token,
  ax_post_check_token_imp,
  ax_post_refresh_token,
  ax_post_refresh_token_imp,
  ax_post_token,
  ax_put_magic_login,
  ax_put_password_expired,
  ax_put_reset_password,
  ax_put_unlock_user,
  check_token_t,
  login_token_t,
  response_t,
  saga_act,
} from 'src/types'

import { shoot, startup_creators } from '../actions'

/**
 * Login
 */
export function* saga_login(action: saga_act<ax_post_token>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(http_login, action)

  if (error) {
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Logout
 */
export function* saga_logout(action: saga_act<ax_client_bearer>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: outcome }: response_t<string> = yield call(http_logout, action)

  yield put(startup_creators.reduce_reset())
  yield put(shoot.reduce_outcome_logout(outcome))
  yield put(shoot.reduce_already_logged(false))

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Check token
 */
export function* saga_check_token(action: saga_act<ax_post_check_token>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: check_token, error }: response_t<check_token_t> = yield call(
    http_check_token,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
  } else {
    yield put(shoot.reduce_check_token(check_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Refresh token
 */
export function* saga_refresh_token(action: saga_act<ax_post_refresh_token>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_refresh_token,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
    yield put(shoot.reduce_login_token())
    yield put(shoot.reduce_check_token())
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Login with magic link
 */
export function* saga_magic_login(action: saga_act<ax_put_magic_login>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_magic_login,
    action
  )

  if (error) {
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Reset password
 */
export function* saga_reset_password(action: saga_act<ax_put_reset_password>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_reset_password,
    action
  )

  if (error) {
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_outcome_reset_password('success'))
    if (action?.args?.magic_link) {
      yield put(shoot.reduce_logged(true))
      yield put(shoot.reduce_login_token(login_token))
    }
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Update Password Expired
 */
export function* saga_password_expired(action: saga_act<ax_put_password_expired>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_password_expired,
    action
  )

  if (error) {
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Unlock user
 */
export function* saga_unlock_user(action: saga_act<ax_put_unlock_user>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_unlock_user,
    action
  )

  if (error) {
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Check token impersonification
 */
export function* saga_check_token_imp(action: saga_act<ax_post_check_token_imp>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: check_token, error }: response_t<check_token_t> = yield call(
    http_check_token_imp,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_check_token(check_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Check token impersonification i360 dir
 */
export function* saga_check_token_imp_i360_dir(action: saga_act<ax_post_check_token_imp>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: check_token, error }: response_t<check_token_t> = yield call(
    http_check_token_imp_i360_dir,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_check_token(check_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Check token impersonification i360 age
 */
export function* saga_check_token_imp_i360_age(action: saga_act<ax_post_check_token_imp>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: check_token, error }: response_t<check_token_t> = yield call(
    http_check_token_imp_i360_age,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
    yield put(shoot.reduce_error(error))
  } else {
    yield put(shoot.reduce_check_token(check_token))
  }

  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Refresh token impersonification
 */
export function* saga_refresh_token_imp(action: saga_act<ax_post_refresh_token_imp>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_refresh_token_imp,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
    yield put(shoot.reduce_login_token())
    yield put(shoot.reduce_check_token())
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Refresh token impersonification i360 dir
 */
export function* saga_refresh_token_imp_i360_dir(
  action: saga_act<ax_post_refresh_token_imp>
): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_refresh_token_imp_i360_dir,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
    yield put(shoot.reduce_login_token())
    yield put(shoot.reduce_check_token())
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Refresh token impersonification i360 age
 */
export function* saga_refresh_token_imp_i360_age(
  action: saga_act<ax_post_refresh_token_imp>
): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: login_token, error }: response_t<login_token_t> = yield call(
    http_refresh_token_imp_i360_age,
    action
  )

  if (error) {
    yield put(shoot.reduce_logged(false))
    yield put(shoot.reduce_login_token())
    yield put(shoot.reduce_check_token())
  } else {
    yield put(shoot.reduce_logged(true))
    yield put(shoot.reduce_login_token(login_token))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}

/**
 * Require SSO
 */
export function* saga_require_sso_otp(action: saga_act<ax_client_bearer>): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const { data: sso_otp }: response_t<string> = yield call(http_require_sso_otp, action)
  yield put(shoot.reduce_sso_otp(sso_otp))

  yield put(shoot.reduce_loading(false, action?.type))
}
