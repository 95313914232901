export const SAGA_GUARDS = {
  AGENCIES: ['SAGA_RESOLVE_CUSTOMER', 'SAGA_RESOLVE_POLICIES', 'SAGA_RESOLVE_AGENCIES'],
  ASSISTANCE: {
    policies: ['SAGA_RESOLVE_POLICIES', 'SAGA_RESOLVE_POLICIES_ASSISTANCE'],
    agencies: ['SAGA_RESOLVE_AGENCIES'],
  },
  CLAIM: ['SAGA_RESOLVE_DISTRICTS', 'SAGA_UPDATE_POLICY', 'SAGA_VERIFY_POLICY'],
  COMMUNITY: ['SAGA_RESOLVE_VOUCHERS'],
  DOCUMENTS: [
    'SAGA_RESOLVE_DOCUMENTS',
    'SAGA_RESOLVE_COMMUNICATIONS',
    'SAGA_DOWNLOAD_DOCUMENT',
    'SAGA_SEND_DOCUMENT',
  ],
  ELECTIONS: ['SAGA_ANSWER_POLL'],
  HOMEPAGE: ['SAGA_RESOLVE_POLICIES'],
  POLICY_DETAIL: {
    fast: [
      'SAGA_RESOLVE_COMMUNICATIONS',
      'SAGA_RESOLVE_HISTORY_REQUESTS',
      'SAGA_RESOLVE_AGENCIES',
      'SAGA_UPDATE_POLICY',
      'SAGA_VERIFY_POLICY',
      'SAGA_UPDATE_CUSTOMER',
      'SAGA_RESOLVE_CUSTOMER',
      'SAGA_DOWNLOAD_DOCUMENT',
      'SAGA_SEND_DOCUMENT',
    ],
    detail: ['SAGA_RESOLVE_POLICY_DETAIL'],
    docs: ['SAGA_RESOLVE_DOCUMENTS_POLICY', 'SAGA_RESOLVE_DOCUMENTS'],
  },
  POLICIES: ['SAGA_RESOLVE_POLICIES'],
  RENEWAL: ['SAGA_MAKE_PAYMENT', 'SAGA_CHECK_PAYMENT'],
  REQUEST_HISTORY: ['SAGA_RESOLVE_HISTORY_REQUESTS'],
  VOUCHER_DETAIL: ['SAGA_RESOLVE_POLICIES', 'SAGA_DOWNLOAD_VOUCHER', 'SAGA_SEND_VOUCHER'],
  FOOTER: ['SAGA_RESOLVE_FOOTER'],
  LOGIN: ['SAGA_CHECK_TOKEN_IMP', 'SAGA_CHECK_TOKEN_IMP_I360_DIR', 'SAGA_CHECK_TOKEN_IMP_I360_AGE'],
}
