export * from './saga-guards'

export const NAV_TO_HOMEPAGE = '/homepage'
export const NAV_TO_LOGIN = '/login'
export const NAV_TO_REGISTRATION = '/registrazione'
export const NAV_TO_POLICIES = '/polizze'
export const NAV_TO_ASSISTANCE = '/assistenza'
export const NAV_TO_ON_BOARDING = '/onboarding'
export const NAV_TO_RECOVERY_CREDENTIALS = '/recupero-credenziali'
export const NAV_TO_NEW_PASSWORD = '/nuova-password'
export const NAV_TO_PASSWORD_RESET = '/reset-password'
export const NAV_TO_PASSWORD_EXPIRED = '/password-scaduta'
export const NAV_TO_SUPPORT = '/supporto'
export const NAV_TO_PROFILE = '/profilo'
export const NAV_TO_FAQS = '/domande-frequenti'
export const NAV_TO_CHANGE_RESIDENCE = '/modifica-residenza'
export const NAV_TO_ON_REGISTRATION_CONFIRMATION = '/conferma-registrazione'
export const NAV_TO_UNLOCK_USER = '/sblocco-recupero-password'
export const NAV_TO_SETUP_PASSWORD = '/impostazione-password'
export const NAV_TO_ON_IMPERSONIFICATION = '/impersonificazione'
export const NAV_TO_POLICY_DETAIL = '/dettaglio-polizza'
export const NAV_TO_DOCUMENTS = '/documenti'
export const NAV_TO_REQUESTS_HISTORY = '/storico-modifiche'
export const NAV_TO_CLAIM = '/liquidazione-sinistro'
export const NAV_TO_DEPOSIT = '/versamento-aggiuntivo'
export const NAV_TO_REDEMPTION = '/riscatto'
export const NAV_TO_RENEWAL = '/rinnovo'
export const NAV_TO_ON_PAYMENT_OUTCOME = '/esito-pagamento'
export const NAV_TO_AGENCIES = '/agenzie'
export const NAV_TO_AGENCY_DETAIL = '/dettaglio-agenzia'
export const NAV_TO_COMMUNITY = '/community'
export const NAV_TO_SERVICIES = '/servizi'
export const NAV_TO_VOUCHER_DETAIL = '/dettaglio-voucher'
export const NAV_TO_MAINTENANCE = '/manutenzione'
export const NAV_TO_NOT_SUPPORTED_BROWSER = '/browser-non-supportato'
export const NAV_TO_ASSISTANCE_DETAIL = '/dettaglio-assistenza'
export const NAV_TO_NOT_COOKIE_POLICY = '/cookie-policy'
export const NAV_TO_DATAMATRIX = '/prova-datamatrix'

export const NAV_TO_INSTITUTIONAL_SITE: string = process.env.REACT_APP_BASE_ROOT_FOOTER_LINK
  ? process.env.REACT_APP_BASE_ROOT_FOOTER_LINK
  : ''
