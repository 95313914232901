import './setup-password.scss'

import base64 from 'base-64'
import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Card, Form, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { info_x_icon, valid_icon } from 'src/assets'
import i18n from 'src/i18n'
import { NAV_TO_HOMEPAGE } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import { select_error, select_logged, select_login_token } from 'src/redux/store'
import {
  regex_password_lowercase,
  regex_password_number,
  regex_password_uppercase,
} from 'src/regex'
import { CheckboxStateProps } from 'src/types/--deprecated/types'
import { unescape_html } from 'src/utils'
import { format_text, isNotNullOrUndefinedOrEmpty } from 'src/utils/--deprecated'
import { Tooltip } from 'src/widgets'
import Accordion from 'src/widgets/--deprecated/accordion/accordion'
import Button from 'src/widgets/--deprecated/button/button'
import CheckBox from 'src/widgets/--deprecated/check-box/check-box'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import Modal, {
  modalProps_genericError,
  ModalPropsDataType,
} from 'src/widgets/--deprecated/modal/modal'
import ProgressBar from 'src/widgets/--deprecated/progress-bar/progress-bar'
import { progress_bar_config } from 'src/widgets/--deprecated/progress-bar/progress-bar-config'
import { Recaptcha, submit_captcha } from 'src/widgets/recaptcha/recaptcha'
import * as Yup from 'yup'

const SetupPassword: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const tr_modal_error: any = t('modal_generic_tecnical_error', {
    returnObjects: true,
  })

  const username = new URLSearchParams(useLocation().search).get('username')
  const magic_link = new URLSearchParams(useLocation().search).get('magicLink')

  /**
   * Use State
   */
  const [show_modal, set_show_modal] = useState(false)
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 810)
  const [checkbox_selected, set_checkbox_selected] = useState<CheckboxStateProps>({})
  const [captcha, set_captcha] = useState('')
  const [password, set_password] = useState('')

  const [modal_info, set_modal_info] = useState({
    title: t('setup_password.info_modal.title'),
    options: [
      { id: '1', text: t('setup_password.info_modal.option_1'), valid: '' },
      { id: '2', text: t('setup_password.info_modal.option_2'), valid: '' },
      { id: '3', text: t('setup_password.info_modal.option_3'), valid: '' },
      { id: '4', text: t('setup_password.info_modal.option_4'), valid: '' },
    ],
  })

  /**
   * Use Selector
   */
  const error = useSelector(select_error())
  const logged = useSelector(select_logged)
  const login_token = useSelector(select_login_token)

  /**
   * Use effect
   */

  useEffect(() => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }, [])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  useEffect(() => {
    if (logged && login_token) {
      history.push(NAV_TO_HOMEPAGE)
    }
  }, [logged, login_token])

  useEffect(() => {
    if (captcha && password) {
      dispatch(
        shoot.saga_magic_login({ username, password: base64.encode(password), magic_link, captcha })
      )
    }
  }, [captcha, password])

  /**
   * Functions
   */
  const toggle_modal = (value: boolean) => {
    set_show_modal(value)
  }

  const on_selected_handler = (id: string, selected: boolean) => {
    set_checkbox_selected({
      [id]: {
        value: !selected,
      },
    })
  }

  const reset_password = (password: string) => {
    set_password('')
    set_captcha('')
    submit_captcha().then((token: string) => {
      set_password(password)
      set_captcha(token)
    })
  }

  /**
   * Render functions
   */

  const render_text_accordion = (): string => {
    return `<div class="text-wrap">${t('setup_password.accordion_text')}
<a
  class="setup-password-paragraph-link"
  href="https://www.vhv.it"
  rel="noopener noreferrer"
  target="_blank"
>
  www.vhv.it
</a></div>`
  }

  /**
   * Error Props
   */
  const modal_props: ModalPropsDataType = {
    ...modalProps_genericError.data,
    showTitle: error && isNotNullOrUndefinedOrEmpty(error.title) ? true : false,
    title: error?.title || tr_modal_error.title,
    showText: error && isNotNullOrUndefinedOrEmpty(error.description_fe) ? true : false,
    text: error?.description_fe || tr_modal_error.text,
    backButtonText: tr_modal_error.backButtonText,
    nextButtonText: tr_modal_error.nextButtonText,
    closeButtonOnClick: () => dispatch(shoot.reduce_error()),
    backButtonOnClick: () => dispatch(shoot.reduce_error()),
    nextButtonOnClick: () => dispatch(shoot.reduce_error()),
  }

  /**
   * Validation schema
   */
  const validation_schema = Yup.object().shape({
    password: Yup.string()
      .required(() => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('setup_password.errors.password_required')
      })
      .min(8, () => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('setup_password.errors.password_invalid')
      })
      .max(7, () => {
        modal_info.options[0].valid = 'valid'
        set_modal_info(modal_info)
      })
      .test('', t('setup_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_uppercase)) {
          modal_info.options[1].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[1].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('setup_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_lowercase)) {
          modal_info.options[2].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[2].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('setup_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_number)) {
          modal_info.options[3].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[3].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      }),
    password_confirmed: Yup.string().test(
      'passwords-match',
      t('setup_password.input_password_2_error'),
      function (value) {
        return this.parent.password === value
      }
    ),
  })

  return (
    <Card className="setup-password-container" onClick={() => toggle_modal(false)}>
      <Recaptcha />
      <div className="setup-password-progress-bar">
        <ProgressBar elements={progress_bar_config[2]} />
      </div>
      <div className="unnamed-character-style-2 setup-password-type">
        {t('setup_password.type')}
      </div>
      <div className="unnamed-character-style-30 setup-password-title">
        {t('setup_password.title')}
      </div>
      <div className="unnamed-character-style-2 setup-password-description">
        {format_text(t('setup_password.description'), 'unnamed-character-style-1-bold')}
      </div>
      <div className="setup-password-body">
        <Formik
          key="password"
          validationSchema={validation_schema}
          initialValues={{
            password: '',
            password_confirmed: '',
          }}
          onSubmit={(values) => {
            reset_password(values.password)
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="setup-password-inputs">
                <Form.Group className="setup-password-inputs--password" controlId="password">
                  <div className="setup-password-label-icon">
                    <Form.Label className="unnamed-character-style-27-13-22 m-0 p-0">
                      {t('setup_password.input_password_1_over_text')}
                    </Form.Label>
                    <Tooltip placement="top">
                      <div>
                        <div className="text-left mb-2">{modal_info.title}</div>
                        {modal_info.options.map((data, index) => (
                          <li
                            key={index}
                            className={`text-left ${
                              data.valid === 'valid'
                                ? 'c-159881'
                                : data.valid === 'invalid'
                                ? 'c-862633'
                                : ''
                            }`}
                          >
                            {data.text}
                            {data.valid === 'valid' ? (
                              <Image src={valid_icon} width={12} className="ml-2" />
                            ) : data.valid === 'invalid' ? (
                              <Image src={info_x_icon} className="ml-2" />
                            ) : (
                              ''
                            )}
                          </li>
                        ))}
                      </div>
                    </Tooltip>{' '}
                  </div>

                  <InputTxt
                    id="password"
                    placeholder={t('setup_password.input_password_1_placeholder')}
                    type="password"
                    value={values.password}
                    on_change={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e)
                      handleBlur(e)
                      setFieldValue('password', e.target.value)
                    }}
                    validators={[
                      {
                        is_valid: () =>
                          !((touched.password && errors.password) || values.password === ''),
                        text: errors.password || '',
                      },
                    ]}
                  />
                  {error && !error.is_tech && !(touched.password && errors.password) ? (
                    <div className="error-character">{error?.description_fe}</div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  className="setup-password-inputs--password-confirmed"
                  controlId="password_confirmed"
                >
                  <InputTxt
                    id="password_confirmed"
                    placeholder={t('setup_password.input_password_2_placeholder')}
                    type="password"
                    value={values.password_confirmed}
                    on_change={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e)
                      handleBlur(e)
                      setFieldValue('password_confirmed', e.target.value)
                    }}
                    validators={[
                      {
                        is_valid: () =>
                          !(
                            (touched.password_confirmed && errors.password_confirmed) ||
                            values.password_confirmed === ''
                          ),
                        text: errors.password_confirmed || '',
                      },
                    ]}
                  />
                </Form.Group>
              </div>
              <div className="setup-password-checkbox">
                <CheckBox
                  id="setup_password_id_checkbox"
                  text={t('setup_password.checkbox_text')}
                  item_style="unnamed-character-style-10"
                  selected={
                    checkbox_selected['setup_password_id_checkbox']
                      ? checkbox_selected['setup_password_id_checkbox'].value
                      : false
                  }
                  checked={on_selected_handler}
                />
              </div>
              <div className="setup-password-paragraph">
                {t('setup_password.paragraph')}
                <a
                  className="setup-password-paragraph-link"
                  href="mailto:privacy@vhv.it"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('setup_password.paragraph_link')}
                </a>

                {t('setup_password.paragraph_after_link')}
              </div>

              <div className="setup-password-accordion">
                <Accordion
                  title={t('setup_password.accordion_title')}
                  title_style="unnamed-character-style-2 uppercase-text"
                  content={unescape_html(render_text_accordion())}
                  line_color="C2CDDD"
                  arrow_up="arrow-accordion-black-up"
                  arrow_bottom="arrow-accordion-black-bottom"
                />
              </div>

              <div className="setup-password-button-submit">
                <Button
                  justify_content="center"
                  name="full_059881"
                  disabled={
                    values.password == '' ||
                    values.password_confirmed == '' ||
                    errors.password != undefined ||
                    errors.password_confirmed != undefined ||
                    !(
                      checkbox_selected['setup_password_id_checkbox'] &&
                      checkbox_selected['setup_password_id_checkbox'].value
                    )
                  }
                >
                  <div>{t('setup_password.button_text')}</div>
                  <div className="right-arrow-white" />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {error?.is_tech && <Modal data={modal_props} />}
    </Card>
  )
}

export default SetupPassword
