import { FC } from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { is_md } from 'src/utils'

const Maintenance: FC = () => {
  const { t } = useTranslation()

  return (
    <Row className={`m-0 p-3 p-md-5 flex-1 d-flex justify-content-center align-items-center`}>
      <div>
        <div className={`${!is_md ? 'font-36 text-center' : 'font-56'}`}>
          {t('maintenance.title')}
        </div>
        <div
          className={`${
            !is_md ? 'font-24 text-center mt-2 font-20 c-bfbfbf' : 'mt-2 font-20 c-bfbfbf'
          } `}
        >
          {t('maintenance.subtitle')}
        </div>
      </div>
    </Row>
  )
}

export default Maintenance
