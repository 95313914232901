import {
  get_policies,
  get_policies_assistance,
  get_policy_detail,
  policies_client,
  post_policy,
  put_policy,
} from 'src/axios'
import { outcome_w } from 'src/backend-codes'
import {
  ax_get_policies,
  ax_get_policies_assistance,
  ax_get_policy_detail,
  ax_post_policy,
  ax_put_policy,
  policy_assistance_t,
  policy_detail_t,
  policy_outcome_t,
  policy_t,
  response_t,
  saga_act,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve policies
 */
export async function http_resolve_policies({
  args,
  type,
}: saga_act<ax_get_policies>): Promise<response_t<policy_t[]>> {
  return do_request<policy_t[]>({
    client: policies_client,
    request: get_policies(v4(), args),
    child: 'policies',
    saga_type: type,
  })
}

/**
 * Resolve policy detail
 */
export async function http_resolve_policy_detail({
  args,
  type,
}: saga_act<ax_get_policy_detail>): Promise<response_t<policy_detail_t>> {
  return do_request<policy_detail_t>({
    client: policies_client,
    request: get_policy_detail(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Update policy
 */
export async function http_update_policy({
  args,
  type,
}: saga_act<ax_post_policy>): Promise<response_t<policy_outcome_t>> {
  return do_request<policy_outcome_t>({
    client: policies_client,
    request: post_policy(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Verify policy
 */
export async function http_verify_policy({
  args,
  type,
}: saga_act<ax_put_policy>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: policies_client,
    request: put_policy(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Resolve assistance policies
 */
export async function http_resolve_assitance_policies({
  args,
  type,
}: saga_act<ax_get_policies_assistance>): Promise<response_t<policy_assistance_t[]>> {
  return do_request<policy_assistance_t[]>({
    client: policies_client,
    request: get_policies_assistance(v4(), args),
    child: 'policies',
    saga_type: type,
  })
}
