import { call, put } from 'redux-saga/effects'
import {
  http_decode_datamatrix,
  http_recovery_client_id,
  http_recovery_password,
  http_register_user,
  http_retrieve_dati_contraente_from_num_ordine,
  http_retrieve_dati_contraente_from_num_polizza,
  http_retrieve_dati_contraente_from_num_polizza_vita,
} from 'src/http'
import { dati_contraente_t, decoded_datamatrix_t, saga_act } from 'src/types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Register user
 */
export function* saga_register_user(action: saga_act): unknown {
  const outcome: string = yield call(saga_do_request, http_register_user, { ...action })
  if (outcome) {
    yield put(shoot.reduce_outcome_registration(outcome))
  }
}

/**
 * Recovery client id
 */
export function* saga_recovery_client_id(action: saga_act): unknown {
  const outcome: string = yield call(saga_do_request, http_recovery_client_id, { ...action })
  if (outcome) {
    yield put(shoot.reduce_outcome_recovery_client_id(outcome))
  }
}

/**
 * Recovery password
 */
export function* saga_recovery_password(action: saga_act): unknown {
  const outcome: string = yield call(saga_do_request, http_recovery_password, { ...action })
  if (outcome) {
    yield put(shoot.reduce_outcome_recovery_password(outcome))
  }
}
/**
 * Get Dati Contraente
 */

export function* saga_retrieve_dati_contraente_from_num_polizza(action: saga_act): unknown {
  yield put(shoot.reduce_loading(true, action?.type))
  const dati_contraente: dati_contraente_t = yield call(
    saga_do_request,
    http_retrieve_dati_contraente_from_num_polizza,
    action
  )
  if (dati_contraente) {
    yield put(shoot.reduce_dati_contraente(dati_contraente))
  } else {
    yield put(shoot.reduce_dati_contraente(new Object(null)))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}

export function* saga_retrieve_dati_contraente_from_num_polizza_vita(action: saga_act): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const dati_contraente: dati_contraente_t = yield call(
    saga_do_request,
    http_retrieve_dati_contraente_from_num_polizza_vita,
    action
  )
  if (dati_contraente) {
    yield put(shoot.reduce_dati_contraente(dati_contraente))
  } else {
    yield put(shoot.reduce_dati_contraente(new Object(null)))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}

export function* saga_retrieve_dati_contraente_from_num_ordine(action: saga_act): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const dati_contraente: dati_contraente_t = yield call(
    saga_do_request,
    http_retrieve_dati_contraente_from_num_ordine,
    action
  )
  if (dati_contraente) {
    yield put(shoot.reduce_dati_contraente(dati_contraente))
  } else {
    yield put(shoot.reduce_dati_contraente(new Object(null)))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}

export function* saga_post_decode_datamatrix(action: saga_act): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  const decoded_datamatrix: decoded_datamatrix_t = yield call(
    saga_do_request,
    http_decode_datamatrix,
    action
  )
  if (decoded_datamatrix) {
    yield put(shoot.reduce_decoded_datamatrix(decoded_datamatrix))
  } else {
    yield put(shoot.reduce_decoded_datamatrix(new Object(null)))
  }
  yield put(shoot.reduce_loading(false, action?.type))
}
