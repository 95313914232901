/* eslint-disable @typescript-eslint/no-explicit-any */

import { call, put, select } from 'redux-saga/effects'
import { react_native_window } from 'src/app'
import { BE_ERROR_CODES } from 'src/backend-codes'
import { http_refresh_token } from 'src/http'
import { error_t, login_token_t, opt, response_t, saga_act } from 'src/types'

import { shoot } from '../actions'
import { select_login_token } from '../store'

/**
 * Do request
 */
export function* saga_do_request(
  service_call: (this: unknown, ...args: any[]) => any,
  action: saga_act
): unknown {
  yield put(shoot.reduce_loading(true, action?.type))

  let attempt = 0
  let error_: opt<error_t> = {}

  while (attempt < 3) {
    /**
     * Try service call
     */
    const lt: login_token_t = yield select(select_login_token)
    const { data, error }: response_t<unknown> = yield call(service_call, {
      ...action,
      args: {
        ...action.args,
        access_token: lt?.access_token,
      },
    })

    /**
     * Success
     */
    if (data) {
      yield put(shoot.reduce_loading(false, action?.type))
      return data
    }

    /**
     * 401 failure
     */
    if (error?.status === 401 && error?.code !== BE_ERROR_CODES.DOC_UNAUTHORIZED) {
      const refresh_ok = yield call(on_401, action?.type)
      if (!refresh_ok) {
        yield put(shoot.reduce_loading(false, action?.type))
        yield put(shoot.saga_logout({ bearer_token: lt?.access_token }))
        const rn_window: react_native_window = window
        rn_window?.ReactNativeWebView?.postMessage?.('401')
      }
    } else {
      attempt = 2
    }
    error_ = error

    attempt++
  }

  /**
   * Generic failure
   */
  yield put(shoot.reduce_error(error_))
  yield put(shoot.reduce_loading(false, action?.type))
  return undefined
}

function* on_401(saga_type: string) {
  const { refresh_token }: login_token_t = yield select(select_login_token)
  if (refresh_token) {
    const { data: new_login_token }: response_t<login_token_t> = yield call(http_refresh_token, {
      args: { refresh_token },
      type: saga_type,
    })
    if (new_login_token) {
      yield put(shoot.reduce_logged(true))
      yield put(shoot.reduce_login_token(new_login_token))
      return true
    }
  }
  return false
}
