import { FC } from 'react'
import { Image } from 'react-bootstrap'
import { success_icon } from 'src/assets'
import i18n from 'src/i18n'
import { success_modal_props } from 'src/types'
import { Button } from 'src/widgets/button/button'

import { Modal } from '../modal'

export const SuccessModal: FC<success_modal_props> = ({
  visible,
  icon = success_icon,
  title,
  description,
  on_close,
  button = {
    flavor: 'accent_next',
    text: i18n.t('commons.close'),
    on_press: () => on_close?.(),
  },
}: success_modal_props) => {
  return (
    <Modal visible={visible} on_close={on_close} classes="d-flex justify-content-center">
      <div className="d-flex align-items-center justify-content-center p-1">
        <div className="d-inline text-center">
          {icon && <Image src={icon} />}
          {title && <p className="mt-5 font-16-semibold text-uppercase">{title}</p>}
          {description && <p className="mt-4 font-16 pre-line">{description}</p>}
          {button?.on_press && (
            <div className="mt-4 mb-2 d-flex align-items-center justify-content-center">
              <Button {...button} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
