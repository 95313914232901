import './button.scss'

import React from 'react'
import { ButtonComponentProps, ButtonProps } from 'src/types/--deprecated/types'
import { ClassNames } from 'src/utils/--deprecated'

// ManageButton is a component used to manage all the features of the button in a generic way
const ManageButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    id,
    target,
    classes = '',
    color,
    background_color,
    border_color,
    width,
    min_width = 'fit-content',
    max_width,
    height,
    min_height,
    max_height,
    uppercase = true,
    disabled = false,
    onClick,
    cursor,
    children,
    justify_content = 'space-around',
    text_decoration = 'unset',
    padding = '0 1.25rem',
    type,
    ...otherProps
  } = props

  return (
    <button
      id={id}
      formTarget={target}
      className={ClassNames(
        'ManageButton',
        classes,
        ...(props?.extra || []),
        disabled ? 'Disabled' : ''
      )}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={{
        width: width,
        minWidth: min_width,
        maxWidth: max_width,
        height: height,
        minHeight: min_height,
        maxHeight: max_height,
        color: color,
        textTransform: uppercase ? 'uppercase' : 'none',
        borderRadius: '0',
        justifyContent: justify_content,
        textDecoration: text_decoration,
        backgroundColor: background_color,
        borderColor: border_color,
        cursor: cursor,
        padding: padding,
      }}
      {...otherProps}
    >
      {children}
    </button>
  )
}

ManageButton.defaultProps = {
  height: '3.125rem', //default
  children: '',
}

// name: {component: ManageButton, extra: ['desired button structure']}
// The structures are defined in src\styles\structure.scss
export const buttons_object = {
  //059881 - green
  //862633 - brown
  //ffffff - white
  //A90433 - red
  //7f7f7f - grey
  text: { component: ManageButton, extra: [''] },
  text_862633: { component: ManageButton, extra: ['text_862633'] },
  text_862633_bold: { component: ManageButton, extra: ['text_862633_bold'] },
  text_059881: { component: ManageButton, extra: ['text_059881'] },
  text_059881_bold: { component: ManageButton, extra: ['text_059881_bold'] },
  text_7f7f7f_hover_862633: { component: ManageButton, extra: ['text_7f7f7f_hover_862633'] },
  text_7f7f7f_hover_862633_mobile: {
    component: ManageButton,
    extra: ['text_7f7f7f_hover_862633_mobile'],
  },
  text_A90433_bold: { component: ManageButton, extra: ['text_A90433_bold'] },
  text_15819e: { component: ManageButton, extra: ['text_15819e'] },
  text_15819e_bold: { component: ManageButton, extra: ['text_15819e_bold'] },
  full_ffffff: { component: ManageButton, extra: ['full_ffffff'] },
  full_862633: { component: ManageButton, extra: ['full_862633'] },
  full_059881: { component: ManageButton, extra: ['full_059881'] },
  full_059881_mobile: { component: ManageButton, extra: ['full_059881_mobile'] },
  border_ffffff: { component: ManageButton, extra: ['border_ffffff'] },
  border_059881: { component: ManageButton, extra: ['border_059881'] },
}

//TODO AGGIUNGERE TESTO CON UNDERLINE (ONBOARDING -> SALTA STEP)

// Button component
const Button: React.FC<ButtonComponentProps> = ({ name, ...props }: ButtonComponentProps) => {
  const ButtonComponent = buttons_object[name].component
  const extraData = buttons_object[name].extra

  return <ButtonComponent {...props} extra={extraData} />
}

export default Button
