import { FC, useState } from 'react'
import { Row } from 'react-bootstrap'
import { tab_bar_props } from 'src/types'

import './tab-bar.scss'

export const TabBar: FC<tab_bar_props> = ({ tabs, default_selected = 0 }: tab_bar_props) => {
  const [selected_tab, set_selected_tab] = useState(default_selected)

  return (
    <main>
      <Row className="m-0 p-0">
        {tabs?.map((tab, idx) => {
          const font =
            idx === selected_tab ? 'font-16-semibold c-862633 selected-tab' : 'font-16 tab'
          return (
            <p
              className={`m-0 p-0 flex-1 text-center ${font}`}
              key={idx}
              role="button"
              onClick={() => set_selected_tab(idx)}
            >
              {tab?.title}
            </p>
          )
        })}
      </Row>
      {tabs?.[selected_tab]?.child}
    </main>
  )
}
