import './password-expired.scss'

import base64 from 'base-64'
import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Container, Form, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { info_icon, info_x_icon, valid_icon } from 'src/assets'
import { BE_ERROR_CODES, SEVERITY_CODES } from 'src/backend-codes'
import { severity_img_mapping } from 'src/error-management/errors'
import i18n from 'src/i18n'
import { NAV_TO_HOMEPAGE, NAV_TO_LOGIN } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import { select_error, select_loading, select_logged, select_login_token } from 'src/redux/store'
import {
  regex_password_lowercase,
  regex_password_number,
  regex_password_uppercase,
} from 'src/regex'
import { error_t, login_token_t, oauth_credential_t } from 'src/types'
import { ClassNames } from 'src/utils/--deprecated'
import { Loader, Tooltip } from 'src/widgets'
import Button from 'src/widgets/--deprecated/button/button'
import InfoModal from 'src/widgets/--deprecated/info-modal/info-modal'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import Modal, {
  modalProps_genericError,
  ModalPropsDataType,
} from 'src/widgets/--deprecated/modal/modal'
import { Recaptcha, submit_captcha } from 'src/widgets/recaptcha/recaptcha'
import * as Yup from 'yup'

const PasswordExpired: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const username = new URLSearchParams(useLocation().search).get('username') || undefined

  const loading = useSelector(select_loading())
  const error: error_t | undefined = useSelector(select_error())
  const is_logged = useSelector(select_logged)
  const login_token: login_token_t | undefined = useSelector(select_login_token)

  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 1200)
  const [show_modal, set_show_modal] = useState(false)
  const [error_visible, set_error_visible] = useState(false)
  const [captcha, set_captcha] = useState('')
  const [credential, set_credential] = useState<oauth_credential_t>()

  const [modal_info, set_modal_info] = useState({
    title: t('password_expired.info_modal.title'),
    options: [
      { id: '1', text: t('password_expired.info_modal.option_1'), valid: '' },
      { id: '2', text: t('password_expired.info_modal.option_2'), valid: '' },
      { id: '3', text: t('password_expired.info_modal.option_3'), valid: '' },
      { id: '4', text: t('password_expired.info_modal.option_4'), valid: '' },
    ],
  })

  const toggle_modal = (value: boolean) => {
    set_show_modal(value)
  }

  const nav_to_login = () => {
    history.push(NAV_TO_LOGIN)
  }

  const update_password_expired = (credential: oauth_credential_t) => {
    set_captcha('')
    set_credential(undefined)
    submit_captcha().then((token: string) => {
      set_captcha(token)
      set_credential(credential)
    })
  }

  const dismiss_error = () => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }

  //Use effect

  useEffect(() => {
    if (
      !error ||
      (error.code !== BE_ERROR_CODES.PASSWORD_EXPIRED &&
        error.code !== BE_ERROR_CODES.CHANGE_PW_POLICY_INVALID)
    ) {
      nav_to_login()
    }
  }, [])

  useEffect(() => {
    if (is_logged === true && login_token !== undefined) {
      dispatch(shoot.saga_resolve_customer())
      history.push(NAV_TO_HOMEPAGE)
    }
  }, [is_logged, login_token])

  useEffect(() => {
    if (error?.is_tech) set_error_visible(true)
  }, [error])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 1200
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  useEffect(() => {
    if (captcha && credential) {
      dispatch(shoot.saga_password_expired({ credential, captcha }))
    }
  }, [captcha, credential])

  const on_click_modal_generic_error_handler = () => {
    if (error?.code === BE_ERROR_CODES.PW_EXP_USER_NOT_FOUND) {
      dismiss_error()
      set_error_visible(false)
      nav_to_login()
    } else {
      dismiss_error()
      set_error_visible(false)
    }
  }

  const modal_generic_error_props: ModalPropsDataType = {
    ...modalProps_genericError.data,
    imgType: error?.severity
      ? severity_img_mapping[error.severity]
      : severity_img_mapping[SEVERITY_CODES.FATAL],
    title: error?.title || '',
    text: error?.description_fe || error?.description_be || '',
    backButtonText: i18n.t('modal_generic_tecnical_error.backButtonText'),
    nextButtonText: i18n.t('modal_generic_tecnical_error.nextButtonText'),
    closeButtonOnClick: on_click_modal_generic_error_handler,
    backButtonOnClick: on_click_modal_generic_error_handler,
    nextButtonOnClick: on_click_modal_generic_error_handler,
  }

  //Form validation schema
  const validation_schema = Yup.object().shape({
    password: Yup.string()
      .required(t('password_expired.errors.password_required'))
      .min(8, t('password_expired.errors.password_invalid')),
    newPassword: Yup.string()
      .required(() => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('new_password.errors.password_required')
      })
      .min(8, () => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('new_password.errors.password_invalid')
      })
      .max(7, () => {
        modal_info.options[0].valid = 'valid'
        set_modal_info(modal_info)
      })
      .test('', t('new_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_uppercase)) {
          modal_info.options[1].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[1].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('new_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_lowercase)) {
          modal_info.options[2].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[2].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('new_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_number)) {
          modal_info.options[3].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[3].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      }),
    passwordConfirmed: Yup.string()
      .required(t('password_expired.errors.password_required'))
      .test('passwords-match', t('new_password.errors.password_different'), function (value) {
        return this.parent.newPassword === value
      }),
  })

  return (
    <Container fluid>
      {loading ? (
        <div className="login-loader">
          <Loader />{' '}
        </div>
      ) : (
        <div>
          <Row>
            <div className="password-expired-button-back">
              <Button name="text_059881" onClick={nav_to_login} padding="0">
                <div className="left-arrow-green" />
                <div> {t('password_expired.back_button')}</div>
              </Button>
            </div>
          </Row>

          <Row className="password-expired-container">
            <div onClick={() => toggle_modal(false)}>
              <div
                className={ClassNames(
                  'password-expired-title',
                  is_mobile ? 'unnamed-character-style-30' : 'unnamed-character-style-29'
                )}
              >
                {t('password_expired.title')}
              </div>
              <div
                className={ClassNames(
                  'password-expired-sutitle',
                  is_mobile ? 'unnamed-character-style-15-26-7f7f7f' : 'unnamed-character-style-18'
                )}
              >
                {t('password_expired.subtitle')}
              </div>

              <Recaptcha />

              <Formik
                key="password"
                validationSchema={validation_schema}
                initialValues={{
                  password: '',
                  passwordConfirmed: '',
                  newPassword: '',
                }}
                onSubmit={(values) => {
                  const credential: oauth_credential_t = {
                    username: username,
                    password: base64.encode(values.password),
                    newPassword: base64.encode(values.newPassword),
                  }
                  update_password_expired(credential)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="password-expired-input-container" controlId="password">
                      <Form.Label
                        className={ClassNames(
                          'password-expired-input-label',
                          'password-expired-input-label--actual-password',
                          is_mobile
                            ? 'unnamed-character-style-27-13-22'
                            : 'unnamed-character-style-48'
                        )}
                      >
                        {t('password_expired.forms.password')}
                      </Form.Label>

                      <InputTxt
                        id="password"
                        placeholder={t('password_expired.forms.password_placeholder')}
                        type="password"
                        value={values.password}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('password', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !((touched.password && errors.password) || values.password === ''),
                            text: errors.password || '',
                          },
                        ]}
                      />
                    </Form.Group>

                    <Form.Group
                      className="password-expired-input-container"
                      controlId="newPassword"
                    >
                      <div className="password-expired-input-icon-container">
                        <Form.Label
                          className={ClassNames(
                            'password-expired-input-label',
                            is_mobile
                              ? 'unnamed-character-style-27-13-22'
                              : 'unnamed-character-style-48'
                          )}
                        >
                          {t('password_expired.forms.new_password')}
                        </Form.Label>
                        <Tooltip placement="top">
                          <div>
                            <div className="text-left mb-2">{modal_info.title}</div>
                            {modal_info.options.map((data, index) => (
                              <li
                                key={index}
                                className={`text-left ${
                                  data.valid === 'valid'
                                    ? 'c-159881'
                                    : data.valid === 'invalid'
                                    ? 'c-862633'
                                    : ''
                                }`}
                              >
                                {data.text}
                                {data.valid === 'valid' ? (
                                  <Image src={valid_icon} width={12} className="ml-2" />
                                ) : data.valid === 'invalid' ? (
                                  <Image src={info_x_icon} className="ml-2" />
                                ) : (
                                  ''
                                )}
                              </li>
                            ))}
                          </div>
                        </Tooltip>
                      </div>

                      <InputTxt
                        id="newPassword"
                        placeholder={t('password_expired.forms.password_placeholder')}
                        type="password"
                        value={values.newPassword}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('newPassword', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !(
                                (touched.newPassword && errors.newPassword) ||
                                values.newPassword === ''
                              ),
                            text: errors.newPassword || '',
                          },
                        ]}
                      />
                      {error && !error.is_tech && !(touched.password && errors.password) && (
                        <div className="error-character">{error?.description_fe}</div>
                      )}
                    </Form.Group>

                    <Form.Group controlId="passwordConfirmed">
                      <Form.Label
                        className={ClassNames(
                          'password-expired-input-label',
                          'password-expired-input-label--confirm-password',
                          is_mobile
                            ? 'unnamed-character-style-27-13-22'
                            : 'unnamed-character-style-48'
                        )}
                      >
                        {t('password_expired.forms.confirm_password')}
                      </Form.Label>

                      <InputTxt
                        id="passwordConfirmed"
                        placeholder={t('password_expired.forms.confirm_password_placeholder')}
                        type="password"
                        value={values.passwordConfirmed}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('passwordConfirmed', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !(
                                (touched.passwordConfirmed && errors.passwordConfirmed) ||
                                values.passwordConfirmed === ''
                              ),
                            text: errors.passwordConfirmed || '',
                          },
                        ]}
                      />
                    </Form.Group>

                    <div className="password-expired-button-submit">
                      <Button
                        justify_content="center"
                        name={is_mobile ? 'full_059881_mobile' : 'full_059881'}
                        disabled={
                          values.password == '' ||
                          values.newPassword == '' ||
                          values.passwordConfirmed == '' ||
                          errors.password != undefined ||
                          errors.newPassword != undefined ||
                          errors.passwordConfirmed != undefined
                        }
                      >
                        <div>{t('password_expired.forms.btn_label')}</div>
                        <div className="right-arrow-white" />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
        </div>
      )}
      {error_visible ? <Modal data={modal_generic_error_props} /> : ''}
    </Container>
  )
}

export default PasswordExpired
