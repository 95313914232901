import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'src/i18n'
import { shoot } from 'src/redux/actions'
import { select_error } from 'src/redux/store'
import { Button } from 'src/widgets'

const LanguageSelector: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  /**
   * Use State
   */
  const [radio_language, set_radio_language] = useState(i18n.language?.split('-')[0])

  /**
   * Use Selector
   */
  const error = useSelector(select_error())

  const on_change = (language: string) => {
    set_radio_language(language)
    change_language(language)
    dismiss_error()
  }

  const dismiss_error = () => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }

  const change_language = (language: string) => {
    i18n.changeLanguage(language)
    dispatch(shoot.saga_invalidate_cache())
    setTimeout(() => window.location.reload())
  }

  return (
    <div className="header-language-button-container">
      <Button
        text="IT"
        flavor="transparent_accent"
        classes={`
        language_character header-language-radio ${radio_language === 'it' ? 'c-862633' : ''}`}
        classes_text="font-14 text-uppercase"
        on_press={() => on_change('it')}
      >
        <div>{t('header.language_it')}</div>
      </Button>
      <div className="language_character header-separator">/</div>
      <Button
        text="DE"
        flavor="transparent_accent"
        classes={`
        language_character header-language-radio ${radio_language === 'de' ? 'c-862633' : ''}`}
        classes_text="font-14 text-uppercase"
        on_press={() => on_change('de')}
      >
        <div>{t('header.language_de')}</div>
      </Button>
    </div>
  )
}

export default LanguageSelector
