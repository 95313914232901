import { BE_ERROR_CODES, SEVERITY_CODES } from 'src/backend-codes'
import { error_t, raw_error, response_t } from 'src/types'

import { fe_errors } from './errors'

export const to_error = <T>(r_error: raw_error, saga_type?: string): response_t<T> => {
  const err = r_error?.response?.data?.error
  const code = err?.code || ''
  return {
    error: {
      ...fe_errors[code],
      code,
      severity: err?.severity || SEVERITY_CODES.ERROR,
      description_be: err?.description,
      status: r_error?.response?.status || 500,
      saga_type,
    },
  }
}

export const is_otp_error = (error?: error_t): boolean => {
  return (
    error?.code === BE_ERROR_CODES.INVALID_OTP1 ||
    error?.code === BE_ERROR_CODES.INVALID_OTP2 ||
    error?.code === BE_ERROR_CODES.INVALID_OTP3 ||
    error?.code === BE_ERROR_CODES.EXPIRED_OTP1 ||
    error?.code === BE_ERROR_CODES.EXPIRED_OTP2
  )
}

export const is_not_otp_error = (error?: error_t): boolean => {
  return !!error?.description_fe && !is_otp_error(error)
}
