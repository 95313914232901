import { payment_client, post_iban, post_payment, post_payment_status } from 'src/axios'
import {
  ax_post_iban,
  ax_post_payment,
  ax_post_payment_status,
  iban_outcome_t,
  payment_outcome_t,
  response_t,
  saga_act,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Generate IBAN
 */
export async function http_generate_iban({
  args,
  type,
}: saga_act<ax_post_iban>): Promise<response_t<iban_outcome_t>> {
  return do_request<iban_outcome_t>({
    client: payment_client,
    request: post_iban(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Do payment
 */
export async function http_make_payment({
  args,
  type,
}: saga_act<ax_post_payment>): Promise<response_t<payment_outcome_t>> {
  return do_request<payment_outcome_t>({
    client: payment_client,
    request: post_payment(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Check payment
 */
export async function http_check_payment({
  args,
  type,
}: saga_act<ax_post_payment_status>): Promise<response_t<payment_outcome_t>> {
  return do_request<payment_outcome_t>({
    client: payment_client,
    request: post_payment_status(v4(), args),
    child: '',
    saga_type: type,
  })
}
