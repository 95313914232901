import React from 'react'

import './progress-upload-file.scss'

interface progress_upload_file_props {
  progress: number;
}

/** Progress bar */

const Progress: React.FC<progress_upload_file_props> = (props: progress_upload_file_props) => {
  return (
    <div className="progress-bar-upload-file">
      <div className="progress-upload-file" style={{ width: props.progress + '%' }} />
    </div>
  )
}

export default Progress
