import axios from 'axios'
import { ax_get_poll, ax_post_poll } from 'src/types/axios/poll.types'

import { AUTHORIZATION_BEARER, axios_request, configify, GET, POST, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_POLL || ''

/**
 * Poll client
 */
export const poll_client = axios.create(configify({ base_url: BASE_URL }))
const CONTEXT_ROOT = 'poll'

/**
 * GET poll
 */
export const get_poll = (requestId: string, { access_token }: ax_get_poll): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * POST poll
 */
export const post_poll = (
  requestId: string,
  { access_token, ...data }: ax_post_poll
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data,
})
