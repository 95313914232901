import { AGENCY_TYPES, company_code_w, COMPANY_CODES } from 'src/backend-codes'
import {
  addr_t,
  agency_t,
  cap_t,
  consent_t,
  contact_t,
  customer_t,
  dati_contraente_t,
  decoded_datamatrix_t,
  district_t,
  document_t,
  error_t,
  history_address_t,
  history_request_t,
  locality_t,
  municipality_t,
  nation_t,
  opt,
  policy_assistance_t,
  policy_detail_t,
  policy_t,
  redux_store,
  toponym_t,
  voucher_document_t,
  voucher_t,
} from 'src/types'
import { poll_t } from 'src/types/http/poll.types'

/**
 * From customer service
 */
export const select_customer = (state: redux_store): opt<customer_t> => state?.data?.customer

export const select_customer_email = (state: redux_store): opt<string> => {
  const find_consent = (company_code: company_code_w) =>
    consents?.find((consent_) => consent_?.companyCode === COMPANY_CODES[company_code])

  const consents = state?.data?.customer?.communication?.consentsTD
  let consent = find_consent('022')
  if (!consent?.email) consent = find_consent('183')
  if (!consent?.email) consent = find_consent('178')

  const main_email = state?.data?.customer?.communication?.contacts?.find(
    (contact) => contact?.type === 'EMAIL' && contact?.flgPrimary === 'S'
  )?.value

  return consent?.email || main_email
}

export const select_contacts = (state: redux_store): opt<contact_t[]> => state?.data?.contacts

export const select_consents = (state: redux_store): opt<consent_t[]> => state?.data?.consents

export const select_language = (state: redux_store): opt<string> => state?.data?.language

export const select_nations = (state: redux_store): opt<nation_t[]> => state?.data?.nations

export const select_districts = (state: redux_store): opt<district_t[]> => state?.data?.districts

export const select_municipalities = (state: redux_store): opt<municipality_t[]> =>
  state?.data?.municipalities

export const select_localities = (state: redux_store): opt<locality_t[]> => state?.data?.localities

export const select_caps = (state: redux_store): opt<cap_t[]> => state?.data?.caps

export const select_toponyms = (state: redux_store): opt<toponym_t[]> => state?.data?.toponyms

export const select_addresses = (state: redux_store): opt<addr_t[]> => state?.data?.addresses

export const select_associated_customers = (state: redux_store): opt<customer_t[]> =>
  state?.data?.associated_customers

/**
 * From policies service
 */
export const select_policies = (state: redux_store): opt<policy_t[]> => state?.data?.policies

export const select_policy =
  (policy_number: string) =>
  (state: redux_store): opt<policy_t> =>
    select_policies(state)?.find((p) => p?.policyNumber === policy_number)

export const select_recent_policy = (state: redux_store): opt<policy_t> =>
  state?.data?.policies?.sort((p1, p2) =>
    p1?.coverageDate || '' < (p2?.coverageDate || '') ? -1 : 1
  )[0]

export const select_policy_detail = (state: redux_store): opt<policy_detail_t> =>
  state?.data?.policy_detail

export const select_policies_assistance = (state: redux_store): opt<policy_assistance_t[]> =>
  state?.data?.policies_assistance

/**
 * From activity service
 */
export const select_history_addresses = (state: redux_store): opt<history_address_t[]> =>
  state?.data?.history_addresses

export const select_history_requests = (state: redux_store): opt<history_request_t[]> => {
  let requests: history_request_t[] = []
  Object.entries(state?.data?.history_requests || {})
    ?.filter(([key, value]) => key !== 'policyNumber' && value)
    ?.forEach(([, value]) => {
      requests = requests.concat(value)
    })
  return requests
}

/**
 * From documents service
 */
export const select_documents = (state: redux_store): opt<document_t[]> => state?.data?.documents

export const select_documents_errors = (state: redux_store): opt<error_t> =>
  state?.data?.documents_errors

export const select_documents_policy = (state: redux_store): opt<document_t[]> =>
  state?.data?.documents_policy

export const select_documents_institutional = (state: redux_store): opt<document_t[]> =>
  state?.data?.documents_institutional

export const select_document = (state: redux_store): opt<document_t> => state?.data?.document

/**
 * From agencies service
 */
export const select_agencies = (state: redux_store): opt<agency_t[]> => {
  const agencies = state?.data?.agencies
  return Object.keys(agencies || {})?.map((key) => ({
    ...agencies?.[key],
    code: key,
    type: AGENCY_TYPES[agencies?.[key]?.codeType || ''],
  }))
}

export const select_agency =
  (agency_code: string) =>
  (state: redux_store): opt<agency_t> =>
    select_agencies(state)?.find((p) => p?.code === agency_code)

/**
 * From voucher service
 */
export const select_vouchers = (state: redux_store): opt<voucher_t[]> => state?.data?.vouchers

export const select_voucher =
  (voucher_code: string) =>
  (state: redux_store): opt<voucher_t> =>
    select_vouchers(state)?.find((p) => p?.idPartner === voucher_code)

export const select_voucher_document = (state: redux_store): opt<voucher_document_t> =>
  state?.data?.voucher_document

/**
 * From poll service
 */
export const select_poll = (state: redux_store): opt<poll_t> => state?.data?.poll

/**
 * From user service
 */
export const select_dati_contraente = (state: redux_store): opt<dati_contraente_t> =>
  state?.data?.dati_contraente

export const select_decoded_datamatrix = (state: redux_store): opt<decoded_datamatrix_t> =>
  state?.data?.decoded_datamatrix
