import {
  banner_cms,
  cookie_informative,
  faq_cms,
  footer_cms,
  opt,
  privacy_cms,
  reactivation_product_cms,
  redux_store,
} from 'src/types'

export const select_privacies = (state: redux_store): opt<privacy_cms[]> =>
  state?.cms?.privacies?.map((privacy) => ({
    ...privacy,
    text: privacy?.text?.replaceAll('\\n', '\n'),
  }))

export const select_banners = (state: redux_store): opt<banner_cms[]> => state?.cms?.banners

export const select_faqs = (state: redux_store): opt<faq_cms[]> => state?.cms?.faqs

export const select_footer = (state: redux_store): opt<footer_cms> => state?.cms?.footer

export const select_onboarding_active = (state: redux_store): opt<boolean> =>
  state?.cms?.onboarding_active

export const select_notes = (state: redux_store): opt<string[]> => state?.cms?.notes

export const select_privacy_link_registration = (state: redux_store): opt<string> =>
  state?.cms?.privacy_link_registration

export const select_privacy_link_profile = (state: redux_store): opt<string> =>
  state?.cms?.privacy_link_profile

export const select_digital_signature_link = (state: redux_store): opt<string> =>
  state?.cms?.digital_signature_link

export const select_digital_signature_text = (state: redux_store): opt<string> =>
  state?.cms?.digital_signature_text

export const select_reactivation_products = (state: redux_store): opt<reactivation_product_cms[]> =>
  state?.cms?.reactivation_products

export const select_maintenance_active = (state: redux_store): opt<boolean> =>
  state?.cms?.maintenance_active

export const select_cookie_informatives = (state: redux_store): opt<cookie_informative[]> =>
  state?.cms?.cookie_informatives
