import './combo-box.scss'
import 'src/styles/typography.scss'

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(() => ({
  // Autocomplete option styles
  root: {
    backgroundColor: '#ffffff',
    opacity: 1,
    '& input': {
      fontFamily: 'SofiaPro-Regular',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '0px',
      color: '#000000',
      marginTop: '-5.5px',
      marginLeft: '0.2rem',
      textTransform: 'capitalize',
    },
    '& input::placeholder': {
      fontFamily: 'SofiaPro-Regular',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '22px',
      letterSpacing: '0px',
      opacity: '1',
      color: '#7F7F7F',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      opacity: 1,
    },
    '&:focus-within': {
      backgroundColor: '#ffffff',
      opacity: 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #C2CDDD',
      borderRadius: '0',
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #C2CDDD',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0px',
      border: '1px solid #C2CDDD',
      outline: 0,
      boxShadow: '0 0 0 0',
    },
  },
  option: {
    border: '1px solid #0000001A',
    fontFamily: 'SofiaPro-Regular',
    marginLeft: '5px',
    marginRight: '5px',
    color: '#7f7f7f',
    textTransform: 'capitalize',
  },
  popupIndicator: {
    marginRight: '0.4rem',

    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  inputRoot: {
    marginTop: '0.6rem',
    height: '2.8125rem',
  },
  listbox: {
    fontFamily: 'sofia_pro_regular !important',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
}))

const ComboBox = (props: any) => {
  const classes = useStyles()
  // se viene passata la props refresh_component viene fatta la render del component quando cambiano le options
  const id = props?.refresh_component ? `${props?.id}-${props?.options?.length}` : props.id

  const handle_change = (obj: any) => {
    if (props.id === 'address') {
      obj != null && obj.description != undefined ? props.change(obj.description) : props.change('')
    } else if (props.id === 'cap') {
      obj != null && obj.postalCode != undefined ? props.change(obj.postalCode) : props.change('')
    } else {
      obj != null && obj.code != undefined ? props.change(obj.code) : props.change('')
    }
  }

  const handle_input_change = () => {
    if (props.id === 'district') {
      props?.on_input_change()
    }
  }

  return (
    <div>
      {props.options != undefined ? (
        <Autocomplete
          key={id}
          classes={classes}
          options={props.options}
          forcePopupIcon={true}
          noOptionsText={props.noOption}
          popupIcon={<div className="arrow-accordion-black-bottom" />}
          getOptionLabel={(option: any) =>
            option && Object.keys(option).length != 0 && option.description != undefined
              ? option.description?.toLowerCase()
              : ''
          }
          defaultValue={props.default ? props.default : null}
          getOptionSelected={(option, obj) =>
            props.id === 'address'
              ? option.description === obj.description
              : props.id === 'cap'
              ? option.postalCode === obj.postalCode
              : option.code === obj.code
          }
          onChange={(event, obj: any) => handle_change(obj)}
          onInputChange={() => handle_input_change()}
          renderInput={(params) => {
            props?.on_text_change?.(params?.inputProps)
            return (
              <TextField
                {...params}
                placeholder={props.placeholder}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )
          }}
        />
      ) : null}
    </div>
  )
}

export default ComboBox
