import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  /**
   * Store
   */
  reduce_privacies: ['privacies'],
  reduce_banners: ['banners'],
  reduce_faqs: ['faqs'],
  reduce_footer: ['footer'],
  reduce_onboarding_active: ['onboarding_active'],
  reduce_maintenance_active: ['maintenance_active'],
  reduce_notes: ['notes'],
  reduce_privacy_link_registration: ['privacy_link_registration'],
  reduce_privacy_link_profile: ['privacy_link_profile'],
  reduce_digital_signature_link: ['digital_signature_link'],
  reduce_digital_signature_text: ['digital_signature_text'],
  reduce_reactivation_products: ['reactivation_products'],
  reduce_cookie_informatives: ['cookie_informatives'],

  /**
   * Sagas
   */
  saga_resolve_privacies: [],
  saga_resolve_banners: ['args'],
  saga_resolve_faqs: [],
  saga_resolve_communications: ['args'],
  saga_resolve_communications_registration: ['args'],
  saga_resolve_footer: [],
})

export const cms_types = Types
export const cms_creators = Creators
