import { footer_translations } from 'src/types/--deprecated/containers/footer-translation.types'
import { FooterItems } from 'src/types/--deprecated/types'

export const products = (message: footer_translations): FooterItems => {
  return {
    id: 'products',
    title: message.title,
    list: [
      {
        name: message.text_1,
        link: message.link_1,
      },
      {
        name: message.text_2,
        link: message.link_2,
      },
      {
        name: message.text_3,
        link: message.link_3,
      },
    ],
  }
}
export const bank_insurance = (message: footer_translations): FooterItems => {
  return {
    id: 'bank_insurance',
    title: message.title,
    list: [
      {
        name: message.text_1,
        link: message.link_1,
      },
      {
        name: message.text_2,
        link: message.link_2,
      },
      {
        name: message.text_3,
        link: message.link_3,
      },
    ],
  }
}
export const quick_links = (message: footer_translations): FooterItems => {
  return {
    id: 'quick_links',
    title: message.title,
    list: [
      {
        name: message.text_1,
        link: message.link_1,
      },
      {
        name: message.text_2,
        link: message.link_2,
      },
      {
        name: message.text_3,
        link: message.link_3,
      },
      {
        name: message.text_4,
        link: message.link_4,
      },
      {
        name: message.text_5,
        link: message.link_5,
      },
      {
        name: message.text_6,
        link: message.link_6,
      },
      {
        name: message.text_7,
        link: message.link_7,
      },
      {
        name: message.text_8,
        link: message.link_8,
      },
      {
        name: message.text_9,
        link: message.link_9,
      },
      {
        name: message.text_10,
        link: message.link_10,
      },
      {
        name: message.text_11,
        link: message.link_11,
      },
      {
        name: message.text_12,
        link: message.link_12,
      },
      {
        name: message.text_13,
        link: message.link_13,
      },
    ],
  }
}

export const claims_service = (message: footer_translations): FooterItems => {
  return {
    id: 'claims_service',
    title: message.title,
    list: [
      {
        icon_email: 'email-icon',
        email: message.email,
        link: 'LINK EMAIL',
      },
      {
        label: message.fixed_network_label,
        icon_phone: 'phone-call',
        phone: message.fixed_network_phone,
        link: 'LINK PHONE 1',
        fromToDay: message.fixed_network_from_to_day,
        fromToHour: message.fixed_network_from_to_hour,
      },
      {
        label: message.mobile_foreign_network_label,
        icon_phone: 'phone-call',
        phone: message.mobile_foreign_network_phone,
        link: 'LINK PHONE 2',
        fromToDay: message.mobile_foreign_network_from_to_day,
        fromToHour: message.mobile_foreign_network_from_to_hour,
      },
    ],

    button_text: message.button_text,
    button_icon: 'write-to-service-icon',
  }
}

export const partner_contacts = (message: footer_translations): FooterItems => {
  return {
    id: 'partner_contacts',
    title: message.title,
    list: [
      {
        icon_phone: 'phone-call',
        phone: message.fixed_network_phone,
        link: 'LINK PHONE',
      },
      {
        fromToDay: message.fixed_network_from_to_day,
        fromToHour: message.fixed_network_from_to_hour,
      },
    ],

    button_text: message.button_text,
    button_icon: 'write-to-service-icon',
  }
}

export const privacy_cookie_policy = (message: footer_translations): FooterItems => {
  return {
    id: 'privacy_cookie_policy',
    title: message.policy_cookie_title_text,
    list: [
      {
        policy: message.policy_cookie_text_mutua,
        link: message.policy_cookie_text_mutua_link,
        name: message.policy_cookie_text_mutua_value,
      },
      {
        policy: message.policy_cookie_text_vita,
        link: message.policy_cookie_text_vita_link,
        name: message.policy_cookie_text_vita_value,
      },
      {
        policy: message.policy_cookie_text_heritage,
        link: message.policy_cookie_text_heritage_link,
        name: message.policy_cookie_text_heritage_value,
      },
      {
        policy: message.policy_cookie_text_insurer,
        link: message.policy_cookie_text_insurer_link,
        name: message.policy_cookie_text_insurer_value,
      },
    ],
  }
}
