import {
  ax_delete_contacts,
  ax_get_access,
  ax_get_addresses,
  ax_get_associated_customers,
  ax_get_caps,
  ax_get_consents,
  ax_get_contacts,
  ax_get_customer,
  ax_get_districts,
  ax_get_language,
  ax_get_localities,
  ax_get_municipalities,
  ax_get_nations,
  ax_post_consents,
  ax_put_access,
  ax_put_customer,
  ax_put_language,
} from 'src/types'

import axios from 'axios'

import {
  AUTHORIZATION_BEARER,
  axios_request,
  configify,
  DELETE,
  GET,
  POST,
  PUT,
  VERSION_V1,
} from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER || ''
const CONTEXT_ROOT = 'customers'

/**
 * Customer client
 */
export const customer_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * GET customer
 */
export const get_customer = (
  requestId: string,
  { access_token }: ax_get_customer
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * PUT customer
 */
export const put_customer = (
  requestId: string,
  { access_token, customer_update }: ax_put_customer
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: PUT,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: customer_update,
})

/**
 * GET contacts
 */
export const get_contacts = (
  requestId: string,
  { access_token, codes }: ax_get_contacts
): axios_request => {
  const qp = (codes || [])?.map((code) => `codes=${code}`)?.join('&')
  return {
    url: `/${VERSION_V1}/${CONTEXT_ROOT}/contacts?${qp}`,
    method: GET,
    headers: {
      requestId,
      Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
    },
  }
}

/**
 * DELETE contacts
 */
export const delete_contacts = (
  requestId: string,
  { access_token, proclie, contacts, agencies }: ax_delete_contacts
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/contacts${proclie ? '?proclie=' + proclie : ''}`,
  method: DELETE,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: {
    contacts,
    agencies,
  },
})

/**
 * GET consents
 */
export const get_consents = (
  requestId: string,
  { access_token }: ax_get_consents
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/consentsTD`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * POST consents
 */
export const post_consents = (
  requestId: string,
  { access_token, consents, proclie }: ax_post_consents
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/consentsTD${proclie ? '?proclie=' + proclie : ''}`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: {
    consentsTD: consents,
  },
})

/**
 * GET language
 */
export const get_language = (
  requestId: string,
  { access_token }: ax_get_language
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/language`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * PUT language
 */
export const put_language = (
  requestId: string,
  { access_token, language, proclie }: ax_put_language
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/language${proclie ? '?proclie=' + proclie : ''}`,
  method: PUT,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: { language },
})

/**
 * GET access
 */
export const get_access = (requestId: string, { access_token }: ax_get_access): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/access`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * PUT access
 */
export const put_access = (
  requestId: string,
  { access_token, access, proclie }: ax_put_access
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/access${proclie ? '?proclie=' + proclie : ''}`,
  method: PUT,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data: {
    firstAccess: access,
  },
})

/**
 * GET nations
 */
export const get_nations = (requestId: string, { nation_code }: ax_get_nations): axios_request => ({
  url: `/${VERSION_V1}/nations${nation_code ? '/nationCode=' + nation_code : ''}`,
  method: GET,
  headers: { requestId },
})

/**
 * GET districts
 */
export const get_districts = (
  requestId: string,
  { nation_code }: ax_get_districts
): axios_request => ({
  url: `/${VERSION_V1}/districts?nationCode=${nation_code}`,
  method: GET,
  headers: { requestId },
})

/**
 * GET municipalities
 */
export const get_municipalities = (
  requestId: string,
  { district_code }: ax_get_municipalities
): axios_request => ({
  url: `/${VERSION_V1}/municipalities?districtCode=${district_code}`,
  method: GET,
  headers: { requestId },
})

/**
 * GET localities
 */
export const get_localities = (
  requestId: string,
  { municipality_code }: ax_get_localities
): axios_request => ({
  url: `/${VERSION_V1}/localities?municipalityCode=${municipality_code}`,
  method: GET,
  headers: { requestId },
})

/**
 * GET caps
 */
export const get_caps = (
  requestId: string,
  { nation_code, district_code, municipality_code }: ax_get_caps
): axios_request => ({
  url: `/${VERSION_V1}/postalcodes?nationCode=${nation_code}&districtCode=${district_code}&municipalityCode=${municipality_code}`,
  method: GET,
  headers: { requestId },
})

/**
 * GET toponyms
 */
export const get_toponyms = (requestId: string): axios_request => ({
  url: `/${VERSION_V1}/toponyms`,
  method: GET,
  headers: { requestId },
})

/**
 * GET addresses
 */
export const get_addresses = (
  requestId: string,
  { nation_code, district_code, locality_code, desc_address, limit }: ax_get_addresses
): axios_request => ({
  url: `/${VERSION_V1}/addresses?nationCode=${nation_code}&districtCode=${district_code}&localityCode=${locality_code}&descAddress=${desc_address}&limit=${limit}`,
  method: GET,
  headers: { requestId },
})

/**
 * GET associated customers
 */
export const get_associated_customers = (
  requestId: string,
  { access_token, tax_code }: ax_get_associated_customers
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/accounts?taxCode=${tax_code}`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})
