/***********************************
 *                                 *
 *  DEPRECATE !!!                  *
 *  NON AGGIUNGERE ALTRE FUNZIONI  *
 *                                 *
 ***********************************/

import base64 from 'base-64'
import { useEffect } from 'react'
import {
  regex_generic_policy_number,
  regex_password_lowercase,
  regex_password_number,
  regex_password_uppercase,
} from 'src/regex'
import { login_token_t } from 'src/types'

import { is_valorized } from './'

export const format_text = (text: string, class_name: string): JSX.Element => {
  text = text.replaceAll('<', '&&<')
  text = text.replaceAll('>', '>&&')
  text = text.replaceAll('_', '_&&')
  const text_pieces = text.split('&&')
  return (
    <span>
      {text_pieces.map((str, index) => {
        if (str.includes('<') || str.includes('>')) {
          return (
            <label className={class_name} style={{ margin: 0 }} key={index}>
              {str.replace('<', '').replace('>', '')}
            </label>
          )
        } else if (str.includes('_')) {
          return <div key={index}>{str.replace('_', '')}</div>
        }
        return str
      })}
    </span>
  )
}

export const is_valorized_not_empty = (value: unknown): boolean => {
  return value !== null && value !== undefined && value !== ''
}

export function ClassNames(...classNames: any): any {
  return classNames.filter((className: any) => typeof className === 'string').join(' ')
}

/**
 * check the value field by type
 * @param value to check
 * @param kind to reconize the value field type
 */
export function isValid_field(value: string, kind: string): boolean {
  switch (kind) {
    case 'cf_pf':
      return isValid_codicefiscale_pf(value)
    case 'cf_pg':
      return isValid_codicefiscale_pg(value)
    case 'vat':
      return isValid_vat(value)
    case 'email':
      return isValid_email(value)
    case 'policy_general_number':
      return isValid_general_number(value)
    case 'policy_damage_number':
      return isValid_damage_number(value)
  }
  return false
}

/**
 * check the value fields by type
 * @param value to check
 * @param kind to reconize the value field type
 */
export function areValid_field(
  value1: string,
  value2: string,
  kind: string,
  fiscal_code: string,
  vat_number: string
): boolean {
  switch (kind) {
    case 'password_1':
    case 'password_2':
      return isValid_password(value1, value2, fiscal_code, vat_number)
    case 'policy_life_number_1':
    case 'policy_life_number_2':
      return isValid_policy_life_numbers(value1, value2)
  }
  return false
}

/**
 * check the policy number
 * @param value (policy life number || policy damage number)
 */
const isValid_general_number = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false
  }

  const policy_numbers = value?.split('/')

  if (policy_numbers?.length === 1) {
    return regex_generic_policy_number.test(value)
  } else {
    return false
  }
}

/**
 * check the policy damage number
 * @param value policy damage number
 */
function isValid_damage_number(value: string): boolean {
  if (value === null || value === undefined) {
    return false
  }

  // The first character of the string must be M, E, P or A, while the second part must be numbers.
  // Or it must be in the following format: xxxx.xxxxxxx.
  // Where the section before the point must assume only numerical values, those after alphanumeric
  const reg = /(^[M,E,P,A,m,e,p,a](\d+)$)|^((\d{4})\.[a-zA-Z0-9]{1,})$/
  if (!reg.test(value)) {
    return false
  }

  return true
}

/**
 * check the policy life number one and number two
 * @param value policy life number one and two
 */
function isValid_policy_life_numbers(number1: string, number2: string): boolean {
  return isValid_policy_life_number_1(number1) && isValid_policy_life_number_2(number2)
}

/**
 * check the policy life number part one
 * @param value policy life number part one
 */
function isValid_policy_life_number_1(value: string): boolean {
  // not null or undefined
  if (value === null || value === undefined || value === '') {
    return false
  }
  const reg = /^[0-9]*$/
  if (!reg.test(value)) {
    return false
  }

  return true
}

/**
 * check the policy life number part two
 * @param value policy life number part two
 */
function isValid_policy_life_number_2(value: string): boolean {
  // not null or undefined
  if (value === null || value === undefined || value === '') {
    return false
  }
  const reg = /^[0-9]{4}$/
  if (!reg.test(value)) {
    return false
  }
  return true
}

/**
 * check the password
 * @param value password
 */
export function isValid_single_password(
  password: string,
  fiscal_code: string,
  vat_number: string
): boolean {
  if (password === null || password === undefined) {
    return false
  }
  if (password_lenght(password)) {
    return false
  }
  if (password_match_regex_lowercase(password)) {
    return false
  }
  if (password_match_regex_uppercase(password)) {
    return false
  }
  if (password_match_regex_number(password)) {
    return false
  }
  if (password_includes_fc(password, fiscal_code)) {
    return false
  }
  if (password_includes_vat(password, vat_number)) {
    return false
  }
  if (passowrd_includes_birth_date(password, fiscal_code)) {
    return false
  }

  return true
}

export const password_lenght = (password: string): boolean => password.trim().length < 8

export const password_match_regex_lowercase = (password: string): boolean =>
  !password.match(regex_password_lowercase)

export const password_match_regex_uppercase = (password: string): boolean =>
  !password.match(regex_password_uppercase)

export const password_match_regex_number = (password: string): boolean =>
  !password.match(regex_password_number)

export const password_includes_fc = (password: string, fiscal_code: string): boolean =>
  password.includes(fiscal_code) && fiscal_code !== ''

export const password_includes_vat = (password: string, vat_number: string): boolean =>
  password.includes(vat_number) && vat_number !== ''

export const passowrd_includes_birth_date = (password: string, cf: string): boolean => {
  const months = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T']
  let day
  let month_index = ''
  // se il numero formato dal decimo e l'undicesimo carattere è >= 40 : cf di una donna
  // per il ottenere il giorno di nascita devo prendere la decima e undicesima cifra e sottrarre 40
  if (+cf.substring(9, 11) >= 40) {
    day = +cf.substring(9, 11) - 40
    // se il numero formato dal decimo e l'undicesimo carattere è < 40 : cf di un uomo
  } else {
    day = cf.substring(9, 11)
  }

  const year = '19' + cf.substring(6, 8)
  months.find((m, index) => {
    if (cf.substring(8, 9) === m) {
      month_index = index < 10 ? '0' + (index + 1) : '' + (index + 1)
      return true
    }
  }) || ''
  const data = day + month_index + year

  return password?.includes(data)
}

function isValid_password(
  password1: string,
  password2: string,
  fiscal_code: string,
  vat_number: string
): boolean {
  // not null or undefined
  if (
    password1 === null ||
    password1 === undefined ||
    password2 === null ||
    password2 === undefined
  ) {
    return false
  }
  // lenght >= 8
  if (password1.trim().length < 8) {
    return false
  }
  if (password1 !== password2) {
    return false
  }

  if (
    !password1.match(regex_password_lowercase) ||
    !password1.match(regex_password_uppercase) ||
    !password1.match(regex_password_number) ||
    (password1.includes(fiscal_code) && fiscal_code !== '') ||
    (password1.includes(vat_number) && vat_number !== '')
  ) {
    return false
  }

  return true
}

/**
 * check cf for pf
 * @param value codice fiscale persona fisica
 */
function isValid_codicefiscale_pf(value: string): boolean {
  // not null or undefined
  if (value === null || value === undefined || value === '') {
    return false
  }

  //\d{2}(?:[A-EHLMPR-T](?:[04][1-9]|[15]\d|[26][0-8])|[DHPS][37]0|[ACELMRT][37][01]|[AC-EHLMPR-T][26]9)|(?:[02468][048]|[13579][26])B[26]9
  // lenght equal to 16
  if (value.length !== 16) {
    return false
  }

  return true
}

/**
 * verifica la validita' del codice fiscale persona giuridica
 * @param value  codice fiscale persona giuridica
 */
function isValid_codicefiscale_pg(value: string): boolean {
  // not null or undefined
  if (value === null || value === undefined) {
    return false
  }

  const reg = /^\S+$/
  if (!reg.test(value)) {
    return false
  }
  // lenght equal to 11
  // if (value.trim().length !== 11) {
  //   return false
  // }
  // only 11 digits
  // const reg = /^\d{11}/
  // const reg = /^\d/
  // if (!reg.test(value)) {
  //   return false
  // }

  return true
}

/**
 * verifica la validita' del codice fiscale persona giuridica
 * @param value  codice fiscale persona giuridica
 */
function isValid_vat(value: string): boolean {
  // not null or undefined
  if (value === null || value === undefined) {
    return false
  }
  // lenght equal to 11
  if (value.length !== 11) {
    return false
  }
  // only 11 digits
  const reg = /^\d{11}/
  if (!reg.test(value)) {
    return false
  }

  return true
}

/**
 * verifica la validita' della email
 * @param value  email
 */
function isValid_email(value: string): boolean {
  // not null or undefined
  if (value === null || value === undefined) {
    return false
  }
  // at least one '@'
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!reg.test(value)) {
    return false
  }

  return true
}

export const isNotNullOrUndefinedOrEmpty = (value: string | undefined): boolean => {
  return value !== null && value !== undefined && value.trim().length > 0
}

export function createMonthsArray(_months_name: any): string[] {
  const months_array = Array<string>()

  months_array.push(_months_name.january)
  months_array.push(_months_name.february)
  months_array.push(_months_name.march)
  months_array.push(_months_name.april)
  months_array.push(_months_name.may)
  months_array.push(_months_name.june)
  months_array.push(_months_name.july)
  months_array.push(_months_name.august)
  months_array.push(_months_name.september)
  months_array.push(_months_name.october)
  months_array.push(_months_name.november)
  months_array.push(_months_name.december)

  return months_array
}

// TODO RIFARE
export function isTokenValid(token: login_token_t): boolean | undefined {
  return token?.expires_in !== undefined && token?.expires_in > 0
}

export const removeFirstCharacter = (s: string): string => {
  return s && s.length > 0 ? s.substring(1, s.length) : ''
}

export const useOutsideClick = (ref: any, handler: any) => {
  return useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export const convert_bytes = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  if (bytes === 0) {
    return 'n/a'
  }

  const i = parseInt('' + Math.floor(Math.log(bytes) / Math.log(1024)))

  if (i === 0) {
    return bytes + ' ' + sizes[i]
  }

  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
}

export const extract_user_name_from_jwt = (jwt_base_64: string | undefined): string => {
  let temp = jwt_base_64 ? jwt_base_64 : ''
  if (is_valorized(temp)) {
    if (
      temp.lastIndexOf('.') > 0 &&
      temp.indexOf('.') > 0 &&
      temp.lastIndexOf('.') > temp.indexOf('.')
    ) {
      temp = temp.substring(temp.indexOf('.') + 1, temp.lastIndexOf('.') - 1)
    }
    let jwt_value = base64.decode(temp)
    jwt_value = jwt_value.substring(0, jwt_value.lastIndexOf('"') + 1) + '}'
    const user_name = JSON.parse(jwt_value).user_name

    return user_name
  }
  return ''
}
