import React from 'react'
import { Modal } from 'react-bootstrap'
import { format_text } from 'src/utils/--deprecated'

import './modal-success.scss'

interface modal_success_props {
  show: boolean;
  handle_close: () => void;
  title: string;
  description: string;
}

const ModalSuccess: React.FC<modal_success_props> = (props: modal_success_props) => {
  return (
    <Modal
      centered
      show={props.show}
      onHide={props.handle_close}
      animation={false}
      className="success-modal"
    >
      <Modal.Body>
        <div className="info-card-container">
          <div className="success-x-button-style">
            <div className="x-button" onClick={props.handle_close} />
          </div>
          <div className="align-center">
            <div className="modal-success-icon" />
            {props.title && (
              <div className="modal-success-icon--title unnamed-character-style-21">
                {props.title}
              </div>
            )}
            <div className="modal-success-icon--description ">
              {format_text(props.description, 'unnamed-character-style-2')}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalSuccess
