import axios, { AxiosInstance } from 'axios'
import qs from 'querystring'
import {
  ax_client_bearer,
  ax_post_check_token,
  ax_post_check_token_imp,
  ax_post_refresh_token,
  ax_post_refresh_token_imp,
  ax_post_token,
  ax_put_magic_login,
  ax_put_password_expired,
  ax_put_reset_password,
  ax_put_unlock_user,
} from 'src/types'

import {
  AUTHORIZATION_BASIC,
  AUTHORIZATION_BASIC_IMP,
  AUTHORIZATION_BASIC_IMP_I360_AGE,
  AUTHORIZATION_BASIC_IMP_I360_DIR,
  AUTHORIZATION_BEARER,
  axios_request,
  configify,
  CONTENT_TYPE_FORM,
  DELETE,
  POST,
  PUT,
  VERSION_V1,
} from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_OAUTH || ''
const CONTEXT_ROOT = 'oauth'
const CONTEXT_APP = 'ar'

export const oauth_client = axios.create(configify({ base_url: BASE_URL }))
export const oauth_client_bearer = ({ bearer_token }: ax_client_bearer): AxiosInstance => {
  return axios.create(
    configify({
      base_url: BASE_URL,
      authorization: AUTHORIZATION_BEARER + bearer_token,
    })
  )
}
export const oauth_client_basic = axios.create(
  configify({
    base_url: BASE_URL,
    authorization: AUTHORIZATION_BASIC,
    content_type: CONTENT_TYPE_FORM,
  })
)
export const oauth_client_basic_imp = axios.create(
  configify({
    base_url: BASE_URL,
    authorization: AUTHORIZATION_BASIC_IMP,
    content_type: CONTENT_TYPE_FORM,
  })
)
export const oauth_client_basic_imp_i360_dir = axios.create(
  configify({
    base_url: BASE_URL,
    authorization: AUTHORIZATION_BASIC_IMP_I360_DIR,
    content_type: CONTENT_TYPE_FORM,
  })
)
export const oauth_client_basic_imp_i360_age = axios.create(
  configify({
    base_url: BASE_URL,
    authorization: AUTHORIZATION_BASIC_IMP_I360_AGE,
    content_type: CONTENT_TYPE_FORM,
  })
)

/**
 * POST token
 */
export const post_token = (
  requestId: string,
  { username, password, captcha }: ax_post_token
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/token`,
  method: POST,
  headers: { requestId, captcha },
  data: qs.stringify({
    grant_type: 'password',
    username,
    password,
  }),
})

/**
 * DELETE token
 */
export const delete_token = (requestId: string): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/${CONTEXT_APP}/access_token`,
  method: DELETE,
  headers: { requestId },
})

/**
 * POST check_token
 */
export const post_check_token = (
  requestId: string,
  { token }: ax_post_check_token
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/check_token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ token }),
})

/**
 * POST refresh_token
 */
export const post_refresh_token = (
  requestId: string,
  { refresh_token }: ax_post_refresh_token
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ grant_type: 'refresh_token', refresh_token }),
})

/**
 * PUT magiclink login
 */
export const put_magic_login = (
  requestId: string,
  { username, password, magic_link, captcha }: ax_put_magic_login
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/${CONTEXT_APP}/magiclink`,
  method: PUT,
  headers: { requestId, captcha },
  data: {
    username,
    password,
    magicLink: magic_link,
    tipoRichiesta: 'CONFIRM_REGISTRATION',
    idInternoRichiesta: 9,
  },
})

/**
 * PUT reset password
 */
export const put_reset_password = (
  requestId: string,
  { access_token, current_password, password, username, magic_link, captcha }: ax_put_reset_password
): axios_request => {
  return access_token
    ? {
        url: `/${VERSION_V1}/accounts/password-reset`,
        method: PUT,
        headers: {
          requestId,
          Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
          captcha,
        },
        data: {
          currentPassword: current_password,
          newPassword: password,
        },
      }
    : {
        url: `/${VERSION_V1}/${CONTEXT_ROOT}/${CONTEXT_APP}/password-reset`,
        method: PUT,
        headers: { requestId, captcha },
        data: {
          username,
          password,
          magicLink: magic_link,
          tipoRichiesta: 'RESET_PASSWORD',
          idInternoRichiesta: 8,
        },
      }
}

/**
 * PUT password expired
 */
export const put_password_expired = (
  requestId: string,
  { credential, captcha }: ax_put_password_expired
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/${CONTEXT_APP}/password-expired`,
  method: PUT,
  headers: { requestId, captcha },
  data: credential,
})

/**
 * PUT unlock user
 */
export const put_unlock_user = (
  requestId: string,
  { username, password, magic_link, captcha }: ax_put_unlock_user
): axios_request => {
  return {
    url: `/${VERSION_V1}/${CONTEXT_ROOT}/${CONTEXT_APP}/unlock-user`,
    method: PUT,
    headers: { requestId, captcha },
    data: {
      username,
      password,
      magicLink: magic_link,
      tipoRichiesta: 'UNLOCK_USER',
      idInternoRichiesta: 10,
    },
  }
}

/**
 * POST check_token impersonification
 */
export const post_check_token_imp = (
  requestId: string,
  { token }: ax_post_check_token_imp
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/check_token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ token }),
})

/**
 * POST check_token impersonification i360 dir
 */
export const post_check_token_imp_i360_dir = (
  requestId: string,
  { token }: ax_post_check_token_imp
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/check_token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ token }),
})

/**
 * POST check_token impersonification i360 age
 */
export const post_check_token_imp_i360_age = (
  requestId: string,
  { token }: ax_post_check_token_imp
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/check_token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ token }),
})

/**
 * POST refresh_token impersonification
 */
export const post_refresh_token_imp = (
  requestId: string,
  { refresh_token }: ax_post_refresh_token_imp
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ grant_type: 'imp_refresh_token', refresh_token }),
})

/**
 * POST refresh_token impersonification i360 dir
 */
export const post_refresh_token_imp_i360_dir = (
  requestId: string,
  { refresh_token }: ax_post_refresh_token_imp
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ grant_type: 'imp_itas360_direzionale_refresh_token', refresh_token }),
})

/**
 * POST refresh_token impersonification i360 age
 */
export const post_refresh_token_imp_i360_age = (
  requestId: string,
  { refresh_token }: ax_post_refresh_token_imp
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/token`,
  method: POST,
  headers: { requestId },
  data: qs.stringify({ grant_type: 'imp_itas360_agenziale_refresh_token', refresh_token }),
})

/**
 * POST sso
 */
export const post_sso = (requestId: string): axios_request => ({
  url: `/${VERSION_V1}/accounts/otp/sso`,
  method: POST,
  headers: { requestId },
})
