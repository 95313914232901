import './tooltip.scss'

import { FC, useRef, useState } from 'react'
import { Image, Overlay, Row, Tooltip as RBTooltip } from 'react-bootstrap'
import { info_icon } from 'src/assets'
import { tooltip_props } from 'src/types'

export const Tooltip: FC<tooltip_props> = ({
  label,
  children,
  placement = 'bottom',
  width_perc = 0.7,
  width_max = 400,
  icon_size = 16,
  classes = '',
  classes_label = 'm-0 p-0 mr-2 font-16',
  classes_icon = '',
}: tooltip_props) => {
  const target = useRef(null)
  const [expanded, set_expanded] = useState(false)

  return (
    <main className={classes}>
      <Row className="m-0 p-0 align-items-center cursor-pointer">
        <p className={classes_label}>{label}</p>
        <Image
          ref={target}
          src={info_icon}
          width={icon_size}
          height={icon_size}
          className={classes_icon}
          onMouseEnter={() => set_expanded(true)}
          onMouseLeave={() => set_expanded(false)}
        />
      </Row>
      <Overlay target={target.current} show={expanded} placement={placement}>
        {(props) => (
          <RBTooltip id="tooltip-wrapper" {...props}>
            <div style={{ width: Math.min(width_perc * window.innerWidth, width_max) }}>
              {children}
            </div>
          </RBTooltip>
        )}
      </Overlay>
    </main>
  )
}
