import React, { Attributes, FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { shoot } from 'src/redux/actions'
import {
  select_check_token,
  select_history_item,
  select_logged,
  select_login_token,
} from 'src/redux/store'
import { Loader } from 'src/widgets'

interface PrivateRoutePropsType extends RouteProps {
  component: React.FC<any>;
  redirect_to: string;
  push: boolean;
  rest?: any;
}

const PrivateRoute = (properties: PrivateRoutePropsType): JSX.Element => {
  const dispatch = useDispatch()

  /**
   * Use Selector
   */
  const is_logged = useSelector(select_logged)
  const login_token = useSelector(select_login_token)
  const check_token = useSelector(select_check_token)
  const from_app = useSelector(select_history_item('from-app'))

  /**
   * Use State
   */
  const [wait, set_wait] = useState(true)
  const [refresh_done, set_refresh_done] = useState(false)

  /**
   * Use Effect
   */
  useEffect(() => {
    if (is_logged) {
      if (login_token?.ar) {
        dispatch(shoot.saga_check_token({ token: login_token?.access_token }))
      }
      //
      else if (login_token?.imp) {
        dispatch(shoot.saga_check_token_imp({ token: login_token?.access_token }))
      }
      //
      else if (login_token?.imp_itas360_dir) {
        dispatch(shoot.saga_check_token_imp_i360_dir({ token: login_token?.access_token }))
      }
      //
      else if (login_token?.imp_itas360_age) {
        dispatch(shoot.saga_check_token_imp_i360_age({ token: login_token?.access_token }))
      }
    }
  }, [])

  useEffect(() => {
    dispatch(shoot.reduce_error())
  })

  useEffect(() => {
    if (check_token?.active) {
      dispatch(shoot.reduce_check_token())
      set_wait(false)
    }
  }, [check_token])

  useEffect(() => {
    if (!is_logged) {
      if (login_token) {
        if (login_token?.ar) {
          dispatch(shoot.saga_refresh_token({ token: login_token.refresh_token }))
        }
        //
        else if (login_token?.imp) {
          dispatch(shoot.saga_refresh_token_imp({ token: login_token.refresh_token }))
        }
        //
        else if (login_token?.imp_itas360_dir) {
          dispatch(shoot.saga_refresh_token_imp_i360_dir({ token: login_token.refresh_token }))
        }
        //
        else if (login_token?.imp_itas360_age) {
          dispatch(shoot.saga_refresh_token_imp_i360_age({ token: login_token.refresh_token }))
        }
        set_refresh_done(true)
      } else {
        set_wait(false)
      }
    }
  }, [is_logged])

  useEffect(() => {
    if (from_app) {
      set_refresh_done(true)
    }
  }, [from_app])

  useEffect(() => {
    if ((is_logged && login_token && refresh_done) || !is_logged) {
      set_wait(false)
    }
  }, [is_logged, login_token, refresh_done])

  const comp: FunctionComponent = properties.component
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...properties.rest}
      render={(props: Attributes) =>
        wait ? (
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader overlay={true} />
          </div>
        ) : is_logged ? (
          React.createElement(comp, props)
        ) : (
          <Redirect to={properties.redirect_to} push={properties.push} />
        )
      }
    />
  )
}

export default PrivateRoute
