import React, { useEffect, useState } from 'react'
import { Card, Container, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { close_icon, italy_icon } from 'src/assets'
import { IS_MOBILE_WIDTH_THRESHOLD_IN_PX, IS_TABLET_WIDTH_THRESHOLD_IN_PX } from 'src/constants'
import { NAV_TO_PROFILE } from 'src/navigation'
import { ClassNames } from 'src/utils/--deprecated'
import Button from 'src/widgets/--deprecated/button/button'
import RadioButton from 'src/widgets/--deprecated/radio-button/radio'

import ForeignResidenceForm from './modify-residence-foreign'
import ItalyResidenceForm from './modify-residence-it'

import './modify-residence.scss'

type residence_type = 'IT' | 'ES' | ''

interface data_radio {
  id: string;
  show: boolean;
  value: residence_type;
  text: string;
  subText: string;
  disableInputId: string;
}

const ModifyResidence: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  /**
   * States
   */
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX)
  const [is_tablet, set_is_tablet] = useState(window.innerWidth < IS_TABLET_WIDTH_THRESHOLD_IN_PX)
  const [type_selected, set_type_selected] = useState<residence_type>('IT')
  const [show_form, set_show_form] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
        const istablet = window.innerWidth < IS_TABLET_WIDTH_THRESHOLD_IN_PX
        if (istablet !== is_tablet) set_is_tablet(istablet)
      },
      false
    )
  }, [is_mobile, is_tablet])

  const radio_elements: data_radio[] = [
    {
      id: 'id_italy',
      show: false,
      value: 'IT',
      text: t('modify_residence.foreign_checkbox.italy'),
      subText: '',
      disableInputId: 'false',
    },
    {
      id: 'id_foreign',
      show: false,
      value: 'ES',
      text: t('modify_residence.foreign_checkbox.foreign'),
      subText: '',
      disableInputId: 'false',
    },
  ]

  const change_residence_type_handler = (value: residence_type) => {
    set_type_selected(value)
  }

  const back_button_handler = () => {
    set_show_form(false)
    history.push(NAV_TO_PROFILE)
  }

  const tr_modify_residence: any = t('modify_residence', {
    returnObjects: true,
  })

  const go_back = () => {
    if (show_form) set_show_form(false)
    else history.goBack()
  }

  const open_form_handler = () => {
    set_show_form(true)
  }

  const SelectNationCheckBox = () => (
    <Card.Body className="modify-residence-content">
      <div
        className={ClassNames(
          is_mobile ? 'unnamed-character-style-55' : 'unnamed-character-style-54'
        )}
        style={{ marginBottom: '10px' }}
      >
        {tr_modify_residence.foreign_checkbox.choose_label}
      </div>
      <div
        className={ClassNames(
          is_mobile ? 'unnamed-character-style-22' : 'unnamed-character-style-11'
        )}
        style={{ marginBottom: '30px' }}
        dangerouslySetInnerHTML={{
          __html: tr_modify_residence.foreign_checkbox.choose_description,
        }}
      />

      <div
        className={ClassNames(
          'mod-residence-radios-container',
          is_mobile ? 'unnamed-character-style-22' : 'unnamed-character-style-49'
        )}
      >
        {radio_elements.map((dataRadio, index) => {
          return (
            <div className="recovery-credential-radios-container--radio-item d-flex" key={index}>
              <RadioButton
                value={dataRadio.value}
                id_category={dataRadio.value}
                selected={type_selected}
                text={dataRadio.text}
                subText={dataRadio.subText}
                onClick={() => change_residence_type_handler(dataRadio.value)}
                disableInputId={dataRadio.disableInputId}
                show={dataRadio.show}
              />
              <Image
                className={ClassNames(dataRadio.value === 'IT' ? 'd-block italy-icon' : 'd-none')}
                src={italy_icon}
                fluid
              />
            </div>
          )
        })}
      </div>
    </Card.Body>
  )

  const SelectNationButton = () => (
    <div className="mod-residence-button-confirm">
      <Button name="full_059881" onClick={open_form_handler}>
        <div>{tr_modify_residence.confirm_button}</div>
        <div className="right-arrow-white" />
      </Button>
    </div>
  )

  const ModifyResidenceForm = () => (
    <div>
      {type_selected === 'IT' ? (
        <ItalyResidenceForm back_button_handler={back_button_handler} />
      ) : (
        <ForeignResidenceForm back_button_handler={back_button_handler} />
      )}
    </div>
  )

  return (
    <div className="modify-residence-container">
      <Container fluid>
        <div className="mod-residence-backward-card">
          <div className="registration-button-back">
            <Button name="text_059881" onClick={go_back} padding="0">
              <div className="left-arrow-green" />
              <div>{tr_modify_residence.back_button}</div>
            </Button>
          </div>
          <Image className={ClassNames(is_mobile ? 'd-block' : 'd-none')} src={close_icon} fluid />
        </div>
        <Card className="mod-residence-card">
          <Card.Body className="modify-residence-content">
            <div
              className={
                is_mobile ? 'unnamed-character-style-30 align-left' : 'font-40-freightdisp-semibold'
              }
            >
              {tr_modify_residence.title}
            </div>

            <hr className="mod-residence-separator" />
          </Card.Body>
          {!show_form ? SelectNationCheckBox() : ModifyResidenceForm()}
          {!show_form ? SelectNationButton() : null}
        </Card>
      </Container>
    </div>
  )
}

export default ModifyResidence
