import './modal.scss'

import { FC } from 'react'
import { Image, Modal as RBModal } from 'react-bootstrap'
import { close_icon } from 'src/assets'
import { modal_props } from 'src/types'

export const Modal: FC<modal_props> = ({
  visible,
  children,
  on_close,
  classes,
  classes_head = 'd-flex justify-content-end pt-4 pr-4',
}: modal_props) => {
  return (
    <RBModal
      dialogClassName="modal-width"
      onHide={() => null}
      centered
      show={visible}
      animation={false}
    >
      {on_close && (
        <div className={classes_head}>
          <Image src={close_icon} fluid role="button" onClick={on_close} />
        </div>
      )}
      <RBModal.Body className={classes}>{children}</RBModal.Body>
    </RBModal>
  )
}
