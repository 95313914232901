import './radio.scss'

import React from 'react'
import { RadioItems } from 'src/types/--deprecated/types'
import { ClassNames, format_text } from 'src/utils/--deprecated'

const RadioButton: React.FC<RadioItems> = (props: RadioItems) => {
  const {
    id_category,
    selected = '',
    onClick,
    text,
    subText = '',
    value,
    show = false,
    disableInputId,
    item_style,
    radio_style,
    readonly,
  }: RadioItems = props

  return (
    <div>
      <div
        className={ClassNames('radio', radio_style)}
        style={readonly ? { cursor: 'not-allowed' } : {}}
        onClick={() => {
          if (!readonly) {
            onClick?.(value, id_category || '', selected, disableInputId || '')
          }
        }}
      >
        <div className={`radio-outer-circle ${value !== selected && 'unselected'}`}>
          <div className={`radio-inner-circle ${value !== selected && 'unselected-circle'}`} />
        </div>
        <div className={ClassNames('radio-text', item_style)}>{text}</div>
      </div>
      {show ? (
        <div className="unnamed-character-style-1-00000080 radio--sub">
          {format_text(subText, 'unnamed-character-style-1-bold')}
        </div>
      ) : (selected === value || false) && subText !== '' ? (
        <div className="unnamed-character-style-1 radio--sub-relative">
          {format_text(subText, 'unnamed-character-style-1-bold')}
        </div>
      ) : null}
    </div>
  )
}

export default RadioButton
