import 'http'

import Cookies from 'js-cookie'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  APP_COOKIE,
  CHUNK_N_COOKIE,
  IMPERSONIFICATION_COOKIE,
  IMPERSONIFICATION_I360_AGE_COOKIE,
  IMPERSONIFICATION_I360_DIR_COOKIE,
} from 'src/config'
import { NAV_TO_HOMEPAGE, NAV_TO_LOGIN, SAGA_GUARDS } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import { select_check_token, select_error, select_login_token } from 'src/redux/store'
import { login_token_t } from 'src/types'
import { Loader } from 'src/widgets'

const RegistrationConfirmation: React.FC = () => {
  const cookie_domain = process.env.REACT_APP_OPENAM_COOKIE_DOMAIN || ''

  const dispatch = useDispatch()
  const history = useHistory()

  const error = useSelector(select_error(SAGA_GUARDS.LOGIN))
  const login_token = useSelector(select_login_token)
  const check_token = useSelector(select_check_token)

  /**
   * Use State
   */
  const [token, set_token] = useState('')
  const token_ref = useRef('')

  token_ref.current = token

  useEffect(() => {
    if (Cookies.get(`${APP_COOKIE}_0`)) {
      set_token(get_app_token() || '')
    } else {
      set_token(get_impersonification_cookie() || '')
    }
  }, [])

  // Access from app
  useEffect(() => {
    if (token && Cookies.get(`${APP_COOKIE}_0`)) {
      const login_token: login_token_t = { access_token: token, ar: { pro: get_app_proclie() } }
      dispatch(shoot.reduce_logged(true))
      dispatch(shoot.reduce_login_token(login_token))
      dispatch(shoot.reduce_ar_access(true))
      dispatch(shoot.reduce_history_item('from-app', 'true'))
      history.push(NAV_TO_HOMEPAGE)
    }
  }, [token])

  // Access from impersonification
  useEffect(() => {
    if (token && !Cookies.get(`${APP_COOKIE}_0`)) {
      dispatch(shoot.reduce_reset())
      const impersonification_type = get_impersonification_type()
      remove_impersonification_cookie()

      const timer = setTimeout(() => {
        if (impersonification_type === 'imp') {
          dispatch(shoot.saga_check_token_imp({ token }))
        }
        //
        else if (impersonification_type === 'imp_i360_dir') {
          dispatch(shoot.reduce_history_item('is-impersonation', 'true'))
          dispatch(shoot.saga_check_token_imp_i360_dir({ token }))
        }
        //
        else if (impersonification_type === 'imp_i360_age') {
          dispatch(shoot.reduce_history_item('is-impersonation', 'true'))
          dispatch(shoot.saga_check_token_imp_i360_age({ token }))
        }
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [token])

  useEffect(() => {
    const from_app = token_ref.current && Cookies.get(`${APP_COOKIE}_0`)
    if (check_token?.active && !from_app) {
      const login_token_imp: login_token_t = {
        access_token: token,
        imp: check_token?.imp,
        jti: check_token?.jti,
        imp_itas360_dir: check_token?.imp_itas360_dir,
        imp_itas360_age: check_token?.imp_itas360_age,
      }
      dispatch(shoot.reduce_logged(true))
      dispatch(shoot.reduce_login_token(login_token_imp))
      dispatch(shoot.reduce_check_token())
      dispatch(shoot.reduce_ar_access(false))
    }
  }, [check_token])

  const get_app_token = () => {
    let token = ''
    if (Cookies.get(CHUNK_N_COOKIE)) {
      for (let i = 0; i < +(Cookies.get(CHUNK_N_COOKIE) || ''); i++) {
        token += Cookies.get(`${APP_COOKIE}_${i}`)
      }
    }
    return token
  }

  const get_app_proclie = () => {
    let proclie = ''
    if (Cookies.get(CHUNK_N_COOKIE)) {
      for (let i = 0; i < +(Cookies.get(CHUNK_N_COOKIE) || ''); i++) {
        proclie += Cookies.get(`proclie_${i}`)
      }
    }
    return proclie
  }

  const get_impersonification_type = () => {
    if (Cookies.get(IMPERSONIFICATION_COOKIE)) return 'imp'
    if (Cookies.get(`${IMPERSONIFICATION_COOKIE}_0`)) return 'imp'
    if (Cookies.get(`${IMPERSONIFICATION_I360_DIR_COOKIE}_0`)) return 'imp_i360_dir'
    if (Cookies.get(`${IMPERSONIFICATION_I360_AGE_COOKIE}_0`)) return 'imp_i360_age'
    if (Cookies.get(IMPERSONIFICATION_I360_DIR_COOKIE)) return 'imp_i360_dir'
    if (Cookies.get(IMPERSONIFICATION_I360_AGE_COOKIE)) return 'imp_i360_age'
    return undefined
  }

  const get_impersonification_cookie = () => {
    const impersonification_type = get_impersonification_type()
    if (impersonification_type === 'imp' && !Cookies.get(CHUNK_N_COOKIE)) {
      return Cookies.get(IMPERSONIFICATION_COOKIE)
    }
    if (Cookies.get(CHUNK_N_COOKIE)) {
      let token = ''
      let token_ = ''
      for (let i = 0; i <= +(Cookies.get(CHUNK_N_COOKIE) || ''); i++) {
        if (impersonification_type === 'imp') {
          token += Cookies.get(`${IMPERSONIFICATION_COOKIE}_${i}`)
        } else if (impersonification_type === 'imp_i360_dir') {
          token_ = Cookies.get(`${IMPERSONIFICATION_I360_DIR_COOKIE}_${i}`) || ''
          token_ = token_?.replaceAll(/['"]+/g, '')
          token += token_
        } else if (impersonification_type === 'imp_i360_age') {
          token_ = Cookies.get(`${IMPERSONIFICATION_I360_AGE_COOKIE}_${i}`) || ''
          token_ = token_?.replaceAll(/['"]+/g, '')
          token += token_
        }
      }
      return token
    }
    if (impersonification_type === 'imp_i360_dir') {
      let token_ = Cookies.get(IMPERSONIFICATION_I360_DIR_COOKIE)
      token_ = token_?.replaceAll(/['"]+/g, '')
      return token_
    }
    if (impersonification_type === 'imp_i360_age') {
      let token_ = Cookies.get(IMPERSONIFICATION_I360_AGE_COOKIE)
      token_ = token_?.replaceAll(/['"]+/g, '')
      return token_
    }
    return ''
  }

  const remove_impersonification_cookie = () => {
    const impersonification_type = get_impersonification_type()
    if (Cookies.get(IMPERSONIFICATION_COOKIE) && !Cookies.get(CHUNK_N_COOKIE)) {
      Cookies.remove(IMPERSONIFICATION_COOKIE, { domain: cookie_domain })
    }
    //
    else if (Cookies.get(CHUNK_N_COOKIE)) {
      for (let i = 0; i <= +(Cookies.get(CHUNK_N_COOKIE) || ''); i++) {
        if (impersonification_type === 'imp') {
          Cookies.remove(`${IMPERSONIFICATION_COOKIE}_${i}`, { domain: cookie_domain })
        } else if (impersonification_type === 'imp_i360_dir') {
          Cookies.remove(`${IMPERSONIFICATION_I360_DIR_COOKIE}_${i}`, { domain: cookie_domain })
        } else if (impersonification_type === 'imp_i360_age') {
          Cookies.remove(`${IMPERSONIFICATION_I360_AGE_COOKIE}_${i}`, { domain: cookie_domain })
        }
      }
      Cookies.remove(CHUNK_N_COOKIE, { domain: cookie_domain })
    }
    //
    else if (Cookies.get(IMPERSONIFICATION_I360_DIR_COOKIE)) {
      Cookies.remove(IMPERSONIFICATION_I360_DIR_COOKIE, { domain: cookie_domain })
    }
    //
    else if (Cookies.get(IMPERSONIFICATION_I360_AGE_COOKIE)) {
      Cookies.remove(IMPERSONIFICATION_I360_AGE_COOKIE, { domain: cookie_domain })
    }
  }

  useEffect(() => {
    if (login_token) {
      history.push(NAV_TO_HOMEPAGE)
    } else if (error) {
      dispatch(shoot.reduce_error())
      history.push(NAV_TO_LOGIN)
    }
  }, [error, login_token])

  return <Loader />
}

export default RegistrationConfirmation
