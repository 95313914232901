import './new-password.scss'

import base64 from 'base-64'
import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Container, Form, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { info_x_icon, valid_icon } from 'src/assets'
import { SEVERITY_CODES } from 'src/backend-codes'
import { severity_img_mapping } from 'src/error-management/errors'
import { NAV_TO_HOMEPAGE, NAV_TO_LOGIN } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import {
  select_error,
  select_loading,
  select_login_token,
  select_outcome_reset_password,
} from 'src/redux/store'
import {
  regex_password_lowercase,
  regex_password_number,
  regex_password_uppercase,
} from 'src/regex'
import { error_t } from 'src/types'
import { ClassNames, format_text } from 'src/utils/--deprecated'
import { Loader, Tooltip } from 'src/widgets'
import Button from 'src/widgets/--deprecated/button/button'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import Modal, {
  modalProps_genericError,
  ModalPropsDataType,
} from 'src/widgets/--deprecated/modal/modal'
import { Recaptcha, submit_captcha } from 'src/widgets/recaptcha/recaptcha'
import * as Yup from 'yup'

/**
 * New Password  components
 */
const NewPassword: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const username = new URLSearchParams(useLocation().search).get('username')
  const magic_link = new URLSearchParams(useLocation().search).get('magicLink')

  /**
   * Use Selector
   */
  const loading = useSelector(select_loading())
  const error: error_t | undefined = useSelector(select_error())
  const outcome_reset_password = useSelector(select_outcome_reset_password)
  const login_token = useSelector(select_login_token)
  const [captcha, set_captcha] = useState('')
  const [password, set_password] = useState('')

  /**
   * Use State
   */
  const [show_modal, set_show_modal] = useState(false)
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 810)
  const [modal_info, set_modal_info] = useState({
    title: t('new_password.info_modal.title'),
    options: [
      { id: '1', text: t('new_password.info_modal.option_1'), valid: '' },
      { id: '2', text: t('new_password.info_modal.option_2'), valid: '' },
      { id: '3', text: t('new_password.info_modal.option_3'), valid: '' },
      { id: '4', text: t('new_password.info_modal.option_4'), valid: '' },
    ],
  })
  const [error_visible, set_error_visible] = useState(false)

  /**
   * Use Effect
   */

  useEffect(() => {
    if (error?.is_tech) set_error_visible(true)
  }, [error])

  useEffect(() => {
    if (outcome_reset_password === 'success' && login_token) {
      history.push(NAV_TO_HOMEPAGE)
    }
  }, [outcome_reset_password, login_token])

  useEffect(() => {
    if (captcha && password) {
      dispatch(
        shoot.saga_reset_password({
          username,
          password: base64.encode(password),
          magic_link,
          captcha,
        })
      )
    }
  }, [captcha, password])

  /**
   * Functions
   */
  const toggle_modal = (value: boolean) => {
    set_show_modal(value)
  }

  const nav_to_login = () => {
    history.push(NAV_TO_LOGIN)
  }

  const reset_password = (password: string) => {
    set_password('')
    set_captcha('')
    submit_captcha().then((token: string) => {
      set_captcha(token)
      set_password(password)
    })
  }

  const dismiss_error = () => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }

  const on_click_modal_props = () => {
    dismiss_error()
    set_error_visible(false)
  }

  const modal_props: ModalPropsDataType = {
    ...modalProps_genericError.data,
    imgType: error?.severity
      ? severity_img_mapping[error.severity]
      : severity_img_mapping[SEVERITY_CODES.FATAL],
    title: error?.title || '',
    text: error?.description_fe || error?.description_be || '',
    showNextButton: false,
    closeButtonOnClick: on_click_modal_props,
  }

  //Form validation schema
  const validation_schema = Yup.object().shape({
    password: Yup.string()
      .required(() => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('new_password.errors.password_required')
      })
      .min(8, () => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('new_password.errors.password_invalid')
      })
      .max(7, () => {
        modal_info.options[0].valid = 'valid'
        set_modal_info(modal_info)
      })
      .test('', t('new_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_uppercase)) {
          modal_info.options[1].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[1].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('new_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_lowercase)) {
          modal_info.options[2].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[2].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('new_password.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_number)) {
          modal_info.options[3].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[3].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      }),
    passwordConfirmed: Yup.string()
      .required(t('new_password.errors.password_required'))
      .test('passwords-match', t('new_password.errors.password_different'), function (value) {
        return this.parent.password === value
      }),
  })

  return (
    <Container fluid>
      {loading ? (
        <div className="login-loader">
          <Loader />{' '}
        </div>
      ) : (
        <div>
          <Row>
            {error_visible ? <Modal data={modal_props} /> : ''}
            <div className="new-password-button-back">
              <Button name="text_059881" onClick={nav_to_login} padding="0">
                <div className="left-arrow-green" />
                <div> {t('new_password.back_button')}</div>
              </Button>
            </div>
          </Row>

          <Row className="new-password-container">
            <div onClick={() => toggle_modal(false)}>
              <div
                className={ClassNames(
                  'new-password-title',
                  is_mobile ? 'unnamed-character-style-30' : 'unnamed-character-style-29'
                )}
              >
                {t('new_password.title')}
              </div>
              <div
                className={ClassNames(
                  'new-password-sutitle',
                  is_mobile ? 'unnamed-character-style-15-26-7f7f7f' : 'unnamed-character-style-18'
                )}
              >
                {format_text(t('new_password.subtitle'), '')}
              </div>
              <Recaptcha />
              <Formik
                key="password"
                validationSchema={validation_schema}
                initialValues={{
                  password: '',
                  passwordConfirmed: '',
                }}
                onSubmit={(values) => {
                  reset_password(values.password)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="password" className="new-password-input-container">
                      <div className="new-password-input-icon-container">
                        <Form.Label
                          className={ClassNames(
                            'new-password-input-label',
                            is_mobile
                              ? 'unnamed-character-style-27-13-22'
                              : 'unnamed-character-style-48'
                          )}
                        >
                          {t('new_password.forms.new_password')}
                        </Form.Label>
                        <Tooltip placement="top">
                          <div>
                            <div className="text-left mb-2">{modal_info.title}</div>
                            {modal_info.options.map((data, index) => (
                              <li
                                key={index}
                                className={`text-left ${
                                  data.valid === 'valid'
                                    ? 'c-159881'
                                    : data.valid === 'invalid'
                                    ? 'c-862633'
                                    : ''
                                }`}
                              >
                                {data.text}
                                {data.valid === 'valid' ? (
                                  <Image src={valid_icon} width={12} className="ml-2" />
                                ) : data.valid === 'invalid' ? (
                                  <Image src={info_x_icon} className="ml-2" />
                                ) : (
                                  ''
                                )}
                              </li>
                            ))}
                          </div>
                        </Tooltip>
                      </div>
                      <InputTxt
                        id="password"
                        placeholder={t('new_password.forms.new_password_placeholder')}
                        type="password"
                        value={values.password}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('password', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !((touched.password && errors.password) || values.password === ''),
                            text: errors.password || '',
                          },
                        ]}
                      />
                      {error && !error.is_tech && !(touched.password && errors.password) && (
                        <div className="error-character">{error?.description_fe}</div>
                      )}
                    </Form.Group>

                    <Form.Group controlId="passwordConfirmed">
                      <Form.Label
                        className={ClassNames(
                          'new-password-input-label',
                          'new-password-input-label--confirm-password',
                          is_mobile
                            ? 'unnamed-character-style-27-13-22'
                            : 'unnamed-character-style-48'
                        )}
                      >
                        {t('new_password.forms.confirm_password')}
                      </Form.Label>

                      <InputTxt
                        id="passwordConfirmed"
                        placeholder={t('new_password.forms.confirm_password_placeholder')}
                        type="password"
                        value={values.passwordConfirmed}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('passwordConfirmed', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !(
                                (touched.passwordConfirmed && errors.passwordConfirmed) ||
                                values.passwordConfirmed === ''
                              ),
                            text: errors.passwordConfirmed || '',
                          },
                        ]}
                      />
                    </Form.Group>

                    <div className="new-password-button-submit">
                      <Button
                        justify_content="center"
                        name={'full_059881'}
                        disabled={
                          values.password == '' ||
                          values.passwordConfirmed == '' ||
                          errors.password != undefined ||
                          errors.passwordConfirmed != undefined
                        }
                      >
                        <div>{t('new_password.forms.btn_label')}</div>
                        <div className="right-arrow-white" />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
        </div>
      )}
    </Container>
  )
}

export default NewPassword
