import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  /**
   * Store
   */
  reduce_outcome_logout: ['logout'],
  reduce_outcome_registration: ['registration'],
  reduce_outcome_reset_password: ['reset_password'],
  reduce_outcome_recovery_client_id: ['recovery_client_id'],
  reduce_outcome_recovery_password: ['recovery_password'],
  reduce_outcome_customer: ['customer'],
  reduce_outcome_contacts: ['contacts'],
  reduce_outcome_consents: ['consents'],
  reduce_outcome_language: ['language'],
  reduce_outcome_access: ['access'],
  reduce_outcome_iban: ['iban'],
  reduce_outcome_payment: ['payment'],
  reduce_outcome_payment_status: ['payment_status'],
  reduce_outcome_email: ['email'],
  reduce_outcome_document: ['document'],
  reduce_outcome_policy: ['policy'],
  reduce_outcome_voucher: ['voucher'],
  reduce_outcome_poll: ['poll'],

  /**
   * Sagas
   */
  saga_update_customer: ['args'],
  saga_remove_contacts: ['args'],
  saga_update_consents: ['args'],
  saga_update_language: ['args'],
  saga_update_access: ['args'],
  saga_generate_iban: ['args'],
  saga_make_payment: ['args'],
  saga_check_payment: ['args'],
  saga_send_email: ['args'],
  saga_send_document: ['args'],
  saga_update_policy: ['args'],
  saga_verify_policy: ['args'],
  saga_send_voucher: ['args'],
  saga_answer_poll: ['args'],
})

export const outcomes_types = Types
export const outcomes_creators = Creators
