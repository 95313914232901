import './input-txt.scss'

import { FC, useEffect, useState } from 'react'
import {
  input_txt_props,
  input_txt_validator,
} from 'src/types/--deprecated/widgets/input-txt.types'
import { ClassNames, format_text } from 'src/utils/--deprecated'

const InputTxt: FC<input_txt_props> = (props: input_txt_props) => {
  const {
    id,
    type = 'text',
    placeholder = '',
    value = '',
    value_style = 'unnamed-character-style-2',
    label = '',
    label_style = 'unnamed-character-style-27-13-22',
    validators = [],
    disabled = false,
    readonly = false,
    color,
    background_color,
    border_color,
    width = '',
    height = '2.8125rem',
    uppercase = false,
    border_radius = '0',
    icon,
    left_icon,
    on_change,
    on_click,
    ...other_props
  } = props

  const [error, set_error] = useState<input_txt_validator>()
  const [success, set_success] = useState<input_txt_validator>()
  const [password_visibility, set_password_visibility] = useState(false)

  /**
   * Check errors
   */
  useEffect(() => {
    let err = false
    if (value !== '') {
      for (const v of validators) {
        if (!v?.is_valid?.(value)) {
          err = true
          set_error({
            text: v?.text,
            color: v?.color || '#862633',
            style: v?.style || 'error-character',
            style_bold: v?.style_bold || 'error-character-bold',
          })
          break
        } else if (v?.is_valid?.(value) && v?.success_text) {
          set_success({
            success_text: v?.success_text,
            color: v?.color || '159881',
            style: v?.style || 'font-12',
          })
          break
        }
      }
    }
    if (!err) {
      set_error(undefined)
    }
    if (err || value === '') {
      set_success(undefined)
    }
  }, [value, validators])

  const input_props = type === 'password' ? {} : { value: value || '' }

  return (
    <div>
      {label && <div className={ClassNames('input-label', label_style)}>{label}</div>}
      <div
        className={ClassNames(
          'manage-input-container',
          disabled ? 'disabled' : '',
          readonly ? 'read-only-style' : ''
        )}
        style={{
          width,
          height,
          borderRadius: border_radius,
          borderColor: error?.color || border_color,
          backgroundColor: readonly ? '' : background_color,
        }}
      >
        {left_icon && (
          <div className="ml-2" style={{ marginTop: '0.6rem' }}>
            {left_icon}
          </div>
        )}
        <input
          {...input_props}
          className={ClassNames('manage-input', value_style, readonly ? 'read-only-style' : '')}
          id={id}
          readOnly={readonly}
          placeholder={placeholder}
          onChange={on_change}
          onClick={on_click}
          type={
            type === 'password'
              ? password_visibility
                ? 'text'
                : 'password'
              : type === 'password-readonly'
              ? 'password'
              : type
          }
          disabled={disabled}
          style={{
            color: readonly ? '#999999' : color,
            textTransform: uppercase ? 'uppercase' : 'none',
            backgroundColor: background_color,
          }}
          {...other_props}
        />

        {type === 'password' ? (
          <div className="d-flex ">
            <div
              className={ClassNames('icon-style m-0 p-0', !error && value !== '' ? 'is-valid' : '')}
            />
            <div
              className={ClassNames(
                'icon-style',
                !password_visibility ? 'visible-password' : 'unvisible-password'
              )}
              onClick={() => set_password_visibility(!password_visibility)}
            />
          </div>
        ) : (
          <div
            className={ClassNames(
              'icon-style',
              !error && value !== '' && !icon ? 'is-valid' : icon
            )}
          />
        )}
      </div>
      {error && (
        <div className={ClassNames('input-error', error?.style)}>
          {format_text(error?.text || '', error?.style_bold || '')}
        </div>
      )}
      {success && (
        <div className={`input-error ${success?.style} c-${success?.color}`}>
          {success?.success_text || ''}
        </div>
      )}
    </div>
  )
}

export default InputTxt
