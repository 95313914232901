import { call, put, select } from 'redux-saga/effects'
import { outcome_w } from 'src/backend-codes'
import {
  http_download_voucher,
  http_resolve_vouchers,
  http_send_voucher,
} from 'src/http/voucher.http'
import { saga_act, voucher_document_t, voucher_t } from 'src/types'

import { shoot } from '../actions'
import { select_vouchers } from '../store'
import { saga_do_request } from './base.saga'

/**
 * Resolve vouchers
 */
export function* saga_resolve_vouchers(action: saga_act): unknown {
  const cached: voucher_t[] = yield select(select_vouchers)
  if (!cached) {
    const vouchers: voucher_t[] = yield call(saga_do_request, http_resolve_vouchers, action)
    if (vouchers) {
      yield put(
        shoot.reduce_vouchers(
          vouchers?.map((voucher) => ({
            ...voucher,
            accessMode: JSON.parse(voucher?.accessMode || '[]')[0],
          }))
        )
      )
    }
  }
}

/**
 * Download voucher
 */
export function* saga_download_voucher(action: saga_act): unknown {
  const voucher: voucher_document_t = yield call(saga_do_request, http_download_voucher, action)
  if (voucher) {
    yield put(shoot.reduce_voucher_document(voucher))
  }
}

/**
 * Send voucher
 */
export function* saga_send_voucher(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_send_voucher, action)
  if (outcome) {
    yield put(shoot.reduce_outcome_voucher(outcome))
  }
}
