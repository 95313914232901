import { call, put } from 'redux-saga/effects'
import { http_resolve_history_addresses, http_resolve_history_requests } from 'src/http'
import { history_address_t, history_requests_t, saga_act } from 'src/types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Resolve address history
 */
export function* saga_resolve_history_addresses(action: saga_act): unknown {
  const history_addresses: history_address_t[] = yield call(
    saga_do_request,
    http_resolve_history_addresses,
    action
  )
  if (history_addresses) {
    yield put(shoot.reduce_history_addresses(history_addresses))
  }
}

/**
 * Resolve policy history
 */
export function* saga_resolve_history_requests(action: saga_act): unknown {
  const history_requests: history_requests_t = yield call(
    saga_do_request,
    http_resolve_history_requests,
    action
  )
  if (history_requests) {
    yield put(shoot.reduce_history_requests(history_requests))
  }
}
