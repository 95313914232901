import {
  download_voucher,
  get_vouchers,
  send_voucher,
  voucher_client,
} from 'src/axios/voucher.axios'
import { outcome_w } from 'src/backend-codes'
import {
  ax_get_vouchers,
  ax_post_voucher,
  response_t,
  saga_act,
  voucher_document_t,
  voucher_t,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve vouchers
 */
export async function http_resolve_vouchers({
  args,
  type,
}: saga_act<ax_get_vouchers>): Promise<response_t<voucher_t[]>> {
  return do_request<voucher_t[]>({
    client: voucher_client,
    request: get_vouchers(v4(), args),
    child: 'vouchers',
    saga_type: type,
  })
}

/**
 * Download voucher
 */
export async function http_download_voucher({
  args,
  type,
}: saga_act<ax_post_voucher>): Promise<response_t<voucher_document_t>> {
  return do_request<voucher_document_t>({
    client: voucher_client,
    request: download_voucher(v4(), args),
    child: 'document',
    saga_type: type,
  })
}

/**
 * Send voucher
 */
export async function http_send_voucher({
  args,
  type,
}: saga_act<ax_post_voucher>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: voucher_client,
    request: send_voucher(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}
