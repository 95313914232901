import './app.scss'

import { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import Layout from 'src/widgets/--deprecated/layout/layout'
import PrivateRoute from 'src/widgets/--deprecated/private-route/private-route'
import ScrollToTop from 'src/widgets/--deprecated/scroll-to-top/scroll-to-top'

import AssistanceDetail from './containers/assistance-detail/assistance-detail'
import Community from './containers/community/community'
import CookiePolicy from './containers/cookie-policy/cookie-policy'
import DataMatrix from './containers/data-matrix/data-matrix'
import Faq from './containers/faq/faq'
import Impersonification from './containers/impersonification/impersonification'
import Maintenance from './containers/maintenance/maintenance'
import ModifyResidence from './containers/modify-residence/modify-residence'
import NewPassword from './containers/new-password/new-password'
import NotSupportedBrowser from './containers/not-supported-browser/not-supported-browser'
import PasswordExpired from './containers/password-expired/password-expired'
import PasswordReset from './containers/password-reset/password-reset'
import PaymentOutcome from './containers/policy-operations/renewal/payment-outcome'
import RecoveryCredentials from './containers/recovery-credentials/recovery-credentials'
import Servicies from './containers/services/servicies'
import SetupPassword from './containers/setup-password/setup-password'
import UnlockUser from './containers/unlock-user/unlock-user'
import VoucherDetail from './containers/voucher-detail/voucher-detail'
import {
  NAV_TO_AGENCIES,
  NAV_TO_AGENCY_DETAIL,
  NAV_TO_ASSISTANCE,
  NAV_TO_ASSISTANCE_DETAIL,
  NAV_TO_CHANGE_RESIDENCE,
  NAV_TO_CLAIM,
  NAV_TO_COMMUNITY,
  NAV_TO_DATAMATRIX,
  NAV_TO_DEPOSIT,
  NAV_TO_DOCUMENTS,
  NAV_TO_FAQS,
  NAV_TO_HOMEPAGE,
  NAV_TO_LOGIN,
  NAV_TO_MAINTENANCE,
  NAV_TO_NEW_PASSWORD,
  NAV_TO_NOT_COOKIE_POLICY,
  NAV_TO_NOT_SUPPORTED_BROWSER,
  NAV_TO_ON_BOARDING,
  NAV_TO_ON_IMPERSONIFICATION,
  NAV_TO_ON_PAYMENT_OUTCOME,
  NAV_TO_ON_REGISTRATION_CONFIRMATION,
  NAV_TO_PASSWORD_EXPIRED,
  NAV_TO_PASSWORD_RESET,
  NAV_TO_POLICIES,
  NAV_TO_POLICY_DETAIL,
  NAV_TO_PROFILE,
  NAV_TO_RECOVERY_CREDENTIALS,
  NAV_TO_REDEMPTION,
  NAV_TO_REGISTRATION,
  NAV_TO_RENEWAL,
  NAV_TO_REQUESTS_HISTORY,
  NAV_TO_SERVICIES,
  NAV_TO_SETUP_PASSWORD,
  NAV_TO_UNLOCK_USER,
  NAV_TO_VOUCHER_DETAIL,
} from './navigation'
import { shoot } from './redux/actions'
import { select_maintenance_active } from './redux/store'
import i18n from './i18n'

export interface react_native_window extends Window {
  ReactNativeWebView?: {
    postMessage?: (message: string) => void,
  };
}

window.onbeforeunload = () => window.scrollTo(0, 0)

const Login = lazy(() => import('./containers/login/login'))
const Assistance = lazy(() => import('./containers/assistance/assistance'))
const QuickRegistration = lazy(() => import('./containers/registration/quick-registration'))
const Body = lazy(() => import('./containers/homepage/homepage'))
const Onboarding = lazy(() => import('./containers/onboarding/onboarding'))
const ReCo = lazy(() => import('./containers/registration-confirmation/registration-confirmation'))
const Profile = lazy(() => import('./containers/profile/profile'))
const Policies = lazy(() => import('./containers/policies/policies'))
const PolicyDetail = lazy(() => import('./containers/policy-detail/policy-detail'))
const Claim = lazy(() => import('./containers/policy-operations/claim/claim'))
const Deposit = lazy(() => import('./containers/policy-operations/deposit/deposit'))
const Redemption = lazy(() => import('./containers/policy-operations/redemption/redemption'))
const Renewal = lazy(() => import('./containers/policy-operations/renewal/renewal'))
const Documents = lazy(() => import('./containers/documents/documents'))
const RequestsHistory = lazy(() => import('./containers/requests-history/requests-history'))
const Agencies = lazy(() => import('./containers/agencies/agencies'))
const AgencyDetail = lazy(() => import('./containers/agency-detail/agency-detail'))

const App: React.FC = () => {
  const dispatch = useDispatch()

  const maintenance_active = useSelector(select_maintenance_active)

  useEffect(() => {
    dispatch(shoot.saga_resolve_communications_registration({ domain: 'MNT', subdomain: 'ASM' }))
  }, [])

  window.addEventListener('message', (message) => {
    const data = message?.data || ''
    if (data.startsWith('LANG:')) {
      const curr_language = i18n.language.slice(0, 2).toLowerCase()
      const next_language = data.split(':')[1] || 'it'
      if (curr_language !== next_language) {
        i18n.changeLanguage(next_language)
        dispatch(shoot.saga_invalidate_cache())
        setTimeout(() => window.location.reload())
      }
    }
  })

  window.addEventListener('message', (message) => {
    const data = message?.data || ''
    if (data.startsWith('FROM_APP')) {
      // TODO: store from_app --> true
    }
  })

  const routes = (
    <Switch>
      <PrivateRoute
        rest={{
          proclie: 'e639059',
          name: undefined,
          denomination: 'ASSOCIAZIONE VOLONTARIA DI PROTEZIONE CIVILE COMUNE DI ROANA',
          type: 'PG',
        }}
        component={Body}
        path={NAV_TO_HOMEPAGE}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Assistance}
        path={NAV_TO_ASSISTANCE}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Onboarding}
        path={NAV_TO_ON_BOARDING}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Profile}
        path={NAV_TO_PROFILE}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        path={NAV_TO_CHANGE_RESIDENCE}
        exact
        component={ModifyResidence}
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Policies}
        path={NAV_TO_POLICIES}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={PolicyDetail}
        path={NAV_TO_POLICY_DETAIL}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Claim}
        path={NAV_TO_CLAIM}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Deposit}
        path={NAV_TO_DEPOSIT}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Redemption}
        path={NAV_TO_REDEMPTION}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Documents}
        path={NAV_TO_DOCUMENTS}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={RequestsHistory}
        path={NAV_TO_REQUESTS_HISTORY}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Renewal}
        path={NAV_TO_RENEWAL}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={PasswordReset}
        path={NAV_TO_PASSWORD_RESET}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={Agencies}
        path={NAV_TO_AGENCIES}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={AgencyDetail}
        path={NAV_TO_AGENCY_DETAIL}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      {/* <PrivateRoute
        component={Community}
        path={NAV_TO_COMMUNITY}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      /> */}
      <PrivateRoute
        component={VoucherDetail}
        path={NAV_TO_VOUCHER_DETAIL}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />
      <PrivateRoute
        component={AssistanceDetail}
        path={NAV_TO_ASSISTANCE_DETAIL}
        exact
        redirect_to={NAV_TO_LOGIN}
        push={true}
      />

      <Route path={NAV_TO_SERVICIES} exact component={Servicies} />
      <Route path={NAV_TO_ON_PAYMENT_OUTCOME} exact component={PaymentOutcome} />
      <Route path={NAV_TO_ON_REGISTRATION_CONFIRMATION} exact component={ReCo} />
      <Route path={NAV_TO_ON_IMPERSONIFICATION} exact component={Impersonification} />
      <Route path={NAV_TO_SETUP_PASSWORD} exact component={SetupPassword} />
      <Route path={NAV_TO_NEW_PASSWORD} exact component={NewPassword} />
      <Route path={NAV_TO_UNLOCK_USER} exact component={UnlockUser} />
      <Route path={NAV_TO_REGISTRATION} component={QuickRegistration} />
      <Route path={NAV_TO_LOGIN} exact component={Login} />
      <Route path={NAV_TO_RECOVERY_CREDENTIALS} exact component={RecoveryCredentials} />
      <Route path={NAV_TO_PASSWORD_EXPIRED} exact component={PasswordExpired} />
      <Route path={NAV_TO_FAQS} exact component={Faq} />
      <Route path={NAV_TO_NOT_SUPPORTED_BROWSER} exact component={NotSupportedBrowser} />
      <Route path={NAV_TO_NOT_COOKIE_POLICY} exact component={CookiePolicy} />
      <Route path={NAV_TO_DATAMATRIX} exact component={DataMatrix} />
      <Route path="/" exact component={Login} />

      <Redirect to="/" />
    </Switch>
  )

  const maintenance_routes = (
    <Switch>
      {/* MAINTENANCE PAGE */}
      <Route path={NAV_TO_MAINTENANCE} component={Maintenance} />
      <Route path="/" exact component={Maintenance} />
      <Redirect to="/" />
    </Switch>
  )

  return (
    <Layout>
      <ScrollToTop />
      <Suspense fallback={<div />}>{maintenance_active ? maintenance_routes : routes}</Suspense>
    </Layout>
  )
}

export default App
