import './bricks.scss'

import { FC, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { DOCUMENT_CATEGORIES, DOCUMENT_TYPES, POLICY_CATEGORIES } from 'src/backend-codes'
import { docs_list_box, document_t } from 'src/types'
import { fill_placeholders } from 'src/utils'

import { ActionsBox, render_buttons } from './actions.box'

export const ListBox: FC<docs_list_box> = ({
  classes = '',
  documents,
  highlight,
}: docs_list_box) => {
  const { t } = useTranslation()

  /**
   * Use State
   */
  const [docs, set_docs] = useState<document_t[]>()
  const [to_print, set_to_print] = useState<document_t>()
  const [to_download, set_to_download] = useState<document_t>()
  const [to_send, set_to_send] = useState<document_t>()

  /**
   * Use Effect
   */
  useEffect(() => {
    if (documents) {
      if (highlight) {
        documents?.sort((doc1, doc2) => {
          if (
            doc1?.documentType === 'LETTERA_DETRAZIONE' &&
            doc2?.documentType !== 'LETTERA_DETRAZIONE'
          ) {
            return -1
          }
          if (
            doc1?.documentType !== 'LETTERA_DETRAZIONE' &&
            doc2?.documentType === 'LETTERA_DETRAZIONE'
          ) {
            return 1
          }
          return 0
        })
      }
      set_docs(documents || [])
    }
  }, [documents, highlight])

  /**
   * Render functions
   */
  const render_doc = (doc: document_t) => {
    const policy_number = doc?.policy?.policyNumber
    const policy_descript = doc?.policy?.policyDescription
    const policy_car_name = doc?.policy?.carName

    let doc_category = ''
    doc?.documentCategories?.forEach((category, idx) => {
      if (idx > 0) {
        doc_category += ' - '
      }
      doc_category += DOCUMENT_CATEGORIES[category] ? DOCUMENT_CATEGORIES[category] : ''
    })

    if (policy_descript) {
      doc_category += doc_category !== '' ? ` - ${policy_descript}` : policy_descript
    }

    let policy_category = POLICY_CATEGORIES[doc?.policy?.policyCategoryCod || ''] || ''
    if (policy_number) {
      policy_category += ` ${policy_number}`
    }
    if (policy_car_name) {
      policy_category += `${policy_category && policy_number ? ' - ' : ''} ${policy_car_name}`
    }
    if (doc?.documentType === 'ATTESTATO_RISCHIO') {
      policy_category += `${policy_category} ${doc?.documentValue}`
    }
    if (doc?.documentType === 'QUIETANZA') {
      policy_category = `${doc?.documentDescription} ${doc?.policy?.policyOrderNumber}/${doc?.policy?.policyMovNumber}`
    }
    if (doc?.documentType === 'LETTERA_DETRAZIONE') {
      const curr_year = doc?.documentDate?.split('/')[2]
      doc.documentDate = curr_year || new Date().getFullYear() - 1 + ''
    }

    return (
      <>
        <p className="m-0 p-0 font-16 c-159881">{doc_category}</p>
        <p className="m-0 p-0 mt-1 font-18-semibold">{DOCUMENT_TYPES[doc?.documentType || '']}</p>
        {doc?.policy && <p className="m-0 p-0 mt-1 font-16">{policy_category}</p>}
        {doc?.documentDate && (
          <p className="m-0 p-0 mt-1 font-15 c-7f7f7f">
            {fill_placeholders(t('documents.of'), [doc?.documentDate])}
          </p>
        )}
      </>
    )
  }

  const render_buttons_ = (doc: document_t) => {
    const props_ = [
      { on_press: () => set_to_print(doc) },
      { on_press: () => set_to_download(doc) },
      { on_press: () => set_to_send(doc) },
    ]
    return (
      <>
        <Row className="m-0 p-0 justify-content-center width-fit-content d-none d-sm-block">
          {render_buttons(props_?.map((p) => ({ ...p, flavor: 'transparent_primary' })))}
        </Row>
        <Row className="m-0 p-0 justify-content-center w-100 d-sm-none">
          {render_buttons(props_)}
        </Row>
      </>
    )
  }

  return (
    <main className={classes}>
      <ActionsBox
        to_print={to_print}
        to_download={to_download}
        to_send={to_send}
        on_clear={() => {
          set_to_print(undefined)
          set_to_download(undefined)
          set_to_send(undefined)
        }}
      >
        <>
          {docs?.map((doc, idx) => (
            <Row
              key={idx}
              className={`m-0 p-3 border align-items-center justify-content-between ${
                highlight && doc?.documentType === 'LETTERA_DETRAZIONE' ? 'doc-highlight' : ''
              }`}
            >
              <div className="flex-1 m-0 p-0 pr-2">{render_doc(doc)}</div>
              {render_buttons_(doc)}
            </Row>
          ))}
        </>
      </ActionsBox>
    </main>
  )
}
