import { call, put } from 'redux-saga/effects'
import { outcome_w } from 'src/backend-codes'
import { http_answer_poll, http_resolve_poll } from 'src/http/poll.http'
import { saga_act } from 'src/types'
import { poll_t } from 'src/types/http/poll.types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Resolve poll
 */
export function* saga_resolve_poll(action: saga_act): unknown {
  const poll: poll_t = yield call(saga_do_request, http_resolve_poll, action)
  if (poll) {
    yield put(shoot.reduce_poll(poll))
  }
}

/**
 * Answer poll
 */
export function* saga_answer_poll(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_answer_poll, action)
  if (outcome) {
    yield put(shoot.reduce_outcome_poll(outcome))
  }
}
