import { Col, Image, Row } from 'react-bootstrap'
import i18n from 'src/i18n'
import { button_flavor, footer_cms, opt } from 'src/types'
import { Button } from 'src/widgets'

import { fill_strong } from './formatters.utils'

export const render_topbar = (
  on_press?: () => void,
  label = i18n.t('commons.back'),
  flavor: button_flavor = 'transparent_accent_back'
): JSX.Element => (
  <Col className="mt-4 p-0 d-flex">
    <Button flavor={flavor} text={label} classes={'mt-2 px-0'} on_press={on_press} />
  </Col>
)

export const render_temporary_failure = (on_press?: () => void): JSX.Element => {
  const msg = 'commons.temporary_error.'
  return (
    <div className="c-7f7f7f">
      <p className="font-16">{fill_strong(i18n.t(msg + 'part_1'))}</p>
      <p className="font-16">
        {`${i18n.t(msg + 'part_2')} `}
        <u className="c-862633">{i18n.t(msg + 'part_3')}</u>.
      </p>
      <Button flavor="primary_next" text="Ricarica pagina" on_press={on_press} />
    </div>
  )
}

export const render_contact = (
  icon: string,
  contact?: string,
  action?: string,
  color = '059881'
): JSX.Element => (
  <Row
    className={`m-0 p-0 my-2 align-items-center width-fit-content c-${color}`}
    onClick={action ? () => window.open(`${action}:${contact}`) : undefined}
    role="button"
  >
    <Image src={icon} className={`mr-2 filter-${color}`} height={20} width={20} />
    <p className="m-0 p-0 font-15">{contact}</p>
  </Row>
)

export const render_contacts_card = (
  t: (key: string) => string,
  car?: boolean,
  phone_icon?: string,
  classes?: string
): JSX.Element => (
  <div className={`${classes} col-sm-12 d-flex flex-column mt-2`}>
    <div className="bg-ffffff col-12 p-4">
      <div className="font-18-semibold">
        {car
          ? t('assistance_detail.card.vehicle_main_text')
          : t('assistance_detail.card.home_main_text')}
      </div>
      <div className="font-15 mt-4">
        {car
          ? t('assistance_detail.card.vehicle_assistance')
          : t('assistance_detail.card.home_assistance')}
      </div>

      <div className="font-15 c-00000080 mt-4">{t('assistance_detail.card.cell_ita_label')}</div>
      {render_contact(phone_icon || '', '800 66 89 99', 'tel')}

      <div className="font-15 c-00000080 mt-4">
        {t('assistance_detail.card.cell_foreign_label')}
      </div>
      {render_contact(phone_icon || '', '+39 0461 896451', 'tel')}
    </div>
  </div>
)

export const render_contact_partners = (
  is_mobile?: boolean,
  phone_icon?: string,
  email_icon?: string,
  footer?: opt<footer_cms>
): JSX.Element => (
  <div className={is_mobile ? '' : 'd-flex justify-content-center'}>
    <div className="d-flex" style={{ marginRight: '3rem' }}>
      <div style={{ marginRight: '0.5rem' }}>
        <Image src={phone_icon} fluid />
      </div>
      <div
        className="unnamed-character-style-15-30-bold Login-forget-credentials"
        onClick={() => {
          footer?.phoneNumber !== undefined && footer?.phoneNumber != ''
            ? window.open('tel:' + footer?.phoneNumber)
            : null
        }}
      >
        <b>{footer?.phoneNumber}</b>
      </div>
    </div>
    <div className="d-flex" style={is_mobile ? { marginTop: '1rem' } : { marginTop: '' }}>
      <div style={{ marginRight: '0.5rem' }}>
        <Image src={email_icon} fluid />
      </div>
      <div className="unnamed-character-style-15-30-bold">
        <b>servizioclienti@vhv.it</b>
      </div>
    </div>
  </div>
)
