import { FC } from 'react'
import { Image } from 'react-bootstrap'
import { valid_icon } from 'src/assets'
import i18n from 'src/i18n'
import { input_text_props } from 'src/types'
import { is_required } from 'src/utils'

import './input-text.scss'

export const InputText: FC<input_text_props> = ({
  id,
  formik_props,
  formik_schema,
  touched,
  disabled,
  placeholder = i18n.t('commons.insert'),
  textarea,
  label,
  error,
  warning,
  icon,
  icon_valid = valid_icon,
  type,
  datalist,
  classes = '',
  classes_field = 'd-flex',
  classes_input = 'font-16 no-border flex-1 px-3 py-2 w-100',
  classes_label = 'm-0 font-15',
  classes_error = 'm-0 font-14 c-862633',
  classes_warning = 'm-0 font-15 c-ffbe00',
  classes_icon = 'px-2 bg-ffffff',
  classes_icon_valid = 'px-2 bg-ffffff',
  on_press_icon,
}: input_text_props) => {
  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? 'd-block' : 'd-none'}
          ${label ? '' : 'opacity-0'}
        `}
      >
        {`${label_} `}
      </label>
    )
  }

  const render_input = () => {
    const props_ = {
      id,
      disabled,
      placeholder,
      type,
      className: `${classes_input} ${disabled ? 'bg-e5e5e5' : ''}`,
      ...formik_props,
    }
    return textarea ? <textarea {...props_} /> : <input list={`${id}-datalist`} {...props_} />
  }

  const render_field = () => (
    <div
      className={`
        ${classes_field}
        ${disabled ? 'bg-e5e5e5' : ''}
        ${warning ? 'border-warning' : ''}
        ${touched && error ? 'border-error' : 'border-normal'}
      `}
    >
      {/* INPUT */}
      {render_input()}

      {/* GENERIC ICON */}
      {icon && (
        <Image
          src={icon}
          width={36}
          className={classes_icon}
          onClick={on_press_icon}
          role={on_press_icon ? 'button' : ''}
        />
      )}

      {/* VALID ICON */}
      {icon !== '' && !error && formik_props?.value && (
        <Image src={icon_valid} width={36} className={classes_icon_valid} onClick={on_press_icon} />
      )}
    </div>
  )

  const render_error = () => (
    <p
      className={`
        ${classes_error}
        ${classes_error ? 'd-block' : 'd-none'}
        ${touched && error ? '' : 'opacity-0'}
      `}
    >
      {`${error} `}
    </p>
  )

  const render_warning = () => <p className={classes_warning}>{`${warning} `}</p>

  const render_datalist = () => (
    <datalist id={`${id}-datalist`}>
      {datalist?.map((data, idx) => (
        <option key={idx} value={data} />
      ))}
    </datalist>
  )

  return (
    <main className={classes}>
      <div className="d-flex flex-column">
        {render_label()}
        {render_field()}
        {warning ? render_warning() : render_error()}
        {datalist && render_datalist()}
      </div>
    </main>
  )
}
