import axios from 'axios'
import { ax_get_document, ax_get_documents, ax_post_document } from 'src/types'

import { AUTHORIZATION_BEARER, axios_request, configify, GET, POST, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_DOCUMENT || ''
const CONTEXT_ROOT = 'documents'

/**
 * Document client
 */
export const document_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * GET documents
 */
export const get_documents = (
  requestId: string,
  { access_token }: ax_get_documents
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * GET documents policy
 */
export const get_documents_policy = (
  requestId: string,
  { access_token, ...params }: ax_get_documents
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/policy`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params,
})

/**
 * GET documents institutional
 */
export const get_documents_institutional = (
  requestId: string,
  { access_token }: ax_get_documents
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/institutional`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * GET document
 */
export const get_document = (
  requestId: string,
  { access_token, ...params }: ax_get_document
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/print`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params,
})

/**
 * POST document
 */
export const post_document = (
  requestId: string,
  { access_token, ...params }: ax_post_document
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/send`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params,
})
