import React, { FC, useEffect, useRef, useState } from 'react'
import { Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { add_file_icon, close_icon } from 'src/assets'
import { file_uploader_props } from 'src/types'
import { capitalize, format_size } from 'src/utils'

import { Button } from '../button/button'

import './file-uploader.scss'

export const FileUploader: FC<file_uploader_props> = ({
  id,
  files,
  label,
  accept = '.jpg,.png,.jpeg,.pdf,.docx',
  disabled,
  classes = '',
  classes_label = 'm-0 font-15',
  max_file_size = 500000,
  on_file_added,
  on_file_removed,
  on_size_exceeded,
}: file_uploader_props) => {
  const { t } = useTranslation()

  /**
   * Use Ref
   */
  const current_file = useRef<HTMLInputElement>(null)

  /**
   * Use State
   */
  const [drop_area, set_drop_area] = useState<HTMLElement>()

  /**
   * Use Effects
   */
  useEffect(() => {
    const drop_area_ = document.getElementById(`drop-area-${id}`)
    set_drop_area(drop_area_ || undefined)
  }, [])

  useEffect(() => {
    const on_insert = ['dragenter', 'dragover']
    const on_remove = ['dragleave', 'drop']

    on_insert.forEach((event: string) => {
      drop_area?.addEventListener(event, () => add_highlight(drop_area), false)
    })
    on_remove.forEach((event: string) => {
      drop_area?.addEventListener(event, () => remove_highlight(drop_area), false)
    })
  }, [drop_area])

  /**
   * Support functions
   */
  const add_highlight = (drop_area_: HTMLElement) => {
    drop_area_?.classList.add('drop-area-highlight')
  }

  const remove_highlight = (drop_area_: HTMLElement) => {
    drop_area_?.classList.remove('drop-area-highlight')
  }

  const open_explorer = () => {
    if (current_file && current_file.current) {
      current_file.current.click()
    }
  }

  const add_file = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: files_ } = e.target
    if (files_?.length) {
      if (files_[0]?.size < max_file_size) {
        on_file_added?.(files_[0])
      } else {
        on_size_exceeded?.(files_[0])
      }
    }
  }

  const on_drag_over = (e: React.DragEvent<HTMLDivElement>) => {
    drop_area?.classList.add('drop-area-highlight')
    e.preventDefault()
  }

  const on_drop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e?.dataTransfer?.files) {
      const files_ = e?.dataTransfer?.files
      if (files_ && files_?.length > 0) {
        on_file_added?.(files_[0])
      }
    }
    drop_area?.classList.remove('drop-area-highlight')
  }

  /**
   * Render functions
   */
  const render_uploader = () => (
    <div>
      {/* LABEL */}
      {label && <label className={classes_label}>{label}</label>}

      {!disabled && (
        <div
          id={`drop-area-${id}`}
          className="m-0 p-4 uploader-border"
          onDragOver={on_drag_over}
          onDrop={on_drop}
        >
          {/* ICON */}
          <Image src={add_file_icon} onClick={open_explorer} role="button" />
          <input
            hidden
            type="file"
            accept={accept}
            ref={current_file}
            onChange={add_file}
            title=""
            value=""
          />

          {/* DESKTOP */}
          <p className="m-0 p-0 mt-3 d-none d-sm-block text-center font-16 c-7f7f7f">
            {`${t('commons.docs.drag_drop')} `}
            <u className="c-159881" onClick={open_explorer} role="button">
              {t('commons.docs.browse')}
            </u>
            {` ${t('commons.docs.files')}`}
          </p>

          {/* MOBILE */}
          <p className="m-0 p-0 d-sm-none text-center font-16 c-7f7f7f">
            <u className="c-159881" onClick={open_explorer} role="button">
              {capitalize(t('commons.docs.browse'))}
            </u>
            {` ${t('commons.docs.files')}`}
          </p>
          <p className="m-0 p-0 d-none d-sm-block font-14 c-7f7f7f">{t('commons.docs.types')}</p>
        </div>
      )}
    </div>
  )

  const render_file_list = () => (
    <div>
      {files?.map((file, idx) => (
        <Row key={idx} className="m-0 p-0 py-2">
          <p className="m-0 p-0 font-16 c-7f7f7f flex-1 text-truncate">
            {`${file?.name} (${format_size(file?.size)})`}
          </p>
          <Button
            iconl={close_icon}
            classes_iconl=""
            classes="p-0 bg-transparent"
            on_press={() => on_file_removed?.(idx)}
          />
          <div className="mt-1 bg-159881 w-100 uploader-row" />
        </Row>
      ))}
    </div>
  )

  return (
    <main className={classes}>
      {render_uploader()}
      {render_file_list()}
    </main>
  )
}
