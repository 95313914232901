import { FC, useState } from 'react'
import { Collapse, Image, Row } from 'react-bootstrap'
import { left_icon } from 'src/assets'
import { accordion_props } from 'src/types'

import './accordion.scss'

export const Accordion: FC<accordion_props> = ({
  title,
  head,
  children,
  flavor = 'base',
  default_expanded = false,
  classes = '',
  classes_head = 'm-0 p-3 justify-content-between flex-nowrap',
  classes_title = 'm-0 p-0 font-16',
  classes_icon,
}: accordion_props) => {
  const [open, set_open] = useState(default_expanded)
  return (
    <main className={classes}>
      {/* HEAD */}
      <Row className={classes_head} role="button" onClick={() => set_open(!open)}>
        {head || <p className={classes_title}>{title}</p>}
        <Image
          src={left_icon}
          className={`
            icon ${open ? '' : 'rotate'}
            ${classes_icon}
            ${flavor === 'green' ? 'filter-159881' : ''}
          `}
        />
      </Row>

      {/* SEPARATOR */}
      {flavor === 'green' && <hr className="m-0 p-0 bg-159881" />}
      {flavor === 'grey' && <hr className="m-0 p-0 bg-ffffff80" />}

      {/* COLLAPSIBLE */}
      <Collapse in={open}>
        <div className="m-0 p-0">{children}</div>
      </Collapse>
    </main>
  )
}
