import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { AccordionProps } from 'src/types/--deprecated/types'
import { fill_strong } from 'src/utils'
import { ClassNames } from 'src/utils/--deprecated'

import './accordion.scss'

const AccordionComponent: React.FC<AccordionProps> = (props: AccordionProps) => {
  const {
    title = '',
    title_style = 'pre-line',
    hide_line = false,
    line_color = '',
    arrow_bottom = '',
    arrow_up = '',
    content,
    content_style = '',
  } = props

  const [open, set_open] = useState(false)
  return (
    <Accordion>
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="event_key"
          className={ClassNames(
            'accordion-component-header--' + (hide_line && open ? 'hide' : line_color)
          )}
          onClick={() => set_open(!open)}
        >
          <div className={title_style}>{title}</div>
          <div className={open ? arrow_up : arrow_bottom} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="event_key">
          <Card.Body className="accordion-component-body">
            <div style={{ whiteSpace: 'pre-line' }} className={content_style}>
              {typeof content === 'string' ? fill_strong(content, 'font-14-semibold') : content}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}
export default AccordionComponent
