import { put } from 'redux-saga/effects'

import { shoot } from '../actions'

/**
 * Startup
 */
export function* saga_startup(): unknown {
  yield put(shoot.reduce_started(true))
}

export function* saga_invalidate_cache(): unknown {
  yield put(shoot.reduce_policy_detail())
  yield put(shoot.reduce_documents_policy())
  yield put(shoot.reduce_documents())
  yield put(shoot.reduce_banners())
  yield put(shoot.reduce_footer())
  yield put(shoot.reduce_privacies())
  yield put(shoot.reduce_vouchers())
  yield put(shoot.reduce_cookie_informatives())
}
