import 'src/containers/login/login.scss'
import './recovery-credentials.scss'

import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Col, Container, Form, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { email_icon, failure_icon, phone_icon } from 'src/assets'
import MailIcon from 'src/assets/images/envelope.svg'
import PhoneIcon from 'src/assets/images/phone-call.svg'
import { BE_ERROR_CODES, subject_type_w } from 'src/backend-codes'
import i18n from 'src/i18n'
import { NAV_TO_LOGIN } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import {
  select_error,
  select_footer,
  select_loading,
  select_outcome_recovery_client_id,
  select_outcome_recovery_password,
} from 'src/redux/store'
import { error_t, recovery_t } from 'src/types'
import { generic_props } from 'src/types/--deprecated/containers/generic.types'
import { render_contact_partners } from 'src/utils'
import { ClassNames, isValid_field } from 'src/utils/--deprecated'
import { Button as NewButton, Loader, Modal } from 'src/widgets'
import Button from 'src/widgets/--deprecated/button/button'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import ModalSuccess from 'src/widgets/--deprecated/modal-success/modal-success'
import RadioButton from 'src/widgets/--deprecated/radio-button/radio'
import { Recaptcha, submit_captcha } from 'src/widgets/recaptcha/recaptcha'
import * as Yup from 'yup'

interface data_radio {
  id: string;
  show: boolean;
  value: subject_type_w;
  text: string;
  subText: string;
  disableInputId: string;
}

/**
 * Recovery Credentials components
 */
const RecoveryCredentials: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  /**
   * Use Selector
   */
  const error: error_t | undefined = useSelector(select_error())
  const loading = useSelector(select_loading())
  const outcome_recovery_client_id = useSelector(select_outcome_recovery_client_id)
  const outcome_recovery_password = useSelector(select_outcome_recovery_password)
  const footer = useSelector(select_footer)

  /**
   * Use State
   */
  const [type_form, set_type_form] = useState('password')
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 810)
  const [type_person, set_type_person] = useState<subject_type_w>('F')
  const [recovery, set_recovery] = useState<recovery_t>()
  const [error_visible, set_error_visible] = useState(false)
  const [success_visible, set_success_visible] = useState(false)
  const [captcha, set_captcha] = useState<string>('')

  const radio_elements: data_radio[] = [
    {
      id: 'id_typePF',
      show: false,
      value: 'F',
      text: t('recovery_credentials.forms.person_F'),
      subText: '',
      disableInputId: 'false',
    },
    {
      id: 'id_typePG',
      show: false,
      value: 'G',
      text: t('recovery_credentials.forms.person_G'),
      subText: '',
      disableInputId: 'false',
    },
  ]

  /**
   * Use Effect
   */
  useEffect(() => {
    if (error?.is_tech) set_error_visible(true)
  }, [error])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  useEffect(() => {
    if (outcome_recovery_client_id === 'success' || outcome_recovery_password === 'success')
      set_success_visible(true)
    if (outcome_recovery_client_id === 'success') {
      dispatch(shoot.reduce_outcome_recovery_client_id(undefined))
    } else if (outcome_recovery_password === 'success') {
      dispatch(shoot.reduce_outcome_recovery_password(undefined))
    }
  }, [outcome_recovery_client_id, outcome_recovery_password])

  useEffect(() => {
    if (captcha !== '') {
      recovery_call()
    }
  }, [captcha])

  /**
   * Support functions
   */
  const change_person_type_handler = (value: subject_type_w) => {
    set_type_person(value)
  }

  const switch_form_handler = (value: string) => {
    if (type_form != value) {
      set_type_person('F')
    }

    set_type_form(value)
  }

  const dismiss_error = () => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }

  const toggle_button_submit = (values: any, errors: any) => {
    let btn_enabled
    if (type_person === 'F') {
      btn_enabled =
        values.cf === '' ||
        values.email === '' ||
        errors.cf !== undefined ||
        errors.email !== undefined ||
        !isValid_field(values.cf, 'cf_pf')
          ? true
          : false
      return btn_enabled
    } else if (type_person === 'G') {
      btn_enabled =
        values.cf == '' ||
        values.pi == '' ||
        values.email == '' ||
        errors.cf != undefined ||
        errors.pi != undefined ||
        errors.email != undefined ||
        !isValid_field(values.pi, 'vat') ||
        !isValid_field(values.cf, 'cf_pg')
          ? true
          : false
      return btn_enabled
    }
  }

  const on_submit = (cf: string, email: string, pi: string) => {
    const recovery: recovery_t = {
      subjectType: type_person,
      email: email,
      taxCode: cf?.toUpperCase(),
      vatNumber: pi,
    }
    set_recovery(recovery)
    submit_captcha().then((token: string) => {
      set_captcha(token)
    })
  }

  const recovery_call = () => {
    if (type_form == 'password') {
      dispatch(shoot.saga_recovery_password({ recovery, captcha }))
    } else if (type_form == 'idCliente') {
      dispatch(shoot.saga_recovery_client_id({ recovery, captcha }))
    }
  }

  const nav_to_login = () => {
    history.push(NAV_TO_LOGIN)
  }

  const dismiss_modal = () => {
    dismiss_error()
    set_error_visible(false)
  }

  /**
   * Form validation schema F person
   */
  const validation_schema_F = Yup.object().shape({
    cf: Yup.string().required(t('recovery_credentials.errors.form_cf_required')),
    email: Yup.string()
      .email(t('recovery_credentials.errors.form_email_invalid'))
      .max(254, t('recovery_credentials.errors.form_email_max_char'))
      .required(t('recovery_credentials.errors.form_email_required')),
  })

  /**
   * Form validation schema G person
   */
  const validation_schema_G = Yup.object().shape({
    cf: Yup.string().required(t('recovery_credentials.errors.form_cf_required')),
    pi: Yup.string().required(t('recovery_credentials.errors.form_pi_required')),
    email: Yup.string()
      .email(t('recovery_credentials.errors.form_email_invalid'))
      .max(254, t('recovery_credentials.errors.form_email_max_char'))
      .required(t('recovery_credentials.errors.form_email_required')),
  })

  const FormPassword = (type: string) => (
    <Formik
      key={type}
      validationSchema={type_person === 'G' ? validation_schema_G : validation_schema_F}
      initialValues={{ cf: '', pi: '', email: '' }}
      onSubmit={(values) => {
        on_submit(values?.cf, values?.email, values?.pi)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="Form-container" controlId="formCf">
            <Form.Label
              className={
                is_mobile ? 'unnamed-character-style-27-13-22' : 'unnamed-character-style-49'
              }
            >
              {t('recovery_credentials.forms.cf')}
            </Form.Label>
            <InputTxt
              id="cf"
              placeholder={t('recovery_credentials.forms.cf_placeholder')}
              type="text"
              value={values.cf}
              on_change={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange(e)
                handleBlur(e)
                dismiss_error()
                setFieldValue('cf', e.target.value)
              }}
              validators={[
                {
                  is_valid: () =>
                    !((touched.cf && errors.cf) || values.cf === '') &&
                    (type_person === 'F'
                      ? isValid_field(values.cf, 'cf_pf')
                      : isValid_field(values.cf, 'cf_pg')),
                  text:
                    !isValid_field(values.cf, 'cf_pf') || !isValid_field(values.cf, 'cf_pg')
                      ? t('recovery_credentials.errors.form_cf_invalid')
                      : errors.cf,
                },
              ]}
            />
          </Form.Group>
          {type_person === 'G' ? (
            <Form.Group className="Form-container" controlId="formPi">
              <Form.Label
                className={
                  is_mobile ? 'unnamed-character-style-27-13-22' : 'unnamed-character-style-49'
                }
              >
                {t('recovery_credentials.forms.pi')}
              </Form.Label>
              <InputTxt
                id="pi"
                placeholder={t('recovery_credentials.forms.pi_placeholder')}
                type="text"
                value={values.pi}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  dismiss_error()
                  setFieldValue('pi', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () =>
                      !((touched.pi && errors.pi) || values.pi === '') &&
                      isValid_field(values.pi, 'vat'),
                    text: !isValid_field(values.pi, 'vat')
                      ? t('recovery_credentials.errors.form_pi_invalid')
                      : errors.pi,
                  },
                ]}
              />
            </Form.Group>
          ) : null}
          <Form.Group className="Form-container" controlId="formEmail">
            <Form.Label
              className={
                is_mobile ? 'unnamed-character-style-27-13-22' : 'unnamed-character-style-49'
              }
            >
              {t('recovery_credentials.forms.email')}
            </Form.Label>
            <InputTxt
              id="email"
              placeholder={t('recovery_credentials.forms.email_placeholder')}
              type="text"
              value={values.email}
              on_change={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange(e)
                handleBlur(e)
                dismiss_error()
                setFieldValue('email', e.target.value)
              }}
              validators={[
                {
                  is_valid: () => !((touched.email && errors.email) || values.email === ''),
                  text: errors.email || '',
                },
              ]}
            />
          </Form.Group>

          <div className="recovery-credentials-button">
            <Button
              name="full_059881"
              disabled={toggle_button_submit(values, errors)}
              justify_content={is_mobile ? 'space-between' : 'center'}
            >
              <div>{t('recovery_credentials.forms.btn_label')}</div>
              <div className="right-arrow-white" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )

  const PassowrdIdClienteSection: React.FC<generic_props> = () => {
    return (
      <div>
        <div className="recovery-credential-radios-container">
          <div className={is_mobile ? 'unnamed-character-style-22' : 'unnamed-character-style-48'}>
            {t('recovery_credentials.forms.choose_person')}
          </div>

          <div
            className={ClassNames(
              'recovery-credential-radios-container--radio',
              is_mobile ? 'unnamed-character-style-22' : 'unnamed-character-style-49'
            )}
          >
            {radio_elements.map((dataRadio, index) => {
              return (
                <div className="recovery-credential-radios-container--radio-item" key={index}>
                  <RadioButton
                    value={dataRadio.value}
                    id_category={dataRadio.value}
                    selected={type_person}
                    text={dataRadio.text}
                    subText={dataRadio.subText}
                    onClick={() => change_person_type_handler(dataRadio.value)}
                    disableInputId={dataRadio.disableInputId}
                    show={dataRadio.show}
                  />
                </div>
              )
            })}
          </div>
        </div>

        {type_form === 'password' ? FormPassword('password') : FormPassword('id_cliente')}
        <Recaptcha />
      </div>
    )
  }

  const EmailSection: React.FC<generic_props> = () => {
    return (
      <div>
        <p className={is_mobile ? 'unnamed-character-style-22' : 'unnamed-character-style-11'}>
          {t('recovery_credentials.forms.email_recover_description')}
        </p>
        <div
          className={ClassNames(
            'recovery-credentials-info',
            is_mobile ? 'recovery-credentials-container-info' : ''
          )}
        >
          <Image src={PhoneIcon} fluid />
          <div
            className={ClassNames(
              'recovery-contact',
              is_mobile ? 'unnamed-character-style-16-30' : 'unnamed-character-style-18-30'
            )}
          >
            {t('recovery_credentials.forms.phone')}
          </div>
        </div>
        <div className="recovery-credentials-info">
          <Image src={MailIcon} fluid />
          <div>
            <a
              className={ClassNames(
                'recovery-contact',
                is_mobile ? 'unnamed-character-style-16-30' : 'unnamed-character-style-18-30'
              )}
              href="mailto:servizioclienti@vhv.it?subject=Recupero email accesso Area Riservata"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('recovery_credentials.forms.email_contact')}
            </a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Container fluid>
      {loading ? (
        <div className="login-loader">
          <Loader />{' '}
        </div>
      ) : (
        <div>
          <Row>
            <div className="recovery-credentials-button-back">
              <Button name="text_059881" padding="0" onClick={nav_to_login}>
                <div className="left-arrow-green" />
                <div>{t('recovery_credentials.back_button')}</div>
              </Button>
            </div>
          </Row>

          <Row className="recovery-container">
            <Col>
              <div
                className={is_mobile ? 'unnamed-character-style-30' : 'unnamed-character-style-29'}
              >
                {t('recovery_credentials.title')}
              </div>
              <p
                className={
                  is_mobile
                    ? 'unnamed-character-style-15-26-7f7f7f mt-3'
                    : 'unnamed-character-style-18 mt-3'
                }
              >
                {t('recovery_credentials.subtitle')}
              </p>
              <div className="sel-container d-flex">
                <div
                  className={`sel-content ${type_form === 'password' ? 'content-selected' : ''}`}
                >
                  <Button
                    onClick={() => switch_form_handler('password')}
                    name={
                      is_mobile ? 'text_7f7f7f_hover_862633_mobile' : 'text_7f7f7f_hover_862633'
                    }
                    justify_content="center"
                    height="2rem"
                    color={type_form === 'password' ? '#862633' : ''}
                    uppercase={false}
                    padding="0"
                  >
                    {t('recovery_credentials.button_password_text')}
                  </Button>
                </div>

                <div className={`sel-content ${type_form === 'email' ? 'content-selected' : ''}`}>
                  <Button
                    onClick={() => switch_form_handler('email')}
                    name={
                      is_mobile ? 'text_7f7f7f_hover_862633_mobile' : 'text_7f7f7f_hover_862633'
                    }
                    justify_content="center"
                    height="2rem"
                    color={type_form === 'email' ? '#862633' : ''}
                    uppercase={false}
                    padding="0"
                  >
                    {t('recovery_credentials.button_email_text')}
                  </Button>
                </div>
                <div
                  className={`sel-content ${type_form === 'idCliente' ? 'content-selected' : ''}`}
                >
                  <Button
                    onClick={() => switch_form_handler('idCliente')}
                    name={
                      is_mobile ? 'text_7f7f7f_hover_862633_mobile' : 'text_7f7f7f_hover_862633'
                    }
                    justify_content="center"
                    height="2rem"
                    color={type_form === 'idCliente' ? '#862633' : ''}
                    uppercase={false}
                    padding="0"
                  >
                    {t('recovery_credentials.button_id_client_text')}
                  </Button>
                </div>
              </div>

              {type_form === 'password' || type_form === 'idCliente'
                ? PassowrdIdClienteSection({})
                : EmailSection({})}
            </Col>
          </Row>
        </div>
      )}
      {error_visible && (
        <Modal visible={error_visible} on_close={() => dismiss_modal()}>
          <div className="d-flex align-items-center justify-content-center p-1">
            <div className="d-inline text-center">
              <Image src={failure_icon} />
              <p className="mx-2 mt-4 font-16">{error?.description_fe || error?.description_be}</p>

              {error?.code === BE_ERROR_CODES.USER_DUPLICATE_RECOVERY &&
                render_contact_partners(is_mobile, phone_icon, email_icon, footer)}
              <div className="d-flex align-items-center justify-content-center">
                <NewButton
                  flavor={'primary'}
                  text={t('commons.close')}
                  on_press={() => dismiss_modal()}
                  classes="mt-3"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {success_visible && (
        <ModalSuccess
          show={success_visible}
          handle_close={() => set_success_visible(false)}
          title=""
          description={i18n.t('recovery_credentials.modal_success.description')}
        />
      )}
    </Container>
  )
}

export default RecoveryCredentials
