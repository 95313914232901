import { outcome_w } from 'src/backend-codes'
import {
  customer_outcome_t,
  dati_contraente_t,
  iban_outcome_t,
  opt,
  payment_outcome_t,
  policy_outcome_t,
  redux_store,
} from 'src/types'

export const select_outcome_logout = (state: redux_store): opt<outcome_w> => state?.outcomes?.logout

export const select_outcome_registration = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.registration

export const select_outcome_reset_password = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.reset_password

export const select_outcome_recovery_client_id = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.recovery_client_id

export const select_outcome_recovery_password = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.recovery_password

export const select_outcome_customer = (state: redux_store): opt<customer_outcome_t> =>
  state?.outcomes?.customer

export const select_outcome_contacts = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.contacts

export const select_outcome_consents = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.consents

export const select_outcome_language = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.language

export const select_outcome_access = (state: redux_store): opt<outcome_w> => state?.outcomes?.access

export const select_outcome_iban = (state: redux_store): opt<iban_outcome_t> =>
  state?.outcomes?.iban

export const select_outcome_payment = (state: redux_store): opt<payment_outcome_t> =>
  state?.outcomes?.payment

export const select_outcome_payment_status = (state: redux_store): opt<payment_outcome_t> =>
  state?.outcomes?.payment_status

export const select_outcome_email = (state: redux_store): opt<outcome_w> => state?.outcomes?.email

export const select_outcome_document = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.document

export const select_outcome_policy = (state: redux_store): opt<policy_outcome_t> =>
  state?.outcomes?.policy

export const select_outcome_voucher = (state: redux_store): opt<outcome_w> =>
  state?.outcomes?.voucher

export const select_outcome_poll = (state: redux_store): opt<outcome_w> => state?.outcomes?.poll
