import './mobile-menu.scss'

import React from 'react'
import { Container } from 'react-bootstrap'
import { ClassNames } from 'src/utils/--deprecated'

import LanguageSelector from '../language-selector/language-selector'
import { useSelector } from 'react-redux'
import { select_history_item } from 'src/redux/store'

interface MenuItemsProps {
  text: string;
  link: string;
  icon?: string;
  icon_brown?: string;
  id: string;
}

interface MobileMenuProps {
  title: string;
  proclie?: string;
  show: boolean;
  closed: () => void;
  menu_items: MenuItemsProps[];
  on_click_item_handler?: (id: string, link: string) => void;
}

window.onbeforeunload = function () {
  window.scrollTo(0, 0)
}

const MobileMenu: React.FC<MobileMenuProps> = (props: MobileMenuProps) => {
  const path = window.location.pathname

  const from_app = useSelector(select_history_item('from-app'))

  const items = props.menu_items.filter(({ id }: MenuItemsProps) => !from_app || id !== 'logout_id')

  return (
    <Container className={ClassNames('menu', props.show ? 'menu--open' : 'menu--close')}>
      <div className="title-x-button-style">
        <div className="icon-menu-main-profile mr-1" />
        <div className="mobile_menu_title_character text-capitalize">
          {props?.title?.toLowerCase()}
        </div>
        <div className="x-button-mobile ml-auto" onClick={props.closed} />
      </div>
      <LanguageSelector />
      <hr className="menu-items--separator mt-4 mx-n5" />
      <div className="menu-items">
        {items.map((item: MenuItemsProps, index: number) => (
          <div key={index} className={item?.id === 'ar_id' ? 'd-none' : 'd-block'}>
            <div
              onClick={() => {
                props.closed(),
                  props.on_click_item_handler
                    ? props.on_click_item_handler(item.id, item.link)
                    : null
              }}
              className="mobile_menu_items_character menu-items--navlink"
            >
              <div
                className={
                  path === item?.link
                    ? 'c-862633'
                    : item?.id === 'logout_id'
                    ? 'c-059881'
                    : 'c-7f7f7f'
                }
              >
                {item.text}
              </div>

              <div className={path === item?.link ? item?.icon_brown : item?.icon} />
            </div>
            {index !== items.length - 1 ? <hr className="menu-items--separator" /> : null}
          </div>
        ))}
      </div>
    </Container>
  )
}
export default MobileMenu
