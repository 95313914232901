export const REM_IN_PX = 16
export const IS_MOBILE_WIDTH_THRESHOLD_IN_PX = 767
export const IS_MOBILE_WIDTH_THRESHOLD_IN_REM = IS_MOBILE_WIDTH_THRESHOLD_IN_PX / REM_IN_PX
export const IS_TABLET_WIDTH_THRESHOLD_IN_PX = 1024
export const IS_TABLET_WIDTH_THRESHOLD_IN_REM = IS_TABLET_WIDTH_THRESHOLD_IN_PX / REM_IN_PX

export const ENV_SVI = 'SVI'
export const ENV_TST = 'TST'
export const ENV_PRE = 'PRE'
export const ENV_PRD = 'PRD'
