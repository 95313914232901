import './password-reset.scss'

import base64 from 'base-64'
import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Container, Form, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { info_x_icon, valid_icon } from 'src/assets'
import { BE_ERROR_CODES, SEVERITY_CODES } from 'src/backend-codes'
import { severity_img_mapping } from 'src/error-management/errors'
import i18n from 'src/i18n'
import { shoot } from 'src/redux/actions'
import {
  select_customer,
  select_error,
  select_loading,
  select_login_token,
  select_outcome_reset_password,
} from 'src/redux/store'
import {
  regex_password_lowercase,
  regex_password_number,
  regex_password_uppercase,
} from 'src/regex'
import { customer_t } from 'src/types'
import { ClassNames, format_text } from 'src/utils/--deprecated'
import { Loader, Tooltip } from 'src/widgets'
import { default as Button } from 'src/widgets/--deprecated/button/button'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import Modal, {
  modalProps_genericError,
  ModalPropsDataType,
} from 'src/widgets/--deprecated/modal/modal'
import { Recaptcha, submit_captcha } from 'src/widgets/recaptcha/recaptcha'
import * as Yup from 'yup'

const PasswordReset: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  /**
   * Selectors
   */
  const loading = useSelector(select_loading())
  const error = useSelector(select_error())
  const login_token = useSelector(select_login_token)
  const outcome_reset_password = useSelector(select_outcome_reset_password)
  const customer = useSelector(select_customer)

  /**
   * States
   */
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < 1200)
  const [show_modal, set_show_modal] = useState(false)
  const [error_visible, set_error_visible] = useState(false)
  const [captcha, set_captcha] = useState('')
  const [password, set_password] = useState('')
  const [current_password, set_current_password] = useState('')

  const [modal_info, set_modal_info] = useState({
    title: t('password_reset.info_modal.title'),
    options: [
      { id: '1', text: t('password_reset.info_modal.option_1'), valid: '' },
      { id: '2', text: t('password_reset.info_modal.option_2'), valid: '' },
      { id: '3', text: t('password_reset.info_modal.option_3'), valid: '' },
      { id: '4', text: t('password_reset.info_modal.option_4'), valid: '' },
      { id: '5', text: t('password_reset.info_modal.option_5'), valid: '' },
      { id: '6', text: t('password_reset.info_modal.option_6'), valid: '' },
      { id: '7', text: t('password_reset.info_modal.option_7'), valid: '' },
      { id: '8', text: t('password_reset.info_modal.option_8'), valid: '' },
      { id: '9', text: t('password_reset.info_modal.option_9'), valid: '' },
    ],
  })

  /**
   * Use Effect
   */

   useEffect(() => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }, [])
  
  useEffect(() => {
    if (error?.is_tech) set_error_visible(true)
  }, [error])

  useEffect(() => {
    if (outcome_reset_password === 'success' && login_token) {
      go_back()
    }
  }, [outcome_reset_password, login_token])


  useEffect(() => {
    if (captcha && password && current_password) {
      dispatch(
        shoot.saga_reset_password({
          current_password: base64.encode(current_password),
          password: base64.encode(password),
          access_token: login_token?.access_token,
          captcha
        })
      )
    }
  }, [captcha, password,current_password])

  /**
   * Functions
   */
  const reset_password = (current_password: string, password: string) => {
    set_password('')
    set_captcha('')
    set_current_password('')
    submit_captcha().then((token: string) => {
      set_password(password)
      set_current_password(current_password)
      set_captcha(token)
    })

  }

  const toggle_modal = (value: boolean) => {
    set_show_modal(value)
  }

  const go_back = () => {
    history.goBack()
  }

  const dismiss_error = () => {
    if (error) {
      dispatch(shoot.reduce_error())
    }
  }

  const on_click_modal_props = () => {
    dismiss_error()
    set_error_visible(false)
  }

  const modal_props: ModalPropsDataType = {
    ...modalProps_genericError.data,
    imgType: error?.severity
      ? severity_img_mapping[error.severity]
      : severity_img_mapping[SEVERITY_CODES.FATAL],
    title: error?.title || '',
    text: error?.description_fe || error?.description_be || '',
    showNextButton: false,
    closeButtonOnClick: on_click_modal_props,
  }


const check_user_reference = (password : string, checked_field: keyof customer_t): boolean => {
  let val = customer?.[checked_field]
  if(val == null){
    return false;
  }
  if(checked_field === 'birthDate'){
    const date_splitted = customer?.birthDate?.split("-")
    val = `${date_splitted?.[2]}${date_splitted?.[1]}${date_splitted?.[0]}`
  }
  return password?.toLocaleLowerCase()?.includes(JSON.stringify(val)?.toLocaleLowerCase()?.replace(/"/g, ""))
  }

  //Form validation schema
  const validation_schema = Yup.object().shape({
    password: Yup.string()
      .required(t('password_reset.errors.password_required'))
      .min(8, t('password_reset.errors.password_invalid')),
    newPassword: Yup.string()
      .required(() => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('password_reset.errors.password_required')
      })
      .min(8, () => {
        modal_info.options[0].valid = 'invalid'
        set_modal_info(modal_info)
        return t('password_reset.errors.password_invalid')
      })
      .max(7, () => {
        modal_info.options[0].valid = 'valid'
        set_modal_info(modal_info)
      })
      .test('', t('password_reset.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_uppercase)) {
          modal_info.options[1].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[1].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('password_reset.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_lowercase)) {
          modal_info.options[2].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[2].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('password_reset.errors.password_invalid'), (value) => {
        if (value?.match(regex_password_number)) {
          modal_info.options[3].valid = 'valid'
          set_modal_info(modal_info)
          return true
        } else {
          modal_info.options[3].valid = 'invalid'
          set_modal_info(modal_info)
          return false
        }
      })
      .test('', t('password_reset.errors.password_invalid'), (value) => {
        if (check_user_reference(value || '', 'name')) {
          modal_info.options[4].valid = 'invalid'
          set_modal_info(modal_info)
          return false
         } else {
          modal_info.options[4].valid = 'valid'
          set_modal_info(modal_info)
          return true
        }
      })
      .test('', t('password_reset.errors.password_invalid'), (value) => {
        if (check_user_reference(value || '', 'surname')) {
          modal_info.options[5].valid = 'invalid'
          set_modal_info(modal_info)
          return false
         } else {
          modal_info.options[5].valid = 'valid'
          set_modal_info(modal_info)
          return true
        }
      })
      .test('', t('password_reset.errors.password_invalid'), (value) => {
        if (check_user_reference(value || '', 'taxCode')) {
          modal_info.options[6].valid = 'invalid'
          set_modal_info(modal_info)
          return false
         }  else {
          modal_info.options[6].valid = 'valid'
          set_modal_info(modal_info)
          return true
        }
      })
      .test('', t('password_reset.errors.password_invalid'), (value) => {
        if (check_user_reference(value || '', 'vatNumber')) {
          modal_info.options[7].valid = 'invalid'
          set_modal_info(modal_info)
          return false
         }  else {
          modal_info.options[7].valid = 'valid'
          set_modal_info(modal_info)
          return true
        }
      }).test('', t('password_reset.errors.password_invalid'), (value) => {
        if (check_user_reference(value || '', 'birthDate')) {
          modal_info.options[8].valid = 'invalid'
          set_modal_info(modal_info)
          return false
         }  else {
          modal_info.options[8].valid = 'valid'
          set_modal_info(modal_info)
          return true
        }
      }),
    passwordConfirmed: Yup.string()
      .required(t('password_reset.errors.password_required'))
      .test('passwords-match', t('password_reset.errors.password_different'), function (value) {
        return this.parent.newPassword === value
      }),
  })

  return (
    <Container fluid>
      {loading ? (
        <div className="login-loader">
          <Loader />{' '}
        </div>
      ) : (
        <div>
          <Row>
            <div className="password-reset-button-back">
              <Button name="text_059881" onClick={go_back} padding="0">
                <div className="left-arrow-green" />
                <div> {t('password_reset.back_button')}</div>
              </Button>
            </div>
          </Row>

          <Row className="password-reset-container">
            <div onClick={() => toggle_modal(false)}>
              <div
                className={ClassNames(
                  'password-reset-title',
                  is_mobile ? 'unnamed-character-style-30' : 'unnamed-character-style-29'
                )}
              >
                {t('password_reset.title')}
              </div>
              <div
                className={ClassNames(
                  'password-reset-sutitle',
                  is_mobile ? 'unnamed-character-style-15-26-7f7f7f' : 'unnamed-character-style-18'
                )}
              >
                {format_text(t('password_reset.subtitle'), '')}
              </div>

              <Recaptcha />
              <Formik
                key="password"
                validationSchema={validation_schema}
                initialValues={{
                  password: '',
                  passwordConfirmed: '',
                  newPassword: '',
                }}
                onSubmit={(values) => {
                  reset_password(values.password, values.newPassword)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="password-reset-input-container" controlId="password">
                      <Form.Label
                        className={ClassNames(
                          'password-reset-input-label',
                          'password-reset-input-label--actual-password',
                          is_mobile
                            ? 'unnamed-character-style-27-13-22'
                            : 'unnamed-character-style-48'
                        )}
                      >
                        {t('password_reset.forms.password')}
                      </Form.Label>

                      <InputTxt
                        id="password"
                        placeholder={t('password_reset.forms.password_placeholder')}
                        type="password"
                        value={values.password}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('password', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !((touched.password && errors.password) || values.password === ''),
                            text: errors.password || '',
                          },
                        ]}
                      />
                      {error &&
                      !error.is_tech &&
                      error.code === BE_ERROR_CODES.PW_RESET_INVALID_CREDENTIAL &&
                      !(touched.password && errors.password) ? (
                        <div className="error-character">{error?.description_fe}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="password-reset-input-container" controlId="newPassword">
                      <div className="password-reset-input-icon-container">
                        <Form.Label
                          className={ClassNames(
                            'password-reset-input-label',
                            is_mobile
                              ? 'unnamed-character-style-27-13-22'
                              : 'unnamed-character-style-48'
                          )}
                        >
                          {t('password_reset.forms.new_password')}
                        </Form.Label>
                        <Tooltip placement="top">
                          <div>
                            <div className="text-left mb-2">{modal_info.title}</div>
                            {modal_info.options.map((data, index) => (
                              <li
                                key={index}
                                className={`text-left ${
                                  data.valid === 'valid'
                                    ? 'c-159881'
                                    : data.valid === 'invalid'
                                    ? 'c-862633'
                                    : ''
                                }`}
                              >
                                {data.text}
                                {data.valid === 'valid' ? (
                                  <Image src={valid_icon} width={12} className="ml-2" />
                                ) : data.valid === 'invalid' ? (
                                  <Image src={info_x_icon} className="ml-2" />
                                ) : (
                                  ''
                                )}
                              </li>
                            ))}
                          </div>
                        </Tooltip>
                      </div>

                      <InputTxt
                        id="newPassword"
                        placeholder={t('password_reset.forms.password_placeholder')}
                        type="password"
                        value={values.newPassword}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('newPassword', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !(
                                (touched.newPassword && errors.newPassword) ||
                                values.newPassword === ''
                              ),
                            text: errors.newPassword || '',
                          },
                        ]}
                      />
                      {error &&
                      !error.is_tech &&
                      error.code === BE_ERROR_CODES.NEW_PASSWORD_INVALID &&
                      !(touched.newPassword && errors.newPassword) ? (
                        <div className="error-character">{error?.description_fe}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="passwordConfirmed">
                      <Form.Label
                        className={ClassNames(
                          'password-reset-input-label',
                          'password-reset-input-label--confirm-password',
                          is_mobile
                            ? 'unnamed-character-style-27-13-22'
                            : 'unnamed-character-style-48'
                        )}
                      >
                        {t('password_reset.forms.confirm_password')}
                      </Form.Label>

                      <InputTxt
                        id="passwordConfirmed"
                        placeholder={t('password_reset.forms.confirm_password_placeholder')}
                        type="password"
                        value={values.passwordConfirmed}
                        on_change={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e)
                          handleBlur(e)
                          dismiss_error()
                          setFieldValue('passwordConfirmed', e.target.value)
                        }}
                        validators={[
                          {
                            is_valid: () =>
                              !(
                                (touched.passwordConfirmed && errors.passwordConfirmed) ||
                                values.passwordConfirmed === ''
                              ),
                            text: errors.passwordConfirmed || '',
                          },
                        ]}
                      />
                    </Form.Group>

                    <div className="password-reset-button-submit">
                      <Button
                        justify_content="center"
                        name={is_mobile ? 'full_059881_mobile' : 'full_059881'}
                        disabled={
                          values.password == '' ||
                          values.newPassword == '' ||
                          values.passwordConfirmed == '' ||
                          errors.password != undefined ||
                          errors.newPassword != undefined ||
                          errors.passwordConfirmed != undefined
                        }
                      >
                        <div>{t('password_reset.forms.btn_label')}</div>
                        <div className="right-arrow-white" />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
        </div>
      )}
      {error_visible ? <Modal data={modal_props} /> : ''}
    </Container>
  )
}

export default PasswordReset
