import { agency_client, get_agencies } from 'src/axios/agencies.axios'
import { agency_t, ax_get_agencies, dict, response_t, saga_act } from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve agencies
 */
export async function http_resolve_agencies({
  args,
  type,
}: saga_act<ax_get_agencies>): Promise<response_t<dict<agency_t>>> {
  return do_request<dict<agency_t>>({
    client: agency_client,
    request: get_agencies(v4(), args),
    child: 'agencies',
    saga_type: type,
  })
}
