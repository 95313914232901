import { call, put, select } from 'redux-saga/effects'
import {
  http_resolve_banners,
  http_resolve_communications,
  http_resolve_communications_registration,
  http_resolve_faqs,
  http_resolve_footer,
  http_resolve_privacies,
} from 'src/http/cms.http'
import {
  banner_cms,
  communication_cms,
  cookie_informative,
  faq_cms,
  footer_cms,
  privacy_cms,
  saga_act,
} from 'src/types'

import { shoot } from '../actions'
import { select_banners, select_footer, select_privacies } from '../store'
import { saga_do_request } from './base.saga'

/**
 * Resolve privacies
 */
export function* saga_resolve_privacies(action: saga_act): unknown {
  const cached: privacy_cms[] = yield select(select_privacies)
  if (!cached) {
    const privacies: privacy_cms[] = yield call(saga_do_request, http_resolve_privacies, action)
    if (privacies) {
      yield put(shoot.reduce_privacies(privacies))
    }
  }
}

/**
 * Resolve banners
 */
export function* saga_resolve_banners(action: saga_act): unknown {
  const cached: banner_cms[] = yield select(select_banners)
  if (!cached) {
    const banners: banner_cms[] = yield call(saga_do_request, http_resolve_banners, action)
    if (banners) {
      yield put(shoot.reduce_banners(banners))
    }
  }
}

/**
 * Resolve FAQs
 */
export function* saga_resolve_faqs(action: saga_act): unknown {
  const faqs: faq_cms[] = yield call(saga_do_request, http_resolve_faqs, action)
  if (faqs) {
    yield put(shoot.reduce_faqs(faqs))
  }
}

/**
 * Resolve communication
 */
export function* saga_resolve_communications(action: saga_act): unknown {
  const communications: communication_cms[] = yield call(
    saga_do_request,
    http_resolve_communications,
    action
  )
  if (communications) {
    if (action?.args?.subdomain === 'ASO') {
      yield put(shoot.reduce_onboarding_active(communications?.[0]?.value1 === 'ON'))
    }
    //
    else if (String(action?.args?.subdomain)?.startsWith('CN')) {
      yield put(shoot.reduce_notes(communications?.map((comm) => comm?.value1)))
    }
    //
    else if (action?.args?.subdomain === 'SPL') {
      yield put(shoot.reduce_privacy_link_profile(communications?.[0]?.value1))
    }
    //
    else if (action?.args?.subdomain === 'FRL') {
      yield put(shoot.reduce_digital_signature_link(communications?.[0]?.value1))
    }
    //
    else if (action?.args?.subdomain === 'FRT') {
      yield put(shoot.reduce_digital_signature_text(communications?.[0]?.value1))
    }
    //
    else if (action?.args?.subdomain === 'PRD') {
      const products = communications?.[0]?.value1?.split(';')?.map((prod) => {
        const data = prod?.split('#')
        return { cod_prod: data[0], cod_sector: data[1] || '-1' }
      })
      yield put(shoot.reduce_reactivation_products(products))
    }
    //
    else if (action?.args?.subdomain === 'CNT') {
      const footer: footer_cms = JSON.parse(communications?.[0]?.value1 || '')
      yield put(shoot.reduce_footer(footer))
    }
  }
}

/**
 * Resolve communication registration
 */
export function* saga_resolve_communications_registration(action: saga_act): unknown {
  const communications: communication_cms[] = yield call(
    saga_do_request,
    http_resolve_communications_registration,
    action
  )
  if (communications) {
    if (action?.args?.subdomain === 'ASM') {
      yield put(shoot.reduce_maintenance_active(communications?.[0]?.value1 === 'ON'))
    } else if (action?.args?.subdomain === 'CNT') {
      const footer: footer_cms = JSON.parse(communications?.[0]?.value1 || '')
      yield put(shoot.reduce_footer(footer))
    } else if (action?.args?.domain === 'CKP' && action?.args?.subdomain === 'INF') {
      const cookie_informatives: cookie_informative[] = communications
        ?.map((communication) => ({
          id: communication?.value1,
          content: atob(communication?.content || ''),
        }))
        ?.sort((a, b) => {
          const id_a = +(a?.id?.split('#')[1] || '')
          const id_b = +(b?.id?.split('#')[1] || '')
          return id_a < id_b ? -1 : id_a > id_b ? 1 : 0
        })
      yield put(shoot.reduce_cookie_informatives(cookie_informatives))
    } else {
      yield put(shoot.reduce_privacy_link_registration(communications?.[0]?.value1))
    }
  }
}

/**
 * Resolve footer
 */
export function* saga_resolve_footer(action: saga_act): unknown {
  const cached: footer_cms = yield select(select_footer)
  if (!cached) {
    const footer: footer_cms = yield call(saga_do_request, http_resolve_footer, action)
    if (footer) {
      yield put(shoot.reduce_footer(footer))
    }
  }
}
