import './servicies.scss'

import React from 'react'
import { Card, Carousel, Col, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  servicies_call_agency_icon,
  servicies_contract_icon,
  servicies_desktop_bg,
  servicies_download_icon,
  servicies_mobile_bg,
  servicies_policies_icon,
  servicies_registration_icon,
  servicies_renewal_icon,
  servicies_tablet_bg,
} from 'src/assets'
import { NAV_TO_LOGIN } from 'src/navigation'
import { fill_strong } from 'src/utils'
import { Button } from 'src/widgets'

interface servicies_props {
  image: string;
  text: string;
}

const Servicies: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const servicies: servicies_props[] = [
    {
      image: servicies_registration_icon,
      text: t('servicies.registration'),
    },
    {
      image: servicies_call_agency_icon,
      text: t('servicies.call_agency'),
    },
    {
      image: servicies_contract_icon,
      text: t('servicies.contract'),
    },
    {
      image: servicies_renewal_icon,
      text: t('servicies.renewal'),
    },
    {
      image: servicies_policies_icon,
      text: t('servicies.policies'),
    },
    {
      image: servicies_download_icon,
      text: t('servicies.download'),
    },
  ]

  /**
   * Render functions
   */
  const render_service = (service: servicies_props) => {
    return (
      <Col className="mb-5">
        <Row className="p-0 justify-content-center">
          <div className="text-center">
            <Image src={service?.image} />
            <div className="mt-3 font-15 c-00000080">{fill_strong(service?.text)}</div>
          </div>
        </Row>
      </Col>
    )
  }

  const render_topbar = () => {
    return (
      <Row className="m-0 p-0 bg-ffffff justify-content-center flex-column-reverse flex-md-row">
        <Col md={4} lg={5} xl={4} className="m-0 p-4 p-md-5">
          <div className="font-40-freightdisp-semibold">{t('servicies.title')}</div>
          <div className="font-21 c-00000080 mt-2">
            {fill_strong(t('servicies.subtitle'), 'font-21-semibold')}
          </div>
        </Col>
        <Col md={8} lg={7} xl={8} className="m-0 p-0 d-none d-xl-flex">
          <Image src={servicies_desktop_bg} className="img-fluid" />
        </Col>
        <Col md={8} lg={7} xl={8} className="m-0 p-0 d-none d-md-flex d-xl-none">
          <Image src={servicies_tablet_bg} className="img-fluid" />
        </Col>
        <Col className="m-0 p-0 d-block d-md-none">
          <Image src={servicies_mobile_bg} className="img-fluid" />
        </Col>
      </Row>
    )
  }

  const render_servicies = () => {
    return (
      <Row className="m-0 p-md-4 justify-content-center">
        <Card className="m-4 pt-5 pb-4 pl-5 pr-5 m-md-5 p-md-5 rounded-0 border-0 w-100">
          {/* DESKTOP */}
          <Row className="d-none d-md-flex justify-content-center">
            {servicies.map((data, index) => (
              <Col className="m-4 col-lg-3" key={index}>
                <Row className="p-0 m-1 justify-content-center">
                  <div className="text-center">
                    <Image src={data?.image} />
                    <div className="mt-3 pl-3 pr-3 font-15 c-00000080">
                      {fill_strong(data?.text)}
                    </div>
                  </div>
                </Row>
              </Col>
            ))}
          </Row>
          {/* MOBILE */}
          <div className="d-block d-md-none">
            <Carousel
              wrap={false}
              indicators={true}
              slide={false}
              fade={false}
              interval={null}
              controls={false}
              className="m-0 p-0"
            >
              {servicies?.map(
                (_, idx) =>
                  idx % 2 === 0 && (
                    <Carousel.Item key={idx} className="mb-4">
                      {render_service(servicies?.[idx])}
                      {render_service(servicies?.[idx + 1])}
                    </Carousel.Item>
                  )
              )}
            </Carousel>
          </div>
          <Row className="justify-content-center mt-3 mt-md-5">
            <Button
              flavor="primary_next"
              text={t('servicies.button')}
              classes="justify-content-around"
              on_press={() => history.push(NAV_TO_LOGIN)}
            />
          </Row>
        </Card>
      </Row>
    )
  }

  return (
    <div>
      {render_topbar()}
      {render_servicies()}
    </div>
  )
}
export default Servicies
