import { get_poll, poll_client, post_poll } from 'src/axios/poll.axios'
import { outcome_w } from 'src/backend-codes'
import { response_t, saga_act } from 'src/types'
import { ax_get_poll, ax_post_poll } from 'src/types/axios/poll.types'
import { poll_t } from 'src/types/http/poll.types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve Poll
 */
export async function http_resolve_poll({
  args,
  type,
}: saga_act<ax_get_poll>): Promise<response_t<poll_t>> {
  return do_request<poll_t>({
    client: poll_client,
    request: get_poll(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Answer Poll
 */
export async function http_answer_poll({
  args,
  type,
}: saga_act<ax_post_poll>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: poll_client,
    request: post_poll(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}
