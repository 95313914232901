import { FC } from 'react'
import { Image } from 'react-bootstrap'
import { button_props } from 'src/types'

import { flavors } from './button.flavors'

import './button.scss'

export const Button: FC<button_props> = ({
  text,
  flavor,
  type = 'button',
  disabled,
  iconl,
  iconr,
  icon_size = 16,
  classes,
  classes_text = 'font-14-semibold text-uppercase',
  classes_iconl = 'mr-3',
  classes_iconr = 'ml-3',
  on_press,
}: button_props) => {
  const fl = flavors[flavor || '']

  if (fl) {
    classes += ` ${fl?.classes}`
    classes_text += ` ${fl?.classes_text}`
    classes_iconl += ` ${fl?.classes_iconl}`
    classes_iconr += ` ${fl?.classes_iconr}`
    iconl = iconl || fl?.iconl
    iconr = iconr || fl?.iconr
  }

  return (
    <button type={type} className={classes} disabled={disabled} onClick={on_press}>
      {iconl && (
        <Image className={classes_iconl} src={iconl} width={icon_size} height={icon_size} />
      )}

      {/* TEXT */}
      <span className={classes_text}>{text}</span>

      {/* ICON RIGHT */}
      {iconr && (
        <Image className={classes_iconr} src={iconr} width={icon_size} height={icon_size} />
      )}
    </button>
  )
}
