import { call, put, select } from 'redux-saga/effects'
import { BE_ERROR_CODES, language_w, outcome_w } from 'src/backend-codes'
import {
  http_remove_contacts,
  http_resolve_access,
  http_resolve_addresses,
  http_resolve_associated_customers,
  http_resolve_caps,
  http_resolve_consents,
  http_resolve_contacts,
  http_resolve_customer,
  http_resolve_districts,
  http_resolve_language,
  http_resolve_localities,
  http_resolve_municipalities,
  http_resolve_nations,
  http_resolve_toponyms,
  http_update_access,
  http_update_consents,
  http_update_customer,
  http_update_language,
} from 'src/http'
import {
  addr_t,
  cap_t,
  consent_t,
  contact_t,
  customer_outcome_t,
  customer_t,
  district_t,
  error_t,
  locality_t,
  municipality_t,
  nation_t,
  saga_act,
} from 'src/types'

import { shoot } from '../actions'
import { select_error } from '../store'
import { saga_do_request } from './base.saga'

/**
 * Resolve customer
 */
export function* saga_resolve_customer(action: saga_act): unknown {
  const customer: customer_t = yield call(saga_do_request, http_resolve_customer, action)
  if (customer) {
    yield put(shoot.reduce_customer(customer))
  }
}

/**
 * Update customer
 */
export function* saga_update_customer(action: saga_act): unknown {
  const co: customer_outcome_t = yield call(saga_do_request, http_update_customer, action)
  if (co?.outcome === 'success') {
    yield put(shoot.reduce_outcome_customer(co))
  }
}

/**
 * Resolve contacts
 */
export function* saga_resolve_contacts(action: saga_act): unknown {
  const contacts: contact_t[] = yield call(saga_do_request, http_resolve_contacts, action)
  if (contacts) {
    yield put(shoot.reduce_contacts(contacts))
  }
}

/**
 * Remove contacts
 */
export function* saga_remove_contacts(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_remove_contacts, action)
  if (outcome === 'success') {
    yield put(shoot.reduce_outcome_contacts(outcome))
  }
}

/**
 * Resolve consents
 */
export function* saga_resolve_consents(action: saga_act): unknown {
  const consents: consent_t[] = yield call(saga_do_request, http_resolve_consents, action)
  if (consents) {
    yield put(shoot.reduce_consents(consents))
  }
}

/**
 * Update consents
 */
export function* saga_update_consents(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_update_consents, action)
  if (outcome === 'success') {
    yield put(shoot.reduce_outcome_consents(outcome))
  }
}

/**
 * Resolve language
 */
export function* saga_resolve_language(action: saga_act): unknown {
  const language: language_w = yield call(saga_do_request, http_resolve_language, action)
  if (language) {
    yield put(shoot.reduce_language(language))
  } else {
    const error: error_t = yield select(select_error())
    if (error?.code === BE_ERROR_CODES.LANGUAGE_NOT_FOUND) {
      yield put(shoot.reduce_error())
      yield put(shoot.reduce_language())
    }
  }
}

/**
 * Update language
 */
export function* saga_update_language(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_update_language, action)
  if (outcome === 'success') {
    yield put(shoot.reduce_outcome_language(outcome))
  }
}

/**
 * Resolve access
 */
export function* saga_resolve_access(action: saga_act): unknown {
  const access: string = yield call(saga_do_request, http_resolve_access, action)
  if (access) {
    yield put(shoot.reduce_access(access))
  }
}

/**
 * Update access
 */
export function* saga_update_access(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_update_access, action)
  if (outcome === 'success') {
    yield put(shoot.reduce_outcome_access(outcome))
  }
}

/**
 * Resolve nations
 */
export function* saga_resolve_nations(action: saga_act): unknown {
  const nations: nation_t[] | nation_t = yield call(saga_do_request, http_resolve_nations, action)
  if (nations) {
    if (Array.isArray(nations)) {
      yield put(shoot.reduce_nations(nations))
    } else {
      yield put(shoot.reduce_nations([nations]))
    }
  }
}

/**
 * Resolve districts
 */
export function* saga_resolve_districts(action: saga_act): unknown {
  const districts: district_t[] = yield call(saga_do_request, http_resolve_districts, action)
  if (districts) {
    yield put(shoot.reduce_districts(districts))
  }
}

/**
 * Resolve municipalities
 */
export function* saga_resolve_municipalities(action: saga_act): unknown {
  const municipalities: municipality_t[] = yield call(
    saga_do_request,
    http_resolve_municipalities,
    action
  )
  if (municipalities) {
    yield put(shoot.reduce_municipalities(municipalities))
  }
}

/**
 * Resolve localities
 */
export function* saga_resolve_localities(action: saga_act): unknown {
  const localities: locality_t[] = yield call(saga_do_request, http_resolve_localities, action)
  if (localities) {
    yield put(shoot.reduce_localities(localities))
  }
}

/**
 * Resolve postal codes
 */
export function* saga_resolve_caps(action: saga_act): unknown {
  const caps: cap_t[] = yield call(saga_do_request, http_resolve_caps, action)
  if (caps) {
    yield put(shoot.reduce_caps(caps))
  }
}

/**
 * Resolve toponyms
 */
export function* saga_resolve_toponyms(action: saga_act): unknown {
  const toponyms: district_t[] = yield call(saga_do_request, http_resolve_toponyms, action)
  if (toponyms) {
    yield put(shoot.reduce_toponyms(toponyms))
  }
}

/**
 * Resolve addresses
 */
export function* saga_resolve_addresses(action: saga_act): unknown {
  const addresses: addr_t[] = yield call(saga_do_request, http_resolve_addresses, action)
  if (addresses) {
    yield put(shoot.reduce_addresses(addresses))
  }
}

/**
 * Resolve associated customers
 */
export function* saga_resolve_associated_customers(action: saga_act): unknown {
  const customers: customer_t[] = yield call(
    saga_do_request,
    http_resolve_associated_customers,
    action
  )
  if (customers) {
    yield put(shoot.reduce_associated_customers(customers))
  }
}
