import { FC } from 'react'
import { Image } from 'react-bootstrap'
import { failure_icon } from 'src/assets'
import i18n from 'src/i18n'
import { failure_modal_props } from 'src/types'
import { Button } from 'src/widgets/button/button'

import { Modal } from '../modal'

export const FailureModal: FC<failure_modal_props> = ({
  error,
  icon = failure_icon,
  description = error?.description_fe,
  on_close,
  button = {
    flavor: 'primary_next',
    text: i18n.t('commons.reload_page'),
    on_press: () => {
      on_close?.()
      window.location.reload()
    },
  },
}: failure_modal_props) => {
  return (
    <Modal visible={!!error?.description_fe} on_close={on_close}>
      <div className="d-flex align-items-center justify-content-center p-1">
        <div className="d-inline text-center">
          {icon && <Image src={icon} />}
          {description && <p className="mt-4 font-16 pre-line">{description}</p>}
          {button?.on_press && (
            <div className="mt-4 mb-2 d-flex align-items-center justify-content-center">
              <Button {...button} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
