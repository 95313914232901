/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, takeLatest } from 'redux-saga/effects'

import * as acts from '../actions'
import * as activity from './activity.saga'
import * as agencies from './agencies.saga'
import * as cms from './cms.saga'
import * as communication from './communication.saga'
import * as customer from './customer.saga'
import * as documents from './documents.saga'
import * as oauth from './oauth.saga'
import * as payment from './payment.saga'
import * as policies from './policies.saga'
import * as poll from './poll.saga'
import * as startup from './startup.saga'
import * as user from './user.saga'
import * as voucher from './voucher.saga'

export default function* root() {
  yield all([
    /**
     * What does takeLatest do?
     *    call the 'startup()' saga when a 'STARTUP' action is triggered
     *    takeLatest(acts.startup_types.STARTUP, startup)
     */

    // Startup sagas
    takeLatest(acts.startup_types.SAGA_STARTUP, startup.saga_startup),
    takeLatest(acts.startup_types.SAGA_INVALIDATE_CACHE, startup.saga_invalidate_cache),

    // Oauth sagas
    takeLatest(acts.oauth_types.SAGA_LOGIN, oauth.saga_login),
    takeLatest(acts.oauth_types.SAGA_LOGOUT, oauth.saga_logout),
    takeLatest(acts.oauth_types.SAGA_CHECK_TOKEN, oauth.saga_check_token),
    takeLatest(acts.oauth_types.SAGA_REFRESH_TOKEN, oauth.saga_refresh_token),
    takeLatest(acts.oauth_types.SAGA_MAGIC_LOGIN, oauth.saga_magic_login),
    takeLatest(acts.oauth_types.SAGA_RESET_PASSWORD, oauth.saga_reset_password),
    takeLatest(acts.oauth_types.SAGA_PASSWORD_EXPIRED, oauth.saga_password_expired),
    takeLatest(acts.oauth_types.SAGA_UNLOCK_USER, oauth.saga_unlock_user),
    takeLatest(acts.oauth_types.SAGA_CHECK_TOKEN_IMP, oauth.saga_check_token_imp),
    takeLatest(acts.oauth_types.SAGA_CHECK_TOKEN_IMP_I360_DIR, oauth.saga_check_token_imp_i360_dir),
    takeLatest(acts.oauth_types.SAGA_CHECK_TOKEN_IMP_I360_AGE, oauth.saga_check_token_imp_i360_age),
    takeLatest(acts.oauth_types.SAGA_REFRESH_TOKEN_IMP, oauth.saga_refresh_token_imp),
    takeLatest(
      acts.oauth_types.SAGA_REFRESH_TOKEN_IMP_I360_DIR,
      oauth.saga_refresh_token_imp_i360_dir
    ),
    takeLatest(
      acts.oauth_types.SAGA_REFRESH_TOKEN_IMP_I360_AGE,
      oauth.saga_refresh_token_imp_i360_age
    ),
    takeLatest(acts.oauth_types.SAGA_REGISTER_USER, user.saga_register_user),
    takeLatest(acts.oauth_types.SAGA_RECOVERY_CLIENT_ID, user.saga_recovery_client_id),
    takeLatest(acts.oauth_types.SAGA_RECOVERY_PASSWORD, user.saga_recovery_password),
    takeLatest(acts.oauth_types.SAGA_REQUIRE_SSO_OTP, oauth.saga_require_sso_otp),

    // Config sagas
    takeLatest(acts.config_types.SAGA_RESOLVE_ACCESS, customer.saga_resolve_access),

    // Data sagas
    takeLatest(acts.data_types.SAGA_RESOLVE_CUSTOMER, customer.saga_resolve_customer),
    takeLatest(acts.data_types.SAGA_RESOLVE_CONTACTS, customer.saga_resolve_contacts),
    takeLatest(acts.data_types.SAGA_RESOLVE_CONSENTS, customer.saga_resolve_consents),
    takeLatest(acts.data_types.SAGA_RESOLVE_LANGUAGE, customer.saga_resolve_language),
    takeLatest(acts.data_types.SAGA_RESOLVE_NATIONS, customer.saga_resolve_nations),
    takeLatest(acts.data_types.SAGA_RESOLVE_DISTRICTS, customer.saga_resolve_districts),
    takeLatest(acts.data_types.SAGA_RESOLVE_MUNICIPALITIES, customer.saga_resolve_municipalities),
    takeLatest(acts.data_types.SAGA_RESOLVE_LOCALITIES, customer.saga_resolve_localities),
    takeLatest(acts.data_types.SAGA_RESOLVE_CAPS, customer.saga_resolve_caps),
    takeLatest(acts.data_types.SAGA_RESOLVE_TOPONYMS, customer.saga_resolve_toponyms),
    takeLatest(acts.data_types.SAGA_RESOLVE_ADDRESSES, customer.saga_resolve_addresses),
    takeLatest(
      acts.data_types.SAGA_RESOLVE_ASSOCIATED_CUSTOMERS,
      customer.saga_resolve_associated_customers
    ),
    takeLatest(
      acts.data_types.SAGA_RESOLVE_HISTORY_ADDRESSES,
      activity.saga_resolve_history_addresses
    ),
    takeLatest(
      acts.data_types.SAGA_RESOLVE_HISTORY_REQUESTS,
      activity.saga_resolve_history_requests
    ),
    takeLatest(acts.data_types.SAGA_RESOLVE_POLICIES, policies.saga_resolve_policies),
    takeLatest(acts.data_types.SAGA_RESOLVE_POLICY_DETAIL, policies.saga_resolve_policy_detail),
    takeLatest(acts.data_types.SAGA_RESOLVE_DOCUMENTS, documents.saga_resolve_documents),
    takeLatest(
      acts.data_types.SAGA_RESOLVE_DOCUMENTS_POLICY,
      documents.saga_resolve_documents_policy
    ),
    takeLatest(
      acts.data_types.SAGA_RESOLVE_DOCUMENTS_INSTITUTIONAL,
      documents.saga_resolve_documents_institutional
    ),
    takeLatest(acts.data_types.SAGA_DOWNLOAD_DOCUMENT, documents.saga_download_document),
    takeLatest(acts.data_types.SAGA_RESOLVE_AGENCIES, agencies.saga_resolve_agencies),
    takeLatest(acts.data_types.SAGA_RESOLVE_VOUCHERS, voucher.saga_resolve_vouchers),
    takeLatest(acts.data_types.SAGA_DOWNLOAD_VOUCHER, voucher.saga_download_voucher),
    takeLatest(
      acts.data_types.SAGA_RESOLVE_POLICIES_ASSISTANCE,
      policies.saga_resolve_policies_assistance
    ),
    takeLatest(acts.data_types.SAGA_RESOLVE_POLL, poll.saga_resolve_poll),
    takeLatest(
      acts.data_types.SAGA_RETRIEVE_DATI_CONTRAENTE_FROM_NUM_POLIZZA,
      user.saga_retrieve_dati_contraente_from_num_polizza
    ),
    takeLatest(
      acts.data_types.SAGA_RETRIEVE_DATI_CONTRAENTE_FROM_NUM_POLIZZA_VITA,
      user.saga_retrieve_dati_contraente_from_num_polizza_vita
    ),
    takeLatest(
      acts.data_types.SAGA_RETRIEVE_DATI_CONTRAENTE_FROM_NUM_ORDINE,
      user.saga_retrieve_dati_contraente_from_num_ordine
    ),
    takeLatest(acts.data_types.SAGA_POST_DECODE_DATAMATRIX, user.saga_post_decode_datamatrix),

    // Outcomes sagas
    takeLatest(acts.outcomes_types.SAGA_UPDATE_CUSTOMER, customer.saga_update_customer),
    takeLatest(acts.outcomes_types.SAGA_REMOVE_CONTACTS, customer.saga_remove_contacts),
    takeLatest(acts.outcomes_types.SAGA_UPDATE_CONSENTS, customer.saga_update_consents),
    takeLatest(acts.outcomes_types.SAGA_UPDATE_LANGUAGE, customer.saga_update_language),
    takeLatest(acts.outcomes_types.SAGA_UPDATE_ACCESS, customer.saga_update_access),
    takeLatest(acts.outcomes_types.SAGA_GENERATE_IBAN, payment.saga_generate_iban),
    takeLatest(acts.outcomes_types.SAGA_MAKE_PAYMENT, payment.saga_make_payment),
    takeLatest(acts.outcomes_types.SAGA_CHECK_PAYMENT, payment.saga_check_payment),
    takeLatest(acts.outcomes_types.SAGA_SEND_EMAIL, communication.saga_send_email),
    takeLatest(acts.outcomes_types.SAGA_SEND_DOCUMENT, documents.saga_send_document),
    takeLatest(acts.outcomes_types.SAGA_UPDATE_POLICY, policies.saga_update_policy),
    takeLatest(acts.outcomes_types.SAGA_VERIFY_POLICY, policies.saga_verify_policy),
    takeLatest(acts.outcomes_types.SAGA_SEND_VOUCHER, voucher.saga_send_voucher),
    takeLatest(acts.outcomes_types.SAGA_ANSWER_POLL, poll.saga_answer_poll),

    // CMS sagas
    takeLatest(acts.cms_types.SAGA_RESOLVE_PRIVACIES, cms.saga_resolve_privacies),
    takeLatest(acts.cms_types.SAGA_RESOLVE_BANNERS, cms.saga_resolve_banners),
    takeLatest(acts.cms_types.SAGA_RESOLVE_FAQS, cms.saga_resolve_faqs),
    takeLatest(acts.cms_types.SAGA_RESOLVE_COMMUNICATIONS, cms.saga_resolve_communications),
    takeLatest(
      acts.cms_types.SAGA_RESOLVE_COMMUNICATIONS_REGISTRATION,
      cms.saga_resolve_communications_registration
    ),
    takeLatest(acts.cms_types.SAGA_RESOLVE_FOOTER, cms.saga_resolve_footer),
  ])
}
