import {
  document_client,
  get_document,
  get_documents,
  get_documents_institutional,
  get_documents_policy,
  post_document,
} from 'src/axios/documents.axios'
import { outcome_w } from 'src/backend-codes'
import {
  ax_get_document,
  ax_get_documents,
  ax_post_document,
  document_t,
  response_t,
  saga_act,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve documents
 */
export async function http_resolve_documents({
  args,
  type,
}: saga_act<ax_get_documents>): Promise<response_t<document_t[]>> {
  return do_request<document_t[]>({
    client: document_client,
    request: get_documents(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Resolve documents policy
 */
export async function http_resolve_documents_policy({
  args,
  type,
}: saga_act<ax_get_documents>): Promise<response_t<document_t[]>> {
  return do_request<document_t[]>({
    client: document_client,
    request: get_documents_policy(v4(), args),
    child: 'documents',
    saga_type: type,
  })
}

/**
 * Resolve documents institutional
 */
export async function http_resolve_documents_institutional({
  args,
  type,
}: saga_act<ax_get_documents>): Promise<response_t<document_t[]>> {
  return do_request<document_t[]>({
    client: document_client,
    request: get_documents_institutional(v4(), args),
    child: 'documents',
    saga_type: type,
  })
}

/**
 * Download document
 */
export async function http_download_document({
  args,
  type,
}: saga_act<ax_get_document>): Promise<response_t<document_t>> {
  return do_request<document_t>({
    client: document_client,
    request: get_document(v4(), args),
    child: 'document',
    saga_type: type,
  })
}

/**
 * Send documents
 */
export async function http_send_document({
  args,
  type,
}: saga_act<ax_post_document>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: document_client,
    request: post_document(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}
