import { ax_get_history_addresses, ax_get_history_requests } from 'src/types'

import axios from 'axios'

import { AUTHORIZATION_BEARER, axios_request, configify, GET, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_ACTIVITY || ''
const CONTEXT_ROOT = 'activities'

/**
 * Activity client
 */
export const activity_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * GET address history
 */
export const get_history_addresses = (
  requestId: string,
  { access_token }: ax_get_history_addresses
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/address`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * GET policy history
 */
export const get_history_requests = (
  requestId: string,
  { access_token, policy_number }: ax_get_history_requests
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/operations`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  params: { policyNumber: policy_number },
})
