import './faq.scss'

import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { left_icon, right_icon } from 'src/assets'
import { IS_MOBILE_WIDTH_THRESHOLD_IN_PX } from 'src/constants'
import { NAV_TO_LOGIN } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import { select_faqs } from 'src/redux/store'
import { dict, faq_cms } from 'src/types'
import { unescape_html } from 'src/utils'
import { ClassNames } from 'src/utils/--deprecated'
import AccordionComponent from 'src/widgets/--deprecated/accordion/accordion'
import Button from 'src/widgets/--deprecated/button/button'

//TODO: mock da rimuovere

interface category_t {
  category: string;
  questions_number: number;
  focus: boolean;
}

const Faq: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  /**
   * Use Selector
   */
  const questions = useSelector(select_faqs)

  /**
   * Use State
   */
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX)
  // const [questions, set_questions] = useState<question_list_t[]>([])
  const [questions_per_cat, set_questions_per_cat] = useState<faq_cms[]>([])
  const [categories, set_categories] = useState<category_t[]>([])
  const [selected_category, set_selected_category] = useState<string>()
  const [current_page, set_current_page] = useState(1)

  /**
   * Use Effect
   */
  useEffect(() => {
    dispatch(shoot.saga_resolve_faqs())
  }, [])

  useEffect(() => {
    const page_numbers = Math.floor(questions_per_cat.length / ITEM_PER_PAGE + 1)
    change_buttons_handler(page_numbers)
  }, [current_page])

  useEffect(() => {
    if (questions) {
      init_categories()
    }
  }, [questions])

  useEffect(() => {
    if (categories && categories?.length > 0) {
      if (!selected_category || selected_category != categories[0].category) {
        set_selected_category(categories[0].category)
      }
    }
  }, [categories])

  useEffect(() => {
    if (selected_category) {
      change_category_handler(selected_category)
    }
  }, [selected_category])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  const ITEM_PER_PAGE = 5

  const [page_buttons, set_page_buttons]: any = useState([])

  const init_categories = () => {
    const cats: category_t[] = []
    const cats_labels: dict<number> = {}
    questions?.forEach((question) => {
      if (question?.category) {
        if (cats_labels[question?.category]) {
          cats_labels[question?.category] += 1
        } else {
          cats_labels[question?.category] = 1
        }
      }
    })
    Object.keys(cats_labels).map((key, index) => {
      cats.push({
        category: key,
        questions_number: cats_labels[key],
        focus: index === 0,
      })
    })
    set_categories(cats)
  }

  const change_buttons_handler = (page_numbers: number) => {
    const btn = []
    if (page_numbers > 1) {
      btn.push(
        <button
          key={0}
          className="faq-no-style-btn"
          style={{ margin: '0 1rem 0 0' }}
          onClick={() => change_page_handler(current_page - 1, page_numbers)}
        >
          <Image src={left_icon} fluid />
        </button>
      )
    }
    for (let i = 0; i < page_numbers; i++) {
      btn.push(
        <button
          key={i + 1}
          className={ClassNames(
            'faq-no-style-btn unnamed-character-style-10-7f7f7f',
            current_page === i + 1 ? 'color-159881' : ''
          )}
          style={{ margin: '0 1rem 0 0' }}
          onClick={() => change_page_handler(i + 1, page_numbers)}
        >
          {i + 1}
        </button>
      )
    }
    // mostro la freccia di scorrimento solo se ho almeno 2 pagine
    if (page_numbers > 1) {
      btn.push(
        <button
          key={btn.length + 1}
          className="faq-no-style-btn"
          onClick={() => change_page_handler(current_page + 1, page_numbers)}
        >
          <Image src={right_icon} fluid />
        </button>
      )
    }

    set_page_buttons(btn)
  }

  const change_category_handler = (category: string) => {
    // filtro le domande appartenenti alla categoria selezionata
    const cat_q: faq_cms[] = []
    questions?.forEach((quest) => {
      if (quest?.category === category) {
        cat_q.push(quest)
      }
    })
    set_questions_per_cat(cat_q)

    // setto il focus per la categoria selezionata e lo rimuovo per tutte le altre
    const cats = categories.map((cat) => {
      const temp: category_t = { ...cat }
      if (cat?.category == category) {
        temp.focus = true
      } else {
        temp.focus = false
      }
      return temp
    })
    set_categories(cats)
    //quando cambio categoria la risetto la pagina numero 1
    set_current_page(1)
    // quando cambio categoria setto i button per scorrere le pagine
    const page_numbers = Math.floor(cat_q.length / ITEM_PER_PAGE + 1)
    change_buttons_handler(page_numbers)
  }

  const change_page_handler = (page: number, page_numbers: number) => {
    if (page > 0 && page <= page_numbers) set_current_page(page)
  }

  const nav_to_login = () => {
    history.push(NAV_TO_LOGIN)
  }

  const tr_faq: any = t('faq', {
    returnObjects: true,
  })

  return (
    <Container fluid>
      <div className="faq-container">
        <Row>
          <div className="faq-button-back">
            <Button name="text_059881" onClick={nav_to_login} padding="0">
              <div className="left-arrow-green" />
              <div>{tr_faq.backward_btn_label}</div>
            </Button>
          </div>
        </Row>
        <Row>
          <div
            className={ClassNames(
              'faq-title',
              is_mobile ? 'unnamed-character-style-30' : 'unnamed-character-style-29'
            )}
          >
            {tr_faq.title}
          </div>
        </Row>
        <Row className="mt-4">
          <Col className="faq-no-padding" md={3}>
            <div className="faq-categories-container">
              <div className="unnamed-character-style-12-28">{tr_faq.category_label}</div>
              <div>
                {categories.map((cat, index) => (
                  <li key={index} style={{ listStyleType: 'none' }}>
                    <div
                      className={ClassNames(
                        'unnamed-character-style-15-30-00000080',
                        cat.focus ? 'color-159881' : ''
                      )}
                    >
                      <button
                        className="faq-no-style-btn"
                        onClick={() => change_category_handler(cat.category)}
                      >
                        {cat?.category} ({cat.questions_number})
                      </button>
                    </div>
                  </li>
                ))}
              </div>
            </div>
          </Col>
          <Col className="faq-no-padding bg-white" md={9}>
            <div>
              {questions_per_cat
                .slice((current_page - 1) * ITEM_PER_PAGE, current_page * ITEM_PER_PAGE)
                .map((question, index) => (
                  <div key={index} className="faq-accordion-row">
                    <AccordionComponent
                      title={question?.question || ''}
                      content={unescape_html(question?.answer)}
                      arrow_up="faq-arrow-accordion-black-up"
                      arrow_bottom="faq-arrow-accordion-black-bottom"
                      title_style="unnamed-character-style-56"
                      content_style="unnamed-character-style-44"
                    />
                  </div>
                ))}
            </div>
          </Col>
        </Row>

        <div className="faq-page-nav">{page_buttons}</div>
      </div>
    </Container>
  )
}

export default Faq
