import { FC, useEffect, useRef, useState } from 'react'
import { Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { warning_icon } from 'src/assets'
import { operation_modal_props } from 'src/types'
import { Button } from 'src/widgets/button/button'

import { Modal } from '../modal'
import { DatePicker_ } from 'src/widgets/date-picker/date-picker'

import * as Yup from 'yup'
import {
  compare_dates,
  fill_placeholders,
  fill_strong,
  format_date,
  parseDate,
  ydate_required,
} from 'src/utils'
import { useFormik } from 'formik'

export const OperationModal: FC<operation_modal_props> = ({
  visible,
  title,
  subtitle: initialSubtitle, // Rinominato per evitare conflitti con lo stato
  description: initialDescription, // Rinominato per evitare conflitti con lo stato
  on_close,
  on_confirm,
  operation,
  disabled = false,
  isNewProcess = false,
  newProcessData = {},
}: operation_modal_props) => {
  const { t } = useTranslation()
  const [minStartDate, setMinStartDate] = useState(() => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow
  })
  const [subtitle, setSubtitle] = useState(initialSubtitle) // Stato per subtitle
  const [description, setDescription] = useState(initialDescription) // Stato per description

  // Usando useEffect, possiamo rilevare i cambiamenti in initialSubtitle e initialDescription
  useEffect(() => {
    setSubtitle(initialSubtitle)
    setDescription(initialDescription)
  }, [initialSubtitle, initialDescription])

  const [isProcessConfirmed, setIsProcessConfirmed] = useState(false) // useState per isNewProcess

  const i_values = useRef({ start_date: '', end_date: '' })

  const { getFieldProps, setFieldValue, setFieldTouched, touched, errors, values } = useFormik({
    initialValues: i_values.current,
    validateOnMount: true,
    onSubmit: () => {
      on_confirm?.()
    },
  })

  function getMinEndDate(start_date: string): Date | undefined {
    if (!values?.start_date) return new Date()
    const startDate = parseDate(start_date)
    startDate?.setDate(startDate.getDate() + 1) // Add 1 day to the start date
    return startDate
  }

  function getMaxEndDate(start_date: string): Date | undefined {
    if (!values?.start_date) new Date(new Date().getDate() - 1)
    const maxEndDate = parseDate(start_date)
    if (!maxEndDate) return new Date(new Date().getDate() - 1)
    maxEndDate.setMonth(maxEndDate.getMonth() + 10)
    return maxEndDate
  }

  function areDateValid(): boolean | undefined {
    return !values?.start_date || !values?.end_date
  }

  return (
    <Modal visible={visible} on_close={on_close}>
      <div className="p-4">
        {/* ICON */}
        <Image src={warning_icon} className="w-100" height={100} />

        {/* HEADER */}
        <h1 className="mt-4 font-18-semibold text-uppercase text-center">{title}</h1>

        {/* DESCRIPTION */}
        <p className="m-0 p-0 mt-4 font-16">{t('commons.dear_customer')}</p>
        <p className="font-16">{subtitle}</p>
        <p className="font-16 c-7f7f7f">{description}</p>
        <p className="font-16">
          {(!isNewProcess || isProcessConfirmed) && t('policy_operations.sure')}
        </p>

        {/* NEW PROCESS DATE INPUTS */}
        {isNewProcess &&
          !isProcessConfirmed && ( // Mostra solo se isNewProcess è true e il processo non è ancora confermato
            <Row className="m-0 p-0 justify-content-center">
              <DatePicker_
                id="start_date"
                label={t('suspension.start_date')}
                formik_props={getFieldProps('start_date')}
                error={errors?.start_date}
                touched={touched?.start_date}
                value={values?.start_date}
                set_field_value={setFieldValue}
                set_field_touched={setFieldTouched}
                disabled_to={minStartDate}
                disabled={true}
                classes="m-0 p-2 mt-4 col-lg-6 col-md-8 col-sm-10 col-12"
              />
              <DatePicker_
                id="end_date"
                label={t('suspension.end_date')}
                formik_props={getFieldProps('end_date')}
                error={errors?.end_date}
                touched={touched?.end_date}
                value={values?.end_date}
                set_field_value={setFieldValue}
                set_field_touched={setFieldTouched}
                disabled_to={getMinEndDate(values?.start_date)}
                disabled_from={getMaxEndDate(values?.start_date)}
                disabled={true}
                classes="m-0 p-2 mt-4 col-lg-6 col-md-8 col-sm-10 col-12"
              />
            </Row>
          )}

        {/* BUTTONS */}
        <Row className="m-0 p-0 mx-1 mx-sm-3 mt-4 justify-content-around flex-column-reverse flex-md-row">
          <Button
            flavor="primary_flip"
            text={t('commons.cancel')}
            on_press={on_close}
            classes="mt-4 mb-2 mt-md-0 mb-md-0 mr-md-5 w-md-100 d-flex justify-content-center"
          />
          <Button
            flavor="accent_next"
            text={t('commons.proceed')}
            on_press={() => {
              if (isNewProcess && !isProcessConfirmed) {
                setSubtitle(
                  fill_strong(
                    fill_placeholders(t('suspension.description'), [
                      newProcessData?.policyDescription || '',
                    ]),
                    'font-16-semibold'
                  )
                )
                setDescription(
                  fill_placeholders(t('policy_operations.descriptions.suspension_dates'), [
                    values?.start_date,
                    values?.end_date,
                  ])
                )
                setIsProcessConfirmed(true)
              } else {
                if (isNewProcess) {
                  on_confirm?.({ startDate: values?.start_date, endDate: values?.end_date })
                } else {
                  on_confirm?.()
                }
              }
            }}
            classes={`justify-content-between ${operation}`}
            disabled={disabled || (areDateValid() && isNewProcess)}
          />
        </Row>
      </div>
    </Modal>
  )
}
