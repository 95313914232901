import { ax_get_agencies } from 'src/types'

import axios from 'axios'

import { AUTHORIZATION_BEARER, axios_request, configify, GET, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_AGENCY || ''
const CONTEXT_ROOT = 'agencies'

/**
 * Agency client
 */
export const agency_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * GET agencies
 */
export const get_agencies = (
  requestId: string,
  { access_token, agency_codes }: ax_get_agencies
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}?agencyCodes=${agency_codes?.join(',')}`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})
