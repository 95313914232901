import { activity_client, get_history_addresses, get_history_requests } from 'src/axios'
import {
  ax_get_history_addresses,
  ax_get_history_requests,
  history_address_t,
  history_requests_t,
  response_t,
  saga_act,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve address history
 */
export async function http_resolve_history_addresses({
  args,
  type,
}: saga_act<ax_get_history_addresses>): Promise<response_t<history_address_t[]>> {
  return do_request<history_address_t[]>({
    client: activity_client,
    request: get_history_addresses(v4(), args),
    child: 'requestsAddress',
    saga_type: type,
  })
}

/**
 * Resolve policy history
 */
export async function http_resolve_history_requests({
  args,
  type,
}: saga_act<ax_get_history_requests>): Promise<response_t<history_requests_t>> {
  return do_request<history_requests_t>({
    client: activity_client,
    request: get_history_requests(v4(), args),
    child: '',
    saga_type: type,
  })
}
