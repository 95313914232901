import './modify-residence.scss'

import { Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Form, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { italy_icon } from 'src/assets'
import { IS_MOBILE_WIDTH_THRESHOLD_IN_PX } from 'src/constants'
import i18n from 'src/i18n'
import { shoot } from 'src/redux/actions'
import {
  select_addresses,
  select_associated_customers,
  select_caps,
  select_customer,
  select_districts,
  select_error,
  select_history_item,
  select_localities,
  select_municipalities,
  select_outcome_customer,
  select_toponyms,
} from 'src/redux/store'
import {
  addr_t,
  cap_t,
  customer_t,
  customer_update_t,
  district_t,
  locality_t,
  municipality_t,
  toponym_t,
} from 'src/types'
import { ClassNames } from 'src/utils/--deprecated'
import Button from 'src/widgets/--deprecated/button/button'
import CheckBox from 'src/widgets/--deprecated/check-box/check-box'
import InputTxt from 'src/widgets/--deprecated/input-txt/input-txt'
import ResidenceComboBox from 'src/widgets/--deprecated/residence-combo-box/residence-combo-box'
import * as Yup from 'yup'

interface checkbox_customer_t extends customer_t {
  selected: boolean;
}

interface residence_form_props {
  back_button_handler: () => void;
}

const ItalyResidenceForm: React.FC<residence_form_props> = (props: residence_form_props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const tr_modify_residence: any = t('modify_residence', {
    returnObjects: true,
  })

  const district_not_found: district_t = {
    code: '0',
    description: tr_modify_residence.form.errors.district_not_found,
  }
  const municipality_not_found: municipality_t = {
    code: '0',
    description: tr_modify_residence.form.errors.municipality_not_found,
  }
  const locality_not_found: locality_t = {
    code: '0',
    description: tr_modify_residence.form.errors.locality_not_found,
  }
  const cap_not_found: cap_t = {
    postalCode: tr_modify_residence.form.errors.cap_not_found,
  }

  const toponym_not_found: toponym_t = {
    code: 0,
    description: tr_modify_residence.form.errors.toponym_not_found,
  }

  /**
   * Selectors
   */
  const districts = useSelector(select_districts)
  const municipalities = useSelector(select_municipalities)
  const localities = useSelector(select_localities)
  const caps = useSelector(select_caps)
  const toponyms = useSelector(select_toponyms)
  const addresses = useSelector(select_addresses)
  const customer = useSelector(select_customer)
  const customer_outcome = useSelector(select_outcome_customer)
  const customer_error = useSelector(select_error(['SAGA_UPDATE_CUSTOMER']))
  const associated_customers = useSelector(select_associated_customers)
  const main_email = useSelector(select_history_item('main-email'))

  const [is_mobile, set_is_mobile] = useState(window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX)

  const [district_selected, set_district_selected] = useState({
    code: '' + customer?.address?.codeProvince,
    description: customer?.address?.province,
  })
  const [district_abbreviation, set_district_abbreviation] = useState('')
  const [municipality_selected, set_municipality_selected] = useState<municipality_t>({
    code: '' + customer?.address?.codeLocality,
    description: customer?.address?.locality,
  })
  const [locality_selected, set_locality_selected] = useState<locality_t>({
    code: '' + customer?.address?.codeCity,
    description: customer?.address?.city,
  })
  const [cap_selected, set_cap_selected] = useState<cap_t>({
    postalCode: customer?.address?.postalCode,
  })
  const [toponym_selected, set_toponym_selected] = useState<toponym_t>({
    code: customer?.address?.codeTopAddress,
    description: customer?.address?.topAddress,
  })
  const [address_selected, set_address_selected] = useState<addr_t>({
    code: '0',
    description: customer?.address?.address,
  })
  const [previous_address, set_previous_address] = useState('')
  const [customers_selected, set_customers_selected] = useState<checkbox_customer_t[]>([])
  const [free_solo, set_free_solo] = useState(false)
  const [customer_update, set_customer_update] = useState<customer_update_t>()

  const send_request_handler = (values: any) => {
    const proclies =
      customers_selected?.filter((cust) => cust?.selected).map((cust) => cust.proClie || '') || []
    let cust_update: customer_update_t
    if (!free_solo) {
      cust_update = {
        proClie: customer?.proClie || '',
        type: 5,
        address: {
          codeCountry: 1,
          country: values?.nation,
          province: district_abbreviation, // sigla provincia
          codeProvince: Number(values?.district), // codice provincia
          codeCity: Number(values?.municipality), // codice comune
          city: municipality_selected?.description, // descrizione comune
          codeLocality: Number(values?.locality), // codice città
          locality: locality_selected?.description, // descrizione città
          postalCode: values?.cap, // cap
          idAddress: customer?.address?.idAddress, // id preso dal customer
          address: values?.address, // descrizione indirizzo
          codeTopAddress: toponym_selected?.code, // codice toponimo
          topAddress: '' + toponym_selected?.description, //descrizione toponimo
          addressNumber: values?.street_number, //numero civico
          personTypeCode: customer?.personTypeCode,
          email: main_email,
        },
        associatedCustomers: proclies,
      }
    } else {
      cust_update = {
        proClie: customer?.proClie || '',
        type: 5,
        address: {
          idAddress: customer?.address?.idAddress,
          codeCountry: 1,
          country: values?.nation,
          province: district_selected?.description,
          city: municipality_selected?.description,
          locality: locality_selected?.description,
          topAddress: toponym_selected?.description,
          address: values?.address,
          addressNumber: values?.street_number,
          postalCode: values?.cap,
          personTypeCode: customer?.personTypeCode,
          email: main_email,
        },
        associatedCustomers: proclies,
      }
    }
    set_customer_update(cust_update)
    dispatch(shoot.saga_update_customer({ customer_update: cust_update }))
  }

  /**
   * UseEffect
   */
  useEffect(() => {
    dispatch(shoot.saga_resolve_associated_customers({ tax_code: customer?.taxCode }))
    dispatch(shoot.saga_resolve_districts({ nation_code: 1 }))
    if (customer?.address?.codeProvince) {
      dispatch(
        shoot.saga_resolve_municipalities({ district_code: customer?.address?.codeProvince })
      )
    }
    if (customer?.address?.codeProvince) {
      dispatch(shoot.saga_resolve_localities({ municipality_code: customer?.address?.codeCity }))
    }
    if (customer?.address?.codeProvince && customer?.address?.codeCity) {
      dispatch(
        shoot.saga_resolve_caps({
          nation_code: 1,
          district_code: customer?.address?.codeProvince,
          municipality_code: customer?.address?.codeCity,
        })
      )
    }
    dispatch(shoot.saga_resolve_toponyms())
  }, [])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < IS_MOBILE_WIDTH_THRESHOLD_IN_PX
        if (ismobile !== is_mobile) set_is_mobile(ismobile)
      },
      false
    )
  }, [is_mobile])

  useEffect(() => {
    if (associated_customers) {
      set_customers_selected(
        associated_customers
          ?.filter((cust) => cust?.proClie !== customer?.proClie)
          ?.map((cust) => ({
            ...cust,
            selected: false,
          })) || []
      )
    }
  }, [associated_customers])

  useEffect(() => {
    if (districts) {
      init_district_abbreviation('' + customer?.address?.codeProvince)
    }
  }, [districts])

  useEffect(() => {
    if (customer_update) {
      if (customer_error) {
        history.goBack()
      }
      if (customer_outcome?.outcome === 'success') {
        dispatch(shoot.reduce_history_item('show-otp', 'true'))
        dispatch(shoot.reduce_history_item('residence-request', JSON.stringify(customer_update)))
        history.goBack()
      }
    }
  }, [customer_update, customer_outcome, customer_error])

  const validation_schema = Yup.object().shape({
    district: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    municipality: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    locality: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    cap: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    toponym: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    address: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
    street_number: Yup.string().required(i18n.t('modify_residence.form.errors.field_required')),
  })

  const init_district_abbreviation = (value: string) => {
    const districts_temp: district_t[] = Object.assign([], districts)
    const districts_filter = districts_temp?.filter((district) => district.code === value) || []
    set_district_abbreviation(districts_filter[0]?.abbreviation || '')
  }

  const select_value_handler = (value: string, type: string) => {
    switch (type) {
      case 'district':
        if (!free_solo) {
          if (value && value != '0') {
            dispatch(shoot.saga_resolve_municipalities({ district_code: value }))
            const districts_temp: locality_t[] = Object.assign([], districts)
            const districts_filter =
              districts_temp?.filter((district) => district.code === value) || []
            set_district_selected({
              code: value,
              description: districts_filter[0]?.description || '',
            })
            init_district_abbreviation(value)
            set_municipality_selected({ code: '0', description: '' })
            set_locality_selected({ code: '0', description: '' })
            set_cap_selected({ postalCode: '' })
            set_address_selected({ code: '0', description: '' })
          } else if (value === '0') {
            set_free_solo(true)
          } else if (value === '') {
            set_district_selected({ code: '0', description: '' })
            set_municipality_selected({ code: '0', description: '' })
            set_locality_selected({ code: '0', description: '' })
            set_cap_selected({ postalCode: '' })
            set_address_selected({ code: '0', description: '' })
          }
        } else if (free_solo) {
          set_district_selected({ code: '0', description: value })
        }
        break
      case 'municipality':
        if (!free_solo) {
          if (value && value != '0') {
            dispatch(shoot.saga_resolve_localities({ municipality_code: value }))
            const municipalities_temp: municipality_t[] = Object.assign([], municipalities)
            const municipalities_filter =
              municipalities_temp?.filter((municipality) => municipality.code === value) || []
            set_municipality_selected({
              code: value,
              description: municipalities_filter[0]?.description || '',
            })
            set_locality_selected({ code: '0', description: '' })
            set_cap_selected({ postalCode: '' })
            set_address_selected({ code: '0', description: '' })
          } else if (value === '0') {
            set_free_solo(true)
          } else if (value === '') {
            set_municipality_selected({ code: '0', description: '' })
            set_locality_selected({ code: '0', description: '' })
            set_cap_selected({ postalCode: '' })
            set_address_selected({ code: '0', description: '' })
          }
        } else if (free_solo) {
          set_municipality_selected({
            code: '0',
            description: value,
          })
        }
        break
      case 'locality':
        if (!free_solo) {
          if (value && value != '0') {
            const district_code = district_selected?.code
            const municipality_code = municipality_selected?.code
            dispatch(
              shoot.saga_resolve_caps({
                nation_code: 1,
                district_code,
                municipality_code,
              })
            )
            const localities_temp: locality_t[] = Object.assign([], localities)
            const localities_filter =
              localities_temp?.filter((locality) => locality.code === value) || []
            set_locality_selected({
              code: value,
              description: localities_filter[0]?.description || '',
            })
            set_cap_selected({ postalCode: '' })
            set_address_selected({ code: '0', description: '' })
          } else if (value === '0') {
            set_free_solo(true)
          } else if (value === '') {
            set_locality_selected({ code: '0', description: '' })
            set_cap_selected({ postalCode: '' })
            set_address_selected({ code: '0', description: '' })
          }
        } else if (free_solo) {
          set_locality_selected({ code: '0', description: value })
        }
        break
      case 'cap':
        if (!free_solo) {
          if (value === '') {
            set_cap_selected({ postalCode: '0' })
            set_address_selected({ code: '0', description: '' })
          } else if (value === tr_modify_residence.form.errors.cap_not_found) {
            set_free_solo(true)
          } else {
            set_cap_selected({ postalCode: value })
            set_address_selected({ code: '0', description: '' })
          }
        }
        break
      case 'toponym':
        if (!free_solo) {
          if (value === '0') {
            set_toponym_selected({ code: 0 })
            set_free_solo(true)
          } else if (value) {
            const code_toponym = +value
            if (code_toponym != 0) {
              const toponyms_temp: toponym_t[] = Object.assign([], toponyms)
              const toponyms_filter =
                toponyms_temp?.filter((toponym) => toponym.code === code_toponym) || []
              set_toponym_selected({
                code: toponyms_filter[0]?.code,
                description: toponyms_filter[0]?.description || '',
              })
            }
          }
        }
        break
      case 'address':
        if (!free_solo) {
          if (
            value &&
            value != address_selected?.description &&
            (previous_address === '' || previous_address != value)
          ) {
            if (
              value?.toUpperCase() !=
              tr_modify_residence.form.errors.address_not_found?.toUpperCase()
            ) {
              set_previous_address(value)
            }
            const district_code = district_selected?.code
            const addrs: addr_t[] = Object.assign([], addresses)
            const addrs_filter = addrs?.filter((add) => add.description === value) || []
            if (addrs_filter?.length > 0) {
              set_address_selected({
                code: addrs_filter[0].code || '0',
                description: addrs_filter[0].description,
              })
            }
            const locality_code = locality_selected?.code
            dispatch(
              shoot.saga_resolve_addresses({
                nation_code: 1,
                district_code,
                locality_code,
                desc_address: value,
                limit: 100,
              })
            )
          } else if (value === '') {
            set_address_selected({ code: '0', description: '' })
          }
        }
        break
    }
  }

  const on_selected_handler = (index: number) => {
    const linked_customers = [...customers_selected]
    const item = { ...linked_customers[index] }
    item.selected = !item.selected
    linked_customers[index] = item
    set_customers_selected(linked_customers)
  }

  const toggle_button_sumbit = (values: any, errors: any) => {
    const btn_enabled =
      values.nation == '' ||
      values.district == '' ||
      values.municipality == '' ||
      values.locality == '' ||
      values.cap == '' ||
      values.toponym == '' ||
      values.address == '' ||
      values.street_number == '' ||
      errors.nation != undefined ||
      errors.district != undefined ||
      errors.municipality != undefined ||
      errors.locality != undefined ||
      errors.cap != undefined ||
      errors.toponym != undefined ||
      errors.address != undefined ||
      errors.street_number != undefined
        ? true
        : false
    return btn_enabled
  }

  return (
    <div className="mod-residence-form-container">
      <Formik
        validationSchema={validation_schema}
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={{
          nation: 'Italia',
          district: customer?.address?.codeProvince,
          municipality: customer?.address?.codeLocality,
          locality: customer?.address?.codeCity,
          cap: customer?.address?.postalCode,
          toponym: customer?.address?.topAddress,
          address: customer?.address?.address,
          street_number: customer?.address?.addressNumber,
        }}
        onSubmit={(values) => {
          send_request_handler(values)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {/* PRIMA RIGA*/}
            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="nation"
                label={tr_modify_residence.form.nation}
                type="text"
                placeholder={tr_modify_residence.form.nation_placeholder}
                value={'Italia'}
                readonly={true}
                left_icon={<Image src={italy_icon} fluid />}
              />
            </div>
            <div className="mod-residence-form-content">
              <div className="unnamed-character-style-27-13-22">
                {tr_modify_residence.form.district}
              </div>
              <ResidenceComboBox
                id="district"
                options={districts || []}
                default={district_selected}
                option_not_found={district_not_found}
                change={(e: string) => {
                  select_value_handler(e, 'district')
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue('district', e)
                  !free_solo
                    ? setTimeout(() => {
                        e === '0'
                          ? setFieldValue('municipality', municipality_selected?.description)
                          : setFieldValue('municipality', '')
                        setTimeout(() => {
                          e === '0'
                            ? setFieldValue('locality', locality_selected?.description)
                            : setFieldValue('locality', '')
                          setTimeout(() => {
                            e === '0'
                              ? setFieldValue('cap', cap_selected?.postalCode)
                              : setFieldValue('cap', '')
                            setTimeout(() => {
                              e === '0'
                                ? setFieldValue('address', address_selected?.description)
                                : setFieldValue('address', '')
                            })
                          })
                        })
                      })
                    : null
                }}
                placeholder={tr_modify_residence.form.district_placeholder}
                free_solo={free_solo}
              />
              <div className="error-character support-extra-error">
                {errors.district && typeof values.district != 'object' ? errors.district : ''}
              </div>
            </div>
            {/* SECONDA RIGA*/}

            <div className="mod-residence-form-content">
              <div className="unnamed-character-style-27-13-22">
                {tr_modify_residence.form.municipality}
              </div>
              <ResidenceComboBox
                id="municipality"
                options={municipalities || []}
                default={municipality_selected}
                option_not_found={municipality_not_found}
                change={(e: string) => {
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue('municipality', e, true)
                  select_value_handler(e, 'municipality')
                  !free_solo
                    ? setTimeout(() => {
                        e === '0'
                          ? setFieldValue('locality', locality_selected?.description)
                          : setFieldValue('locality', '')
                        setTimeout(() => {
                          e === '0'
                            ? setFieldValue('cap', cap_selected?.postalCode)
                            : setFieldValue('cap', '')
                          setTimeout(() => {
                            e === '0'
                              ? setFieldValue('address', address_selected?.description)
                              : setFieldValue('address', '')
                          })
                        })
                      })
                    : null
                }}
                placeholder={tr_modify_residence.form.municipality_placeholder}
                free_solo={free_solo}
              />
              <div className="error-character support-extra-error">
                {errors.municipality && typeof values.municipality != 'object'
                  ? errors.municipality
                  : ''}
              </div>
            </div>
            <div className="mod-residence-form-content">
              <div className="unnamed-character-style-27-13-22">
                {tr_modify_residence.form.locality}
              </div>
              <ResidenceComboBox
                id="locality"
                options={localities || []}
                default={locality_selected}
                option_not_found={locality_not_found}
                change={(e: string) => {
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue('locality', e, true)
                  select_value_handler(e, 'locality')
                  !free_solo
                    ? setTimeout(() => {
                        e === '0'
                          ? setFieldValue('cap', cap_selected?.postalCode)
                          : setFieldValue('cap', '')
                        setTimeout(() => {
                          e === '0'
                            ? setFieldValue('address', address_selected?.description)
                            : setFieldValue('address', '')
                        })
                      })
                    : null
                }}
                placeholder={tr_modify_residence.form.locality_placeholder}
                free_solo={free_solo}
              />
              <div className="error-character support-extra-error">
                {errors.locality && typeof values.locality != 'object' ? errors.locality : ''}
              </div>
            </div>
            {/* TERZA RIGA*/}
            <div className="mod-residence-form-content">
              <div className="unnamed-character-style-27-13-22">{tr_modify_residence.form.cap}</div>
              <ResidenceComboBox
                id="cap"
                options={caps || []}
                default={cap_selected}
                option_not_found={cap_not_found}
                change={(e: string) => {
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue('cap', e)
                  select_value_handler(e, 'cap')
                  !free_solo
                    ? setTimeout(() => {
                        e === '0'
                          ? setFieldValue('address', address_selected?.description)
                          : setFieldValue('address', '')
                      })
                    : null
                }}
                placeholder={tr_modify_residence.form.cap_placeholder}
                free_solo={free_solo}
              />
              <div className="error-character support-extra-error">
                {errors.cap && typeof values.cap != 'object' ? errors.cap : ''}
              </div>
            </div>
            <div className="mod-residence-form-content">
              <div className="unnamed-character-style-27-13-22">
                {tr_modify_residence.form.toponym}
              </div>
              <ResidenceComboBox
                id="toponym"
                options={toponyms || []}
                default={toponym_selected}
                option_not_found={toponym_not_found}
                change={(e: number) => {
                  handleChange('' + e)
                  handleBlur('' + e)
                  setFieldValue('toponym', '' + e)
                  select_value_handler('' + e, 'toponym')
                }}
                placeholder={tr_modify_residence.form.toponym_placeholder}
                free_solo={free_solo}
              />
              <div className="error-character support-extra-error">
                {errors.toponym && typeof values.toponym != 'object' ? errors.toponym : ''}
              </div>
            </div>
            {/* QUARTA RIGA*/}
            <div className="mod-residence-form-content">
              <div className="unnamed-character-style-27-13-22">
                {tr_modify_residence.form.address}
              </div>
              <ResidenceComboBox
                id="address"
                options={addresses || []}
                default={address_selected}
                option_not_found={''}
                input_change={(e: string) => {
                  select_value_handler(e, 'address')
                }}
                change={(e: string) => {
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue(
                    'address',
                    e?.toUpperCase() ===
                      tr_modify_residence.form.errors.address_not_found?.toUpperCase()
                      ? previous_address
                      : e
                  )
                  set_address_selected({
                    code: '0',
                    description:
                      e?.toUpperCase() ===
                      tr_modify_residence.form.errors.address_not_found?.toUpperCase()
                        ? previous_address
                        : e,
                  })
                }}
                on_blur={(e: string) => {
                  setTimeout(() => {
                    if (!free_solo) {
                      if (e?.toLowerCase() != address_selected?.description?.toLowerCase()) {
                        setFieldValue('address', e)
                        set_address_selected({
                          code: '0',
                          description: e,
                        })
                      }
                    }
                  })
                }}
                placeholder={tr_modify_residence.form.address_placeholder}
                free_solo={free_solo}
              />
              <div className="error-character support-extra-error">
                {errors.address && typeof values.address != 'object' ? errors.address : ''}
              </div>
            </div>
            <div className="mod-residence-form-content unnamed-character-style-27-13-22">
              <InputTxt
                id="street_number"
                label={tr_modify_residence.form.street_number}
                type="text"
                placeholder={tr_modify_residence.form.street_number_placeholder}
                value={values.street_number}
                on_change={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  handleBlur(e)
                  setFieldValue('street_number', e.target.value)
                }}
                validators={[
                  {
                    is_valid: () => !(touched.street_number && errors.street_number),
                    text: errors.street_number || '',
                  },
                ]}
              />
              <div className="error-character support-extra-error">
                {touched.street_number && errors.street_number ? errors.street_number : ''}
              </div>
            </div>
            <div
              className={ClassNames(
                'mt-5',
                is_mobile && customers_selected?.length != 0
                  ? 'unnamed-character-style-22 d-block'
                  : customers_selected?.length != 0
                  ? 'unnamed-character-style-11 d-block'
                  : 'd-none'
              )}
            >
              <div>{tr_modify_residence.form.associated_customer_label}</div>
              {customers_selected?.map((customer: customer_t, index_checkbox: number) => (
                <div className="checkbox-item" key={index_checkbox}>
                  <CheckBox
                    id={customer.proClie + ''}
                    text={
                      customer.name ? `${customer.surname} ${customer.name}` : customer.vatNumber
                    }
                    item_style="unnamed-character-style-10"
                    selected={customers_selected[index_checkbox].selected}
                    checked={() => on_selected_handler(index_checkbox)}
                  />
                </div>
              ))}
            </div>
            <div
              style={{ width: '100%', margin: 'auto' }}
              className={ClassNames(is_mobile ? 'mod-residence-d-block' : 'mod-residence-d-flex')}
            >
              <div className="mod-residence-button-confirm">
                <Button name="text_A90433_bold" onClick={props?.back_button_handler}>
                  <div style={{ margin: 'auto' }}>{tr_modify_residence.form.cancel}</div>
                </Button>
              </div>
              <div className="mod-residence-button-confirm">
                <Button
                  name="full_059881"
                  disabled={toggle_button_sumbit(values, errors)}
                  classes="gtm-residence"
                >
                  <div>{tr_modify_residence.form.send}</div>
                  <div className="right-arrow-white" />
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default ItalyResidenceForm
