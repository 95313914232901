import { left_arrow_icon, right_arrow_icon } from 'src/assets'
import { button_props, dict } from 'src/types'

export const flavors: dict<button_props> = {
  /**
   * Primary
   */
  primary: {
    classes: 'primary',
  },
  primary_flip: {
    classes: 'primary-flip',
  },
  primary_back: {
    classes: 'primary',
    classes_iconl: 'filter-ffffff',
    iconl: left_arrow_icon,
  },
  primary_next: {
    classes: 'primary',
    classes_iconr: 'filter-ffffff',
    iconr: right_arrow_icon,
  },
  primary_flip_back: {
    classes: 'primary-flip',
    classes_iconl: 'filter-862633',
    iconl: left_arrow_icon,
  },
  primary_flip_next: {
    classes: 'primary-flip',
    classes_iconr: 'filter-862633',
    iconr: right_arrow_icon,
  },
  primary_flip_back_border: {
    classes: 'primary-flip-border',
    classes_iconl: 'filter-862633',
    iconl: left_arrow_icon,
  },
  primary_flip_next_border: {
    classes: 'primary-flip-border',
    classes_iconr: 'filter-862633',
    iconr: right_arrow_icon,
  },

  /**
   * Accent
   */
  accent: {
    classes: 'accent',
  },
  accent_flip: {
    classes: 'accent-flip',
  },
  accent_back: {
    classes: 'accent',
    classes_iconl: 'filter-ffffff',
    iconl: left_arrow_icon,
  },
  accent_next: {
    classes: 'accent',
    classes_iconr: 'filter-ffffff',
    iconr: right_arrow_icon,
  },
  accent_flip_back: {
    classes: 'accent-flip',
    classes_iconl: 'filter-059881',
    iconl: left_arrow_icon,
  },
  accent_flip_next: {
    classes: 'accent-flip',
    classes_iconr: 'filter-059881',
    iconr: right_arrow_icon,
  },
  accent_flip_back_border: {
    classes: 'accent-flip-border',
    classes_iconl: 'filter-059881',
    iconl: left_arrow_icon,
  },
  accent_flip_next_border: {
    classes: 'accent-flip-border',
    classes_iconr: 'filter-059881',
    iconr: right_arrow_icon,
  },

  /**
   * Transparent primary
   */
  transparent_primary: {
    classes: 'transparent-primary',
  },
  transparent_primary_back: {
    classes: 'transparent-primary',
    classes_iconl: 'filter-862633',
    iconl: left_arrow_icon,
  },
  transparent_primary_next: {
    classes: 'transparent-primary',
    classes_iconr: 'filter-862633',
    iconr: right_arrow_icon,
  },

  /**
   * Transparent accent
   */
  transparent_accent: {
    classes: 'transparent-accent',
  },
  transparent_accent_back: {
    classes: 'transparent-accent',
    classes_iconl: 'filter-059881',
    iconl: left_arrow_icon,
  },
  transparent_accent_next: {
    classes: 'transparent-accent',
    classes_iconr: 'filter-059881',
    iconr: right_arrow_icon,
  },

  /**
   * Transparent white
   */
  transparent_white: {
    classes: 'transparent-white',
  },
  transparent_white_border: {
    classes: 'transparent-white-border',
  },
}
