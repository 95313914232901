import { useFormik } from 'formik'
import { FC, useEffect, useRef } from 'react'
import { Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { select_customer_email } from 'src/redux/store'
import { email_modal_props } from 'src/types'
import { yemail_required } from 'src/utils'
import { Button } from 'src/widgets/button/button'
import { InputText } from 'src/widgets/input-text/input-text'
import * as Yup from 'yup'

import { Modal } from '../modal'

export const EmailModal: FC<email_modal_props> = ({
  visible,
  title,
  description,
  cancel,
  confirm,
}: email_modal_props) => {
  /**
   * Use Selector
   */
  const customer_email = useSelector(select_customer_email)

  /**
   * Formik
   */
  const i_values = useRef({ email: customer_email })
  const i_schema = Yup.object({ email: yemail_required })
  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting,
    isSubmitting,
    isValid,
    touched,
    values,
    errors,
  } = useFormik({
    initialValues: i_values.current,
    validateOnMount: true,
    validationSchema: i_schema,
    onSubmit: () => {
      confirm?.on_press?.(values?.email || '')
      setSubmitting(false)
    },
  })

  /**
   * UseEffect
   */
  useEffect(() => {
    setFieldValue('email', customer_email)
  }, [visible, customer_email])

  return (
    <Modal visible={visible} on_close={cancel?.on_press}>
      <div className="px-1 px-md-3 py-5 d-flex flex-column align-items-center">
        <h1 className="m-0 p-0 font-18-semibold text-uppercase">{title}</h1>

        <p className="m-0 p-0 mt-3 font-16 text-center">{description}</p>

        <form onSubmit={handleSubmit} className="mx-4 mt-3 col-12 col-lg-9 col-xl-7">
          <InputText
            id="email"
            formik_props={getFieldProps('email')}
            formik_schema={i_schema}
            label="Email"
            error={errors?.email}
            touched={touched?.email}
          />
          <Row className="m-0 p-0 mx-1 mx-sm-3 mt-4 justify-content-between flex-column-reverse flex-md-row">
            <Button
              flavor="primary_flip"
              text={cancel?.label}
              on_press={cancel?.on_press}
              classes="mt-4 mb-2 mt-md-0 mb-md-0 mr-md-5 w-md-100 d-flex justify-content-center"
            />
            <Button
              flavor="accent_next"
              type="submit"
              text={confirm?.label}
              classes="justify-content-between"
              disabled={!isValid || isSubmitting}
            />
          </Row>
        </form>
      </div>
    </Modal>
  )
}
