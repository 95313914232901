import { WEBAPP_ENV } from 'src/config'

export const regex_email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const regex_number = /^[0-9]*$/

export const regex_username =
  /^[E,e,X][0-9]{4,}$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const regex_client_id = /^[E,e,X][0-9]{4,}$/

export const regex_generic_policy_number_without_t = /^([P]([0-9]{7,8}))$/
export const regex_generic_policy_number_with_t = /^([T]?[P]([0-9]{7,8}))$/
export const regex_generic_policy_number =
  WEBAPP_ENV === 'SVI' || WEBAPP_ENV === 'TST'
    ? regex_generic_policy_number_with_t
    : regex_generic_policy_number_without_t

export const regex_name = /^[a-zA-Z ]{2,}$/

export const regex_vat_number = /^(\b[0-9]{11}\b)$/

export const regex_tax_code =
  /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/

export const regex_phone_number = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3,4}[\s.-]?\d{3}$/

export const regex_iban =
  /^([A-Z]{2}[ ]?[0-9]{2})(?=(?:[ ]?[A-Z0-9]){9,30}$)((?:[ ]?[A-Z0-9]{3,5}){2,7})([ ]?[A-Z0-9]{1,3})?$/

export const regex_date = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/

export const regex_otp = /^[0-9]{4,8}$/

export const regex_password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

export const regex_password_lowercase = /[a-z]/

export const regex_password_uppercase = /[A-Z]/

export const regex_password_number = /\d/
