import { call, put, select } from 'redux-saga/effects'
import { outcome_w } from 'src/backend-codes'
import {
  http_download_document,
  http_resolve_documents,
  http_resolve_documents_institutional,
  http_resolve_documents_policy,
  http_send_document,
} from 'src/http/documents.http'
import { document_general_t, document_t, error_t, saga_act } from 'src/types'

import { shoot } from '../actions'
import { select_documents } from '../store'
import { saga_do_request } from './base.saga'

/**
 * Resolve documents
 */
export function* saga_resolve_documents(action: saga_act): unknown {
  const cached: document_t[] = yield select(select_documents)
  if (!cached) {
    const docs: document_general_t = yield call(saga_do_request, http_resolve_documents, action)
    if (docs) {
      docs?.documents?.sort((d1, d2) =>
        (d1?.documentDate || '') < (d2?.documentDate || '') ? 1 : -1
      )
      yield put(
        shoot.reduce_documents(
          docs?.documents?.map((doc) => ({ ...doc, documentCategories: [doc?.documentCategory] }))
        )
      )

      let error_ = ''
      docs?.errors?.forEach(
        (error, index) => (error_ += index !== 0 ? '\n' + error?.description : error?.description)
      )
      const error_fe_: error_t = {
        code: '500',
        description_fe: error_,
        severity: docs?.errors?.[0]?.severity,
        saga_type: docs?.errors?.[0]?.type,
      }

      yield put(shoot.reduce_documents_errors(error_fe_))
    }
  }
}

/**
 * Resolve documents policy
 */
export function* saga_resolve_documents_policy(action: saga_act): unknown {
  const docs_policy: document_t[] = yield call(
    saga_do_request,
    http_resolve_documents_policy,
    action
  )
  if (docs_policy) {
    yield put(
      shoot.reduce_documents_policy(
        docs_policy?.map((doc) => ({ ...doc, documentCategories: [doc?.documentCategory] }))
      )
    )
  }
}

/**
 * Resolve documents institutional
 */
export function* saga_resolve_documents_institutional(action: saga_act): unknown {
  const docs_institutional: document_t[] = yield call(
    saga_do_request,
    http_resolve_documents_institutional,
    action
  )
  if (docs_institutional) {
    yield put(
      shoot.reduce_documents_institutional(
        docs_institutional?.map((doc) => {
          const categories =
            doc?.documentCategory === 'ALL' ? ['ITAS_VALPIAVE'] : [doc?.documentCategory]
          return { ...doc, documentCategories: categories }
        })
      )
    )
  }
}

/**
 * Download document
 */
export function* saga_download_document(action: saga_act): unknown {
  const doc: document_t = yield call(saga_do_request, http_download_document, action)
  if (doc) {
    yield put(shoot.reduce_document(doc))
  }
}

/**
 * Send document
 */
export function* saga_send_document(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_send_document, action)
  if (outcome) {
    yield put(shoot.reduce_outcome_document(outcome))
  }
}
