import { call, put, select } from 'redux-saga/effects'
import { outcome_w, POLICY_CATEGORIES } from 'src/backend-codes'
import {
  http_resolve_assitance_policies,
  http_resolve_policies,
  http_resolve_policy_detail,
  http_update_policy,
  http_verify_policy,
} from 'src/http'
import {
  policy_assistance_t,
  policy_detail_t,
  policy_outcome_t,
  policy_t,
  saga_act,
} from 'src/types'
import { format_euro } from 'src/utils'

import { shoot } from '../actions'
import {
  select_policies,
  select_policies_assistance,
  select_policy,
  select_policy_detail,
} from '../store'
import { saga_do_request } from './base.saga'

/**
 * Resolve policies
 */
export function* saga_resolve_policies(action: saga_act): unknown {
  const cached: policy_t[] = yield select(select_policies)
  if (!cached) {
    const policies: policy_t[] = yield call(saga_do_request, http_resolve_policies, action)
    if (policies) {
      yield put(
        shoot.reduce_policies(
          policies?.map((policy) => ({
            ...policy,
            company: policy?.company || '183',
            category: POLICY_CATEGORIES[policy?.policyCategoryCod || ''],
            grossTotalNextPayment:
              policy?.grossTotalNextPayment && format_euro(Number(policy?.grossTotalNextPayment)),
          }))
        )
      )
    }
  }
}

/**
 * Resolve policy detail
 */
export function* saga_resolve_policy_detail(action: saga_act): unknown {
  const policy_number = action?.args?.policy_id
  const cached: policy_detail_t = yield select(select_policy_detail)

  if (cached?.policyNumber !== policy_number) {
    yield put(shoot.reduce_policy_detail())
    const policy_detail: policy_detail_t = yield call(
      saga_do_request,
      http_resolve_policy_detail,
      action
    )
    if (policy_detail) {
      const policy: policy_t = yield select(select_policy(String(policy_number)))
      yield put(shoot.reduce_policy_detail({ ...policy, ...policy_detail }))
    }
  }
}

/**
 * Update policy
 */
export function* saga_update_policy(action: saga_act): unknown {
  const outcome: policy_outcome_t = yield call(saga_do_request, http_update_policy, action)
  if (outcome) {
    yield put(shoot.reduce_outcome_policy(outcome))
  }
}

/**
 * Verify policy
 */
export function* saga_verify_policy(action: saga_act): unknown {
  const outcome: outcome_w = yield call(saga_do_request, http_verify_policy, action)
  if (outcome) {
    yield put(shoot.reduce_outcome_policy({ outcome }))
  }
}

/**
 * Resolve policies assistance
 */
export function* saga_resolve_policies_assistance(action: saga_act): unknown {
  const cached: policy_assistance_t[] = yield select(select_policies_assistance)
  if (!cached) {
    const policies_assistance: policy_assistance_t[] = yield call(
      saga_do_request,
      http_resolve_assitance_policies,
      action
    )
    if (policies_assistance) {
      yield put(
        shoot.reduce_policies_assistance(
          policies_assistance?.map((policy) => ({
            ...policy,
            category: POLICY_CATEGORIES[policy?.policyCategoryCod || ''],
          }))
        )
      )
    }
  }
}
