import { createReducer } from 'reduxsauce'
import { outcomes_store } from 'src/types'

import { outcomes_types } from '../../actions'

export const reduce_outcome_logout = (
  state: outcomes_store,
  { logout }: outcomes_store
): outcomes_store => ({
  ...state,
  logout,
})

export const reduce_outcome_registration = (
  state: outcomes_store,
  { registration }: outcomes_store
): outcomes_store => ({
  ...state,
  registration,
})

export const reduce_outcome_reset_password = (
  state: outcomes_store,
  { reset_password }: outcomes_store
): outcomes_store => ({
  ...state,
  reset_password,
})

export const reduce_outcome_recovery_client_id = (
  state: outcomes_store,
  { recovery_client_id }: outcomes_store
): outcomes_store => ({
  ...state,
  recovery_client_id,
})

export const reduce_outcome_recovery_password = (
  state: outcomes_store,
  { recovery_password }: outcomes_store
): outcomes_store => ({
  ...state,
  recovery_password,
})

export const reduce_outcome_customer = (
  state: outcomes_store,
  { customer }: outcomes_store
): outcomes_store => ({
  ...state,
  customer,
})

export const reduce_outcome_contacts = (
  state: outcomes_store,
  { contacts }: outcomes_store
): outcomes_store => ({
  ...state,
  contacts,
})

export const reduce_outcome_consents = (
  state: outcomes_store,
  { consents }: outcomes_store
): outcomes_store => ({
  ...state,
  consents,
})

export const reduce_outcome_language = (
  state: outcomes_store,
  { language }: outcomes_store
): outcomes_store => ({
  ...state,
  language,
})

export const reduce_outcome_access = (
  state: outcomes_store,
  { access }: outcomes_store
): outcomes_store => ({
  ...state,
  access,
})

export const reduce_outcome_iban = (
  state: outcomes_store,
  { iban }: outcomes_store
): outcomes_store => ({
  ...state,
  iban,
})

export const reduce_outcome_payment = (
  state: outcomes_store,
  { payment }: outcomes_store
): outcomes_store => ({
  ...state,
  payment,
})

export const reduce_outcome_payment_status = (
  state: outcomes_store,
  { payment_status }: outcomes_store
): outcomes_store => ({
  ...state,
  payment_status,
})

export const reduce_outcome_email = (
  state: outcomes_store,
  { email }: outcomes_store
): outcomes_store => ({
  ...state,
  email,
})

export const reduce_outcome_document = (
  state: outcomes_store,
  { document }: outcomes_store
): outcomes_store => ({
  ...state,
  document,
})

export const reduce_outcome_policy = (
  state: outcomes_store,
  { policy }: outcomes_store
): outcomes_store => ({
  ...state,
  policy,
})

export const reduce_outcome_voucher = (
  state: outcomes_store,
  { voucher }: outcomes_store
): outcomes_store => ({
  ...state,
  voucher,
})

export const reduce_outcome_poll = (
  state: outcomes_store,
  { poll }: outcomes_store
): outcomes_store => ({
  ...state,
  poll,
})

export default createReducer(
  {},
  {
    [outcomes_types.REDUCE_OUTCOME_LOGOUT]: reduce_outcome_logout,
    [outcomes_types.REDUCE_OUTCOME_REGISTRATION]: reduce_outcome_registration,
    [outcomes_types.REDUCE_OUTCOME_RESET_PASSWORD]: reduce_outcome_reset_password,
    [outcomes_types.REDUCE_OUTCOME_RECOVERY_CLIENT_ID]: reduce_outcome_recovery_client_id,
    [outcomes_types.REDUCE_OUTCOME_RECOVERY_PASSWORD]: reduce_outcome_recovery_password,
    [outcomes_types.REDUCE_OUTCOME_CUSTOMER]: reduce_outcome_customer,
    [outcomes_types.REDUCE_OUTCOME_CONTACTS]: reduce_outcome_contacts,
    [outcomes_types.REDUCE_OUTCOME_CONSENTS]: reduce_outcome_consents,
    [outcomes_types.REDUCE_OUTCOME_LANGUAGE]: reduce_outcome_language,
    [outcomes_types.REDUCE_OUTCOME_ACCESS]: reduce_outcome_access,
    [outcomes_types.REDUCE_OUTCOME_IBAN]: reduce_outcome_iban,
    [outcomes_types.REDUCE_OUTCOME_PAYMENT]: reduce_outcome_payment,
    [outcomes_types.REDUCE_OUTCOME_PAYMENT_STATUS]: reduce_outcome_payment_status,
    [outcomes_types.REDUCE_OUTCOME_EMAIL]: reduce_outcome_email,
    [outcomes_types.REDUCE_OUTCOME_DOCUMENT]: reduce_outcome_document,
    [outcomes_types.REDUCE_OUTCOME_POLICY]: reduce_outcome_policy,
    [outcomes_types.REDUCE_OUTCOME_VOUCHER]: reduce_outcome_voucher,
    [outcomes_types.REDUCE_OUTCOME_POLL]: reduce_outcome_poll,
  }
)
