import './bricks.scss'

import { FC, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { download_icon, email_icon, print_icon } from 'src/assets'
import i18n from 'src/i18n'
import { shoot } from 'src/redux/actions'
import { select_document, select_history_item } from 'src/redux/store'
import { button_props, docs_actions_box, document_t } from 'src/types'
import { download_blob, to_blob } from 'src/utils'
import { Button, EmailModal } from 'src/widgets'
import { react_native_window } from 'src/app'

type action_t = 'print' | 'download' | 'send' | ''

export const ActionsBox: FC<docs_actions_box> = ({
  policy,
  to_print,
  to_download,
  to_send,
  children,
  on_clear,
}: docs_actions_box) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  /**
   * Use Selector
   */
  const itas_document = useSelector(select_document)
  const from_app = useSelector(select_history_item('from-app'))

  /**
   * Use State
   */
  const [action, set_action] = useState<action_t>('')

  /**
   * Use Effect
   */
  useEffect(() => {
    if (action === '') {
      on_clear?.()
    }
  }, [action])

  useEffect(() => {
    if (to_print) {
      print_document(to_print)
    }
  }, [to_print])

  useEffect(() => {
    if (to_download) {
      download_document(to_download)
    }
  }, [to_download])

  useEffect(() => {
    if (to_send) {
      send_document()
    }
  }, [to_send])

  useEffect(() => {
    if (itas_document) {
      if (from_app && (action === 'download' || action === 'print')) {
        const rn_window: react_native_window = window
        rn_window?.ReactNativeWebView?.postMessage?.('PDF_BASE64:' + itas_document?.pdf)
        set_action('')
      } else if (action === 'download') {
        download_blob(to_blob(itas_document?.pdf || ''), itas_document?.name)
        set_action('')
      } else if (action === 'print') {
        window.open(URL.createObjectURL(to_blob(itas_document?.pdf || '')))
        set_action('')
      }
    }
  }, [itas_document])

  /**
   * Net functions
   */
  const to_params = ({
    documentValue,
    documentCategories,
    documentType,
    legacy,
    policy: p,
  }: document_t) => ({
    documentValue,
    documentCategory:
      documentCategories && documentCategories?.length > 1 ? 'ALL' : documentCategories?.[0],
    documentType,
    legacy,
    policyNumber: p?.policyNumber || policy?.policyNumber,
    policyOrderNumber: p?.policyOrderNumber || policy?.policyOrderNumber,
    policyMovNumber: p?.policyMovNumber || policy?.policyMovNumber,
    policyTargaNumber: p?.targaNumber || policy?.targaNumber,
  })

  const print_document = (doc: document_t) => {
    dispatch(shoot.saga_download_document(to_params(doc)))
    set_action('print')
  }

  const download_document = (doc: document_t) => {
    dispatch(shoot.saga_download_document(to_params(doc)))
    set_action('download')
  }

  const send_document = () => {
    set_action('send')
  }

  return (
    <main className="bg-ffffff">
      <EmailModal
        visible={action === 'send'}
        title={t('documents.receive_via_email')}
        description={t('documents.insert_email')}
        cancel={{
          label: t('commons.cancel'),
          on_press: () => set_action(''),
        }}
        confirm={{
          label: t('commons.send'),
          on_press: (email) => {
            set_action('')
            dispatch(shoot.saga_send_document({ ...to_params(to_send || {}), email }))
          },
        }}
      />
      {children}
    </main>
  )
}

export const render_buttons = (buttons: button_props[]): JSX.Element => (
  <Row className="m-0 p-0 justify-content-between">
    {render_button({
      text: i18n.t('commons.print'),
      iconr: print_icon,
      classes: 'mr-3 p-2 mt-2',
      ...buttons[0],
    })}
    {render_button({
      text: i18n.t('commons.download'),
      iconr: download_icon,
      classes: 'mr-3 p-2 mt-2',
      ...buttons[1],
    })}
    {render_button({
      text: i18n.t('commons.email'),
      iconr: email_icon,
      classes: 'p-2 mt-2',
      ...buttons[2],
    })}
  </Row>
)

export const render_button = (props: button_props): JSX.Element => (
  <Button
    classes_text="font-14-semibold text-uppercase c-159881 d-none d-sm-block"
    classes_iconr="mx-3 ml-sm-2 mr-sm-0 filter-159881"
    {...props}
  />
)
