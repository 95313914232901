import { ProgressBarElementType } from './progress-bar'

const progress_bar_step1: Array<ProgressBarElementType> = [
  { kind: 'dot', state: 'on' },
  { kind: 'line', state: 'off' },
  { kind: 'dot', state: 'off' },
  { kind: 'line', state: 'off' },
  { kind: 'dot', state: 'off' },
]

const progress_bar_step2: Array<ProgressBarElementType> = [
  { kind: 'dot', state: 'on' },
  { kind: 'line', state: 'on' },
  { kind: 'dot', state: 'on' },
  { kind: 'line', state: 'off' },
  { kind: 'dot', state: 'off' },
]

const progress_bar_step3: Array<ProgressBarElementType> = [
  { kind: 'dot', state: 'on' },
  { kind: 'line', state: 'on' },
  { kind: 'dot', state: 'on' },
  { kind: 'line', state: 'on' },
  { kind: 'dot', state: 'on' },
]

export const progress_bar_config = [progress_bar_step1, progress_bar_step2, progress_bar_step3]
