import 'src/styles/typography.scss'
import './residence-combo-box.scss'

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

const ResidenceComboBox = (props: any) => {
  const useStyles = makeStyles(() => ({
    // Autocomplete option styles
    root: {
      backgroundColor: '#ffffff',
      opacity: 1,
      '& input': {
        fontFamily: 'var(--unnamed-font-family-sofia-pro)',
        fontSize: 'var(--unnamed-font-size-15)',
        lineHeight: 'var(--unnamed-line-spacing-22)',
        fontStyle: 'var(--unnamed-font-style-normal)',
        fontWeight: 'var(--unnamed-font-weight-normal)',
        letterSpacing: 'var(--unnamed-character-spacing-0)',
        color: 'var(--unnamed-color-000000)',
        marginTop: '-5.5px',
        marginLeft: '0.2rem',
        textTransform: 'capitalize',
      },
      '& input::placeholder': {
        fontFamily: 'var(--unnamed-font-family-sofia-pro)',
        fontSize: 'var(--unnamed-font-size-15)',
        lineHeight: 'var(--unnamed-line-spacing-22)',
        fontStyle: 'var(--unnamed-font-style-normal)',
        fontWeight: 'var(--unnamed-font-weight-normal)',
        letterSpacing: 'var(--unnamed-character-spacing-0)',
        color: 'var(--unnamed-color-7f7f7f)',
        opacity: 1,
        textTransform: 'capitalize',
      },
      '&:hover': {
        backgroundColor: '#ffffff',
        opacity: 1,
      },
      '&:focus-within': {
        backgroundColor: '#ffffff',
        opacity: 1,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C2CDDD',
        borderRadius: '0',
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C2CDDD',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0px',
        border: '1px solid #C2CDDD',
        outline: 0,
        boxShadow: '0 0 0 0',
      },
    },
    option: {
      border: '1px solid #0000001A',
      marginLeft: '5px',
      marginRight: '5px',
      color: '#7f7f7f',
      textTransform: 'capitalize',
    },
    popupIndicator: {
      marginRight: '0.4rem',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
    // clearIndicator: {
    //   '&:hover': {
    //     display: props?.free_solo ? 'none' : 'block',
    //   },
    // },
    inputRoot: {
      marginTop: '0.6rem',
      height: '2.8125rem',
    },
    listbox: {
      fontFamily: 'sofia_pro_regular !important',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
  }))

  const classes = useStyles()

  const filterOptions = createFilterOptions({
    limit: 3,
    matchFrom: 'start',
  })

  // se viene passata la props refresh_component viene fatta la render del component quando cambiano le options
  // const id = `${props?.id}-${props?.free_solo}`
  const id = `${props?.id}-${props?.free_solo}`

  const handle_change = (obj: any) => {
    if (props.id === 'address') {
      obj != null && obj.description != undefined ? props.change(obj.description) : props.change('')
    } else if (props.id === 'cap') {
      obj != null && obj.postalCode != undefined ? props.change(obj.postalCode) : props.change('')
    } else {
      obj != null && obj.code != undefined ? props.change(obj.code) : props.change('')
    }
  }

  const handle_blur = (obj: any) => {
    if (props?.free_solo) {
      props?.change(obj?.target?.defaultValue)
    } else {
      if (props?.id === 'address') {
        props?.on_blur(obj?.target?.defaultValue)
      }
    }
  }

  const handle_input_change = (value: any) => {
    if (props.id === 'address' && value != '') {
      if (!props?.free_solo) {
        value != '' && value?.length >= 3 ? props?.input_change(value) : null
      } else {
        props.change(value)
      }
    }
  }

  return (
    <div>
      {props.options != undefined ? (
        <Autocomplete
          key={id}
          classes={classes}
          freeSolo={props?.free_solo}
          options={props?.options}
          value={props?.default}
          filterOptions={(options, state) => {
            if (props?.free_solo) {
              return []
            } else {
              const results = filterOptions(options, state)
              props?.option_not_found && results.push(props?.option_not_found)
              return results
            }
          }}
          popupIcon={props?.free_solo ? null : <div className="arrow-accordion-black-bottom" />}
          getOptionLabel={(option: any) =>
            option && Object.keys(option).length != 0 && option.description != undefined
              ? option.description?.toLowerCase()
              : option && Object.keys(option).length != 0 && option.postalCode != undefined
              ? option.postalCode
              : ''
          }
          getOptionSelected={(option, obj) =>
            props.id === 'address'
              ? option.description === obj.description
              : props.id === 'cap'
              ? option.postalCode === obj.postalCode
              : option.code === obj.code
          }
          onChange={(event, obj: any) => handle_change(obj)}
          onInputChange={(event, obj: any) => handle_input_change(obj)}
          onBlur={(obj: any) => handle_blur(obj)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.placeholder}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      ) : null}
    </div>
  )
}

export default ResidenceComboBox
