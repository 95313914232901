import axios from 'axios'
import { ax_post_email } from 'src/types'

import { AUTHORIZATION_BEARER, axios_request, configify, POST, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_COMMUNICATION || ''

/**
 * Communication client
 */
export const communication_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * POST email
 */
export const post_email = (
  requestId: string,
  { access_token, email, captcha }: ax_post_email
): axios_request => ({
  url: `/${VERSION_V1}/email/support`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
    captcha,
  },
  data: JSON.stringify(email),
})
