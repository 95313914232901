import React, { CSSProperties } from 'react'
import { ButtonName } from 'src/types/--deprecated/types'

import { is_valorized } from 'src/utils'
import ButtonItas from '../button/button'

import './modal.scss'

const closeButton = (config: ModalPropsDataType): JSX.Element => {
  return config.showCloseButton ? (
    <div onClick={config.closeButtonOnClick} className="Modal-CloseButton" />
  ) : (
    <div />
  )
}

const img = (config: ModalPropsDataType) => {
  return config.showImg ? <div className={'Modal-Img-' + config.imgType} /> : ''
}

const title = (config: ModalPropsDataType) => {
  return config.showTitle ? (
    <div className="Modal-Title unnamed-character-style-12-28">{config.title}</div>
  ) : (
    ''
  )
}

const text = (config: ModalPropsDataType) => {
  if (config && config.showText) {
    let alignment = ''
    if (config.textAlign) {
      alignment = '-' + config.textAlign
    }
    return (
      <div
        className={'unnamed-character-style-2 Modal-Text' + alignment}
        style={{ whiteSpace: 'pre-line' }}
      >
        {config.text}
      </div>
    )
  }
  return ''
}

const backButton = (config: ModalPropsDataType) => {
  if (config && config.showBackButton) {
    const backButtonType = config.backButtonType ? config.backButtonType : 'text'
    const backButtonColor = config.backButtonColor
      ? config.backButtonColor
      : '--unnamed-color-862633'

    return (
      <ButtonItas onClick={config.backButtonOnClick} name={backButtonType} color={backButtonColor}>
        {config.backButtonText}
      </ButtonItas>
    )
  }
  return ''
}

const nextButton = (config: ModalPropsDataType) => {
  if (config && config.showNextButton) {
    const nextButtonType = config.nextButtonType ? config.nextButtonType : 'full_862633'
    const nextButtonTextColor = config.nextButtonTextColor
      ? config.nextButtonTextColor
      : '--unnamed-color-ffffff'
    const nextButtonColor = config.nextButtonColor
      ? config.nextButtonColor
      : '--unnamed-color-059881'
    return (
      <ButtonItas
        onClick={config.nextButtonOnClick}
        name={nextButtonType}
        color={nextButtonTextColor}
        background_color={nextButtonColor}
      >
        {config.nextButtonText}
        <div className="right-arrow-white" />
      </ButtonItas>
    )
  }
  return ''
}

const bottomTextElements = (bottomText: Array<BottomTextElementType>) => {
  const bottomTextElements = Array<JSX.Element>()

  if (is_valorized(bottomText) && bottomText.length > 0) {
    bottomText.forEach((element) => {
      bottomTextElements.push(createBottomTextElement(element))
    })
  }
  return { bottomTextElements }
}

function createBottomTextElement(element: BottomTextElementType): JSX.Element {
  if (element && element.showBottomText) {
    const bottomTextIcon = element.bottomTextIcon ? '-' + element.bottomTextIcon : undefined
    const bottomTextIconAlign = element.bottomTextIconAlign ? element.bottomTextIconAlign : ''

    return (
      <div className="Modal-BottomText .unnamed-character-style-37">
        {bottomTextIcon && bottomTextIconAlign === 'left' ? (
          <div className={'Modal-BottomText-Icon' + bottomTextIcon} />
        ) : (
          ''
        )}
        <div className="Modal-BottomText-text" />
        {bottomTextIcon && bottomTextIconAlign === 'right' ? (
          <div className={'Modal-BottomText-Icon' + bottomTextIcon} />
        ) : (
          ''
        )}
      </div>
    )
  }
  return <div />
}

interface ModalPropsType {
  onClick?: () => void;
  data: ModalPropsDataType;
}

export interface ModalPropsDataType {
  // 'X' Close button
  showCloseButton: boolean;
  closeButtonOnClick?: () => void;
  // Img
  showImg: boolean;
  imgType: string;
  // Title
  showTitle: boolean;
  title: string;
  // Text
  showText: boolean;
  text: string;
  extra?: JSX.Element;
  textAlign?: string;
  // Back Button
  showBackButton: boolean;
  backButtonType?: ButtonName;
  backButtonColor?: string;
  backButtonText?: string;
  backButtonOnClick?: () => void;

  bottomTextsElements?: Array<BottomTextElementType>;

  // Next Button
  showNextButton: boolean;
  nextButtonType?: ButtonName;
  nextButtonColor?: string;
  nextButtonText?: string;
  nextButtonTextColor?: string;
  nextButtonOnClick?: () => void;
}

type BottomTextElementType = {
  // Bottom Text Element [with optional icon]
  showBottomText?: boolean,
  bottomTextText?: string,
  bottomTextIcon?: 'telephone' | 'email',
  bottomTextIconAlign?: 'right' | 'left',
  bottomTextStyle?: CSSProperties,
}

export const modalProps_genericError: ModalPropsType = {
  data: {
    showCloseButton: true,
    showImg: true,
    imgType: 'Alert',
    showTitle: true,
    title: 'title',
    showText: true,
    text: 'text',
    textAlign: 'center',
    showBackButton: false,
    backButtonType: 'text',
    backButtonText: 'CANCELLA',
    showNextButton: true,
    nextButtonType: 'full_862633',
    nextButtonText: 'button',
  },
}

export function createElement(data: ModalPropsDataType, onClick?: () => void): JSX.Element {
  const modalContent = (
    <div className="Modal" onClick={onClick}>
      <div className="Modal-Content-Row-CloseButton">
        {data.showCloseButton ? closeButton(data) : ''}
      </div>
      <div className="Modal-Content">
        {data.showImg ? <div className="Modal-Content-Row">{img(data)}</div> : ''}
        {data.showTitle ? <div className="Modal-Content-Row">{title(data)}</div> : ''}
        {data.showText ? <div className="Modal-Content-Row">{text(data)}</div> : ''}

        {data.extra && (
          <div className="Modal-Content-Row Modal-Content-Row-extra">{data.extra}</div>
        )}

        {data.showBackButton || data.showNextButton ? (
          <div className="Modal-Content-Row">
            {data.showBackButton ? <div className="Modal-Content-Row">{backButton(data)}</div> : ''}
            {data.showBackButton && data.showNextButton ? (
              <div className="Modal-Space-between-buttons" />
            ) : (
              ''
            )}
            {data.showNextButton ? <div className="Modal-Content-Row">{nextButton(data)}</div> : ''}
          </div>
        ) : (
          ''
        )}

        {data.bottomTextsElements && data.bottomTextsElements.length > 0 ? (
          <div className="Modal-Content-Row">{bottomTextElements(data.bottomTextsElements)}</div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
  return modalContent
}

const Modal: React.FC<ModalPropsType> = (props: ModalPropsType) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        display: 'flex',
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: 2,
        left: '0',
        top: '0',
        background: 'var(--unnamed-color-00000080) 0% 0% no-repeat padding-box',
        alignItems: 'center',
      }}
    >
      {createElement(props.data, props.onClick)}
    </div>
  )
}

export default Modal
