import {
  get_retrieve_dati_contraente_from_num_ordine,
  get_retrieve_dati_contraente_from_num_polizza,
  get_retrieve_dati_contraente_from_num_polizza_vita,
  post_decode_datamatrix,
  post_recovery_client_id,
  post_recovery_password,
  post_user,
  user_client,
} from 'src/axios'

import {
  ax_get_retrieve_dati_contraente_from_num_ordine,
  ax_get_retrieve_dati_contraente_from_num_polizza,
  ax_get_retrieve_dati_contraente_from_num_polizza_vita,
  ax_post_decode_datamatrix,
  ax_post_recovery_client_id,
  ax_post_recovery_password,
  ax_post_user,
  dati_contraente_t,
  decoded_datamatrix_t,
  response_t,
  saga_act,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Register user
 */
export async function http_register_user({
  args,
  type,
}: saga_act<ax_post_user>): Promise<response_t<string>> {
  return do_request<string>({
    client: user_client,
    request: post_user(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Recovery client id
 */
export async function http_recovery_client_id({
  args,
  type,
}: saga_act<ax_post_recovery_client_id>): Promise<response_t<string>> {
  return do_request<string>({
    client: user_client,
    request: post_recovery_client_id(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Recovery password
 */
export async function http_recovery_password({
  args,
  type,
}: saga_act<ax_post_recovery_password>): Promise<response_t<string>> {
  return do_request<string>({
    client: user_client,
    request: post_recovery_password(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Retrieve Dati Contraente
 */
export async function http_retrieve_dati_contraente_from_num_ordine({
  args,
  type,
}: saga_act<ax_get_retrieve_dati_contraente_from_num_ordine>): Promise<
  response_t<dati_contraente_t>
> {
  return do_request<dati_contraente_t>({
    client: user_client,
    request: get_retrieve_dati_contraente_from_num_ordine(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}
export async function http_retrieve_dati_contraente_from_num_polizza({
  args,
  type,
}: saga_act<ax_get_retrieve_dati_contraente_from_num_polizza>): Promise<
  response_t<dati_contraente_t>
> {
  return do_request<dati_contraente_t>({
    client: user_client,
    request: get_retrieve_dati_contraente_from_num_polizza(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

export async function http_retrieve_dati_contraente_from_num_polizza_vita({
  args,
  type,
}: saga_act<ax_get_retrieve_dati_contraente_from_num_polizza_vita>): Promise<
  response_t<dati_contraente_t>
> {
  return do_request<dati_contraente_t>({
    client: user_client,
    request: get_retrieve_dati_contraente_from_num_polizza_vita(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

export async function http_decode_datamatrix({
  args,
  type,
}: saga_act<ax_post_decode_datamatrix>): Promise<response_t<decoded_datamatrix_t>> {
  return do_request<decoded_datamatrix_t>({
    client: user_client,
    request: post_decode_datamatrix(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}
