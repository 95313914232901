import React from 'react'
import { ClassNames } from 'src/utils/--deprecated'
import { CheckBoxProps } from 'src/types/--deprecated/types'
import './check-box.scss'

//CheckBox component

const CheckBox: React.FC<CheckBoxProps> = (props: CheckBoxProps) => {
  return (
    <div
      className="checkbox"
      style={{ cursor: props?.readonly ? 'default' : 'pointer' }}
      onClick={() => {
        if (!props?.readonly) {
          props?.checked?.(props?.id || '', !!props?.selected)
        }
      }}
    >
      <div className="checkbox-empty-square">
        <div
          className={ClassNames(
            'checkbox-full-square',
            props?.readonly ? 'checkbox-full-square--readonly' : '',
            !props.selected ? 'unselected_checkbox' : ''
          )}
        >
          <div className="checkbox-check-icon" />
        </div>
      </div>
      <div className={props.item_style}>{props.text}</div>
    </div>
  )
}

export default CheckBox
