import axios from 'axios'
import { ax_get_vouchers, ax_post_voucher } from 'src/types'

import { AUTHORIZATION_BEARER, axios_request, configify, GET, POST, VERSION_V1 } from './base.axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_VOUCHER || ''
const CONTEXT_ROOT = 'vouchers'

/**
 * Voucher client
 */
export const voucher_client = axios.create(configify({ base_url: BASE_URL }))

/**
 * GET vouchers
 */
export const get_vouchers = (
  requestId: string,
  { access_token }: ax_get_vouchers
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}`,
  method: GET,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
})

/**
 * Download voucher
 */
export const download_voucher = (
  requestId: string,
  { access_token, ...data }: ax_post_voucher
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/print`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data,
})

/**
 * Send voucher
 */
export const send_voucher = (
  requestId: string,
  { access_token, ...data }: ax_post_voucher
): axios_request => ({
  url: `/${VERSION_V1}/${CONTEXT_ROOT}/send`,
  method: POST,
  headers: {
    requestId,
    Authorization: `${AUTHORIZATION_BEARER}${access_token}`,
  },
  data,
})
