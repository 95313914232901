import i18n from 'src/i18n'
import { regex_date, regex_otp } from 'src/regex'
import { dict } from 'src/types'
import * as Yup from 'yup'

export const ynum = Yup.number()
export const ystr = Yup.string()
export const ystr_required = ystr.required(i18n.t('commons.required'))
export const yemail = Yup.string().email(i18n.t('commons.email_invalid'))
export const yemail_required = yemail.required(i18n.t('commons.email_required'))
export const ydate = ystr.matches(regex_date, i18n.t('commons.invalid_date'))
export const ydate_required = ydate.required(i18n.t('commons.required'))
export const yotp = ystr.matches(regex_otp, i18n.t('commons.invalid_otp'))
export const yotp_required = yotp.required(i18n.t('commons.required'))

export const is_required = (schema?: Yup.AnyObjectSchema, key = ''): boolean => {
  return schema?.fields?.[key]?.exclusiveTests?.required
}

export const formikify =
  (args: {
    get_field_props: (key: string) => unknown,
    schema?: Yup.AnyObjectSchema,
    errors?: dict,
    touched?: dict,
    classes?: string,
  }): ((id: string) => dict) =>
  (id: string): dict => {
    return {
      id,
      formik_props: args?.get_field_props(id),
      formik_schema: args?.schema,
      error: args?.errors?.[id],
      touched: args?.touched?.[id],
      classes: args?.classes,
    }
  }
