import {
  customer_client,
  delete_contacts,
  get_access,
  get_addresses,
  get_associated_customers,
  get_caps,
  get_consents,
  get_contacts,
  get_customer,
  get_districts,
  get_language,
  get_localities,
  get_municipalities,
  get_nations,
  get_toponyms,
  post_consents,
  put_access,
  put_customer,
  put_language,
} from 'src/axios'
import { language_w, option_w, outcome_w } from 'src/backend-codes'
import {
  addr_t,
  ax_delete_contacts,
  ax_get_access,
  ax_get_addresses,
  ax_get_associated_customers,
  ax_get_caps,
  ax_get_consents,
  ax_get_contacts,
  ax_get_customer,
  ax_get_districts,
  ax_get_language,
  ax_get_localities,
  ax_get_municipalities,
  ax_get_nations,
  ax_post_consents,
  ax_put_access,
  ax_put_customer,
  ax_put_language,
  cap_t,
  consent_t,
  contact_t,
  customer_outcome_t,
  customer_t,
  district_t,
  locality_t,
  municipality_t,
  nation_t,
  response_t,
  saga_act,
  toponym_t,
} from 'src/types'
import { v4 } from 'uuid'

import { do_request } from './base.http'

/**
 * Resolve customer
 */
export async function http_resolve_customer({
  args,
  type,
}: saga_act<ax_get_customer>): Promise<response_t<customer_t>> {
  return do_request<customer_t>({
    client: customer_client,
    request: get_customer(v4(), args),
    child: 'customer',
    saga_type: type,
  })
}

/**
 * Update customer
 */
export async function http_update_customer({
  args,
  type,
}: saga_act<ax_put_customer>): Promise<response_t<customer_outcome_t>> {
  return do_request<customer_outcome_t>({
    client: customer_client,
    request: put_customer(v4(), args),
    child: '',
    saga_type: type,
  })
}

/**
 * Resolve contacts
 */
export async function http_resolve_contacts({
  args,
  type,
}: saga_act<ax_get_contacts>): Promise<response_t<contact_t>> {
  return do_request<contact_t>({
    client: customer_client,
    request: get_contacts(v4(), args),
    child: 'contacts',
    saga_type: type,
  })
}

/**
 * Remove contacts
 */
export async function http_remove_contacts({
  args,
  type,
}: saga_act<ax_delete_contacts>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: customer_client,
    request: delete_contacts(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Resolve consents
 */
export async function http_resolve_consents({
  args,
  type,
}: saga_act<ax_get_consents>): Promise<response_t<consent_t>> {
  return do_request<consent_t>({
    client: customer_client,
    request: get_consents(v4(), args),
    child: 'consentsTD',
    saga_type: type,
  })
}

/**
 * Update consents
 */
export async function http_update_consents({
  args,
  type,
}: saga_act<ax_post_consents>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: customer_client,
    request: post_consents(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Resolve language
 */
export async function http_resolve_language({
  args,
  type,
}: saga_act<ax_get_language>): Promise<response_t<language_w>> {
  return do_request<language_w>({
    client: customer_client,
    request: get_language(v4(), args),
    child: 'language',
    saga_type: type,
  })
}

/**
 * Update language
 */
export async function http_update_language({
  args,
  type,
}: saga_act<ax_put_language>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: customer_client,
    request: put_language(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Resolve access
 */
export async function http_resolve_access({
  args,
  type,
}: saga_act<ax_get_access>): Promise<response_t<option_w>> {
  return do_request<option_w>({
    client: customer_client,
    request: get_access(v4(), args),
    child: 'firstAccess',
    saga_type: type,
  })
}

/**
 * Update access
 */
export async function http_update_access({
  args,
  type,
}: saga_act<ax_put_access>): Promise<response_t<outcome_w>> {
  return do_request<outcome_w>({
    client: customer_client,
    request: put_access(v4(), args),
    child: 'outcome',
    saga_type: type,
  })
}

/**
 * Resolve nation
 */
export async function http_resolve_nations({
  args,
  type,
}: saga_act<ax_get_nations>): Promise<response_t<nation_t[] | nation_t>> {
  return do_request<nation_t[] | nation_t>({
    client: customer_client,
    request: get_nations(v4(), args),
    child: args?.nation_code ? '' : 'nations',
    saga_type: type,
  })
}

/**
 * Resolve districts
 */
export async function http_resolve_districts({
  args,
  type,
}: saga_act<ax_get_districts>): Promise<response_t<district_t[]>> {
  return do_request<district_t[]>({
    client: customer_client,
    request: get_districts(v4(), args),
    child: 'districts',
    saga_type: type,
  })
}

/**
 * Resolve municipalities
 */
export async function http_resolve_municipalities({
  args,
  type,
}: saga_act<ax_get_municipalities>): Promise<response_t<municipality_t[]>> {
  return do_request<municipality_t[]>({
    client: customer_client,
    request: get_municipalities(v4(), args),
    child: 'municipalities',
    saga_type: type,
  })
}

/**
 * Resolve localities
 */
export async function http_resolve_localities({
  args,
  type,
}: saga_act<ax_get_localities>): Promise<response_t<locality_t[]>> {
  return do_request<locality_t[]>({
    client: customer_client,
    request: get_localities(v4(), args),
    child: 'localities',
    saga_type: type,
  })
}

/**
 * Resolve postal codes
 */
export async function http_resolve_caps({
  args,
  type,
}: saga_act<ax_get_caps>): Promise<response_t<cap_t[]>> {
  return do_request<cap_t[]>({
    client: customer_client,
    request: get_caps(v4(), args),
    child: 'postalCodes',
    saga_type: type,
  })
}

/**
 * Resolve toponyms
 */
export async function http_resolve_toponyms(): Promise<response_t<toponym_t[]>> {
  return do_request<toponym_t[]>({
    client: customer_client,
    request: get_toponyms(v4()),
    child: 'toponyms',
  })
}

/**
 * Resolve addresses
 */
export async function http_resolve_addresses({
  args,
  type,
}: saga_act<ax_get_addresses>): Promise<response_t<addr_t[]>> {
  return do_request<addr_t[]>({
    client: customer_client,
    request: get_addresses(v4(), args),
    child: 'addresses',
    saga_type: type,
  })
}

/**
 * Resolve associated customers
 */
export async function http_resolve_associated_customers({
  args,
  type,
}: saga_act<ax_get_associated_customers>): Promise<response_t<customer_t[]>> {
  return do_request<customer_t[]>({
    client: customer_client,
    request: get_associated_customers(v4(), args),
    child: 'users',
    saga_type: type,
  })
}
