import { FC } from 'react'
import { Image } from 'react-bootstrap'
import {
  radio_empty_disabled,
  radio_empty_enabled,
  radio_full_disabled,
  radio_full_enabled,
} from 'src/assets'
import { dict, radio_group_props } from 'src/types'
import { is_required } from 'src/utils'

const SVG: dict<string> = {
  'full-disabled': radio_full_disabled,
  'full-enabled': radio_full_enabled,
  'empty-disabled': radio_empty_disabled,
  'empty-enabled': radio_empty_enabled,
}

export const RadioGroup: FC<radio_group_props> = ({
  id,
  formik_props,
  formik_schema,
  disabled,
  label,
  options,
  classes = 'w-100',
  classes_label = 'm-0 p-0 font-16 d-flex align-items-center',
  vertically,
  on_change,
}: radio_group_props) => {
  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? 'd-block' : 'd-none'}
          ${label ? '' : 'opacity-0'}
          ${disabled ? 'c-7f7f7f' : ''}
        `}
      >
        {`${label_} `}
      </label>
    )
  }

  const render_input = () => (
    <div className={`d-flex ${vertically ? 'flex-column' : ''}`}>
      {options?.map((option, idx) => {
        const svg_1 = formik_props?.value === option?.id ? 'full' : 'empty'
        const svg_2 = disabled ? 'disabled' : 'enabled'
        const src = SVG[`${svg_1}-${svg_2}`]
        return (
          <label key={idx} role="button" className={idx > 0 && !vertically ? 'ml-5 ' : ''}>
            <input
              type="radio"
              hidden={true}
              value={option?.id}
              disabled={disabled}
              checked={formik_props?.value === option?.id}
              onChange={() => on_change?.(option?.id)}
              role="button"
            />
            <Image src={src} width={18} height={18} className="m-0 p-0 mr-2" />
            {option?.value}
          </label>
        )
      })}
    </div>
  )

  return (
    <main className={classes}>
      {render_label()}
      {render_input()}
    </main>
  )
}
