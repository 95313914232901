import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DOCUMENT_CATEGORIES, DOCUMENT_TYPES } from 'src/backend-codes'
import { docs_overview_box, text_kit } from 'src/types'
import { fill_placeholders } from 'src/utils'
import { TabBar } from 'src/widgets'

interface mobile_tab {
  title?: string;
  child?: JSX.Element;
}
interface item_t extends text_kit {
  kind: 'category' | 'type';
}

export const OverviewBox: FC<docs_overview_box> = ({
  classes = '',
  n_documents,
  n_documents_filtered,
  categories,
  types,
  on_filter,
}: docs_overview_box) => {
  const { t } = useTranslation()

  /**
   * Use State
   */
  const [mobile_tabs, set_mobile_tabs] = useState<mobile_tab[]>([])
  const [selected_item, set_selected_item] = useState({
    category: 'all-category',
    type: 'all-type',
  })

  /**
   * Use Effect
   */
  useEffect(() => {
    on_filter(selected_item)
  }, [selected_item])

  useEffect(() => {
    const mobile_tabs_ = []
    if (Object.keys(categories || {})?.length > 1) {
      mobile_tabs_.push({
        title: t('documents.categories'),
        child: render_items('category', 'mt-4'),
      })
    }

    mobile_tabs_.push({
      title: t('documents.types'),
      child: render_items('type', 'mt-4'),
    })

    set_mobile_tabs(mobile_tabs_)
  }, [categories, types])

  /**
   * Render functions
   */
  const render_items = (kind: 'category' | 'type', classes_?: string) => {
    const dict_ = kind === 'category' ? categories : types
    const maps_ = kind === 'category' ? DOCUMENT_CATEGORIES : DOCUMENT_TYPES
    const alls_ =
      kind === 'category'
        ? fill_placeholders(t('documents.all_categories'), [String(n_documents || '')])
        : fill_placeholders(t('documents.all_types'), [String(n_documents_filtered || '')])
    return (
      <div className={classes_}>
        {render_item({
          key: `all-${kind}`,
          value: alls_,
          kind,
        })}
        {Object.entries(dict_ || {}).map(([key, value]) => {
          return render_item({
            key,
            value: maps_[key] ? `${maps_[key]} (${value})` : '',
            kind,
          })
        })}
      </div>
    )
  }

  const render_item = (item: item_t) => {
    const highlight = item?.key === selected_item?.category || item?.key === selected_item?.type
    const font = highlight ? 'font-16-semibold c-159881' : 'font-16 c-7f7f7f'
    return (
      <p
        key={item?.key}
        className={`m-0 p-0 mt-2 width-fit-content ${font}`}
        role="button"
        onClick={() => {
          set_selected_item({ ...selected_item, [item?.kind]: item?.key })
        }}
      >
        {item?.value}
      </p>
    )
  }

  return (
    <main className={classes}>
      {/* DESKTOP */}
      <div className="d-none d-lg-block">
        {Object.keys(categories || {})?.length > 1 && (
          <div>
            <p className="m-0 p-0 font-14-semibold text-uppercase">{t('documents.categories')}</p>
            {render_items('category')}
            <div className="my-4 border" />
          </div>
        )}

        <div>
          <p className="m-0 p-0 font-14-semibold text-uppercase">{t('documents.types')}</p>
          {render_items('type')}
        </div>
      </div>

      {/* MOBILE */}
      <div className="d-lg-none">
        <TabBar tabs={mobile_tabs} />
      </div>
    </main>
  )
}
