import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  /**
   * Store
   */
  reduce_access: ['access'],
  reduce_onboarding_showed: ['onboarding_showed'],
  reduce_support_visibility: ['support_visibility'],
  reduce_elections_showed: ['elections_showed'],
  reduce_policies_renewed: ['policies_renewed'],
  reduce_ar_access: ['ar_access'],

  /**
   * Sagas
   */
  saga_resolve_access: [],
})

export const config_types = Types
export const config_creators = Creators
