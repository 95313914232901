import { createReducer } from 'reduxsauce'
import { data_store } from 'src/types'

import { data_types } from '../../actions'

/**
 * From customer service
 */
export const reduce_customer = (state: data_store, { customer }: data_store): data_store => ({
  ...state,
  customer,
})

export const reduce_contacts = (state: data_store, { contacts }: data_store): data_store => ({
  ...state,
  contacts,
})

export const reduce_consents = (state: data_store, { consents }: data_store): data_store => ({
  ...state,
  consents,
})

export const reduce_language = (state: data_store, { language }: data_store): data_store => ({
  ...state,
  language,
})

export const reduce_nations = (state: data_store, { nations }: data_store): data_store => ({
  ...state,
  nations,
})

export const reduce_districts = (state: data_store, { districts }: data_store): data_store => ({
  ...state,
  districts,
})

export const reduce_municipalities = (
  state: data_store,
  { municipalities }: data_store
): data_store => ({
  ...state,
  municipalities,
})

export const reduce_localities = (state: data_store, { localities }: data_store): data_store => ({
  ...state,
  localities,
})

export const reduce_caps = (state: data_store, { caps }: data_store): data_store => ({
  ...state,
  caps,
})

export const reduce_toponyms = (state: data_store, { toponyms }: data_store): data_store => ({
  ...state,
  toponyms,
})

export const reduce_addresses = (state: data_store, { addresses }: data_store): data_store => ({
  ...state,
  addresses,
})

export const reduce_associated_customers = (
  state: data_store,
  { associated_customers }: data_store
): data_store => ({
  ...state,
  associated_customers,
})

/**
 * From activity service
 */
export const reduce_history_addresses = (
  state: data_store,
  { history_addresses }: data_store
): data_store => ({
  ...state,
  history_addresses,
})

export const reduce_history_requests = (
  state: data_store,
  { history_requests }: data_store
): data_store => ({
  ...state,
  history_requests,
})

/**
 * From policies service
 */
export const reduce_policies = (state: data_store, { policies }: data_store): data_store => ({
  ...state,
  policies,
})

export const reduce_policy_detail = (
  state: data_store,
  { policy_detail }: data_store
): data_store => ({
  ...state,
  policy_detail,
})

export const reduce_policies_assistance = (
  state: data_store,
  { policies_assistance }: data_store
): data_store => ({
  ...state,
  policies_assistance,
})

/**
 * From documents service
 */
export const reduce_documents = (state: data_store, { documents }: data_store): data_store => ({
  ...state,
  documents,
})

export const reduce_documents_errors = (
  state: data_store,
  { documents_errors }: data_store
): data_store => ({
  ...state,
  documents_errors,
})

export const reduce_documents_policy = (
  state: data_store,
  { documents_policy }: data_store
): data_store => ({
  ...state,
  documents_policy,
})

export const reduce_documents_institutional = (
  state: data_store,
  { documents_institutional }: data_store
): data_store => ({
  ...state,
  documents_institutional,
})

export const reduce_document = (state: data_store, { document }: data_store): data_store => ({
  ...state,
  document,
})

/**
 * From agencies service
 */
export const reduce_agencies = (state: data_store, { agencies }: data_store): data_store => ({
  ...state,
  agencies,
})

/**
 * From voucher service
 */
export const reduce_vouchers = (state: data_store, { vouchers }: data_store): data_store => ({
  ...state,
  vouchers,
})

export const reduce_voucher_document = (
  state: data_store,
  { voucher_document }: data_store
): data_store => ({
  ...state,
  voucher_document,
})

/**
 * From poll service
 */
export const reduce_poll = (state: data_store, { poll }: data_store): data_store => ({
  ...state,
  poll,
})

/**
 * From user service
 */
export const reduce_dati_contraente = (
  state: data_store,
  { dati_contraente }: data_store
): data_store => ({
  ...state,
  dati_contraente,
})

export const reduce_decoded_datamatrix = (
  state: data_store,
  { decoded_datamatrix }: data_store
): data_store => ({
  ...state,
  decoded_datamatrix,
})

export default createReducer(
  {},
  {
    [data_types.REDUCE_CUSTOMER]: reduce_customer,
    [data_types.REDUCE_CONTACTS]: reduce_contacts,
    [data_types.REDUCE_CONSENTS]: reduce_consents,
    [data_types.REDUCE_LANGUAGE]: reduce_language,
    [data_types.REDUCE_NATIONS]: reduce_nations,
    [data_types.REDUCE_DISTRICTS]: reduce_districts,
    [data_types.REDUCE_MUNICIPALITIES]: reduce_municipalities,
    [data_types.REDUCE_LOCALITIES]: reduce_localities,
    [data_types.REDUCE_CAPS]: reduce_caps,
    [data_types.REDUCE_TOPONYMS]: reduce_toponyms,
    [data_types.REDUCE_ADDRESSES]: reduce_addresses,
    [data_types.REDUCE_ASSOCIATED_CUSTOMERS]: reduce_associated_customers,
    [data_types.REDUCE_HISTORY_ADDRESSES]: reduce_history_addresses,
    [data_types.REDUCE_HISTORY_REQUESTS]: reduce_history_requests,
    [data_types.REDUCE_POLICIES]: reduce_policies,
    [data_types.REDUCE_POLICY_DETAIL]: reduce_policy_detail,
    [data_types.REDUCE_DOCUMENTS]: reduce_documents,
    [data_types.REDUCE_DOCUMENTS_ERRORS]: reduce_documents_errors,
    [data_types.REDUCE_DOCUMENTS_POLICY]: reduce_documents_policy,
    [data_types.REDUCE_DOCUMENTS_INSTITUTIONAL]: reduce_documents_institutional,
    [data_types.REDUCE_DOCUMENT]: reduce_document,
    [data_types.REDUCE_AGENCIES]: reduce_agencies,
    [data_types.REDUCE_VOUCHERS]: reduce_vouchers,
    [data_types.REDUCE_VOUCHER_DOCUMENT]: reduce_voucher_document,
    [data_types.REDUCE_POLICIES_ASSISTANCE]: reduce_policies_assistance,
    [data_types.REDUCE_POLL]: reduce_poll,
    [data_types.REDUCE_DATI_CONTRAENTE]: reduce_dati_contraente,
    [data_types.REDUCE_DECODED_DATAMATRIX]: reduce_decoded_datamatrix,
  }
)
