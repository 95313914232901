import { call, put } from 'redux-saga/effects'
import { http_check_payment, http_generate_iban, http_make_payment } from 'src/http'
import { iban_outcome_t, payment_outcome_t, saga_act } from 'src/types'

import { shoot } from '../actions'
import { saga_do_request } from './base.saga'

/**
 * Generate IBAN
 */
export function* saga_generate_iban(action: saga_act): unknown {
  const outcome: iban_outcome_t = yield call(saga_do_request, http_generate_iban, {
    ...action,
  })
  if (outcome) {
    yield put(shoot.reduce_outcome_iban(outcome))
  }
}

/**
 * Make payment
 */
export function* saga_make_payment(action: saga_act): unknown {
  const outcome: payment_outcome_t = yield call(saga_do_request, http_make_payment, {
    ...action,
  })
  if (outcome) {
    yield put(shoot.reduce_outcome_payment(outcome))
  }
}

/**
 * Check payment
 */
export function* saga_check_payment(action: saga_act): unknown {
  const outcome: payment_outcome_t = yield call(saga_do_request, http_check_payment, {
    ...action,
  })
  if (outcome) {
    yield put(shoot.reduce_outcome_payment_status(outcome))
  }
}
