import { option_w } from 'src/backend-codes'
import { dict, opt, redux_store } from 'src/types'

export const select_access = (state: redux_store): opt<option_w> => state?.config?.access

export const select_onboarding_showed = (state: redux_store): opt<boolean> =>
  state?.config?.onboarding_showed

export const select_support_visibility = (state: redux_store): opt<boolean> =>
  state?.config?.support_visibility

export const select_elections_showed = (state: redux_store): opt<boolean> =>
  state?.config?.elections_showed

export const select_policies_renewed = (state: redux_store): opt<dict<string>> =>
  state?.config?.policies_renewed

export const select_ar_access = (state: redux_store): opt<boolean> => state?.config?.ar_access
