import './layout.scss'

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Support from 'src/containers/support/support'
import { shoot } from 'src/redux/actions'
import { select_support_visibility } from 'src/redux/store'
import { ClassNames } from 'src/utils/--deprecated'
import Backdrop from 'src/widgets/--deprecated/backdrop/backdrop'
import Footer from 'src/widgets/--deprecated/footer/footer'
import Header from 'src/widgets/--deprecated/header/header'

// Component to manage the homepage.
// Formed by the toolbar (Header), the child elements (in this case the Body) and the Footer

const Layout: React.FC = (props) => {
  const [backdrop, setBackdrop] = useState(false)

  // const history = useHistory()
  // const [visibleButton, setVisibleButton] = useState(true)

  // const funtionTemp = () => {
  //   history.push(NAV_TO_ON_BOARDING)
  //   setVisibleButton(true)
  // }

  const showBackdropHandler = () => {
    setBackdrop(!backdrop)
  }

  // Modal Support 'Scrivi a ITAS' manage state variable and methods
  const show_modal_support = useSelector(select_support_visibility)

  const dispatch = useDispatch()
  // const open_modal_support_handler = () => {
  //   dispatch(shoot.reduce_support_visibility(true))
  //   window.scrollTo(0, 0)
  // }
  const close_modal_support_handler = () => {
    dispatch(shoot.reduce_support_visibility(false))
  }

  return (
    <Card>
      <Support show={show_modal_support} handleClose={close_modal_support_handler} />
      <div className="layout--header" onClick={() => (backdrop ? setBackdrop(false) : null)}>
        <Header backdrop_open={backdrop} showBackdrop={showBackdropHandler} />
      </div>
      <div className={ClassNames('layout--body', backdrop ? '--show-backdrop' : null)}>
        {props.children}
      </div>
      <div className="layout--footer">
        <Footer />
      </div>
      <Backdrop show={backdrop} clicked={showBackdropHandler} />
    </Card>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
