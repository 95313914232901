/**
 * This file contains all the codes used by the back-end
 */

import {
  boat_bg,
  boat_icon,
  car_bg,
  car_icon,
  enterprise_bg,
  enterprise_icon,
  house_bg,
  house_icon,
  pension_bg,
  pension_icon,
  people_bg,
  people_icon,
  saving_bg,
  saving_icon,
  scooter_bg,
  scooter_icon,
  tractor_bg,
  tractor_icon,
} from 'src/assets'
import i18n from 'src/i18n'
import { dict, policy_t, text_kit } from 'src/types'

/**
 * Error codes
 */
export const BE_ERROR_CODES: dict<string> = {
  //LOGIN
  INVALID_USERNAME: '401000105',
  INVALID_PASSWORD: '401000107',
  PASSWORD_EXPIRED: '401000119',
  INVALID_PASSWORD_LAST_ATTEMPT: '401000121',
  INVALID_PASSWORD_USER_BLOCKED: '401000122',
  LOGIN_USER_BLOCKED: '401000123',
  UNVERIFIED_EMAIL: '401000140',

  SERVICE_UNAVAILABLE: '500000113',
  AUTHENTICATION_ERROR_1: '500000120',
  AUTHENTICATION_ERROR_2: '401000120',
  MAGIC_LINK_EXPIRED: '403000124',
  MAGIC_LINK_INVALIDATED: '400000125',
  USER_DUPLICATE_LOGIN: '403000148',

  //REGISTRATION
  EMAIL_ALREADY_REGISTERED_1: '403001110',
  EMAIL_ALREADY_REGISTERED_2: '403001111',
  WRONG_OR_ABSENT_POLICY_ID: '404003103',
  WRONG_USER_DATA_1: '500003104',
  WRONG_USER_DATA_2: '404003105',
  WRONG_TAXCODE_OR_VATCODE: '403003107',
  INACTIVE_POLICY: '403003106',
  INVALID_POLICY: '403003108',
  LANGUAGE_NOT_FOUND: '404004128',
  SEARCH_POLICY: '500003115',

  CUSTOMER_CHECK: '500004103', // TO CHECK

  //RECOVERY CREDENTIAL
  PROCLIE_RECOVERY: '500001112',
  PASSWORD_RECOVERY: '500001121',
  RECOVERY_USER_NOT_FOUND: '404001113',
  USER_EXTERNAL_SERVICE: '500001102',
  NEW_PASSWORD_INVALID: '403000135',
  CHANGE_PASSWORD: '500001105',
  ACCESS_DATA_NOT_FOUND: '403001106',
  USER_BLOCKED: '403001107',
  ACCESS_DENIED_USER_BLOCKED: '403000123',
  USER_DUPLICATE_RECOVERY: '403001125',

  //PASSWORD EXPIRED
  PW_RESET_INVALID_CREDENTIAL: '401000142',

  //PASSWORD EXPIRED
  PW_EXP_INVALID_CREDENTIAL: '401000133',
  PW_EXP_USER_NOT_FOUND: '404000134',

  //OTP
  INVALID_OTP1: '500004116', // customer
  INVALID_OTP2: '400004116', // customer
  INVALID_OTP3: '500003121', // policy
  EXPIRED_OTP1: '500004117', // customer
  EXPIRED_OTP2: '500003122', // policy

  //DOCUMENT
  DOC_EXTERNAL_SERVICE: '500010100',
  DOC_ANT_DANNI_LIST_DEDUCTIONS: '500010101',
  DOC_USER_NOT_FOUND: '404010102',
  DOC_ANT: '500010103',
  DOC_ANT_DANNI_LIST_RISK: '500010104',
  DOC_ANT_VITA_LIST_COMUN_FIN: '500010105',
  DOC_ANT_VITA_LIST_LETT_DETRAZ: '500010106',
  DOC_SERVICE: '500010108',
  DOC_ANT_DANNI_STAMPE: '500010109',
  DOC_ANT_VITA_STAMPE: '500010110',
  DOC_DETAIL: '500010111',
  DOC_DETAIL_VITA_COMUN_FIN: '500010112',
  DOC_DETAIL_VITA_LETT_DETRAZ: '500010113',
  DOC_DETAIL_VITA_ESTR_CONT: '500010114',
  DOC_DETAIL_VITA_STAMPA: '500010115',
  DOC_DETAIL_NOT_FOUND: '404010116',
  DOC_DETAIL_DANNI_LETT_DETRAZ: '500010117',
  DOC_DETAIL_DANNI_AVVISI: '500010118',
  DOC_ANT_DANNI_LIST_AVVISI: '500010119',
  DOC_DETAIL_DANNI_ATTESTATO: '500010120',
  DOC_DETAIL_DANNI_ATTESTATO_COMPANY: '500010126',
  DOC_DETAIL_DANNI_ATTESTATO_NOT_AVAILABLE: '404010127',
  DOC_DETAIL_DANNI_STAMPE: '500010121',
  DOC_DETAIL_DANNI_STAMPE_CGA: '500010122',
  DOC_LISTA_INSTITUZIONALI: '500010124',
  DOC_UNAUTHORIZED: '401010128',

  //PASSWORD
  PASSWORD_POLICY_INVALID: '403001108',
  CHANGE_PW_POLICY_INVALID: '403000143',

  //GENERIC
  UNAUTHORIZED: '401000120',

  //PAYMENT
  PA_CHECK_PAYMENT_KO: '500007117',
  PA_PAYMENT_NOT_FOUND: '404007118',
  PA_CHECK_PAYMENT_KO_REJECTED: '500007120',
  PA_CHECK_PAYMENT_EXTERNAL_ESEGUI_INC: '500007119',
  PA_CHECK_PAYMENT: '500007115',
}

//NEW

//403001108 si / no tech  -> TO CHECK

// NOT_FOUND: '400000001',
//400000001 si / si tech  / come mappare i bad request -> non trovato
//500000102 si / si tech  / generic error
//401000102 si / si tech /  generic error

//400000104 si / si tech /  generic error
//401000106 si / si tech /  generic error
//401000111 si / si tech /  generic error
//500000116 si / si tech /  generic error
//500000117 si / si tech /  generic error
//500000118 si / si tech /  generic error

/**
 * Severity error codes
 */
export const SEVERITY_CODES: dict<string> = {
  FATAL: 'fatal',
  ERROR: 'error',
  WARNING: 'warning',
  INFORMATIONAL: 'informational',
}

/**
 * Contact codes
 */
export const CONTACT_CODES: dict<number> = {
  EMAIL_AREA_RISERVATA: 26,
  CELLULARE_AREA_RISERVATA: 31,
  EMAIL: 2,
  EMAIL_SEPA: 27,
  EMAIL_PEC: 10,
  CELLULARE: 1,
  OTP: 29,
  PAGAMENTO: 30,
  TELEFONO_ABITAZIONE: 3,
  TELEFONO_UFFICIO: 9,
}

/**
 * Company codes
 */
export const COMPANY_CODES: dict<string> = {
  '022': 'MUTUA',
  '183': 'VITA',
  '178': 'VALPIAVE',
}

/**
 * Origin codes
 */
export const ORIGIN_CODES: dict<string> = {
  ANAGRAFICA_ITAS360: i18n.t('profile.personal_data_section.card_2.origin_anagrafica_itas360'),
  PORTAFOGLIO_ITAS360: i18n.t('profile.personal_data_section.card_2.origin_portafoglio_itas360'),
  AREA_RISERVATA: i18n.t('profile.personal_data_section.card_2.origin_area_riservata'),
  DIR: i18n.t('profile.personal_data_section.card_2.origin_dir'),
  EVO: i18n.t('profile.personal_data_section.card_2.origin_evo'),
  ASIA: i18n.t('profile.personal_data_section.card_2.origin_asia'),
  EMAIL: i18n.t('profile.personal_data_section.card_2.origin_email'),
  WEB: i18n.t('profile.personal_data_section.card_2.origin_web'),
  ECO: i18n.t('profile.personal_data_section.card_2.origin_eco'),
  ANAGRAFICA_CENTRALIZZATA: i18n.t(
    'profile.personal_data_section.card_2.origin_anagrafica_centralizzata'
  ),
  CRM: i18n.t('profile.personal_data_section.card_2.origin_crm'),
  ALTRO: i18n.t('profile.personal_data_section.card_2.origin_altro'),
}

/**
 * Policy codes
 */
export const POLICY_CATEGORY_KEYS = (category_code: string, sector_code?: string): string => {
  if (category_code === '06') {
    if (sector_code === '1' || sector_code === '2' || sector_code === '5')
      return `06-${sector_code}`
    if (sector_code === '8' || sector_code === '9') return '06-8'
    return '06-x'
  }
  return category_code
}

export const POLICY_CATEGORIES: dict<string> = {
  '01': i18n.t('policy_categories.savings'),
  '02': i18n.t('policy_categories.pension'),
  '03': i18n.t('policy_categories.person'),
  '04': i18n.t('policy_categories.company'),
  '05': i18n.t('policy_categories.home'),
  '06': i18n.t('policy_categories.car'),
  '06-1': i18n.t('policy_categories.car'),
  '06-2': i18n.t('policy_categories.car'),
  '06-5': i18n.t('policy_categories.motorcycle'),
  '06-8': i18n.t('policy_categories.other'),
  '06-x': i18n.t('policy_categories.other'),
}

export const POLICY_BACKGROUNDS: dict<string> = {
  '01': saving_bg,
  '02': pension_bg,
  '03': people_bg,
  '04': enterprise_bg,
  '05': house_bg,
  '06-1': car_bg,
  '06-2': car_bg,
  '06-5': scooter_bg,
  '06-8': boat_bg,
  '06-x': tractor_bg,
}

export const POLICY_ICONS: dict<string> = {
  '01': saving_icon,
  '02': pension_icon,
  '03': people_icon,
  '04': enterprise_icon,
  '05': house_icon,
  '06-1': car_icon,
  '06-2': car_icon,
  '06-5': scooter_icon,
  '06-8': boat_icon,
  '06-x': tractor_icon,
}

export const POLICY_STATES: dict<text_kit> = {
  '01': { color: '00a720', value: i18n.t('policy_detail.state_active') },
  '02': { color: 'eb5a00', value: i18n.t('policy_detail.state_expiring') },
  '03': { color: 'a90433', value: i18n.t('policy_detail.state_expired') },
  '04': { color: '15819e', value: i18n.t('policy_detail.state_suspended') },
  '05': { color: '15819e', value: i18n.t('policy_detail.state_reversed') },
  '06': { color: '00a720', value: i18n.t('policy_detail.state_payed') },
  '07': { color: 'a90433', value: i18n.t('policy_detail.state_not_payed') },
}

export const POLICY_OPERATIONS: dict<string> = {
  CONFIRMED: i18n.t('commons.request_states.confirmed'),
  FORWARDED: i18n.t('commons.request_states.forwarded'),
  TO_CONFIRM: i18n.t('commons.request_states.to_confirm'),
}

export const POLICY_COMPANIES = (policy?: policy_t): string => {
  return policy?.policyType === 'VITA' ? 'VITA' : COMPANY_CODES[policy?.company || '']
}

/**
 * Document codes
 */
export const DOCUMENT_CATEGORIES: dict<string> = {
  ITAS_MUTUA: i18n.t('documents.type_mutua'),
  ITAS_VITA: i18n.t('documents.type_vita'),
  ITAS_VALPIAVE: i18n.t('documents.type_valpiave'),
}

export const DOCUMENT_TYPES: dict<string> = {
  LETTERA_DETRAZIONE: i18n.t('documents.category_lettera_di_detrazione'),
  ESTRATTO_CONTO: i18n.t('documents.category_estratto_conto'),
  COMUNICAZIONE_FINALE: i18n.t('documents.category_comunicazioni_finali'),
  AVVISO_SCADENZA: i18n.t('documents.category_avviso_di_scadenza'),
  ATTESTATO_RISCHIO: i18n.t('documents.category_attestato_di_rischio'),
  CGA: i18n.t('documents.category_cga'),
  STAMPA: i18n.t('documents.category_stampa'),
  ASSEMBLEA_PARZIALE: i18n.t('documents.category_assemblea_parziale'),
  ASSEMBLEA_GENERALE: i18n.t('documents.category_assemblea_generale'),
  STATUTO_ITAS_MUTUA: i18n.t('documents.category_statuto_itas_mutua'),
  INFORMATIVA: i18n.t('documents.category_informativa'),
  QUIETANZA: i18n.t('documents.category_quietanza'),
}

/**
 * Request codes
 */
export const REQUEST_TYPE: dict<string> = {
  SOSPENSIONE: i18n.t('requests_history.suspension'),
  RIATTIVAZIONE: i18n.t('requests_history.reactivation'),
  LIQUIDAZIONE_SINISTRO: i18n.t('requests_history.claim_settlement'),
  RISCATTO: i18n.t('requests_history.redemption'),
  VERSAMENTO_AGGIUNTIVO: i18n.t('requests_history.additional_payment'),
}

export const REQUEST_STATE: dict<string> = {
  CONFIRMED: i18n.t('commons.request_states.confirmed'),
  FORWARDED: i18n.t('commons.request_states.forwarded'),
  TO_CONFIRM: i18n.t('commons.request_states.to_confirm'),
  CANCEL: i18n.t('commons.request_states.cancel'),
}

/**
 * Agency codes
 */
export const AGENCY_TYPES: dict<string> = {
  AGENZIA: i18n.t('agencies.agency'),
  BROKER: i18n.t('agencies.broker'),
  BANCA: i18n.t('agencies.bank'),
}

/**
 * Splitting up codes
 */
export const SPLITTING_UP_TYPES: dict<string> = {
  ANNUALE: i18n.t('policy_detail.splitting_up_annual'),
  SEMESTRALE: i18n.t('policy_detail.splitting_up_half_yearly'),
}

/**
 * GTM classes
 */
export const GTM_CLASSES: dict<string> = {
  SOSPENSIONE: 'gtm-otp-suspension',
  RISCATTO: 'gtm-otp-redemption',
  VERSAMENTO_AGGIUNTIVO: 'gtm-otp-deposit',
  LIQUIDAZIONE_SINISTRO: 'gtm-otp-claim',
  RIATTIVAZIONE: 'gtm-otp-reactivation',
}

/**
 * Types
 */
export type outcome_w = 'success' | 'failure'
export type option_w = 'S' | 'N'
export type person_w = 'F' | 'G'
export type gender_w = 'F' | 'M'
export type subject_type_w = 'F' | 'G'
export type language_w = 'IT' | 'DE'

export type company_code_w = '022' | '183' | '178' // 022=MUTUA | 183=VITA | 178=VALPIAVE
export type contact_type_w = 'EMAIL' | 'PHONE'
export type contact_status_w = 0 | 1 | 2 | undefined // 0=TO-VERIFY, 1=VERIFIED, 2=NOT-EXPRESSED
export type consent_status_w = 0 | 1 | undefined // 0=TO-VERIFY, 1=VERIFIED
export type consent_type_w = 'D1' | 'D2' | 'D3' | 'D4' | 'D5'

export type portfolio_w = 'DANNI' | 'VITA' | 'PLURIFONDS'
export type policy_redemption_type_w = 'PARZIALE' | 'TOTALE'
export type policy_operation_status_w = 'CONFIRMED' | 'FORWARDED' | 'TO_CONFIRM'
export type policy_operation_type_w =
  | 'RISCATTO'
  | 'SOSPENSIONE'
  | 'VERSAMENTO_AGGIUNTIVO'
  | 'LIQUIDAZIONE_SINISTRO'
  | 'RIATTIVAZIONE'
  | 'RINNOVO'

export type agency_w = 'AGENZIA' | 'BANCA' | 'BROKER' | 'AGENZIA DI SEDE'

export type document_category_w = 'ITAS_MUTUA' | 'ITAS_VITA' | 'ITAS_VALPIAVE'
export type document_type_w =
  | 'LETTERA_DETRAZIONE'
  | 'ESTRATTO_CONTO'
  | 'COMUNICAZIONE_FINALE'
  | 'AVVISO_SCADENZA'
  | 'ATTESTATO_RISCHIO'
  | 'CGA'
  | 'STAMPA'
  | 'ASSEMBLEA_PARZIALE'
  | 'ASSEMBLEA_GENERALE'
  | 'STATUTO_ITAS_MUTUA'
  | 'INFORMATIVA'
  | 'QUIETANZA'

export type banner_type_w =
  | 'CONTENUTO_EDITORIALE'
  | 'TUTORIAL'
  | 'NOTIFICA_AUTOMATICA_RINNOVO'
  | 'NOTIFICA_AUTOMATICA_RIATTIVAZIONE'
  | 'NOTIFICA_AUTOMATICA_DETRAZIONE'
  | 'NOTIFICA_MANUALE'
  | 'COMMERCIALE_DESCRITTIVO'
  | 'COMMERCIALE_IMMAGINE'
  | 'DOPPIA_COMPAGNIA'

export type parametric_domain_w = 'DOC' | 'PRO' | 'ONB' | 'DET' | 'SOS' | 'REG'
export type parametric_subdomain_w =
  | 'APD'
  | 'SPL'
  | 'FRL'
  | 'FRT'
  | 'ASO'
  | 'CND'
  | 'CNV'
  | 'CNP'
  | 'IET'
  | 'LPR'

export type voucher_access_mode_w = 'download' | 'partner_access'
export type poll_status_w = 'NONE' | 'IN_PROGRESS' | 'COMPLETED' | 'FINISHED'
export type registration_mode_t = 'DATAMATRIX' | 'MANUALE'
