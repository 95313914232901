import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { NAV_TO_HOMEPAGE } from 'src/navigation'
import { shoot } from 'src/redux/actions'
import {
  select_error,
  select_outcome_payment,
  select_outcome_payment_status,
  select_policies,
  select_policies_renewed,
} from 'src/redux/store'
import { dict, policy_t } from 'src/types'
import { Loader } from 'src/widgets'

const PaymentOutcome: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // const payment_id = new URLSearchParams(useLocation().search).get('productId')
  // const transaction_id = new URLSearchParams(useLocation().search).get('token')
  const status = new URLSearchParams(useLocation().search).get('status')

  /**
   * Use Selector
   */
  const error = useSelector(select_error())
  const status_payment_outcome = useSelector(select_outcome_payment_status)
  const policies = useSelector(select_policies)
  const policies_renewed = useSelector(select_policies_renewed)
  const payment_outcome = useSelector(select_outcome_payment)

  /**
   * Use State
   */
  const [awake, set_awake] = useState(false)
  const [policy, set_policy] = useState<policy_t>()
  const [awake_rinn, set_awake_rinn] = useState(false)

  /**
   * Use Effect
   */
  useEffect(() => {
    if (status === 'success') {
      dispatch(shoot.reduce_outcome_payment_status())
      dispatch(shoot.reduce_history_item('expanded-payment', 'success'))
      goto_policy_detail()
    } else if (status === 'failure') {
      dispatch(shoot.saga_check_payment({ payment_id: payment_outcome?.paymentID }))
    }
  }, [])

  useEffect(() => {
    if (status_payment_outcome?.outcome === 'success') {
      if (
        policy?.policyOrderNumber === status_payment_outcome?.policy?.policyOrderNumber &&
        policy?.policyMovNumberNextTitle ===
          status_payment_outcome?.policy?.policyMovNumberNextTitle
      ) {
        //aggiorno lo storage
        if (policy) {
          set_awake_rinn(true)
          set_awake(false)
          policy.flgRinn = 0
          dispatch(
            shoot.reduce_policies(
              policies?.map((p) => (p?.policyNumber === policy?.policyNumber ? policy : p))
            )
          )
        }
      }
    } else if (status_payment_outcome?.outcome === 'processing') {
      dispatch(shoot.reduce_outcome_payment_status())
      dispatch(shoot.reduce_history_item('expanded-payment', 'processing'))
      save_payment_status()
      dispatch(shoot.reduce_history_item('to-policy-detail', payment_outcome?.policyNumber))
      dispatch(shoot.reduce_outcome_payment())
      history.push(NAV_TO_HOMEPAGE)
    } else if (status_payment_outcome?.outcome === 'failure') {
      dispatch(shoot.reduce_history_item('expanded-payment', 'failure'))
      dispatch(shoot.reduce_history_item('to-policy-detail', payment_outcome?.policyNumber))
      setTimeout(() => {
        history.push(NAV_TO_HOMEPAGE)
      }, 1000)
    }
  }, [status_payment_outcome])

  useEffect(() => {
    if (error) {
      dispatch(shoot.reduce_history_item('expanded-payment', 'failure'))
      goto_policy_detail()
    }
  }, [error])

  useEffect(() => {
    if (policies && awake) {
      const _policy = policies?.find((p) => p?.policyNumber === payment_outcome?.policyNumber)
      if (_policy?.flgRinn === 1) {
        set_policy(_policy)
        dispatch(shoot.saga_check_payment({ payment_id: payment_outcome?.paymentID }))
      } else {
        dispatch(shoot.reduce_history_item('to-policy-detail', payment_outcome?.policyNumber))
        dispatch(shoot.reduce_outcome_payment())
        setTimeout(() => {
          history.push(NAV_TO_HOMEPAGE)
        }, 1000)
      }
    }
  }, [awake, policies])

  useEffect(() => {
    if (policies && awake_rinn) {
      dispatch(shoot.reduce_outcome_payment_status())
      dispatch(shoot.reduce_history_item('to-policy-detail', payment_outcome?.policyNumber))
      dispatch(shoot.reduce_outcome_payment())
      setTimeout(() => {
        history.push(NAV_TO_HOMEPAGE)
      }, 1000)
    }
  }, [awake_rinn, policies])

  /**
   * Support functions
   */
  const goto_policy_detail = () => {
    dispatch(shoot.reduce_policies())
    dispatch(shoot.reduce_policy_detail())
    setTimeout(() => {
      dispatch(shoot.saga_resolve_policies())
      set_awake(true)
    }, 10000)
  }

  const save_payment_status = (): void => {
    if (payment_outcome?.policyNumber) {
      if (!policies_renewed) {
        const policies_renewed_: dict<string> = {}
        policies_renewed_[payment_outcome?.policyNumber] = 'processing'
        dispatch(shoot.reduce_policies_renewed(policies_renewed_))
      } else {
        if (!policies_renewed[payment_outcome?.policyNumber]) {
          policies_renewed[payment_outcome?.policyNumber] = 'processing'
          dispatch(shoot.reduce_policies_renewed(policies_renewed))
        }
      }
    }
  }

  return <Loader />
}

export default PaymentOutcome
