import { createReducer } from 'reduxsauce'
import { oauth_store } from 'src/types'

import { oauth_types } from '../../actions'
import INITIAL_STATE from './initial-state'

export const reduce_logged = (state: oauth_store, { logged }: oauth_store): oauth_store => ({
  ...state,
  logged,
})

export const reduce_login_token = (
  state: oauth_store,
  { login_token }: oauth_store
): oauth_store => ({
  ...state,
  login_token,
})

export const reduce_already_logged = (
  state: oauth_store,
  { already_logged }: oauth_store
): oauth_store => ({
  ...state,
  already_logged,
})

export const reduce_check_token = (
  state: oauth_store,
  { check_token }: oauth_store
): oauth_store => ({
  ...state,
  check_token,
})

export const reduce_sso_otp = (state: oauth_store, { sso_otp }: oauth_store): oauth_store => ({
  ...state,
  sso_otp,
})

export default createReducer(INITIAL_STATE, {
  [oauth_types.REDUCE_LOGGED]: reduce_logged,
  [oauth_types.REDUCE_LOGIN_TOKEN]: reduce_login_token,
  [oauth_types.REDUCE_ALREADY_LOGGED]: reduce_already_logged,
  [oauth_types.REDUCE_CHECK_TOKEN]: reduce_check_token,
  [oauth_types.REDUCE_SSO_OTP]: reduce_sso_otp,
})
