import { FC, useEffect } from 'react'
import { Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { shoot } from 'src/redux/actions'
import { select_cookie_informatives } from 'src/redux/store'

const CookiePolicy: FC = () => {
  const dispatch = useDispatch()

  /**
   * Use Selector
   */
  const cookie_informatives = useSelector(select_cookie_informatives)

  /**
   * Use Effect
   */
  useEffect(() => {
    if (!cookie_informatives) {
      setTimeout(() => {
        dispatch(
          shoot.saga_resolve_communications_registration({ domain: 'CKP', subdomain: 'INF' })
        )
      })
    }
  }, [])

  return (
    <Container fluid className="d-flex justify-content-center">
      <Col lg={11} className="mt-5">
        {cookie_informatives?.[0] && (
          <div
            dangerouslySetInnerHTML={{
              __html: cookie_informatives[0]?.content || '',
            }}
          />
        )}
        {/* OneTrust Cookies List start  */}
        <div id="ot-sdk-cookie-policy" />
        {/* OneTrust Cookies List end  */}

        {/* OneTrust Cookies Settings button start */}
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button>
        {/* OneTrust Cookies Settings button end */}

        {cookie_informatives?.[1] && (
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{
              __html: cookie_informatives[1]?.content || '',
            }}
          />
        )}
      </Col>
    </Container>
  )
}

export default CookiePolicy
