import { createReducer } from 'reduxsauce'
import { cms_types } from 'src/redux/actions/cms.actions'
import { cms_store } from 'src/types'

export const reduce_privacies = (state: cms_store, { privacies }: cms_store): cms_store => ({
  ...state,
  privacies,
})

export const reduce_banners = (state: cms_store, { banners }: cms_store): cms_store => ({
  ...state,
  banners,
})

export const reduce_faqs = (state: cms_store, { faqs }: cms_store): cms_store => ({
  ...state,
  faqs,
})

export const reduce_footer = (state: cms_store, { footer }: cms_store): cms_store => ({
  ...state,
  footer,
})

export const reduce_onboarding_active = (
  state: cms_store,
  { onboarding_active }: cms_store
): cms_store => ({
  ...state,
  onboarding_active,
})

export const reduce_notes = (state: cms_store, { notes }: cms_store): cms_store => ({
  ...state,
  notes,
})

export const reduce_privacy_link_registration = (
  state: cms_store,
  { privacy_link_registration }: cms_store
): cms_store => ({
  ...state,
  privacy_link_registration,
})

export const reduce_privacy_link_profile = (
  state: cms_store,
  { privacy_link_profile }: cms_store
): cms_store => ({
  ...state,
  privacy_link_profile,
})

export const reduce_digital_signature_link = (
  state: cms_store,
  { digital_signature_link }: cms_store
): cms_store => ({
  ...state,
  digital_signature_link,
})

export const reduce_digital_signature_text = (
  state: cms_store,
  { digital_signature_text }: cms_store
): cms_store => ({
  ...state,
  digital_signature_text,
})

export const reduce_reactivation_products = (
  state: cms_store,
  { reactivation_products }: cms_store
): cms_store => ({
  ...state,
  reactivation_products,
})

export const reduce_maintenance_active = (
  state: cms_store,
  { maintenance_active }: cms_store
): cms_store => ({
  ...state,
  maintenance_active,
})

export const reduce_cookie_informatives = (
  state: cms_store,
  { cookie_informatives }: cms_store
): cms_store => ({
  ...state,
  cookie_informatives,
})

export default createReducer(
  {},
  {
    [cms_types.REDUCE_PRIVACIES]: reduce_privacies,
    [cms_types.REDUCE_BANNERS]: reduce_banners,
    [cms_types.REDUCE_FAQS]: reduce_faqs,
    [cms_types.REDUCE_FOOTER]: reduce_footer,
    [cms_types.REDUCE_MAINTENANCE_ACTIVE]: reduce_maintenance_active,
    [cms_types.REDUCE_ONBOARDING_ACTIVE]: reduce_onboarding_active,
    [cms_types.REDUCE_NOTES]: reduce_notes,
    [cms_types.REDUCE_PRIVACY_LINK_REGISTRATION]: reduce_privacy_link_registration,
    [cms_types.REDUCE_PRIVACY_LINK_PROFILE]: reduce_privacy_link_profile,
    [cms_types.REDUCE_DIGITAL_SIGNATURE_LINK]: reduce_digital_signature_link,
    [cms_types.REDUCE_DIGITAL_SIGNATURE_TEXT]: reduce_digital_signature_text,
    [cms_types.REDUCE_REACTIVATION_PRODUCTS]: reduce_reactivation_products,
    [cms_types.REDUCE_COOKIE_INFORMATIVES]: reduce_cookie_informatives,
  }
)
