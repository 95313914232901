import i18n from 'src/i18n'

export const MONTHS: string[] = [
  i18n.t('months.january'),
  i18n.t('months.february'),
  i18n.t('months.march'),
  i18n.t('months.april'),
  i18n.t('months.may'),
  i18n.t('months.june'),
  i18n.t('months.july'),
  i18n.t('months.august'),
  i18n.t('months.september'),
  i18n.t('months.october'),
  i18n.t('months.november'),
  i18n.t('months.december'),
]

export const DAYS: string[] = [
  i18n.t('days_abbreviation.mon'),
  i18n.t('days_abbreviation.tue'),
  i18n.t('days_abbreviation.wed'),
  i18n.t('days_abbreviation.thu'),
  i18n.t('days_abbreviation.fri'),
  i18n.t('days_abbreviation.sat'),
  i18n.t('days_abbreviation.sun'),
]
