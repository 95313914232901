import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  /**
   * Store
   */
  reduce_customer: ['customer'],
  reduce_contacts: ['contacts'],
  reduce_consents: ['consents'],
  reduce_language: ['language'],
  reduce_nations: ['nations'],
  reduce_districts: ['districts'],
  reduce_municipalities: ['municipalities'],
  reduce_localities: ['localities'],
  reduce_caps: ['caps'],
  reduce_toponyms: ['toponyms'],
  reduce_addresses: ['addresses'],
  reduce_associated_customers: ['associated_customers'],
  reduce_history_addresses: ['history_addresses'],
  reduce_history_requests: ['history_requests'],
  reduce_policies: ['policies'],
  reduce_policy_detail: ['policy_detail'],
  reduce_documents: ['documents'],
  reduce_documents_errors: ['documents_errors'],
  reduce_documents_policy: ['documents_policy'],
  reduce_documents_institutional: ['documents_institutional'],
  reduce_document: ['document'],
  reduce_agencies: ['agencies'],
  reduce_vouchers: ['vouchers'],
  reduce_voucher_document: ['voucher_document'],
  reduce_policies_assistance: ['policies_assistance'],
  reduce_poll: ['poll'],
  reduce_dati_contraente: ['dati_contraente'],
  reduce_decoded_datamatrix: ['decoded_datamatrix'],
  /**
   * Sagas
   */
  saga_resolve_customer: [],
  saga_resolve_contacts: ['args'],
  saga_resolve_consents: [],
  saga_resolve_language: [],
  saga_resolve_nations: ['args'],
  saga_resolve_districts: ['args'],
  saga_resolve_municipalities: ['args'],
  saga_resolve_localities: ['args'],
  saga_resolve_caps: ['args'],
  saga_resolve_toponyms: [],
  saga_resolve_addresses: ['args'],
  saga_resolve_associated_customers: ['args'],
  saga_resolve_history_addresses: ['args'],
  saga_resolve_policies: ['args'],
  saga_resolve_policy_detail: ['args'],
  saga_resolve_documents: ['args'],
  saga_resolve_documents_policy: ['args'],
  saga_resolve_documents_institutional: ['args'],
  saga_download_document: ['args'],
  saga_resolve_history_requests: ['args'],
  saga_resolve_agencies: ['args'],
  saga_resolve_vouchers: [],
  saga_download_voucher: ['args'],
  saga_resolve_policies_assistance: ['args'],
  saga_resolve_poll: ['args'],
  saga_retrieve_dati_contraente_from_num_polizza: ['args'],
  saga_retrieve_dati_contraente_from_num_polizza_vita: ['args'],
  saga_retrieve_dati_contraente_from_num_ordine: ['args'],
  saga_post_decode_datamatrix: ['args'],
})

export const data_types = Types
export const data_creators = Creators
